.rw__button {
    @extend .rounded-4;
    @extend .fw-400;
    box-shadow: none !important;
    font-size: rem(16);
    height: rem(42);
    min-width: rem(96);
    padding: 0 rem(20);
    text-decoration: none;
    &-xss {
        font-size: rem(12) !important;
        height: rem(24) !important;
        min-width: 0 !important;
        padding: 0 !important;
        i {
            font-size: rem(12) !important;
        }
        &.rw__button-icon {
            min-width: auto !important;
            width: rem(24) !important;
            &-fluid {
                width: 100% !important;
            }
        }
        .p-splitbutton-defaultbutton {
            font-size: rem(12) !important;
            padding: 0 0 0 rem(6) !important;
        }
        .p-splitbutton-menubutton {
            padding: 0 rem(6) 0 rem(10) !important;
            width: auto !important;
            .pi {
                font-size: rem(12) !important;
            }
        }
    }
    &-xs {
        font-size: rem(14) !important;
        height: rem(32) !important;
        min-width: rem(80) !important;
        padding: 0 rem(12) !important;

        i,
        .p-button-icon {
            font-size: rem(14) !important;
        }

        &.rw__button-icon {
            min-width: auto !important;
            width: rem(32) !important;
            &-fluid {
                width: 100% !important;
            }
        }
        .p-splitbutton-defaultbutton {
            font-size: rem(14) !important;
            padding-left: rem(12) !important;
            span.p-button-icon {
                font-size: rem(14);
            }
        }
        .p-splitbutton-menubutton {
            padding: rem(8) !important;
            width: auto !important;
            .pi {
                font-size: rem(12) !important;
            }
        }
    }

    &-sm {
        font-size: rem(14) !important;
        height: rem(36) !important;
        min-width: rem(96) !important;
        padding: 0 rem(15) !important;

        i {
            font-size: rem(16) !important;
        }

        &.rw__button-icon {
            min-width: auto !important;
            width: rem(36) !important;
            &-fluid {
                width: 100% !important;
            }
        }
        .p-splitbutton-defaultbutton {
            font-size: rem(14) !important;
            padding-left: rem(15) !important;
            padding-right: 0 !important;
        }
        .p-splitbutton-menubutton {
            padding-right: rem(15) !important;
            .pi {
                font-size: rem(14) !important;
            }
        }
    }
    &-md{
        height: rem(42) !important;
        min-width: rem(96);
        padding: 0 rem(15);
        @include mq('md','max'){
            height: 100% !important;
            min-height: rem(42);
        }
    }
    &-lg {
        height: rem(48) !important;
        min-width: rem(112) !important;
        padding: 0 rem(25) !important;

        &.rw__button-icon {
            min-width: auto !important;
            width: rem(48) !important;
            &-fluid {
                width: 100% !important;
            }
        }
        .p-splitbutton-defaultbutton {
            padding-left: rem(25) !important;
        }
        .p-splitbutton-menubutton {
            padding-right: rem(25) !important;
        }
    }
    &-icon {
        @extend .d-flex;
        @extend .align-items-center;
        @extend .justify-content-center;
        padding: 0 !important;
        min-width: auto !important;
        width: rem(42);

        .p-button-label {
            display: none;
        }

        .rw__button {
            @extend .d-flex;
            @extend .align-items-center;
            @extend .justify-content-center;
            padding: 0 !important;
            min-width: auto !important;
            width: rem(42);
        }

        i {
            font-size: rem(18);
            margin: 0 !important;
        }
        &-fluid {
            width: 100% !important;
        }
    }

    &-icon-only-resp{
        @include mq('md','max'){
            justify-content: center;
            min-width: rem(32) !important; 
            padding: 0 !important;
            button{
                min-width: rem(32) !important;
            }
            i {
                font-size: rem(12) !important;
                margin-right: 0 !important;
            }
            .p-button-icon{
                margin-inline: 0 !important;
            }
            .p-button-label,.custom-label{
                display: none;
            }
            .p-splitbutton{
                &-defaultbutton{
                    padding-right: rem(12) !important;
                }
            }
        }
    }

    &.p-button-raised {
        &.p-button-white {
            background-color: white !important;
            color: variableValues($teal, "700") !important;

            &:focus,
            &:hover {
                // background-color: #F4F5F5 !important;
            background-color: variableValues($gray, "200") !important;
            }

            &:active {
                background-color: variableValues($teal, "200") !important;
            // background-color: variableValues($gray, "300") !important;
            }
            .p-splitbutton-defaultbutton,
            .p-splitbutton-menubutton {
                background-color: white !important;
                color: variableValues($teal, "700") !important;
            }
        }

        &.p-button-primary {
            background-color: variableValues($teal, "700") !important;

            &:focus,
            &:hover {
                background-color: variableValues($teal, "600") !important;
            }

            &:active {
                background-color: variableValues($teal, "900") !important;
            }
            .p-splitbutton-defaultbutton,
            .p-splitbutton-menubutton {
                background-color: variableValues($teal, "700") !important;
                &:disabled {
                    background-color: variableValues($gray, "20") !important;
                }
            }
        }

        &.p-button-primary-light {
            background-color: variableValues($teal, "200") !important;
            color: variableValues($teal, "700") !important;
            border: 1px solid transparent;

            &:focus,
            &:hover {   
                border-color: variableValues($teal, "700") !important
            }

            &:active {
                background-color: #DAF5F5 !important;
                border-color: variableValues($teal, "700") !important
            }
            .p-splitbutton-defaultbutton,
            .p-splitbutton-menubutton {
                background-color: variableValues($teal, "200") !important;
                color: variableValues($teal, "700") !important;
            }
            &:disabled{
                background-color: variableValues($gray, "300") !important;
                img{
                    filter: grayscale(1);
                }
            }
        }

        &.p-button-danger {
            background-color: variableValues($red, "700") !important;

            &:focus,
            &:hover {
                background-color: variableValues($red, "600") !important;
            }

            &:active {
                background-color: variableValues($red, "800") !important;
            }
            .p-splitbutton-defaultbutton,
            .p-splitbutton-menubutton {
                background-color: variableValues($red, "700") !important;
            }
        }

        // &.p-button-danger-light {
        //     background-color: variableValues($red, "9") !important;
        //     color: variableValues($red, "60") !important;

        //     &:focus,
        //     &:hover {
        //         background-color: variableValues($red, "10") !important;
        //     }

        //     &:active {
        //         background-color: variableValues($red, "20") !important;
        //     }
        //     .p-splitbutton-defaultbutton,
        //     .p-splitbutton-menubutton {
        //         background-color: variableValues($red, "9") !important;
        //         color: variableValues($red, "60") !important;
        //     }
        // }

        &.p-button-purple {
            background-color: white !important;
            color: variableValues($purple, "700") !important;

            &:focus,
            &:hover {
                background-color: #F4F5F5 !important;
            }

            &:active {
                background-color: #E3E8E8 !important;
            }
            .p-splitbutton-defaultbutton,
            .p-splitbutton-menubutton {
                background-color: white !important;
                color: variableValues($purple, "700") !important;
            }
        }

        &:disabled,
        &.rw__button-loading {
            background-color: variableValues($gray, "200") !important;
            color: variableValues($gray, "500") !important;
            pointer-events: none;
        }
    }

    &.p-button-outlined {
        &.p-button-white {
            border-color: white !important;
            color: white !important;

            &:focus,
            &:hover {
                background-color: variableValues($gray, "100") !important;
                color: variableValues($teal, "700") !important;
            }

            &:active {
                background-color: variableValues($teal, "200") !important;
                color: variableValues($teal, "700") !important;
            }
            .p-splitbutton-defaultbutton,
            .p-splitbutton-menubutton {
                border-color: white !important;
                color: white !important;
            }
        }

        &.p-button-primary {
            border-color: variableValues($teal, "700") !important;
            color: variableValues($teal, "700") !important;
           
            &:focus,
            &:hover {
                background-color: variableValues($gray, "200") !important;
            }

            &:active {
                background-color: variableValues($gray, "300") !important;
            }
            .p-splitbutton-defaultbutton,
            .p-splitbutton-menubutton {
                border-color: variableValues($teal, "700") !important;
                color: variableValues($teal, "700") !important;
            }
        }

        &.p-button-primary-fill-light {
            background-color: variableValues($teal, "9") !important;
            border-color: variableValues($teal, "700") !important;

            &:focus,
            &:hover {
                background-color: variableValues($gray, "9") !important;
            }

            &:active {
                background-color: variableValues($teal, "100") !important;
            }
            .p-splitbutton-defaultbutton,
            .p-splitbutton-menubutton {
                background-color: variableValues($teal, "9") !important;
                border-color: variableValues($teal, "700") !important;
            }
            .p-splitbutton-defaultbutton,
            .p-splitbutton-menubutton {
                border-color: variableValues($teal, "700") !important;
            }
        }

        &.p-button-danger {
            border-color: variableValues($red, "700") !important;
            color: variableValues($red, "700") !important;

            &:focus,
            &:hover {
                background-color: variableValues($red, "100") !important;
            }

            &:active {
                background-color: variableValues($red, "200") !important;
            }
            .p-splitbutton-defaultbutton,
            .p-splitbutton-menubutton {
                border-color: variableValues($red, "700") !important;
                color: variableValues($red, "700") !important;
            }
        }

        &:disabled,
        &.rw__button-loading,
        .p-splitbutton-defaultbutton:disabled,
        .p-splitbutton-menubutton:disabled {
            border-color: variableValues($gray, "300") !important;
            color: variableValues($gray, "500") !important;
            pointer-events: none;
        }
    }

    &.p-button-text {
        &.p-button-white {
            color: white !important;

            &:focus,
            &:hover {
                background-color: #F4F5F5 !important;
                color: variableValues($gray, "60") !important;
            }

            &:active {
                background-color: variableValues($gray, "200") !important;
                color: variableValues($gray, "60") !important;
            }
            .p-splitbutton-defaultbutton,
            .p-splitbutton-menubutton {
                color: white !important;
            }
        }

        &.p-button-primary {
            color: variableValues($teal, "700") !important;

            &:focus,
            &:hover {
                background-color: variableValues($gray, "200") !important;
            }

            &:active {
                background-color: variableValues($teal, "300") !important;
            }
            .p-splitbutton-defaultbutton,
            .p-splitbutton-menubutton {
                color: variableValues($teal, "700") !important;
            }
        }

        &.p-button-danger {
            color: variableValues($red, "700") !important;

            &:focus,
            &:hover {
                background-color: variableValues($red, "100") !important;
            }

            &:active {
                background-color: variableValues($red, "200") !important;
            }
            .p-splitbutton-defaultbutton,
            .p-splitbutton-menubutton {
                color: variableValues($red, "700") !important;
            }
        }

        &.p-button-plain {
            color: variableValues($gray, "700") !important;

            &:focus,
            &:hover {
                background-color: variableValues($gray, "200") !important;
            }

            &:active {
                background-color: variableValues($gray, "300") !important;
            }
            .p-splitbutton-defaultbutton,
            .p-splitbutton-menubutton {
                color: variableValues($gray, "700") !important;
            }
        }

        &:disabled,
        &.rw__button-loading {
            color: variableValues($gray, "500") !important;
            pointer-events: none;
        }
        &.rw__tooltip-button {
            &[disabled], &:disabled{
                pointer-events: auto !important;
            &:hover{
                background-color: transparent !important;
            }
            }
        }
    }

    i {
        font-size: rem(18);
        margin-right: rem(10);
    }

    &.p-splitbutton {
        padding: 0 !important;
        .p-splitbutton-defaultbutton {
            background-color: transparent !important;
            padding-left: rem(20);
            font-size: rem(16);
            @extend .fw-400;
        }

        .p-splitbutton-menubutton {
            background-color: transparent !important;
            padding-right: rem(20);
            .p-button-label {
                display: none;
            }
            & + p-tieredmenu {
                .p-tieredmenu {
                    width: 100%;
                }
            }
        }
    }

    &-hide-dropdown.p-splitbutton {
        .p-splitbutton-defaultbutton {
            border-right: rem(1) solid !important;
            border-top-right-radius: rem(4) !important;
            border-bottom-right-radius: rem(4) !important;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .p-splitbutton-menubutton {
            display: none !important;
        }
    }

    &.disabled {
        opacity: 1 !important;
    }

    &-tooltip {
        @extend .border-0;
        @extend .rw__p-0;
        background-color: transparent;

        &:hover {
            opacity: 1 !important;
        }
    }

    &-hide-button-label,
    &-hide-default-label {
        .p-button-label {
            display: none;
        }
    }

    .p-badge {
        font-size: rem(12);
        font-weight: normal;
        height: rem(20);
        min-width: rem(20);
        line-height: rem(20);
    }
    
    &-select {
        .p-button {
            font-size: rem(16);
            font-weight: 400;
            height: rem(42);
            padding-left: rem(25);
            padding-right: rem(25);
            &.p-highlight,
            &.p-highlight:hover,
            &.p-highlight:focus {
                background-color: variableValues($teal, "60");
                color: white;
            }
        }
        &-lg {
            .p-button {
                height: rem(48);
            }            
        }
    }
}

.rtl {
    .rw__button {
        @extend .fw-500;
        i {
            margin-left: rem(10);
            margin-right: 0 !important;
        }

        .p-button-icon {
            &-left {
                margin-left: rem(8);
                margin-right: 0;
            }
        }
        &.p-button-icon-only .p-button-icon {
            margin: 0 !important;
        }

        &.p-splitbutton {
            .p-splitbutton-defaultbutton {
                border-radius: 0 rem(4) rem(4) 0 !important;
                padding-right: rem(20);
                padding-left: rem(12);
            }
    
            .p-splitbutton-menubutton {
                border-radius: rem(4) 0 0 rem(4) !important;
                padding-right: rem(12);
                padding-left: rem(20);
            }
        }

        &.p-button-outlined {
            &.p-button-white {
                .p-splitbutton-defaultbutton {
                    border-right: rem(1) solid white !important;
                }
                .p-splitbutton-menubutton {
                    border-left: rem(1) solid white !important;
                }
            }
    
            &.p-button-primary,
            &.p-button-primary-fill-light {
                .p-splitbutton-defaultbutton {
                    border-right: rem(1) solid variableValues($teal, "700") !important;
                }
                .p-splitbutton-menubutton {
                    border-left: rem(1) solid variableValues($teal, "700") !important;
                }
            }
    
            &.p-button-danger {
                .p-splitbutton-defaultbutton {
                    border-right: rem(1) solid variableValues($red, "700") !important;
                    border-left: 0 !important;
                }
                .p-splitbutton-menubutton {
                    border-left: rem(1) solid variableValues($red, "700") !important;
                    border-right: 0 !important;
                }
            }
            
            .p-splitbutton-defaultbutton {
                border-left: 0 !important;
            }
            .p-splitbutton-menubutton {
                border-right: 0 !important;
            }

            .p-splitbutton-defaultbutton:disabled,
            .p-splitbutton-menubutton:disabled {
                border-color: variableValues($gray, "20") !important;
            }
        }
    }
}

.rw__radio-button{
    &-inline-wrap{
        @extend .d-flex;
        @extend .flex-wrap;
        @extend .align-items-center;
        p-radiobutton{
            &:not(:last-child){
                @extend .rw__me-8;
            }
        }
    }
    &-block-wrap{
        @extend .d-flex;
        @extend .flex-wrap;
        @extend .flex-column;
        p-radiobutton{
            &:not(:last-child){
                margin-bottom: rem(18);
            }
        }
    }
    .p-radiobutton {
        width: 24px;
        height: 24px;
        @extend .rounded-circle;
        &:not(.p-radiobutton-disabled):not(.p-radiobutton-focused):hover {
            box-shadow: none;
        }
        .p-radiobutton-box {
            width: 100%;
            height: 100%;
            @extend .rounded-circle;
            border: 2px solid variableValues($gray, "60");
            background: transparent;

            &:not(.p-disabled):not(.p-highlight):hover {
                border-color: variableValues($teal, "60");
            }

            .p-radiobutton-icon {
                width: 16px;
                height: 16px;
                transition: transition(0.2s);
                background-color: variableValues($teal, "60");
            }
            &.p-highlight {
                border-color: variableValues($teal, "60");
                background: transparent;

                &:not(.p-disabled):hover {
                    border-color: transparent;
                    background: transparent;
                }
            }
        }
        &-checked{
            &:not(.p-radiobutton-disabled):hover {
                box-shadow: 0 0 1px 10px rgba(variableValues($teal, "60"), .12);
            }
        }
        &-label{
            @extend .rw__fs-9;
            @extend .fw-400;
            line-height: rem(20);
            margin-bottom: 0;
            color: variableValues($gray, "60");
            &-p-disabled{
                color: variableValues($gray, "50");
            }
        }
        &-disabled{
            .p-radiobutton-box {
                border-color: variableValues($gray, "50");
            }
        }
    }

}
.rw__custom-radio-button{
    &-inline-wrap{
        @extend .d-flex;
        @extend .flex-wrap;
        @extend .align-items-center;
        p-radiobutton{
            &:not(:last-child){
                @extend .rw__me-8;
            }
        }
    }
    &-block-wrap{
        @extend .d-flex;
        @extend .flex-wrap;
        @extend .flex-column;
        p-radiobutton{
            &:not(:last-child){
                margin-bottom: rem(18);
            }
        }
    }        
    .p-radiobutton {
        width: 20px;
        height: 20px;
        @extend .rounded-circle;
        &:not(.p-radiobutton-disabled):not(.p-radiobutton-focused):hover {
            box-shadow: none;
        }
        .p-radiobutton-box {
            border-width: 0 !important;
            border-radius: 50% !important;
            background: transparent;
            &:before{
                content: '';
                background: white;
                border-radius: 100%;
                border: 2px solid;
                border-color: variableValues($gray, "60");
                display: block;
                width: 20px;
                height: 20px;
                position: relative;
                vertical-align: top;
                cursor: pointer;
                text-align: center;
            }

            .p-radiobutton-icon {
                display: none;
            }
            &.p-highlight {
                &:before{
                    background-color: variableValues($teal, "60");
                    border-color: variableValues($teal, "60");
                    box-shadow: inset 0 0 0 3px #fff;
                }
                &:not(.p-disabled):hover {
                    box-shadow: 0 0 1px 10px rgba(variableValues($teal, "60"), .12);
                }
            }
            &:not(.p-disabled):not(.p-highlight):hover {
                box-shadow: none;
            }
        }
        &:not(.p-radiobutton-disabled):not(.p-radiobutton-focused):hover {
            .p-radiobutton-box {
                &:before{
                    border-color: variableValues($teal, "60");
                }
            }
        }
        &-checked{
            &:not(.p-radiobutton-disabled):hover {
                box-shadow: none;
            }
        }
        &-label{
            @extend .rw__fs-9;
            @extend .fw-400;
            line-height: rem(20);
            margin-bottom: 0;
            color: variableValues($gray, "60");
            &-p-disabled{
                color: variableValues($gray, "50");
            }
        }
        &-disabled, &:disabled{
            .p-radiobutton-box {
                &:before{
                    content: '';
                    border-color: variableValues($gray, "50");
                }
                &.p-disabled{
                    opacity: 0.8;
                }
            }
        }
    }
}
.p-field-checkbox {
    display: flex;
    align-items: center;
}

//TO-DO: remove variables when _variable.scss is updated
$teal-2: #D5ECEC;
$teal-6: #00A5A3;
$teal-7: #00807D;
$teal-9: #094040;
$gray-1: #F4F6F6;
$gray-2: #E3E8E8;
$gray-5: #AFB6B6;
$gray-6: #8D9696;
$gray-7: #5C7070;
$gray-9: #0D2222;
.rw__checkbox {
    .p-checkbox {
        display: block;
        width: 16px;
        height: 16px;
        &:hover{
            box-shadow: none; 
        }
        .p-checkbox-box {
            border: 2px solid $gray-7;
            background: transparent;
            width: 100%;
            height: 100%;
            border-radius: 3px;

            &.p-highlight {
                border-color: $teal-7;
                background: $teal-7;
                &.p-disabled{
                    background: $gray-5;
                }
            }
            &.p-disabled{
                border-color: $gray-5;
                opacity: 1;
            }
            .p-checkbox-icon{
                &.pi-check {
                    // -webkit-mask-image: url(../../assets/icons/check-small.svg);
                    // mask-image: url(../../assets/icons/check-small.svg);
                    &:before{
                        content: "\f00c";
                        font-family: 'Font Awesome 6 Pro';
                        position: absolute;
                        top: 50% !important;
                        left: 50%;
                        border-right: none;
                        border-bottom: none;
                        transform: translate(-50%,-50%);
                        transform-origin: 0 100%;
                        animation: unset;
                        font-size: rem(12);
                    }
                }
            }
        }

        &:not(.p-checkbox-disabled) {
            .p-checkbox-box {
                //TO-DO: remove commented css when checkbox design is confirmed!
                &:hover {
                    // border-color: variableValues($teal, "60");
                }
                //TO-DO: remove commented css when checkbox design is confirmed!
                &.p-highlight:hover {
                    // border-color: variableValues($teal, "60");
                    // background: variableValues($teal, "60");
                }
            }
        }
    }
    &-md{
        .p-checkbox {
            width: 16px;
            height: 16px;
        }
        &+label {
            @extend .rw__fs-9;
            line-height: rem(20);
        }
    }
    &-sm{
        .p-checkbox {
            width: 13px;
            height: 13px;
        }
        &+label {
            @extend .rw__fs-10;
            line-height: rem(16);
        }
    }

    &+label {
        @extend .rw__fs-9;
        line-height: rem(20);
        @extend .fw-400;
        color: $gray-9;
        @extend .rw__ms-4;
    }

}
.rtl{
    .rw__checkbox {
        .p-checkbox {
            .p-checkbox-box {
                .p-checkbox-icon{
                    &.pi-check {
                        &:before{
                            top: 50% !important;
                            right: auto;
                        }
                    }
                }
            }
        }
    }
}
.p-field-switch{
    display: flex;
    align-items: center;
}
.rw__switch{
    .p-inputswitch{
        height: 20px;
        width: 32px;
        display: flex;
        &-slider{
            background: variableValues($gray, "60");
            border-radius: rem(12);
            &:before{
                width: rem(14);
                height: rem(14);
                margin-top: -0.44rem;
            }
        }
        &-checked{
            .p-inputswitch-slider{
                background: variableValues($teal, "60");
                &:before{
                    background-color: #fff;
                }
            }
        }
    }
    &+label{
        @extend .fw-400;
        @extend .rw__fs-9;
    }
}
.ltr{
    .rw__switch{
        .p-inputswitch{
            &-slider{
                &:before{
                    left: 3px;
                }
            }
            &-checked{
                .p-inputswitch-slider{
                    &:before{
                        transform: translateX(.8rem);
                    }
                }
            }
        }
    }
}
.rtl{
    .rw__switch{
        .p-inputswitch{
            &-slider{
                &:before{
                    right: 3px;
                }
            }
            &-checked{
                .p-inputswitch-slider{
                    &:before{
                        transform: translateX(-.7rem);
                    }
                }
            }
        }
    }
}