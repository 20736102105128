$clearButtonBg: #fff;
$clearButtonBg: #fff;
$clearButtonTextColor: #212121;
$clearButtonBorder: 0 none;
$clearButtonHoverBg: rgba(92, 100, 96, 0.04);
$clearButtonTextHoverColor: #5c6460;

.p-datatable .p-paginator-bottom {
   justify-content: right;
}
.p-multiselect {
   width: 100%;
}

.required-asterisk {
   color: red;
   font-weight: bold;
   margin-left: 3px;
}

.required:after {
   content: ' *';
   color: red;
}
.p-dropdown {
   display: flex !important;
}
.pi-reports {
   background: url('/assets/icons/Reports-Icon.svg');
   width: 1.2rem;
   height: 1.2rem;
   background-position: center;
   background-repeat: no-repeat;
   background-size: contain;
}
.pi-dashboard {
   background: url('/assets/icons/dashboard.svg');
   width: 1.2rem;
   height: 1.2rem;
   background-position: center;
   background-repeat: no-repeat;
   background-size: contain;
}
.pi-inventory {
   background: url('/assets/icons/inventory.svg');
   width: 1.2rem;
   height: 1.2rem;
   background-position: center;
   background-repeat: no-repeat;
   background-size: contain;
}

.pi-order-management {
   background: url('/assets/icons/order-management.svg');
   width: 1.2rem;
   height: 1.2rem;
   background-position: center;
   background-repeat: no-repeat;
   background-size: contain;
}

.pi-stock-control {
   background: url('/assets/icons/stock-control.svg');
   width: 1.2rem;
   height: 1.2rem;
   background-position: center;
   background-repeat: no-repeat;
   background-size: contain;
}
.pi-applications {
   background: url('/assets/icons/applications.svg');
   width: 1.2rem;
   height: 1.2rem;
   background-position: center;
   background-repeat: no-repeat;
   background-size: contain;
}
.pi-setting {
   background: url('/assets/icons/Setting icon.svg');
   width: 1.2rem;
   height: 1.2rem;
   background-position: center;
   background-repeat: no-repeat;
   background-size: contain;
}
.pi-cashier-machine {
   background: url('/assets/icons/cashier-machine.svg');
   width: 1.2rem;
   height: 1.2rem;
   background-position: center;
   background-repeat: no-repeat;
   background-size: contain;
}

.pi-beta-en {
   background-image: url('/assets/icons/EnglishBetaHeader.svg');
   width: 4.5rem;
   height: 1.2rem;
   background-position: center;
   background-repeat: no-repeat;
   background-size: contain;
}
.pi-beta-ar {
   background-image: url('/assets/icons/ArabicBetaHeader.svg');
   width: 4.5rem;
   height: 1.2rem;
   background-position: center;
   background-repeat: no-repeat;
   background-size: contain;
   transform: none !important;
}

//.pi-arrow-right::before {
//   content: '';
//}
//
//.pi-arrow-right {
//   background: url('/assets/icons/arrow-right.svg');
//   width: 1.2rem;
//   height: 1.2rem;
//}

.min-w-fit {
   min-width: fit-content;
}

//for toolbar button icon margin
.p-toolbar-group-right > button > span {
   margin: 3px;
}

.p-button-outlined {
   padding-top: 8px !important;
   padding-bottom: 8px !important;
}

//button clear
.p-button.p-button-clear,
.p-buttonset.p-button-clear > .p-button,
.p-splitbutton.p-button-clear > .p-button {
   color: $clearButtonTextColor;
   background: $clearButtonBg;
   border: $clearButtonBorder;

   &:enabled:hover {
      background: $clearButtonHoverBg;
      color: $clearButtonTextHoverColor;
      border-color: $clearButtonBorder;
   }

   &:enabled:focus {
      box-shadow: $dangerButtonFocusShadow;
   }

   &:enabled:active {
      background: $clearButtonHoverBg;
      color: $clearButtonTextHoverColor;
      border-color: $clearButtonBorder;
   }

   &.p-button-outlined {
      background-color: transparent;
      color: $clearButtonBg;
      border: $outlinedButtonBorder;

      &:enabled:hover {
         background: $clearButtonHoverBg;
         color: $clearButtonTextHoverColor;
         border-color: $clearButtonBorder;
      }

      &:enabled:active {
         background: rgba($clearButtonBg, $textButtonActiveBgOpacity);
         color: $clearButtonBg;
         border: $outlinedButtonBorder;
      }
   }

   &.p-button-text {
      background-color: transparent;
      color: $clearButtonBg;
      border-color: transparent;

      &:enabled:hover {
         background: rgba($clearButtonBg, $textButtonHoverBgOpacity);
         border-color: transparent;
         color: $clearButtonBg;
      }

      &:enabled:active {
         background: rgba($clearButtonBg, $textButtonActiveBgOpacity);
         border-color: transparent;
         color: $clearButtonBg;
      }
   }
}
.p-splitbutton {
   &.p-button-sm {
      @include scaledFontSize($fontSize, $scaleSM);
      @include scaledPadding($buttonPadding, $scaleSM);

      .p-button-icon {
         @include scaledFontSize($primeIconFontSize, $scaleSM);
      }
   }
}

///default table loading
.p-datatable-loading-overlay {
   background-color: transparent;
}
@media only screen and (max-width: 767px) {
   ///toolbar header for mobile
   .p-toolbar {
      flex-direction: column;
      align-items: flex-start !important;
      //display: block;
   }

   .p-toolbar-group-right {
      margin-top: 10px !important;
   }
}

//primng rtl
.rtl {
   direction: RTL;

   .p-paginator .p-dropdown .p-dropdown-label {
      padding: 0.5rem;
   }

   //input group
   .p-inputgroup-addon:first-child,
   .p-inputgroup button:first-child,
   .p-inputgroup input:first-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
   }
   .p-inputgroup-addon:last-child,
   .p-inputgroup button:last-child,
   .p-inputgroup input:last-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
   }
   .pi-chevron-left:before {
      content: '\e901';
   }

   .pi-chevron-right:before {
      content: '\e900';
   }
   .p-splitbutton .p-menu {
      left: auto !important;
      right: 0;
   }
   .p-datepicker .p-datepicker-header .p-datepicker-title {
      margin: 0 0 0 auto;
   }
   .p-checkbox .p-checkbox-box.p-highlight .p-checkbox-icon.pi-check:before {
      top: 14px !important;
      right: 3px;
      transform-origin: 50% 0%;
   }
   .p-multiselect-panel .p-multiselect-items .p-multiselect-item .p-checkbox {
      margin-left: 0.5rem;
   }
   //input floating label
   .p-float-label > label {
      right: 0.75rem;
      font-size: 0.875rem;
      width: min-content;
      min-inline-size: fit-content;
   }

   //primeng table
   .p-datatable .p-datatable-tbody > tr > td {
      text-align: right;
   }
   .p-datatable .p-datatable-thead > tr > th {
      text-align: right;
   }
   .p-datatable .p-datatable-tfoot > tr > td {
      text-align: right;
   }
}

.word-wrap {
   word-wrap: break-word;
}

.p-tooltip {
   word-break: break-all;
}
.bg-prime-modal {
   background-color: #f4f4f5;
}
.btn-popup {
   color: #fff;
   background-color: #00b6b4;
   border-color: #00b6b4;
}
.btn-border-pop {
   border: 1px solid #00b6b4;
}
.pi-path {
   background-image: url('/assets/icons/path.png');
   height: 1.2rem;
   background-position: center;
   background-repeat: no-repeat;
   background-size: contain;
   transform: none !important;
}

.side-menu-content {
   overflow-y: auto;
   overflow-x: hidden;
   flex-grow: 1;
   padding-right: 0.5rem;
   &::-webkit-scrollbar {
      width: 8px;
      border-radius: 10px;
      background-color: whitesmoke;
   }
   &::-webkit-scrollbar-thumb {
      background: linear-gradient(360deg, rgba(22, 26, 43, 1) 0%, rgba(44, 50, 79, 1) 100%);
   }
}

.list-group {
   width: 100%;
   background-color: transparent;
   border: none;
   overflow: visible;
}
.list-group-item {
   background-color: transparent;
   border: none;
   padding: 0.7rem 0;
}
.list-group-item:not(:first-child),
.list-group-item:not(:last-child) {
   border-top: 2px solid #6c757d;
}

.menu {
   overflow-y: hidden;
   max-height: 0px;
   border: none;
   &.opened {
      max-height: fit-content;
   }
}
.menu-item {
   padding-bottom: 0.3rem;
   padding-top: 0.3rem;
   margin: 0px;
   border: 0px;
   padding-left: 0;
   padding-right: 0;
   list-style: none;
   overflow: hidden;
}
.menu-item-header {
   color: inherit;
   padding: 0.7rem 0;
   margin: 0px;
   border: 0px;
   padding-left: 1rem;
   padding-right: 0;
   list-style: none;
   &.opened {
      border-left: 3px solid #00aaa8;
   }
   &:hover {
      color: unset;
   }
}
.cursor-pointer {
   cursor: pointer;
}

.rtl {
   .mr-3 {
      margin-left: 1rem;
   }
   .menu-item-header {
      &.opened {
         border: 0;
         border-right: 3px solid #00aaa8;
      }
   }
}
