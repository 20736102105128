.rw__otp {
  @extend .d-flex;
  @extend .align-items-center;
  &-w-100 {
    .rw__input {
      flex: 0 1 100% !important;
      height: rem(48);
    }
  }
  &-lg {
    .rw__input {
      flex: 0 1 rem(48) !important;
      height: rem(48);
    }
  }
  .rw__input {
    @extend .rw__me-1;
    &-lg {
      flex: 0 1 rem(48);
      height: rem(48);
    }
    input {
      width: 100%;
      text-align: center;
      padding: rem(10);
    }
  }
}
