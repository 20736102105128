.p-chips {
   flex: 1;
   .p-chips-multiple-container {
      flex: 1;
      &:not(.p-disabled).p-focus {
         box-shadow: inset 0 0 0 1px $inputFocusBorderColor, inset 0 0 0 1px $inputFocusBorderColor,
            inset 0 0 0 1px $inputFocusBorderColor, inset 0 0 0 1px $inputFocusBorderColor;
      }
      .p-inputtext {
         width: 100%;
      }
      .p-chips-token {
         border-radius: 16.5px;
         color: #313d3d;
         background-color: #edf0f0;
         margin-left: 1rem;
      }
   }
}

.p-input-filled {
   .p-chips-multiple-container {
      @include filled-input-wrapper();
   }
}

.p-float-label {
   .p-chips-multiple-container {
      .p-chips-token {
         padding: 0.25rem 1rem;
      }
   }
}

.p-input-filled {
   .p-float-label {
      .p-chips {
         .p-chips-multiple-container {
            .p-chips-token {
               padding-top: 0;
               padding-bottom: 0;

               .p-chips-token-icon {
                  font-size: 75%;
               }
            }

            .p-chips-input-token {
               padding: 0;
            }
         }
      }
   }

   p-chips.ng-dirty.ng-invalid {
      .p-chips .p-chips-multiple-container {
         @include invalid-filled-input-wrapper();
      }
   }
}

p-chips.ng-dirty.ng-invalid {
   .p-chips .p-chips-multiple-container {
      &:not(.p-disabled).p-focus {
         box-shadow: inset 0 0 0 1px $inputErrorBorderColor, inset 0 0 0 1px $inputErrorBorderColor,
            inset 0 0 0 1px $inputErrorBorderColor, inset 0 0 0 1px $inputErrorBorderColor;
      }
   }
}

.rtl .p-chips .p-chips-multiple-container .p-chips-token .p-chips-token-icon {
   margin-right: 1rem !important;
   margin-left: 0px;
}

.p-chips .p-chips-multiple-container .p-chips-token .p-chips-token-icon {
   margin-right:  0px;
   margin-left: 1rem !important;
 }

.p-chips-token-icon  {
   &::before {
       content: "\e90b";
   }
   font-size: 0.525rem;
   border-radius: 50%;
   color: #fff;
   background: #313d3d;
   display: grid;
   align-items: center;
   text-align: center;
   width: 18px;
   height: 18px;
}

.p-chips .p-chips-multiple-container .p-chips-token {
   margin: 4px;
}
