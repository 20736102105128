// Override ngx-datatable
.ngx-datatable.material .datatable-footer .datatable-pager a {
  color: rgba(0, 0, 0, 0.54) !important;
  background: white;
}
.rtlDataTable {
  .ngx-datatable.material .empty-row {
    text-align: right;
  }
  .datatable-checkbox input[type='checkbox'] {
    margin: 0 0 1rem 0;
  }
}

@media print {
  .ngx-datatable.material .datatable-footer {
    display: none;
  }
  .datatable-header {
    max-height: 70px !important;
    height: 70px !important;
  }
  .datatable-row-center,
  .datatable-body-row,
  .datatable-body,
  .datatable-header,
  .datatable-header-inner {
    width: 100% !important;
  }
  .datatable-header-cell-template-wrap {
    padding-top: 14px;
    font-weight: 600;
  }
  .datatable-header-cell {
    font-size: 14px !important;
    font-weight: 500 !important;
  }
  .headAlign {
    text-align: center !important;
  }
  .ngx-datatable.material {
    margin-left: 30px;
    margin-right: 20px;
    border: none !important;
    border-top: none !important;
    border-bottom: none !important;
  }
  .datatable-header-cell,
  .datatable-header {
    border: 1px solid #dee2e6 !important;
    border-top: 1px solid #dee2e6 !important;
    border-bottom: 1px solid #dee2e6 !important;
  }
  .datatable-body-cell,
  .datatable-header-cell,
  .datatable-row-wrapper {
    border: 1px solid #dee2e6;
  }
}
.ngx-datatable.material {
  box-shadow: none;
  border-top: 2px solid #00aaa8;
  background: transparent;
  font-family: Roboto;
  .datatable-header {
    border-bottom: 2px solid #00aaa8;
    max-height: 55px;
    .datatable-header-cell {
      font-size: 14px;
      font-weight: 300;
      padding-top: 10px;
      padding-bottom: 10px;
      &:first-child {
        input[type='checkbox'] {
          top: 3px;
        }
        padding-top: 5px;
      }
      &:hover {
        .resize-handle {
          visibility: hidden !important;
        }
      }
    }
  }
  .datatable-checkbox input[type='checkbox'] {
    top: 2px;
  }
  .datatable-row-wrapper {
    border-top: 1px solid #d4d0d0;
  }
  .datatable-checkbox {
    input {
      &:not(:checked) {
        &::before {
          width: 1.1rem;
          height: 1.1rem;
          border: 1px solid #abaaaa;
          border-radius: 4px;
        }
      }
    }
  }
  .image-icon {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    padding: 0;
    border: solid 1px #dee2e6;
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      border: none;
      box-shadow: 0 0 3px #000;
    }
  }

  .multi-image-icon {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: solid 1px #dee2e6;
    padding: 1px 1px 1px 1px;
  }

  // fix ellipsis on header
  datatable-header-cell {
    white-space: normal !important;
    span {
      display: inline-block;

      span {
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .datatable-body {
    background: #fff;
    .datatable-body-row {
      .datatable-body-cell {
        text-align: start !important;
        padding-top: 10px;
        color: #565656;
        padding-bottom: 10px;
      }
      .projected-items span {
        background: #00aaa8;
        color: #fff;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        padding: 2px 8px;
      }
      &:hover {
        input[type='checkbox'] {
          &::before,
          &::after {
            background: #eee;
          }
        }
      }
    }
  }
  // fix ellipsis on cells
  datatable-body-cell:not(:first-child) {
    .datatable-body-cell-label {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 100%;
      height: 100%;
      padding-top: 5px;
      .dropdown {
        position: absolute;
        .dropdown-menu {
          background-color: rgb(255, 255, 255);
          opacity: 1;
          color: rgb(0, 0, 0);
          .dropdown-item {
            &:hover {
              background-color: #83d8d7e1;
            }
          }
        }
      }
    }
  }
  .datatable-footer {
    margin-top: 50px;
    margin-bottom: 50px;
    background: #f4f4f5;
    border-top: 1px solid #f4f4f5 !important;
  }
}
.ngx-datatable.order-view-tableData {
  border-top: 1px solid #00aaa8;
  border-bottom: 1px solid #00aaa8;
  .datatable-header {
    border-bottom: 1px solid #00aaa8;
  }
}
.ngx-datatable.sales-order-tableData {
  border-top: 1px solid #d4d0d0;
  border-bottom: 1px solid #d4d0d0;
  .datatable-header {
    border-bottom: 1px solid #d4d0d0;
  }
  .sales-total {
    font-weight: bolder;
  }
}
.ngx-datatable.error-logs-tableData {
  border-top: 1px solid #00aaa8;
  .datatable-header {
    border-bottom: 1px solid #00aaa8;
  }
}

.purchase-order {
  $width: 142.636px !important;
  .datatable-body {
    .datatable-body-row {
      .datatable-body-cell:nth-child(5) {
        width: $width;
      }
    }
  }
  .datatable-header {
    .datatable-header-cell:nth-child(5) {
      width: $width;
    }
  }
}