@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
       $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
       @each $gap in map-keys($gaps) {
          .gap#{$infix}-#{$gap} {
             gap: map-get($gaps, $gap);
          }
       }
    }
 }
 @each $font-size in map-keys($font-sizes) {
    .rw-fs-#{$font-size} {
       font-size: map-get($font-sizes, $font-size);
    }
 }
 @each $font-weight in map-keys($font-weights) {
    .rw-fs-weight-#{$font-weight} {
       font-weight: map-get($font-weights, $font-weight);
    }
 }
 
 
 .font-family-1{
      font-family: 'cairo', sans-serif !important;
 }