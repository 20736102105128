@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import '../../styles/helpers/variables';

$primary: #00807d;
$secondary: #8d508c;
$success: #689f38;
$info: #2196f3;
$warning: #fbc02d;
$spacers: (
   6: 4,
   7: 5,
   8: 6,
   9: 7,
   10: 8
);
$gaps: (
   1: 1rem,
   2: 2rem,
   3: 3rem
);
$font-sizes: (
   '1': 0.625rem,
   '2': 0.75rem,
   '3': 0.875rem,
   '4': 1rem,
   '5': 1.125rem,
   '6': 1.25rem,
   '7': 1.5rem,
   '8': 2rem
);
$font-weights: (
   'bold': 700,
   'semi-bold': 600,
   'medium': 500,
   'regular': 400,
);

/* Main Colors */
$rw-color-grey-200: #f7f7f7;
$rw-color-grey-600: #768282;
$rw-color-grey-800: #394444;
$rw-color-white: #ffffff;
$rw-color-success: #689f38;
$rw-color-boarders: #dfe1e5;
$rw-color-title: #0d2222;
$rw-color-body: #0d2222;
$rw-color-info: #2196f3;
$rw-color-text-action-100: #313d3d;
$rw-color-text-action-200: #00807d;
$rw-color-navigation-text: #00b6b4;
$rw-color-placeholder-text: #5b6565;
$rw-color-focus: #e6f3f3;
$rw-color-warning: #fbc02d;
$rw-color-primary: #00a5a3;
$rw-color-secondary: #8d508c;
$rw-color-dark-green: #003939;
$rw-color-text: #1e1e1e;
$rw-color-light: #f0f5f5;
/**/

/* Custom colors */
$mainColor: #00aaa8;
$white: #fff;
$magenta: #9d5a91;
$dashboard-card-height: 30rem;
$dashboard-amount-card-height: 12.5rem;
$box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.06);
$dangerColor: #d32f2f;

// SIDE_NAV
$sidenav-background: #0d2222;
$toggle-button: #00a5a3;
$selectedItem-background: #00a5a3;
$selectedItem-font-color: #00b6b4;
$sidenav-font-color: variableValues($whiteOpacity, "80");
$color-1: #dfe1e5;
$color-2: #00b6b4;
$color-3: #25a37d;
$color-4: rgba(56, 177, 141, 0.1);
$color-5: rgba(56, 177, 141, 0.1);
$color-6: #00a5a3;
$color-7: #d8dcdc;
$color-8: #818181;
$color-9: #0051510f;
$color-10: #313d3d;
$color-11: #f9fafa;
$color-12: #c4c4c4;
/**/

/* Side bar dimensions */
$submenu-items-font-size: 16px;
$submenu-items-child-font-size: 14px;
$header-height: 64px;
$trail-header-height: 60px;
$sidenav-width: 292px;
//


@import 'bootstrap/scss/mixins';
/**/

/* Fonts */
@font-face {
   font-family: 'geThameen';
   src: local('geThameen'), url('/assets/fonts/GE_Thameen_Book.otf') format('truetype');
}

/**/

$shadow: 0 0 6px 0 rgba(0, 0, 0, 0.08);

/*Media*/
$screen-sm: 1200px;
/**/
