.rw__breadcrumb {
  @extend .bg-white;
  @extend .border-bottom-1;
  .p-breadcrumb {
    padding: rem(16) rem(32);
    &-chevron {
      color: #9cabab;
      @extend .rw__fs-10;
    }
    ul {
      li {
        &:not(:only-of-type):not(:last-of-type),
        .p-menuitem-link-active {
          .p-menuitem-text {
            color: variableValues($teal, '50');
          }
        }
        &:last-child {
          @extend .text-grey-80;
        }
        a {
          @extend .rw__fs-9;
          @extend .fw-400;
          line-height: rem(20);
        }
      }
    }
  }
}
