// Colors
$primaryLightColors: (
    "primary" rgba(variableValues($teal, "60"), 0.12) variableValues($teal, "60"),
    "teal" variableValues($teal, "10") variableValues($teal, "60"),
    "danger" rgba(variableValues($red, "60"), 0.12) variableValues($red, "60"),
    "warning" rgba(variableValues($yellow, "60"), 0.12) variableValues($yellow, "60"),
    "success" rgba(variableValues($green, "60"), 0.12) variableValues($green, "60"),
    "info" rgba(variableValues($blue, "60"), 0.12) variableValues($blue, "60"),
    "grey" rgba(variableValues($gray, "10"), 1) variableValues($gray, "60"),
    "white" rgba(white, 0.12) rgba(white, 67%),
);

// Generate Headings Class
@each $name, $background, $color in $primaryLightColors {
    .rw__icon-box-#{$name} {
        @extend .d-flex;
        @extend .align-items-center;
        @extend .justify-content-center;
        @extend .rounded-6;
        background-color: #{$background};
        flex-shrink: 0;
        height: rem(52);
        width: rem(52);
        @include mq('md', 'max') {
            height: rem(40);
            width: rem(40);
        }
        i {
            color: #{$color};
            font-size: rem(26);
            @include mq('md', 'max') {
                font-size: rem(18) !important;
            }
        }
    }
}

.rw__icon-box{
    &-lg{
        height: rem(80) !important;
        width: rem(80) !important;
        i {
            font-size: rem(27) !important;
        } 
    }
    &-md{
        height: rem(52) !important;
        width: rem(52) !important;
        i {
            font-size: rem(27) !important;
        } 
    }
    &-sm {
        height: rem(35) !important;
        width: rem(35) !important;
        i {
            font-size: rem(18) !important;
        }
    }
    &-xs {
        height: rem(24) !important;
        width: rem(24) !important;
        i {
            font-size: rem(16) !important;
        }
    }
}

.rw__icon-box-sm {
  height: rem(35) !important;
  width: rem(35) !important;
  i {
    font-size: rem(18) !important;
  }
}

.rw__icon-box-outline-light {
  @extend .d-flex;
  @extend .align-items-center;
  @extend .justify-content-center;
  @extend .rounded-6;
  @extend .border-1;
  border-color: #fff;
  background-color: transparent;
  flex-shrink: 0;
  height: rem(52);
  width: rem(52);
  @include mq('md', 'max') {
    height: rem(40);
    width: rem(40);
  }
  i {
    @extend .text-grey-60;
    font-size: rem(26);
    @include mq('md', 'max') {
      font-size: rem(18) !important;
    }
  }
}

.p-button-icon {
  font-size: 18px;
}

.fa-solid,
.fa-regular,
.fa-lg {
  line-height: 1;
  vertical-align: middle;
}