// Sizing
$sizing: (
    "h" "-25" "height" "25%",
    "h" "-50" "height" "50%",
    "h" "-50vh" "height" "50vh",
    "h" "-56vh" "height" "56vh",
    "h" "-75" "height" "75%",
    "h" "-100" "height" "100%",
    "h" "-100vh" "height" "100vh",
    "h" "-auto" "height" "auto",
    "minh" "-auto" "min-height" "auto",
    "w" "-20" "width" "20%",
    "w" "-25" "width" "25%",
    "w" "-30" "width" "30%",
    "w" "-35" "width" "35%",
    "w" "-40" "width" "40%",
    "w" "-50" "width" "50%",
    "w" "-60" "width" "60%",
    "w" "-60p" "width" rem(60),
    "maxw" "-60p" "max-width" rem(60),
    "w" "-75" "width" "75%",
    "w" "-100" "width" "100%",
    "w" "-auto" "width" "auto",
    "w" "-90p" "width" rem(90),
    "maxw" "-90p" "max-width" rem(90),
    "minw" "-0" "min-width" "0",
    "minw" "-auto" "min-width" "auto",
    "maxw" "-100" "max-width" "100%",
);

// Generate Flex Classes
@each $name, $nameLast, $title, $value in $sizing {
    .#{$name}#{$nameLast} {
        #{$title}: #{$value} !important;
    }
    @include mq('md', 'min') {
        .#{$name}-md#{$nameLast} {
            #{$title}: #{$value} !important;
        }
    }
}
