// Rounded
$rounded: (
    "0": 0,
    "1": 1,
    "2": 2,
    "3": 3,
    "4": 4,
    "5": 5,
    "6": 6,
    "7": 7,
    "8": 8,
    "9": 9,
    "10": 10,
    "12": 12,
    "14": 14,
    "16": 16,
    "circle": 50,
);

// Generate Rounded Class
@each $name, $value in $rounded {
    .rounded-#{$name} {
        border-radius: rem($value) !important;
    }
    .rounded-top-#{$name} {
        border-top-left-radius: rem($value) !important;
        border-top-right-radius: rem($value) !important;
    }
    .rounded-bottom-#{$name} {
        border-bottom-left-radius: rem($value) !important;
        border-bottom-right-radius: rem($value) !important;
    }
}

.ltr {
    @each $name, $value in $rounded {
        .rounded-start-#{$name} {
            border-bottom-left-radius: rem($value) !important;
            border-top-left-radius: rem($value) !important;
        }
        .rounded-end-#{$name} {
            border-bottom-right-radius: rem($value) !important;
            border-top-right-radius: rem($value) !important;
        }
        .rounded-bottom-start-#{$name} {
            border-bottom-left-radius: rem($value) !important;
        }
        .rounded-bottom-end-#{$name} {
            border-bottom-right-radius: rem($value) !important;
        }
    }
}

.rtl {
    @each $name, $value in $rounded {
        .rounded-start-#{$name} {
            border-bottom-right-radius: rem($value) !important;
            border-top-right-radius: rem($value) !important;
        }
        .rounded-end-#{$name} {
            border-bottom-left-radius: rem($value) !important;
            border-top-left-radius: rem($value) !important;
        }
        .rounded-bottom-start-#{$name} {
            border-bottom-right-radius: rem($value) !important;
        }
        .rounded-bottom-end-#{$name} {
            border-bottom-left-radius: rem($value) !important;
        }
    }
}