.dropdown-menu {
  // max-width:400px;
}
/*
.dropdown-item {

  &.hover,
  &:hover {
    color: #fff;
    opacity: 0.5;
    outline-color: #00AAA8;
    background-color: #fff;
  }

  &.focus,
  &:focus {
    color: #fff;
    opacity: 0.5;
    outline-color: #00AAA8;
    background-color: #fff;
  }

  &.active,
  &:active {
    color: #fff;
    opacity: 0.5;
    outline-color: #00AAA8;
    background-color: #fff;
  }

  &.disabled,
  &:disabled {
    color: #fff;
    opacity: 0.5;
    outline-color: #00AAA8;
    background-color: #fff;
  }
}
*/
