//TO-DO: remove variables when _variable.scss is updated
$teal-2: #D5ECEC;
$teal-6: #00A5A3;
$teal-7: #00807D;
$teal-9: #094040;
$gray-1: #F4F6F6;
$gray-2: #E3E8E8;
$gray-5: #AFB6B6;
$gray-6: #8D9696;
$gray-7: #5C7070;
$gray-9: #0D2222;

.rw__datatable-v3 {
    border: rem(1) solid variableValues($gray, "22");
    @extend .rounded-6;
    min-height: 0;
    max-height: 100%;
    position: relative;
    @include mq('md', 'max'){
    border: none;
    }
    .custom-loader{
        position: absolute;
        top: calc(50% + 35px);
        transform: translateY(-50%);
        font-weight: 700;
        z-index: 9;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;

        }
    p-table {
        display: flex;
        flex-direction: column;
        height: 100%;
        min-height: 0;
        overflow: auto;
    }
    .p-datatable {
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow: auto;
        &-loading-overlay{
            top: 50%;
            transform: translateY(-50%);
            display: none !important;;
        }

        &-scrollable {
            .p-datatable-wrapper{
                &::-webkit-scrollbar{
                    height: 14px;
                }
            }
        }
        &-wrapper {
            @extend .rounded-6;
        }
        &-thead {
            z-index: 2 !important;
            tr {
                th {
                    border-bottom: rem(1) solid variableValues($gray, "22");
                    font-size: rem(12);
                    min-height: rem(48);
                    min-width: rem(200);
                    padding: rem(6) rem(16);
                    line-height: rem(16);
                    vertical-align: middle;
                    gap: rem(8);
                    color: $gray-9;
                    @extend .fw-500;
                    &:first-child {
                        border-start-start-radius: rem(6);
                    }
                    &:last-child {
                        border-start-end-radius: rem(6);
                    }
                    p-sorticon {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        @extend .rounded-circle;
                        margin-left: rem(5);
                        height: rem(32);
                        width: rem(32);
                        i {
                            font-size: rem(14);
                            margin: 0 !important;
                        }
                    }
                    &.rw__filter-active {
                        border-bottom-color:$teal-6;
                        p-sorticon {
                            background-color: variableValues($teal, "21") !important;
                            i {
                                color: variableValues($teal, "60") !important;
                            }
                        }
                    }
                    &.rightFrozenColumn {
                        background-color: $gray-1;
                        color: $gray-7;
                        &:hover{
                        background-color: #EBEDED;
                        border-bottom-color: #D2D9D9;
                        }
                        &.col-customized{
                            background-color: #D8DDDD;
                            color: $gray-9;
                            .fa-table-list {
                            color: $gray-9;
                            }
                        }
                    }
                }
            }
        }
        &-tbody {
            tr {
                &:hover{
                    td {
                        background-color: $gray-1;
                    }
                    .custom-cell:hover{
                        background-color: variableValues($gray, '200');
                    }
                }
                &.selected-row {
                    td {
                        background-color: #f3fafa;
                    }
                }
                &.new {                    
                    border-color: variableValues($purple, "20") !important;
                    td {
                        background-color: lighten(variableValues($purple, "10"), 4.0%);
                        &:first-child {
                            &:before {
                                background-color: variableValues($purple, "50");
                                content: '';
                                height: 100%;
                                position: absolute;
                                left: 0;
                                top: 0;
                                width: rem(4);
                            }
                        }
                    }
                }
                &:not(:last-child) {
                    border-bottom: rem(1) solid $gray-2;
                }
                td {
                    background-color: white;
                    border-bottom: 0;
                    font-size: rem(14);
                    min-height: rem(72);
                    max-height: rem(84);
                    padding: rem(12) rem(16);
                    line-height: rem(20);
                    word-break: break-word;
                    white-space: normal !important;
                    .rw__data-val{
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                    }
                    .rw__user-role{
                        color: variableValues($gray, "700");
                    }
                }

                .product-title-container{
                    @include mq('md', 'max'){
                        flex-direction: row-reverse;
                        justify-content: space-between;
                        flex: 1;
                    }
                }
            }
        }
        .leftFrozenColumn {
            box-shadow: rem(4) 0 rem(6) rem(-1) rgba(black, 0.1);
            flex: auto;
            position: sticky;
            left: 0;
            min-width: auto;
            z-index: 1;
        }
        .rightFrozenColumn {
            box-shadow: rem(-4) 0 rem(6) rem(-1) rgba(black, 0.1);
            display: flex;
            justify-content: center;
            flex: auto;
            position: sticky;
            right: 0;
            min-width: auto;
            .p-button{
                &:active,&:focus{
                    background-color: $teal-2 !important;
                    color: $teal-9 !important;
                }
            }
        }
    }
    &-paginator {
        @extend .border-top-1;
        @extend .rounded-bottom-4;
        background-color: white;
        min-height: rem(56);
        select {
            height: rem(36);
            min-width: rem(50);
        }
        .p-paginator {
            padding: 0;
            &-pages .p-paginator-page, .p-paginator-element{
                border-radius: 50%;
                color: $gray-7;
                min-width: rem(36);
                height: rem(36);
                &.p-highlight{
                    background-color: $teal-2;
                    color: $teal-7;
                }
            }
            &-pages .p-paginator-page{
                font-size: rem(14);
            }
            &-element{
                &.p-disabled{
                    color: $gray-5;
                    opacity: 1;
                }
                .p-paginator-icon{
                    font-size: rem(18);
                }
            }
        }
        span:not(.p-dropdown span){
            color: $gray-7;
        }
    }
    &-resize {
        .p-datatable {
            &-thead {
                th {
                    min-width: auto !important;
                    &.p-resizable-column {
                        .p-column-resizer {
                            border-left: rem(1) solid variableValues($gray, "22");
                        }
                    }
                }
            }
            &-tbody {
                td {
                    min-width: auto !important;
                }
            }
        }
    }
    &-box {
        background-color: white;
        overflow: hidden;
        &-row {
            border-top: rem(1) solid variableValues($gray, "9");
            padding: rem(12);
        }
        &-head {
            padding: rem(4) rem(4) rem(4) rem(12);
        }
        &.selected {
            background-color: variableValues($teal, "10");
            .rw__datatable-v2-box-row {
                border-top-color: variableValues($gray, "22") !important;
            }
        }
        &.new {
            background-color: rgba(variableValues($purple, "10"), 0.3);
            .rw__datatable-v2-box-head {
                position: relative;
                &:before {
                    background-color: variableValues($purple, "50");
                    content: '';
                    height: rem(4);
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                }
            }
            .rw__datatable-v2-box-row {
                border-top-color: variableValues($purple, "20") !important;
            }
        }
    }
    &-mobile{
        p-table{
        overflow: unset;
        }
        .p-datatable{
            overflow: unset;
            &-wrapper{
                overflow: unset !important;
            }
            &-thead{
                position: sticky;
                top: 70px;
            }
            &-tbody{
                tr{
                    display: flex;
                    flex-direction: column;
                    margin-top: rem(12);
                    td{
                        min-height: rem(40);
                        border-bottom: 1px solid variableValues($gray, '20') !important;
                        order: 1;
                        &:last-child{
                            order: 0;
                        }
                    }
                    .p-column-title {
                        color: $gray-7;
                    }
                }
            }
        }
    }
}

.rtl {
    .rw__datatable-v3 {
       .p-datatable{
        .leftFrozenColumn {
            box-shadow: rem(-4) 0 rem(6) rem(-1) rgba(black, 0.1) !important;
            left: auto !important;
            right: 0 !important;
        }
        .rightFrozenColumn {
            box-shadow: rem(4) 0 rem(6) rem(-1) rgba(black, 0.1) !important;
            right: auto !important;
            left: 0 !important;
        }
       } 
    }
}

@media print {
    .rw__datatable-v3 {
        .p-datatable {
            th {
                border-bottom: 0 !important;
            }
        }
    }
}

.rw__datatable-v2{
    &-box{
        &-row {
            &:not(:nth-last-child(2)){
            border-bottom: 1px solid $gray-1 !important;
           }
        }
    }
}
.custom-cell{
    &.p-overlaypanel{
        .p-overlaypanel-content{
            background-color: variableValues($gray, '900');
            color: variableValues($gray, '200');
            border-radius: rem(6);
            padding: rem(16);
        }
        &-flipped{
            &:before, &:after{
                border-top-color: variableValues($gray, '900');
            }
        }
        &:after{
            border-bottom-color: variableValues($gray, '900');
        }
    }
}

.time-selector-container{
    max-width: rem(200);
}