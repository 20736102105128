.dropdown-toggle::after {
  display: inline;
  vertical-align: 0;
  border-top: 0;
  border-right: 0;
  border-bottom: 0;
  border-left: 0;
  font-size: 12px;
  font-weight: 400;
  font-family: 'Material Icons';
  content: '\e313' !important;
}

.dropdown-item {
  font-family: Roboto;
  font-weight: 400;
  font-size: 12px;
}

.disabled-dropdown-item {
  color: gray;
}

.btn {
  &:focus,
  &.focus {
    outline: 0;
    box-shadow: none;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    background-image: none;
    box-shadow: none;

    &:focus {
      box-shadow: none;
    }
  }
}

.btn-primary {
  color: #00aaa8;
  font-family: Roboto;
  font-weight: 400;
  background-color: #ffffff;
  border-color: #00aaa8;
}

.btn-primary:hover {
  color: #fff;
  opacity: 0.5;
  background-color: #00aaa8;
  border-color: #00aaa8;
}

.btn-primary:focus,
.btn-primary.focus {
  box-shadow: none;
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #00aaa8;
  background-color: #ffffff;
  border-color: #00aaa8;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #00aaa8;
  background-color: #f6f6f6;
  border-color: #00aaa8;
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-danger {
  color: red;
  font-family: Roboto;
  font-weight: 400;
  background-color: #ffffff;
  border-color: red;
}

.btn-danger:hover {
  color: #fff;
  opacity: 0.5;
  background-color: red;
  border-color: red;
}

.btn-danger:focus,
.btn-primary.focus {
  box-shadow: none;
}

.btn-primary:disabled {
  color: #00aaa8;
  background-color: #ffffff;
  border-color: #00aaa8;
}

.btn-danger.disabled {
  color: red;
  background-color: #ffffff;
  border-color: red;
}

.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #ababab;
  background-color: #f6f6f6;
  border-color: #ababab;
}

.btn-danger:not(:disabled):not(.disabled):active:focus,
.btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}

.dropdown-menu-icon {
  color: #5b5b5b;
  width: 13px;
  height: 13px;
  font-size: 18px;
  font-weight: 400;
  vertical-align: middle;
  margin-right: 20px;
}

.dropdown-menu-span {
  color: #5b5b5b;
}

.btn-circle.btn-sm.btn-primary {
  width: 30px;
  height: 30px;
  padding: 5px 0px;
  border-radius: 15px;
  font-size: 8px;
  text-align: center;
}
.btn-rw-primary {
  color: #fff;
  font-family: Roboto;
  font-weight: 400;
  background-color: #00aaa8;
  border-color: #00aaa8;
  &:hover {
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
    color: #fff;
  }
}
@mixin a-button($base) {
  .btn-rw-woocommerce {
    color: #000;
    font-family: Roboto;
    font-weight: 400;
    border-color: $base;
    border-radius: 0px;
    &:hover {
      opacity: 0.5;
      color: #fff;
      background-color: $base;
    }
  }
}
.btn-rw-blue {
  color: #424448;
  font-family: Roboto;
  font-weight: 400;
  background-color: #ffffff;
  border-color: #4fbfe9;
  &:hover {
    color: #fff;
    opacity: 0.5;
    background-color: #4fbfe9;
    border-color: #4fbfe9;
  }
}
.btn-rw-gray {
  color: #848688;
  font-family: Roboto;
  font-weight: 400;
  background-color: #ffffff;
  border-color: #848688;
  &:hover {
    color: #fff;
    opacity: 0.5;
    background-color: #848688;
    border-color: #848688;
  }
}
.btn-rw-defult {
  color: #00aaa8;
  font-family: Roboto;
  font-weight: 400;
  background-color: #ffffff;
  border-color: #00aaa8;
  &:hover {
    color: #fff;
    opacity: 0.5;
    background-color: #00aaa8;
    border-color: #00aaa8;
  }
}
.btn-rw-dropdown {
  font-weight: 300;
  background-color: #ffffff;
  border-color: #e2e2e3;
  opacity: 1;
  text-align: left;
  font-size: 15px;
  &:after {
    right: 7px;
    position: absolute;
    font-size: 17px;
  }
}
