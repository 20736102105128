.rw__spinner {
    .p-progress-spinner-circle {
        animation: custom-progress-spinner-dash 1.5s ease-in-out infinite, custom-progress-spinner-color 6s ease-in-out infinite;
      }
}
  
  @keyframes custom-progress-spinner-color {
    100%,
    0% {
      stroke: rgba(variableValues($teal, "50"), 0.3);
    }
    40% {
      stroke: rgba(variableValues($teal, "50"), 0.5);
    }
    66% {
      stroke: rgba(variableValues($teal, "50"), 0.8);
    }
    80%,
    90% {
      stroke: rgba(variableValues($teal, "50"), 1);
    }
  }
  