.icon-sidenav-applications {
  background: url('/assets/icons/sidenav/applications-sidebar-icons.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 0.9rem !important;
  height: 0.9rem !important;
}

.icon-sidenav-dashboard {
  background: url('/assets/icons/sidenav/dashboard-sidebar-icons.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 0.9rem !important;
  height: 0.9rem !important;
}

.icon-sidenav-inventory {
  background: url('/assets/icons/sidenav/inventory-sidebar-icons.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 0.9rem !important;
  height: 0.9rem !important;
}

.icon-menu-close {
  background: url('/assets/icons/sidenav/menu-icon-close.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 0.9rem !important;
  height: 0.9rem !important;
}

.icon-money-bill-icons {
  background: url('/assets/icons/sidenav/money-bill-icons.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 0.9rem !important;
  height: 0.9rem !important;
}

.icon-order-management-sidebar-icons {
  background: url('/assets/icons/sidenav/order-management-sidebar-icons.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 0.9rem !important;
  height: 0.9rem !important;
}

.icon-reports_sidebar_icons {
  background: url('/assets/icons/sidenav/reports_sidebar_icons.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 0.9rem !important;
  height: 0.9rem !important;
}

.icon-pos-sidebar-icons {
  background: url('/assets/icons/sidenav/pos-sidebar-icons.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 0.9rem !important;
  height: 0.9rem !important;
}

.icon-settings-sidebar-icons {
  background: url('/assets/icons/sidenav/settings-sidebar-icons.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 0.9rem !important;
  height: 0.9rem !important;
}

.icon-stock-control-sidebar-icons {
  background: url('/assets/icons/sidenav/stock-control-sidebar-icons.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 0.9rem !important;
  height: 0.9rem !important;
}

.icon-tag {
  background: url('/assets/icons/sidenav/tag.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 0.9rem !important;
  height: 0.9rem !important;
}

.icon-dollar {
  background: url('/assets/icons/sidenav/icon-dollar.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 0.9rem !important;
  height: 0.9rem !important;
}

.icon-calender {
  background: url('/assets/icons/calender-icon.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 0.9rem !important;
  height: .9rem !important;
}

.pi-calendar{
  background: url('/assets/icons/calender-icon.svg') !important;
  background-repeat: no-repeat;
  background-size: contain;
  background-position:center;
  width:  0.9rem  !important;
  height: 0.9rem  !important;
}

.icon-product-track-batch{
    background: url("/assets/icons/products/batch.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position:center;
    width: 6rem;
    height: 6rem;
}

.icon-product-track-bar-code{
    background: url("/assets/icons/products/bar-code.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position:center;
    width: 6rem;
    height: 6rem;
}

.quote-icon {
  background: url(/assets/icons/handshake.svg) no-repeat right,#fff;
  width: 1rem !important;
  height: 1rem !important;
}

.invoice-icon {
  background: url(/assets/icons/invoice.svg) no-repeat right,#fff;
  width: 1rem !important;
  height: 1rem !important;
}

.add-withdraw-icon {
  background: url(/assets/icons/moneybill.svg) no-repeat right,#fff;
  width: 1rem !important;
  height: 1rem !important;
}