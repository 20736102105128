.rtl {
    .fw-400 {
        font-weight: 500 !important;
    }
    .fw-500 {
        font-weight: 600 !important;
    }
    .fw-600 {
        font-weight: 700 !important;
    }
    .field-checkbox > label,
    .field-radiobutton > label {
        margin-left: 0;
        margin-right: 0.5rem;
    }
}