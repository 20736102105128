// Custom Columns
.rw__columns {
    @extend .d-flex;
    @extend .flex-wrap;
    margin: 0 rem(-12);
    @include mq('md', 'max') {
        flex-direction: column !important;
    }
    & > [class*="rw__col"]:not([class*="rw__col-12"]) {
        @include mq('lg', 'max') {
            width: 50% !important;
        }
        @include mq('md', 'max') {
            width: 100% !important;
        }
    }
    .rw__col-3 {
        padding: 0 rem(12);
        width: 25%;
    }
    .rw__col-4 {
        padding: 0 rem(12);
        width: 33.33%;
    }
    .rw__col-5 {
        padding: 0 rem(12);
        width: 41.66%;
    }
    .rw__col-6 {
        padding: 0 rem(12);
        width: 50%;
    }
    .rw__col-7 {
        padding: 0 rem(12);
        width: 58.33%;
    }
    .rw__col-8 {
        padding: 0 rem(12);
        width: 66.66%;
    }
    .rw__col-9 {
        padding: 0 rem(12);
        width: 75%;
    }
    .rw__col-12 {
        padding: 0 rem(12);
        width: 100%;
    }
}

// Tabs
.rw__tabs {
        li {
            a {
                cursor: pointer;
                &:after {
                    border-radius: rem(4) rem(4) 0 0;
                    content: '';
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    height: rem(4);
                    width: 100%;;
                }
                &.p-tabview-nav-link {
                    font-size: rem(16);
                    @extend .fw-400;
                }
                &.active,&.rw__active-tab {
                    color: variableValues($teal, "60") !important;
                    &:after {
                        @extend .bg-primary;
                    }
                    &:hover:after {
                        @extend .bg-primary;
                    }
                }
            }
            &.p-highlight {
            .p-tabview-nav-link {
                    @extend .text-primary;
                    @extend .fw-500;
                }
                &.p-disabled {
                    .p-tabview-nav-link {
                        @extend .text-primary;
                        @extend .fw-400;
                    }
                }
            }
            }
    .p-tabview-panels {
        padding: 0;
    }
    &-disabled {
        .p-tabview-ink-bar {
            display: none !important;
        }
    }
    &-box {
        ul {
            @include mq('lg', 'max') {
                overflow: auto;
            }
            li {
                background-color: white;
                border: rem(1) solid variableValues($gray, "20");
                @extend .rounded-4;
                min-width: rem(145);
                width: rem(220);
                button {
                    background-color: transparent;
                    border: 0;
                    color: variableValues($gray, "60");
                    display: flex;
                    align-items: center;
                    font-size: rem(16);
                    outline: none;
                    padding: rem(16);
                    width: 100%;
                    i {
                        font-size: rem(24);
                    }
                }
                &:not(:first-child) {
                    margin-inline-start: rem(16);
                }
                &.active {
                    background-color: variableValues($teal, "10");
                    border-color: variableValues($teal, "50");
                    button {
                        color: variableValues($teal, "60");
                    }
                }
            }    
        }
    }
}

//P-TABS
// Tabs
.rw__p-tabs {
    .p-tabview-nav{
        border-width: 0 0 2px 0;
        li {
            padding: 0 rem(16);
            &:first-child{
                @extend .rw__ps-7;
            }
            a {
                padding: rem(16) 0 !important;
                font-size: rem(16);
                font-weight: variableValues($weights, '500');
                line-height: rem(24);
                color: variableValues($gray, '60');
                overflow: visible;
                &:after {
                    border-radius: rem(4);
                    content: '';
                    position: absolute;
                    left: 0;
                    bottom: -2px;
                    height: rem(2);
                    width: 100%;;
                }
                &.p-tabview-nav-link {
                    font-size: rem(16);
                    @extend .fw-400;
                }
                &.active {
                    @extend .text-primary;
                    &:after {
                        @extend .bg-primary;
                    }
                    &:hover:after {
                        @extend .bg-primary;
                    }
                }
                &:hover {
                    background-color: transparent;
                    @extend .text-primary;
                    &:after,&:hover:after{
                        @extend .text-primary;
                        @extend .bg-primary;
    
                    }
                }
                // &:hover:after {
                //     @extend .bg-primary;
                // }
            }
            &.p-highlight {
                a.p-tabview-nav-link {
                    @extend .text-primary;
                    @extend .fw-500;
                    &:after{
                        @extend .text-primary;
                        @extend .bg-primary;
    
                    }
                    &:hover {
                        background-color: transparent;
                        @extend .text-primary;
                        &:after,&:hover:after{
                            @extend .text-primary;
                            @extend .bg-primary;
        
                        }
                    }
                    &:hover,&:focus,&:hover:after {
                        background-color: transparent !important;
                        @extend .text-primary;
                    }
                    &:focus:after{
                        @extend .bg-primary;
                    }
                }
                &.p-disabled {
                    .p-tabview-nav-link {
                        @extend .text-primary;
                        @extend .fw-400;
                    }
                }
            }
            &.p-tabview-ink-bar{
                display: none !important;
            }
            &:hover{
                .p-tabview-nav-link{
                    background-color: transparent !important;
                }
            }
        }
    }

    .p-tabview-panels {
        padding: 0;
    }
    &-disabled {
        .p-tabview-ink-bar {
            display: none !important;
        }
    }
    &-transparent {
        .p-tabview-nav,
        .p-tabview-nav li a,
        .p-tabview-panels {
            background-color: transparent !important;
        }
    }
}
// Tooltip
.rw__tooltip {
    .p-tooltip-text {
        background-color: variableValues($gray, "80");
        @extend .rw__p-5;
        @extend .rw__fs-9;
        @extend .rounded-6;
    }
    .p-tooltip-arrow {
        @extend .d-block;
    }
    &.p-tooltip {
        max-width: rem(332);
        &-left {
            .p-tooltip-text {
                margin-right: rem(2);
            }
            .p-tooltip-arrow {
                border-left-color: variableValues($gray, "80");
                border-width: rem(7.5) 0 rem(7.5) rem(6);
                margin-top: rem(-8);
            }
        }
        &-right {
            .p-tooltip-text {
                margin-left: rem(2);
            }
            .p-tooltip-arrow {
                border-right-color: variableValues($gray, "80");
                border-width: rem(7.5) rem(6) rem(7.5) 0;
                margin-top: rem(-8);
            }
        }
        &-top {
            .p-tooltip-arrow {
                border-top-color: variableValues($gray, "80");
                border-width: rem(6) rem(7.5) 0;
                bottom: rem(-2);
            }
        }
    }
}

// Accordion
.rw__accordion {
    .p-accordion {
        &-tab {
            @extend .border-1;
            @extend .rounded-6;
            box-shadow: none !important;
            overflow: hidden;
        }
        &-header {
            &-link {
                @extend .rw__fs-9;
                @extend .fw-500;
                padding: rem(12) rem(16) !important;
                text-decoration: none;
                &:hover {
                    .p-accordion-header-text {
                        text-decoration: underline;
                    }
                }
            }
            &-text {
                color: variableValues($gray, "80");
                flex: 1;
            }
            .rw__tag-circle {
                background-color: variableValues($purple, "10");
                border-radius: 50%;
                color: variableValues($purple, "70");
                @extend .d-flex;
                @extend .align-items-center;
                @extend .justify-content-center;
                font-size: rem(12);
                height: rem(24);
                width: rem(24);
            }
        }
        &-content {
            padding: 0 rem(12) rem(16) !important;
        }
        &-toggle-icon {
            @extend .bg-grey-10;
            @extend .d-flex;
            @extend .align-items-center;
            @extend .justify-content-center;
            @extend .rounded-circle;
            @extend .rw__me-0;
            @extend .rw__fs-12;
            height: rem(24);
            width: rem(24);
            &.pi-chevron-down {
                transform: rotate(180deg);
            }
            &.pi-chevron-right {
                transform: rotate(90deg);
            }
        }
        .rw__accordion-default-header {
            .p-accordion-header-text {
                display: none !important;
            }
        }
    }
    &-text-danger {
        .p-accordion-header-text {
            @extend .text-danger;
        }
    }
    &-grey-header {
        .p-accordion {
            &-header-link {
                background-color: variableValues($gray, "9") !important;
                justify-content: space-between;
            }
            &-content {
                border-top: rem(1) solid variableValues($gray, "20");
            }
            &-toggle-icon {
                background-color: transparent !important;
                font-size: rem(14) !important;
                height: rem(20);
                width: rem(20);
            }
        }
    }
    &-grey-border {
        .p-accordion-header {
            padding: rem(16);
            &-link {
                background-color: transparent !important;
                padding: 0 !important;
            }
        }
        .p-accordion-content {
            padding: 0 rem(16) rem(16) !important;
        }
        .p-accordion-toggle-icon {
            background-color: transparent !important;
            font-size: rem(14) !important;
        }
    }
}

// Progress Bar
.rw__progress-bar {
    .p-progressbar {
        background-color: variableValues($gray, "20");
        border-radius: rem(4);
        height: rem(8);
        &-value {
            background-color: variableValues($teal, "50");
            border-radius: rem(4);
        }
        &-label {
            display: none !important;
        }
    }
    &-white {
        .p-progressbar {
            background-color: rgba(white, 0.38);
            &-value {
                background-color: white;
            }
        }
    }
    &-purple {
        .p-progressbar {
            &-value {
                background-color: variableValues($purple, "60");
            }
        }
    }
}

// Report and Point Box
.rw__point-box,
.rw__report-box {
    &.cursor-pointer {
        &:hover {
            background-color: variableValues($teal, "10") !important;
        }
    }
}

.rtl {
    .rw__accordion {
        .p-accordion {
            &-toggle-icon {
                &.pi-chevron-down {
                    transform: rotate(-180deg) !important;
                }
                &.pi-chevron-right {
                    transform: rotate(-90deg) !important;
                }
            }
        }
    }
}
