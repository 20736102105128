@use "sass:math";
@use "sass:map";

$breakpoints: (
  'xs': 320px,
  'sm': 640px,
  'md': 960px,
  'lg': 1366px,
);

@mixin mq($breakpoint, $type: min) {
  @if map_has_key($breakpoints, $breakpoint) {
    $width: map_get($breakpoints, $breakpoint);
    @if $type==max {
      $width: $width - 1px;
    }
    @media (#{$type}-width: $width) {
      @content;
    }
  }
}

@mixin mq-only($min-width, $max-width) {
  @if map_has_key($breakpoints, $min-width) and map_has_key($breakpoints, $max-width) {
    $min-width: map_get($breakpoints, $min-width);
    $max-width: map_get($breakpoints, $max-width) - 1px;
    @media (min-width: $min-width) and (max-width: $max-width) {
      @content;
    }
  }
}

@function rem($pixels) {
  // @return ($pixels / 16) * 1rem;
  @return math.div($pixels, 16) * 1rem;
}


@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}

@function variableValues($variable, $value) {
  @return map.get($variable, $value);
}