@import "./variables";
.card-height {
  height: $dashboard-card-height;
}
.amount-card-height {
  height: $dashboard-amount-card-height;
}
.dashboard-numbers {
  margin: 4rem auto auto auto;
  span:nth-child(1) {
    font-size: xx-large;
    margin-right: 0.5rem;
  }
  span:last-child {
    font-size: x-large;
  }
}
.hover-shadow {
  &:hover {
    cursor: pointer;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  }
}
.justify-content-between {
  display: flex;
  flex-direction: row;
  align-content: space-between;
}
