//paginator 
$paginatorBg:#ffffff;
$paginatorTextColor:$textColor;
$paginatorBorder:solid #e4e4e4;
$paginatorBorderWidth:0;
$paginatorPadding:.5rem 1rem;
$paginatorElementWidth:$buttonIconOnlyWidth;
$paginatorElementHeight:$buttonIconOnlyWidth;
$paginatorElementBg:transparent;
$paginatorElementBorder:0 none;
$paginatorElementIconColor:$textSecondaryColor;
$paginatorElementHoverBg:rgba(0,0,0,.04);
$paginatorElementHoverBorderColor:transparent;
$paginatorElementIconHoverColor:$textSecondaryColor;
$paginatorElementBorderRadius:0;
$paginatorElementMargin:.143rem;
$paginatorElementPadding:0;
$highlightBg:transparent;
$inputFocusBorderColor:#00A5A3;