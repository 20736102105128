// Colors
$primaryColors: (
    "primary": variableValues($teal, "700"),
    "danger": variableValues($red, "60"),
    "warning": variableValues($yellow, "60"),
    "success": variableValues($green, "60"),
    "info": variableValues($blue, "60"),
    "disabled": variableValues($gray, "30"),
    "white": white,
    "transparent": transparent,
    "grey-7": variableValues($gray, "7"),
    "grey-8": variableValues($gray, "8"),
    "grey-9": variableValues($gray, "9"),
    "grey-10": variableValues($gray, "10"),
    "grey-20": variableValues($gray, "20"),
    "grey-21": variableValues($gray, "21"),
    "grey-22": variableValues($gray, "22"),
    "grey-30": variableValues($gray, "30"),
    "grey-40": variableValues($gray, "40"),
    "grey-50": variableValues($gray, "50"),
    "grey-60": variableValues($gray, "60"),
    "grey-70": variableValues($gray, "70"),
    "grey-80": variableValues($gray, "80"),    
    "grey-90": variableValues($gray, "90"),
    "teal-10": variableValues($teal, "10"),
    "teal-20": variableValues($teal, "20"),
    "teal-50": variableValues($teal, "50"),
    "teal-60": variableValues($teal, "60"),
    "teal-90": variableValues($teal, "90"),
    "purple-10": variableValues($purple, "10"),
    "purple-60": variableValues($purple, "60"),
    "purple-70": variableValues($purple, "70"),
    "blue-60": variableValues($blue, "60"),
    "green-9": variableValues($green, "9"),
    "green-10": variableValues($green, "10"),
    "green-20": variableValues($green, "20"),
    "green-30": variableValues($green, "30"),
    "green-40": variableValues($green, "40"),
    "green-50": variableValues($green, "50"),
    "green-60": variableValues($green, "60"),
    "danger-10": variableValues($red, "10"),
    "danger-50": variableValues($red, "50"),
    "yellow-10": variableValues($yellow, "10"),
    "yellow-20": variableValues($yellow, "20"),
    "yellow-30": variableValues($yellow, "30"),
    "yellow-40": variableValues($yellow, "40"),
    "yellow-50": variableValues($yellow, "50"),
    "yellow-60": variableValues($yellow, "60"),
    "yellow-70": variableValues($yellow, "70"),
    "yellow-80": variableValues($yellow, "80"),
    "red-10":  variableValues($red, "10"),
    "red-20":  variableValues($red, "20"),
    "red-30":  variableValues($red, "30"),
    "red-40":  variableValues($red, "40"),
    "red-50":  variableValues($red, "50"),
    "red-60":  variableValues($red, "60"),
    "red-70":  variableValues($red, "70"),
    "red-80":  variableValues($red, "80"),

    //Updated rds-2.0 *please use the updated colors range from 100 to 900*

    "teal-100":  variableValues($teal, "100"),
    "teal-200":  variableValues($teal, "200"),
    "teal-300":  variableValues($teal, "300"),
    "teal-400":  variableValues($teal, "400"),
    "teal-500":  variableValues($teal, "500"),
    "teal-600":  variableValues($teal, "600"),
    "teal-700":  variableValues($teal, "700"),
    "teal-800":  variableValues($teal, "800"),
    "teal-900":  variableValues($teal, "900"),

    "gray-100":  variableValues($gray, "100"),
    "gray-200":  variableValues($gray, "200"),
    "gray-300":  variableValues($gray, "300"),
    "gray-400":  variableValues($gray, "400"),
    "gray-500":  variableValues($gray, "500"),
    "gray-600":  variableValues($gray, "600"),
    "gray-700":  variableValues($gray, "700"),
    "gray-800":  variableValues($gray, "800"),
    "gray-900":  variableValues($gray, "900"),

    "purple-100":  variableValues($purple, "100"),
    "purple-200":  variableValues($purple, "200"),
    "purple-300":  variableValues($purple, "300"),
    "purple-400":  variableValues($purple, "400"),
    "purple-500":  variableValues($purple, "500"),
    "purple-600":  variableValues($purple, "600"),
    "purple-700":  variableValues($purple, "700"),
    "purple-800":  variableValues($purple, "800"),
    "purple-900":  variableValues($purple, "900"),
    
    "green-100":  variableValues($green, "100"),
    "green-200":  variableValues($green, "200"),
    "green-300":  variableValues($green, "300"),
    "green-400":  variableValues($green, "400"),
    "green-500":  variableValues($green, "500"),
    "green-600":  variableValues($green, "600"),
    "green-700":  variableValues($green, "700"),
    "green-800":  variableValues($green, "800"),
    "green-900":  variableValues($green, "900"),  
    
    "yellow-100":  variableValues($yellow, "100"),
    "yellow-200":  variableValues($yellow, "200"),
    "yellow-300":  variableValues($yellow, "300"),
    "yellow-400":  variableValues($yellow, "400"),
    "yellow-500":  variableValues($yellow, "500"),
    "yellow-600":  variableValues($yellow, "600"),
    "yellow-700":  variableValues($yellow, "700"),
    "yellow-800":  variableValues($yellow, "800"),
    "yellow-900":  variableValues($yellow, "900"),  
    
    "red-100":  variableValues($red, "100"),
    "red-200":  variableValues($red, "200"),
    "red-300":  variableValues($red, "300"),
    "red-400":  variableValues($red, "400"),
    "red-500":  variableValues($red, "500"),
    "red-600":  variableValues($red, "600"),
    "red-700":  variableValues($red, "700"),
    "red-800":  variableValues($red, "800"),
    "red-900":  variableValues($red, "900"),
    
    "blue-100":  variableValues($blue, "100"),
    "blue-200":  variableValues($blue, "200"),
    "blue-300":  variableValues($blue, "300"),
    "blue-400":  variableValues($blue, "400"),
    "blue-500":  variableValues($blue, "500"),
    "blue-600":  variableValues($blue, "600"),
    "blue-700":  variableValues($blue, "700"),
    "blue-800":  variableValues($blue, "800"),
    "blue-900":  variableValues($blue, "900"),     
);

// Generate Headings Class
@each $name, $value in $primaryColors {
    .bg-#{$name} {
        background-color: #{$value} !important;
    }
    .text-#{$name} {
      color: #{$value} !important;
    }
    .border-#{$name} {
        border-color: #{$value} !important;
    }
    .border-start-#{$name} {
        border-left-color: #{$value} !important;
    }
    .border-end-#{$name} {
        border-right-color: #{$value} !important;
    }
}

.nb__anchor-primary {
    color: variableValues($teal, "60") !important;
    text-decoration: underline;
    &:hover {
        text-decoration: none;
    }
}
