.english-font {
    font-family: variableValues($fonts, "english"), sans-serif !important;
}

.arabic-font {
    font-family: variableValues($fonts, "arabic"), sans-serif !important;
}

.bg-shorthand-center {
    background: no-repeat center / cover;
}

.cursor-pointer {
    cursor: pointer;
}

.border-danger-dashed {
    background-image: url(
        "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='6' ry='6' stroke='%23D61F1FFF' stroke-width='3' stroke-dasharray='4%2c 11' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}

.flip-icon-x {
    transform: scaleX(-1);
}

.rw__direction {
    &-left {
        direction: ltr !important;
    }
    &-right {
        direction: rtl !important;
    }
}

.pointer-events-none {
    pointer-events: none !important;
}

.ltr {
    direction: ltr;
    text-align: left;
    .lh-normal {
        line-height: normal
    }
}

.rtl {
    direction: rtl;
    text-align: right;
    .flip-icon-x-rtl {
        transform: scaleX(-1);
    }
}

.text {
    &-start {
        text-align: left !important;
    }
    &-end {
        text-align: right !important;
    }
    &-capitalize {
        text-transform: capitalize !important;
    }
}

.ltr{
    .text {
        &-fix-start {
            text-align: left !important;
        }
        &-fix-end {
            text-align: right !important;
        }
    }
}

.rtl{
    .text {
        &-fix-start {
            text-align: right !important;
        }
        &-fix-end {
            text-align: left !important;
        }
    }
}

.list-style {
    &-none {
        list-style: none;
    }
    &-decimal {
        list-style: decimal;
    }
    &-decimal-leading-zero {
        list-style: decimal-leading-zero;
    }
    &-list {
        list-style: none;
        padding-inline-start: 0;
        margin-bottom: 0;
    }
    &-disc {
        list-style: disc;
        &-internal {
            display: flex;
            align-items: center;
            &:before {
                @extend .rounded-circle;
                background-color: variableValues($gray, "50");
                content: '';
                height: rem(6);
                width: rem(6);
            }
        }
    }
}

.lh-normal {
    line-height: normal !important;
}

.text-truncate {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.text-decoration-line-through {
    text-decoration: line-through!important;
}

.rw__input-text-end {
    .p-inputtext {
        direction: ltr;
        text-align: end;
    }
}

.white-space-nowrap {
    white-space: nowrap;
}

.rw__hide-on-mobile {
    @include mq('md', 'max') {
        display: none !important;
    }
}

.rw__hide-on-desktop {
    @include mq('md', 'min') {
        display: none !important;
    }
}

.rw__hide {
  display: none !important;
}

.zi-1 {
    z-index: 1;
}

a {
    &.rw__link{
        color: variableValues($teal, '60');
        &:hover{
            color: rgba(variableValues($teal, "60"), 0.8);
        }
    }
}

.table-vertical-align-middle {
    td {
        vertical-align: middle !important;
    }
}

.overflow-x-hidden {
    overflow-x: hidden !important;
}
.btn-label-minw{
      .p-button-label{
        flex-grow: 0;
      }
}

.invisible {
    visibility: hidden;
}

.float-left {
    float: left;
}

.float-right {
    float: right;
}
