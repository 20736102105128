// Display
$display: (
    "d" "-flex" "display" "flex",
    "d" "-inline-flex" "display" "inline-flex",
    "d" "-none" "display" "none",
    "d" "-block" "display" "block",
    "d" "-inline-block" "display" "inline-block",
    "d" "-table" "display" "table",
    "d" "-table-cell" "display" "table-cell",
);

// Generate Headings Class
@each $name, $lastName, $title, $value in $display {
    .#{$name}#{$lastName} {
        #{$title}: #{$value};
    }
    @include mq('lg', 'min') {
        .#{$name}-lg#{$lastName} {
            #{$title}: #{$value} !important;
        }
    }
    @include mq('md', 'min') {
        .#{$name}-md#{$lastName} {
            #{$title}: #{$value} !important;
        }
    }
    @include mq('sm', 'min') {
        .#{$name}-sm#{$lastName} {
            #{$title}: #{$value} !important;
        }
    }
}
