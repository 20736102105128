// Notifications
$notifications: (
    "info" variableValues($blue, "10") variableValues($blue, "60"),
    "success" variableValues($green, "10") variableValues($green, "60"),
    "warning" variableValues($yellow, "10") variableValues($yellow, "60"),
    "error" variableValues($red, "10") variableValues($red, "60"),
);

// Generate Notification Class
@each $name, $background, $borderDark, $icon in $notifications {
  .rw__notification-#{$name} {
    background-color: $background;
    border-width: rem(1) rem(1) rem(1) rem(6);
    border-color: variableValues($gray, "20");
    border-left-color: $borderDark;
    border-style: solid;
    @extend .d-flex;
    @extend .align-items-center;
    @extend .rounded-4;
    padding: rem(16);
    min-height: 56px;
    .notification{
      &-body{
      flex: 1;
      @extend .d-flex;
      align-items: center;
        i {
          color: $borderDark;
          @extend .rw__fs-6;
          @extend .rw__me-4;
        }
        p {
          @extend .rw__fs-9;
          @extend .fw-400;
          line-height: rem(20);
          color: variableValues($gray, "70");
        }
      }
      &-footer{
        @extend .d-flex;
        @extend .align-items-center;
        padding-inline: rem(6);
        .fa-xmark{
          color: variableValues($gray, "80");
          cursor: pointer;
          @extend .rw__ms-5;
        }
      }
    }
  }
  .rw__notification-toast-#{$name} {
    .p-toast {
      &-message {
        background: $background !important;
        border-width: rem(1) rem(1) rem(1) rem(6) !important;
        border-color: variableValues($gray, "20") !important;
        border-left-color: $borderDark !important;
        border-style: solid !important;
        &-icon {
          color: $borderDark !important;
        }
        &-text {
          margin: 0 0 0 rem(16) !important;
        }
        &-content {
          @extend .d-flex;
          @extend .align-items-center;
          @extend .rw__px-5;
          @extend .rw__py-4;
        }
      }
      &-icon-close {
        &-icon {
          @extend .rw__fs-8;
        }
      }
      &-detail {
        margin: 0 !important;
      }
      a {
        color: variableValues($teal, '60');
      }
    }
  }
  .p-toast {
    opacity: 1;
    .p-toast-message {
      &-content {
        gap: rem(16);
      }
      &.p-toast-message-#{$name} {
        background-color: $background !important;
        border-color: $background !important;
        border-left-color: $borderDark !important;
        .p-toast-message-icon {
          font-size: rem(24);
        }
        .p-toast-message-text {
          @extend .rw__fs-9;
          color: variableValues($gray, "80");
          line-height: normal !important;
          margin: 0;
        }
        .p-toast-detail:only-child {
          margin-top: 0;
        }
      }
    }
  }
  .rtl {
    .rw__notification-#{$name} {
      border-left-color: variableValues($gray, "20");
      border-right-color: $borderDark;
      border-width: rem(1) rem(6) rem(1) rem(1);
    }
    .p-toast .p-toast-message.p-toast-message-#{$name} {
      border-width: 0 6px 0 0;
      border-right-color: $borderDark !important;
    }

  }
}

.rw__notification {
  .p-toast {
    min-width: rem(400);
    width: auto;
  }
}

.rw__notification-toast-hide-icon {
  .p-toast-message {
    &-icon {
      display: none !important;
    }
    &-text {
      margin-left: 0 !important;
    }
  }
}

#toast-container .p-toast .p-toast-message {
  background-image: none !important;
  border-width: 0 0 0 rem(6) !important;
  border-style: solid !important;
  border-radius: rem(4) !important;
  box-shadow: none !important;
  display: flex;
  align-items: center;
  padding: rem(13) rem(18) !important;
  opacity: 1 !important;
  .p-toast-message-icon {
    font-family: variableValues($fonts, "icons");
    font-size: rem(20);
    margin-right: rem(18);
  }
}

.rtl {
  #toast-container .p-toast .p-toast-message {
    border-width: 0 rem(6) 0 0 !important;
    .p-toast-message-icon {
      margin-left: rem(18);
      margin-right: 0 !important;
    }
  }
}

@media print {
  #toast-container {
    display: none !important;
  }
}
