.sidebar-w840px {
  width: 840px !important;
}

.sidebar-sub-accounts {
	width: 75% !important;
	min-width: 768px;
	max-width: 1280px;
}

.sidebar-no-head{
	.p-sidebar-header{
		display: none;
	}
}

.sidebar-no-foot{
	.p-sidebar-footer{
		display: none;
	}
}

.sidebar-no-content{
	.p-sidebar-content{
		padding:0;
	}
}