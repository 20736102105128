.p-steps {
    .p-steps-item {
        justify-content: flex-start;
        flex-direction: row-reverse;
        align-items: center;
        &:before {
            position: static;
            left: auto;
            top: auto;
            margin-top: 0;
        }

        &:last-child {
            flex-grow: 0;

            &:before {
                display: none;
            }
        }
        .p-menuitem-link {
            flex-direction: row;
            flex: 1 1 auto;
            overflow: visible;
            padding: 0 $inlineSpacing;
            .p-steps-number {
                background-color: #dfe2e2;
                color: #8d9696;
                font-size: 0.857rem;
                min-width: 2rem;
                height: 2rem;
                line-height: 2rem;
                margin-bottom: 1rem;
            }

            .p-steps-title {
                margin: 0;
                padding-left: $inlineSpacing;
            }
        }

        &.p-highlight {
            .p-steps-number {
                background-color: $primaryColor;
                color: $primaryTextColor;
            }

            .p-steps-title {
                color: $textColor;
            }
        }

        &.p-disabled {
            opacity: 1;
        }
        &.p-steps-current {
            .p-steps-number {
                background-color: white !important;
                border: 2px solid rgb(0, 165, 163) !important;
                color: rgb(0, 165, 163) !important;
                font-weight: 700;
            }
        }
        &.step-completed {
            background-color: rgb(0, 165, 163) !important;
            color: #fff !important;
        }
    }
}
