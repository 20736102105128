.sku-input {
   rw-input {
      input {
         border-right: 0;
         border-bottom-right-radius: 0;
         border-top-right-radius: 0;
         min-height: 3.23rem;
      }
   }

   rw-sku-generator {
      .p-button {
         border-left: 0;
         border-bottom-left-radius: 0;
         border-top-left-radius: 0;
         background: aliceblue;
      }
   }
}

.rtl {
   .sku-input {
      rw-input {
         input {
            border-right: 1px solid #afb6b6;
            border-bottom-right-radius: 4px;
            border-top-right-radius: 4px;
            border-left: 0;
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
         }
      }
      rw-sku-generator {
         .p-button {
            border-left: 1px solid #00a19f;
            border-bottom-left-radius: 4px;
            border-top-left-radius: 4px;
            border-right: 0;
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
         }
      }
   }
}
