@import "~primeflex/primeflex.css";
.border-0, .rw__button-tooltip, .rw__input-custom-multi-select .p-multiselect, .rw__input .rw__input-search-with-button input {
  border: 0 solid transparent !important;
}

.border-top-0, .rw__datatable .p-datatable-header {
  border-top: 0 solid transparent !important;
}

.border-bottom-0 {
  border-bottom: 0 solid transparent !important;
}

.border-start-0 {
  border-left: 0 solid transparent !important;
}

.border-end-0 {
  border-right: 0 solid transparent !important;
}

.border-width-0 {
  border-width: 0 !important;
}

.border-top-width-0 {
  border-top-width: 0 !important;
}

.border-bottom-width-0 {
  border-bottom-width: 0 !important;
}

.border-start-width-0 {
  border-left-width: 0 !important;
}

.border-end-width-0 {
  border-right-width: 0 !important;
}

@media (min-width: 640px) {
  .border-sm-0 {
    border: 0 solid transparent !important;
  }
  .border-top-sm-0 {
    border-top: 0 solid transparent !important;
  }
  .border-bottom-sm-0 {
    border-bottom: 0 solid transparent !important;
  }
  .border-start-sm-0 {
    border-left: 0 solid transparent !important;
  }
  .border-end-sm-0 {
    border-right: 0 solid transparent !important;
  }
  .border-width-sm-0 {
    border-width: 0 !important;
  }
  .border-top-width-sm-0 {
    border-top-width: 0 !important;
  }
  .border-bottom-width-sm-0 {
    border-bottom-width: 0 !important;
  }
  .border-start-width-sm-0 {
    border-left-width: 0 !important;
  }
  .border-end-width-sm-0 {
    border-right-width: 0 !important;
  }
}
.border-1, .rw__datatable, .rw__accordion .p-accordion-tab, .rw__icon-box-outline-light {
  border: 0.0625rem solid #D2DADA !important;
}

.border-top-1, .rw__datatable-v3-paginator, .rw__datatable-paginator, .rw__steps .p-steps-item:last-child:before, .rw__steps .p-steps-item:before {
  border-top: 0.0625rem solid #D2DADA !important;
}

.border-bottom-1, .rw__datatable .p-datatable-header, .rw__breadcrumb {
  border-bottom: 0.0625rem solid #D2DADA !important;
}

.border-start-1, .rtl .rw__datatable .p-frozen-column[ng-reflect-align-frozen=left] {
  border-left: 0.0625rem solid #D2DADA !important;
}

.border-end-1, .rtl .rw__datatable .p-frozen-column[ng-reflect-align-frozen=right] {
  border-right: 0.0625rem solid #D2DADA !important;
}

.border-width-1 {
  border-width: 0.0625rem !important;
}

.border-top-width-1 {
  border-top-width: 0.0625rem !important;
}

.border-bottom-width-1 {
  border-bottom-width: 0.0625rem !important;
}

.border-start-width-1 {
  border-left-width: 0.0625rem !important;
}

.border-end-width-1 {
  border-right-width: 0.0625rem !important;
}

@media (min-width: 640px) {
  .border-sm-1 {
    border: 0.0625rem solid #D2DADA !important;
  }
  .border-top-sm-1 {
    border-top: 0.0625rem solid #D2DADA !important;
  }
  .border-bottom-sm-1 {
    border-bottom: 0.0625rem solid #D2DADA !important;
  }
  .border-start-sm-1 {
    border-left: 0.0625rem solid #D2DADA !important;
  }
  .border-end-sm-1 {
    border-right: 0.0625rem solid #D2DADA !important;
  }
  .border-width-sm-1 {
    border-width: 0.0625rem !important;
  }
  .border-top-width-sm-1 {
    border-top-width: 0.0625rem !important;
  }
  .border-bottom-width-sm-1 {
    border-bottom-width: 0.0625rem !important;
  }
  .border-start-width-sm-1 {
    border-left-width: 0.0625rem !important;
  }
  .border-end-width-sm-1 {
    border-right-width: 0.0625rem !important;
  }
}
.border-2 {
  border: 0.125rem solid #D2DADA !important;
}

.border-top-2 {
  border-top: 0.125rem solid #D2DADA !important;
}

.border-bottom-2 {
  border-bottom: 0.125rem solid #D2DADA !important;
}

.border-start-2 {
  border-left: 0.125rem solid #D2DADA !important;
}

.border-end-2 {
  border-right: 0.125rem solid #D2DADA !important;
}

.border-width-2 {
  border-width: 0.125rem !important;
}

.border-top-width-2 {
  border-top-width: 0.125rem !important;
}

.border-bottom-width-2 {
  border-bottom-width: 0.125rem !important;
}

.border-start-width-2 {
  border-left-width: 0.125rem !important;
}

.border-end-width-2 {
  border-right-width: 0.125rem !important;
}

@media (min-width: 640px) {
  .border-sm-2 {
    border: 0.125rem solid #D2DADA !important;
  }
  .border-top-sm-2 {
    border-top: 0.125rem solid #D2DADA !important;
  }
  .border-bottom-sm-2 {
    border-bottom: 0.125rem solid #D2DADA !important;
  }
  .border-start-sm-2 {
    border-left: 0.125rem solid #D2DADA !important;
  }
  .border-end-sm-2 {
    border-right: 0.125rem solid #D2DADA !important;
  }
  .border-width-sm-2 {
    border-width: 0.125rem !important;
  }
  .border-top-width-sm-2 {
    border-top-width: 0.125rem !important;
  }
  .border-bottom-width-sm-2 {
    border-bottom-width: 0.125rem !important;
  }
  .border-start-width-sm-2 {
    border-left-width: 0.125rem !important;
  }
  .border-end-width-sm-2 {
    border-right-width: 0.125rem !important;
  }
}
.border-style-dashed {
  border-style: dashed !important;
}

.bg-primary, .rw__p-tabs .p-tabview-nav li.p-highlight a.p-tabview-nav-link:focus:after, .rw__p-tabs .p-tabview-nav li.p-highlight a.p-tabview-nav-link:hover:after, .rw__p-tabs .p-tabview-nav li.p-highlight a.p-tabview-nav-link:hover:hover:after, .rw__p-tabs .p-tabview-nav li.p-highlight a.p-tabview-nav-link:after, .rw__p-tabs .p-tabview-nav li a:hover:after, .rw__p-tabs .p-tabview-nav li a:hover:hover:after, .rw__p-tabs .p-tabview-nav li a.active:hover:after, .rw__p-tabs .p-tabview-nav li a.active:after, .rw__tabs li a.active:hover:after, .rw__tabs li a.rw__active-tab:hover:after, .rw__tabs li a.active:after, .rw__tabs li a.rw__active-tab:after {
  background-color: #00807D !important;
}

.text-primary, .rw__datatable-paginator .p-paginator-element .p-paginator-icon, .rw__p-tabs .p-tabview-nav li.p-highlight.p-disabled .p-tabview-nav-link, .rw__p-tabs .p-tabview-nav li.p-highlight a.p-tabview-nav-link:focus, .rw__p-tabs .p-tabview-nav li.p-highlight a.p-tabview-nav-link:hover:after, .rw__p-tabs .p-tabview-nav li.p-highlight a.p-tabview-nav-link:hover:hover:after, .rw__p-tabs .p-tabview-nav li.p-highlight a.p-tabview-nav-link:hover, .rw__p-tabs .p-tabview-nav li.p-highlight a.p-tabview-nav-link:after, .rw__p-tabs .p-tabview-nav li.p-highlight a.p-tabview-nav-link, .rw__p-tabs .p-tabview-nav li a:hover:after, .rw__p-tabs .p-tabview-nav li a:hover:hover:after, .rw__p-tabs .p-tabview-nav li a:hover, .rw__p-tabs .p-tabview-nav li a.active, .rw__tabs li.p-highlight.p-disabled .p-tabview-nav-link, .rw__tabs li.p-highlight .p-tabview-nav-link, .rw__input-custom-multi-select .p-multiselect .p-multiselect-label, .rw__input .rw__input-search-with-button button:hover, .rw__input .rw__input-search-with-button button:focus {
  color: #00807D !important;
}

.border-primary {
  border-color: #00807D !important;
}

.border-start-primary {
  border-left-color: #00807D !important;
}

.border-end-primary {
  border-right-color: #00807D !important;
}

.bg-danger {
  background-color: #BA1B1B !important;
}

.text-danger, .rw__accordion-text-danger .p-accordion-header-text {
  color: #BA1B1B !important;
}

.border-danger {
  border-color: #BA1B1B !important;
}

.border-start-danger {
  border-left-color: #BA1B1B !important;
}

.border-end-danger {
  border-right-color: #BA1B1B !important;
}

.bg-warning {
  background-color: #D9A200 !important;
}

.text-warning {
  color: #D9A200 !important;
}

.border-warning {
  border-color: #D9A200 !important;
}

.border-start-warning {
  border-left-color: #D9A200 !important;
}

.border-end-warning {
  border-right-color: #D9A200 !important;
}

.bg-success {
  background-color: #008547 !important;
}

.text-success {
  color: #008547 !important;
}

.border-success {
  border-color: #008547 !important;
}

.border-start-success {
  border-left-color: #008547 !important;
}

.border-end-success {
  border-right-color: #008547 !important;
}

.bg-info {
  background-color: #007ACC !important;
}

.text-info {
  color: #007ACC !important;
}

.border-info {
  border-color: #007ACC !important;
}

.border-start-info {
  border-left-color: #007ACC !important;
}

.border-end-info {
  border-right-color: #007ACC !important;
}

.bg-disabled {
  background-color: #C7CCCC !important;
}

.text-disabled {
  color: #C7CCCC !important;
}

.border-disabled {
  border-color: #C7CCCC !important;
}

.border-start-disabled {
  border-left-color: #C7CCCC !important;
}

.border-end-disabled {
  border-right-color: #C7CCCC !important;
}

.bg-white, .rw__breadcrumb {
  background-color: white !important;
}

.text-white {
  color: white !important;
}

.border-white {
  border-color: white !important;
}

.border-start-white {
  border-left-color: white !important;
}

.border-end-white {
  border-right-color: white !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.text-transparent {
  color: transparent !important;
}

.border-transparent {
  border-color: transparent !important;
}

.border-start-transparent {
  border-left-color: transparent !important;
}

.border-end-transparent {
  border-right-color: transparent !important;
}

.bg-grey-7 {
  background-color: #f6f8fa !important;
}

.text-grey-7 {
  color: #f6f8fa !important;
}

.border-grey-7 {
  border-color: #f6f8fa !important;
}

.border-start-grey-7 {
  border-left-color: #f6f8fa !important;
}

.border-end-grey-7 {
  border-right-color: #f6f8fa !important;
}

.bg-grey-8 {
  background-color: rgba(246, 246, 246, 0.4) !important;
}

.text-grey-8 {
  color: rgba(246, 246, 246, 0.4) !important;
}

.border-grey-8 {
  border-color: rgba(246, 246, 246, 0.4) !important;
}

.border-start-grey-8 {
  border-left-color: rgba(246, 246, 246, 0.4) !important;
}

.border-end-grey-8 {
  border-right-color: rgba(246, 246, 246, 0.4) !important;
}

.bg-grey-9 {
  background-color: #F4F5F5 !important;
}

.text-grey-9 {
  color: #F4F5F5 !important;
}

.border-grey-9 {
  border-color: #F4F5F5 !important;
}

.border-start-grey-9 {
  border-left-color: #F4F5F5 !important;
}

.border-end-grey-9 {
  border-right-color: #F4F5F5 !important;
}

.bg-grey-10, .rw__accordion .p-accordion-toggle-icon, .rw__steps .p-steps-item .p-menuitem-link .p-steps-number {
  background-color: #F7F8F8 !important;
}

.text-grey-10 {
  color: #F7F8F8 !important;
}

.border-grey-10 {
  border-color: #F7F8F8 !important;
}

.border-start-grey-10 {
  border-left-color: #F7F8F8 !important;
}

.border-end-grey-10 {
  border-right-color: #F7F8F8 !important;
}

.bg-grey-20, .rw__input .p-multiselect-token {
  background-color: #DFE2E2 !important;
}

.text-grey-20 {
  color: #DFE2E2 !important;
}

.border-grey-20 {
  border-color: #DFE2E2 !important;
}

.border-start-grey-20 {
  border-left-color: #DFE2E2 !important;
}

.border-end-grey-20 {
  border-right-color: #DFE2E2 !important;
}

.bg-grey-21 {
  background-color: #edf0f0 !important;
}

.text-grey-21 {
  color: #edf0f0 !important;
}

.border-grey-21 {
  border-color: #edf0f0 !important;
}

.border-start-grey-21 {
  border-left-color: #edf0f0 !important;
}

.border-end-grey-21 {
  border-right-color: #edf0f0 !important;
}

.bg-grey-22 {
  background-color: #E3E8E8 !important;
}

.text-grey-22 {
  color: #E3E8E8 !important;
}

.border-grey-22 {
  border-color: #E3E8E8 !important;
}

.border-start-grey-22 {
  border-left-color: #E3E8E8 !important;
}

.border-end-grey-22 {
  border-right-color: #E3E8E8 !important;
}

.bg-grey-30 {
  background-color: #C7CCCC !important;
}

.text-grey-30 {
  color: #C7CCCC !important;
}

.border-grey-30 {
  border-color: #C7CCCC !important;
}

.border-start-grey-30 {
  border-left-color: #C7CCCC !important;
}

.border-end-grey-30 {
  border-right-color: #C7CCCC !important;
}

.bg-grey-40 {
  background-color: #A7B6B6 !important;
}

.text-grey-40, .rw__datatable-custom-paginator .p-paginator .p-link.p-disabled .p-paginator-icon {
  color: #A7B6B6 !important;
}

.border-grey-40 {
  border-color: #A7B6B6 !important;
}

.border-start-grey-40 {
  border-left-color: #A7B6B6 !important;
}

.border-end-grey-40 {
  border-right-color: #A7B6B6 !important;
}

.bg-grey-50 {
  background-color: #8D9696 !important;
}

.text-grey-50 {
  color: #8D9696 !important;
}

.border-grey-50 {
  border-color: #8D9696 !important;
}

.border-start-grey-50 {
  border-left-color: #8D9696 !important;
}

.border-end-grey-50 {
  border-right-color: #8D9696 !important;
}

.bg-grey-60 {
  background-color: #5B6565 !important;
}

.text-grey-60, .rw__datatable-custom-paginator .p-paginator .p-link .p-paginator-icon, .rw__datatable-custom-paginator .p-paginator .p-link, .rw__icon-box-outline-light i {
  color: #5B6565 !important;
}

.border-grey-60 {
  border-color: #5B6565 !important;
}

.border-start-grey-60 {
  border-left-color: #5B6565 !important;
}

.border-end-grey-60 {
  border-right-color: #5B6565 !important;
}

.bg-grey-70 {
  background-color: #313D3D !important;
}

.text-grey-70, .rw__datatable-custom-paginator .p-paginator .p-dropdown, .rw__datatable-internal-paginator .p-paginator .p-dropdown, .rw__datatable-internal-paginator .p-paginator .p-link {
  color: #313D3D !important;
}

.border-grey-70 {
  border-color: #313D3D !important;
}

.border-start-grey-70 {
  border-left-color: #313D3D !important;
}

.border-end-grey-70 {
  border-right-color: #313D3D !important;
}

.bg-grey-80 {
  background-color: #0D2222 !important;
}

.text-grey-80, .rw__datatable-custom-paginator .p-paginator .p-paginator-current, .rw__datatable-internal-paginator .p-paginator .p-link .p-paginator-icon, .rw__datatable-internal-paginator .p-paginator .p-paginator-current, .rw__breadcrumb .p-breadcrumb ul li:last-child, .rw__input .p-multiselect-token {
  color: #0D2222 !important;
}

.border-grey-80 {
  border-color: #0D2222 !important;
}

.border-start-grey-80 {
  border-left-color: #0D2222 !important;
}

.border-end-grey-80 {
  border-right-color: #0D2222 !important;
}

.bg-grey-90 {
  background-color: #212529 !important;
}

.text-grey-90 {
  color: #212529 !important;
}

.border-grey-90 {
  border-color: #212529 !important;
}

.border-start-grey-90 {
  border-left-color: #212529 !important;
}

.border-end-grey-90 {
  border-right-color: #212529 !important;
}

.bg-teal-10 {
  background-color: #EEF8F8 !important;
}

.text-teal-10 {
  color: #EEF8F8 !important;
}

.border-teal-10 {
  border-color: #EEF8F8 !important;
}

.border-start-teal-10 {
  border-left-color: #EEF8F8 !important;
}

.border-end-teal-10 {
  border-right-color: #EEF8F8 !important;
}

.bg-teal-20 {
  background-color: #DAF5F5 !important;
}

.text-teal-20 {
  color: #DAF5F5 !important;
}

.border-teal-20 {
  border-color: #DAF5F5 !important;
}

.border-start-teal-20 {
  border-left-color: #DAF5F5 !important;
}

.border-end-teal-20 {
  border-right-color: #DAF5F5 !important;
}

.bg-teal-50 {
  background-color: #00A5A3 !important;
}

.text-teal-50 {
  color: #00A5A3 !important;
}

.border-teal-50 {
  border-color: #00A5A3 !important;
}

.border-start-teal-50 {
  border-left-color: #00A5A3 !important;
}

.border-end-teal-50 {
  border-right-color: #00A5A3 !important;
}

.bg-teal-60 {
  background-color: #00807D !important;
}

.text-teal-60 {
  color: #00807D !important;
}

.border-teal-60 {
  border-color: #00807D !important;
}

.border-start-teal-60 {
  border-left-color: #00807D !important;
}

.border-end-teal-60 {
  border-right-color: #00807D !important;
}

.bg-teal-90 {
  background-color: #072525 !important;
}

.text-teal-90 {
  color: #072525 !important;
}

.border-teal-90 {
  border-color: #072525 !important;
}

.border-start-teal-90 {
  border-left-color: #072525 !important;
}

.border-end-teal-90 {
  border-right-color: #072525 !important;
}

.bg-purple-10 {
  background-color: #F7EAF4 !important;
}

.text-purple-10 {
  color: #F7EAF4 !important;
}

.border-purple-10 {
  border-color: #F7EAF4 !important;
}

.border-start-purple-10 {
  border-left-color: #F7EAF4 !important;
}

.border-end-purple-10 {
  border-right-color: #F7EAF4 !important;
}

.bg-purple-60 {
  background-color: #824481 !important;
}

.text-purple-60 {
  color: #824481 !important;
}

.border-purple-60 {
  border-color: #824481 !important;
}

.border-start-purple-60 {
  border-left-color: #824481 !important;
}

.border-end-purple-60 {
  border-right-color: #824481 !important;
}

.bg-purple-70 {
  background-color: #561157 !important;
}

.text-purple-70 {
  color: #561157 !important;
}

.border-purple-70 {
  border-color: #561157 !important;
}

.border-start-purple-70 {
  border-left-color: #561157 !important;
}

.border-end-purple-70 {
  border-right-color: #561157 !important;
}

.bg-blue-60 {
  background-color: #007ACC !important;
}

.text-blue-60 {
  color: #007ACC !important;
}

.border-blue-60 {
  border-color: #007ACC !important;
}

.border-start-blue-60 {
  border-left-color: #007ACC !important;
}

.border-end-blue-60 {
  border-right-color: #007ACC !important;
}

.bg-green-9 {
  background-color: #ebf1f1 !important;
}

.text-green-9 {
  color: #ebf1f1 !important;
}

.border-green-9 {
  border-color: #ebf1f1 !important;
}

.border-start-green-9 {
  border-left-color: #ebf1f1 !important;
}

.border-end-green-9 {
  border-right-color: #ebf1f1 !important;
}

.bg-green-10 {
  background-color: #EBFFF5 !important;
}

.text-green-10 {
  color: #EBFFF5 !important;
}

.border-green-10 {
  border-color: #EBFFF5 !important;
}

.border-start-green-10 {
  border-left-color: #EBFFF5 !important;
}

.border-end-green-10 {
  border-right-color: #EBFFF5 !important;
}

.bg-green-20 {
  background-color: #C2FFE0 !important;
}

.text-green-20 {
  color: #C2FFE0 !important;
}

.border-green-20 {
  border-color: #C2FFE0 !important;
}

.border-start-green-20 {
  border-left-color: #C2FFE0 !important;
}

.border-end-green-20 {
  border-right-color: #C2FFE0 !important;
}

.bg-green-30 {
  background-color: #99FFCC !important;
}

.text-green-30 {
  color: #99FFCC !important;
}

.border-green-30 {
  border-color: #99FFCC !important;
}

.border-start-green-30 {
  border-left-color: #99FFCC !important;
}

.border-end-green-30 {
  border-right-color: #99FFCC !important;
}

.bg-green-40 {
  background-color: #A5D98F !important;
}

.text-green-40 {
  color: #A5D98F !important;
}

.border-green-40 {
  border-color: #A5D98F !important;
}

.border-start-green-40 {
  border-left-color: #A5D98F !important;
}

.border-end-green-40 {
  border-right-color: #A5D98F !important;
}

.bg-green-50 {
  background-color: #14A85E !important;
}

.text-green-50 {
  color: #14A85E !important;
}

.border-green-50 {
  border-color: #14A85E !important;
}

.border-start-green-50 {
  border-left-color: #14A85E !important;
}

.border-end-green-50 {
  border-right-color: #14A85E !important;
}

.bg-green-60 {
  background-color: #008547 !important;
}

.text-green-60 {
  color: #008547 !important;
}

.border-green-60 {
  border-color: #008547 !important;
}

.border-start-green-60 {
  border-left-color: #008547 !important;
}

.border-end-green-60 {
  border-right-color: #008547 !important;
}

.bg-danger-10 {
  background-color: #FFF0ED !important;
}

.text-danger-10 {
  color: #FFF0ED !important;
}

.border-danger-10 {
  border-color: #FFF0ED !important;
}

.border-start-danger-10 {
  border-left-color: #FFF0ED !important;
}

.border-end-danger-10 {
  border-right-color: #FFF0ED !important;
}

.bg-danger-50 {
  background-color: #E44D47 !important;
}

.text-danger-50 {
  color: #E44D47 !important;
}

.border-danger-50 {
  border-color: #E44D47 !important;
}

.border-start-danger-50 {
  border-left-color: #E44D47 !important;
}

.border-end-danger-50 {
  border-right-color: #E44D47 !important;
}

.bg-yellow-10 {
  background-color: #FFF9EB !important;
}

.text-yellow-10 {
  color: #FFF9EB !important;
}

.border-yellow-10 {
  border-color: #FFF9EB !important;
}

.border-start-yellow-10 {
  border-left-color: #FFF9EB !important;
}

.border-end-yellow-10 {
  border-right-color: #FFF9EB !important;
}

.bg-yellow-20 {
  background-color: #FFF0D1 !important;
}

.text-yellow-20 {
  color: #FFF0D1 !important;
}

.border-yellow-20 {
  border-color: #FFF0D1 !important;
}

.border-start-yellow-20 {
  border-left-color: #FFF0D1 !important;
}

.border-end-yellow-20 {
  border-right-color: #FFF0D1 !important;
}

.bg-yellow-30 {
  background-color: #FCE5A9 !important;
}

.text-yellow-30 {
  color: #FCE5A9 !important;
}

.border-yellow-30 {
  border-color: #FCE5A9 !important;
}

.border-start-yellow-30 {
  border-left-color: #FCE5A9 !important;
}

.border-end-yellow-30 {
  border-right-color: #FCE5A9 !important;
}

.bg-yellow-40 {
  background-color: #FFD469 !important;
}

.text-yellow-40 {
  color: #FFD469 !important;
}

.border-yellow-40 {
  border-color: #FFD469 !important;
}

.border-start-yellow-40 {
  border-left-color: #FFD469 !important;
}

.border-end-yellow-40 {
  border-right-color: #FFD469 !important;
}

.bg-yellow-50 {
  background-color: #FCC63D !important;
}

.text-yellow-50 {
  color: #FCC63D !important;
}

.border-yellow-50 {
  border-color: #FCC63D !important;
}

.border-start-yellow-50 {
  border-left-color: #FCC63D !important;
}

.border-end-yellow-50 {
  border-right-color: #FCC63D !important;
}

.bg-yellow-60 {
  background-color: #D9A200 !important;
}

.text-yellow-60 {
  color: #D9A200 !important;
}

.border-yellow-60 {
  border-color: #D9A200 !important;
}

.border-start-yellow-60 {
  border-left-color: #D9A200 !important;
}

.border-end-yellow-60 {
  border-right-color: #D9A200 !important;
}

.bg-yellow-70 {
  background-color: #996B00 !important;
}

.text-yellow-70 {
  color: #996B00 !important;
}

.border-yellow-70 {
  border-color: #996B00 !important;
}

.border-start-yellow-70 {
  border-left-color: #996B00 !important;
}

.border-end-yellow-70 {
  border-right-color: #996B00 !important;
}

.bg-yellow-80 {
  background-color: #402D00 !important;
}

.text-yellow-80 {
  color: #402D00 !important;
}

.border-yellow-80 {
  border-color: #402D00 !important;
}

.border-start-yellow-80 {
  border-left-color: #402D00 !important;
}

.border-end-yellow-80 {
  border-right-color: #402D00 !important;
}

.bg-red-10 {
  background-color: #FFF0ED !important;
}

.text-red-10 {
  color: #FFF0ED !important;
}

.border-red-10 {
  border-color: #FFF0ED !important;
}

.border-start-red-10 {
  border-left-color: #FFF0ED !important;
}

.border-end-red-10 {
  border-right-color: #FFF0ED !important;
}

.bg-red-20 {
  background-color: #FFDCD6 !important;
}

.text-red-20 {
  color: #FFDCD6 !important;
}

.border-red-20 {
  border-color: #FFDCD6 !important;
}

.border-start-red-20 {
  border-left-color: #FFDCD6 !important;
}

.border-end-red-20 {
  border-right-color: #FFDCD6 !important;
}

.bg-red-30 {
  background-color: #FFBFB5 !important;
}

.text-red-30 {
  color: #FFBFB5 !important;
}

.border-red-30 {
  border-color: #FFBFB5 !important;
}

.border-start-red-30 {
  border-left-color: #FFBFB5 !important;
}

.border-end-red-30 {
  border-right-color: #FFBFB5 !important;
}

.bg-red-40 {
  background-color: #FF897A !important;
}

.text-red-40 {
  color: #FF897A !important;
}

.border-red-40 {
  border-color: #FF897A !important;
}

.border-start-red-40 {
  border-left-color: #FF897A !important;
}

.border-end-red-40 {
  border-right-color: #FF897A !important;
}

.bg-red-50 {
  background-color: #E44D47 !important;
}

.text-red-50 {
  color: #E44D47 !important;
}

.border-red-50 {
  border-color: #E44D47 !important;
}

.border-start-red-50 {
  border-left-color: #E44D47 !important;
}

.border-end-red-50 {
  border-right-color: #E44D47 !important;
}

.bg-red-60 {
  background-color: #BA1B1B !important;
}

.text-red-60 {
  color: #BA1B1B !important;
}

.border-red-60 {
  border-color: #BA1B1B !important;
}

.border-start-red-60 {
  border-left-color: #BA1B1B !important;
}

.border-end-red-60 {
  border-right-color: #BA1B1B !important;
}

.bg-red-70 {
  background-color: #930006 !important;
}

.text-red-70 {
  color: #930006 !important;
}

.border-red-70 {
  border-color: #930006 !important;
}

.border-start-red-70 {
  border-left-color: #930006 !important;
}

.border-end-red-70 {
  border-right-color: #930006 !important;
}

.bg-red-80 {
  background-color: #680003 !important;
}

.text-red-80 {
  color: #680003 !important;
}

.border-red-80 {
  border-color: #680003 !important;
}

.border-start-red-80 {
  border-left-color: #680003 !important;
}

.border-end-red-80 {
  border-right-color: #680003 !important;
}

.bg-teal-100 {
  background-color: #EEF8F8 !important;
}

.text-teal-100 {
  color: #EEF8F8 !important;
}

.border-teal-100 {
  border-color: #EEF8F8 !important;
}

.border-start-teal-100 {
  border-left-color: #EEF8F8 !important;
}

.border-end-teal-100 {
  border-right-color: #EEF8F8 !important;
}

.bg-teal-200 {
  background-color: #D5ECEC !important;
}

.text-teal-200 {
  color: #D5ECEC !important;
}

.border-teal-200 {
  border-color: #D5ECEC !important;
}

.border-start-teal-200 {
  border-left-color: #D5ECEC !important;
}

.border-end-teal-200 {
  border-right-color: #D5ECEC !important;
}

.bg-teal-300 {
  background-color: #BDEFEF !important;
}

.text-teal-300 {
  color: #BDEFEF !important;
}

.border-teal-300 {
  border-color: #BDEFEF !important;
}

.border-start-teal-300 {
  border-left-color: #BDEFEF !important;
}

.border-end-teal-300 {
  border-right-color: #BDEFEF !important;
}

.bg-teal-400 {
  background-color: #9DEFEF !important;
}

.text-teal-400 {
  color: #9DEFEF !important;
}

.border-teal-400 {
  border-color: #9DEFEF !important;
}

.border-start-teal-400 {
  border-left-color: #9DEFEF !important;
}

.border-end-teal-400 {
  border-right-color: #9DEFEF !important;
}

.bg-teal-500 {
  background-color: #00BFBC !important;
}

.text-teal-500 {
  color: #00BFBC !important;
}

.border-teal-500 {
  border-color: #00BFBC !important;
}

.border-start-teal-500 {
  border-left-color: #00BFBC !important;
}

.border-end-teal-500 {
  border-right-color: #00BFBC !important;
}

.bg-teal-600 {
  background-color: #00A5A3 !important;
}

.text-teal-600 {
  color: #00A5A3 !important;
}

.border-teal-600 {
  border-color: #00A5A3 !important;
}

.border-start-teal-600 {
  border-left-color: #00A5A3 !important;
}

.border-end-teal-600 {
  border-right-color: #00A5A3 !important;
}

.bg-teal-700 {
  background-color: #00807D !important;
}

.text-teal-700 {
  color: #00807D !important;
}

.border-teal-700 {
  border-color: #00807D !important;
}

.border-start-teal-700 {
  border-left-color: #00807D !important;
}

.border-end-teal-700 {
  border-right-color: #00807D !important;
}

.bg-teal-800 {
  background-color: #005E5D !important;
}

.text-teal-800 {
  color: #005E5D !important;
}

.border-teal-800 {
  border-color: #005E5D !important;
}

.border-start-teal-800 {
  border-left-color: #005E5D !important;
}

.border-end-teal-800 {
  border-right-color: #005E5D !important;
}

.bg-teal-900 {
  background-color: #094040 !important;
}

.text-teal-900 {
  color: #094040 !important;
}

.border-teal-900 {
  border-color: #094040 !important;
}

.border-start-teal-900 {
  border-left-color: #094040 !important;
}

.border-end-teal-900 {
  border-right-color: #094040 !important;
}

.bg-gray-100 {
  background-color: #F4F6F6 !important;
}

.text-gray-100 {
  color: #F4F6F6 !important;
}

.border-gray-100 {
  border-color: #F4F6F6 !important;
}

.border-start-gray-100 {
  border-left-color: #F4F6F6 !important;
}

.border-end-gray-100 {
  border-right-color: #F4F6F6 !important;
}

.bg-gray-200 {
  background-color: #E3E8E8 !important;
}

.text-gray-200 {
  color: #E3E8E8 !important;
}

.border-gray-200 {
  border-color: #E3E8E8 !important;
}

.border-start-gray-200 {
  border-left-color: #E3E8E8 !important;
}

.border-end-gray-200 {
  border-right-color: #E3E8E8 !important;
}

.bg-gray-300 {
  background-color: #D2DADA !important;
}

.text-gray-300 {
  color: #D2DADA !important;
}

.border-gray-300 {
  border-color: #D2DADA !important;
}

.border-start-gray-300 {
  border-left-color: #D2DADA !important;
}

.border-end-gray-300 {
  border-right-color: #D2DADA !important;
}

.bg-gray-400 {
  background-color: #C7CCCC !important;
}

.text-gray-400 {
  color: #C7CCCC !important;
}

.border-gray-400 {
  border-color: #C7CCCC !important;
}

.border-start-gray-400 {
  border-left-color: #C7CCCC !important;
}

.border-end-gray-400 {
  border-right-color: #C7CCCC !important;
}

.bg-gray-500 {
  background-color: #AFB6B6 !important;
}

.text-gray-500 {
  color: #AFB6B6 !important;
}

.border-gray-500 {
  border-color: #AFB6B6 !important;
}

.border-start-gray-500 {
  border-left-color: #AFB6B6 !important;
}

.border-end-gray-500 {
  border-right-color: #AFB6B6 !important;
}

.bg-gray-600 {
  background-color: #8D9696 !important;
}

.text-gray-600 {
  color: #8D9696 !important;
}

.border-gray-600 {
  border-color: #8D9696 !important;
}

.border-start-gray-600 {
  border-left-color: #8D9696 !important;
}

.border-end-gray-600 {
  border-right-color: #8D9696 !important;
}

.bg-gray-700 {
  background-color: #5C7070 !important;
}

.text-gray-700 {
  color: #5C7070 !important;
}

.border-gray-700 {
  border-color: #5C7070 !important;
}

.border-start-gray-700 {
  border-left-color: #5C7070 !important;
}

.border-end-gray-700 {
  border-right-color: #5C7070 !important;
}

.bg-gray-800 {
  background-color: #313D3D !important;
}

.text-gray-800 {
  color: #313D3D !important;
}

.border-gray-800 {
  border-color: #313D3D !important;
}

.border-start-gray-800 {
  border-left-color: #313D3D !important;
}

.border-end-gray-800 {
  border-right-color: #313D3D !important;
}

.bg-gray-900 {
  background-color: #0D2222 !important;
}

.text-gray-900 {
  color: #0D2222 !important;
}

.border-gray-900 {
  border-color: #0D2222 !important;
}

.border-start-gray-900 {
  border-left-color: #0D2222 !important;
}

.border-end-gray-900 {
  border-right-color: #0D2222 !important;
}

.bg-purple-100 {
  background-color: #F7EAF4 !important;
}

.text-purple-100 {
  color: #F7EAF4 !important;
}

.border-purple-100 {
  border-color: #F7EAF4 !important;
}

.border-start-purple-100 {
  border-left-color: #F7EAF4 !important;
}

.border-end-purple-100 {
  border-right-color: #F7EAF4 !important;
}

.bg-purple-200 {
  background-color: #F8DCF1 !important;
}

.text-purple-200 {
  color: #F8DCF1 !important;
}

.border-purple-200 {
  border-color: #F8DCF1 !important;
}

.border-start-purple-200 {
  border-left-color: #F8DCF1 !important;
}

.border-end-purple-200 {
  border-right-color: #F8DCF1 !important;
}

.bg-purple-300 {
  background-color: #F0BDED !important;
}

.text-purple-300 {
  color: #F0BDED !important;
}

.border-purple-300 {
  border-color: #F0BDED !important;
}

.border-start-purple-300 {
  border-left-color: #F0BDED !important;
}

.border-end-purple-300 {
  border-right-color: #F0BDED !important;
}

.bg-purple-400 {
  background-color: #C679C5 !important;
}

.text-purple-400 {
  color: #C679C5 !important;
}

.border-purple-400 {
  border-color: #C679C5 !important;
}

.border-start-purple-400 {
  border-left-color: #C679C5 !important;
}

.border-end-purple-400 {
  border-right-color: #C679C5 !important;
}

.bg-purple-500 {
  background-color: #A45DA3 !important;
}

.text-purple-500 {
  color: #A45DA3 !important;
}

.border-purple-500 {
  border-color: #A45DA3 !important;
}

.border-start-purple-500 {
  border-left-color: #A45DA3 !important;
}

.border-end-purple-500 {
  border-right-color: #A45DA3 !important;
}

.bg-purple-600 {
  background-color: #824481 !important;
}

.text-purple-600 {
  color: #824481 !important;
}

.border-purple-600 {
  border-color: #824481 !important;
}

.border-start-purple-600 {
  border-left-color: #824481 !important;
}

.border-end-purple-600 {
  border-right-color: #824481 !important;
}

.bg-purple-700 {
  background-color: #561157 !important;
}

.text-purple-700 {
  color: #561157 !important;
}

.border-purple-700 {
  border-color: #561157 !important;
}

.border-start-purple-700 {
  border-left-color: #561157 !important;
}

.border-end-purple-700 {
  border-right-color: #561157 !important;
}

.bg-purple-800 {
  background-color: #37003D !important;
}

.text-purple-800 {
  color: #37003D !important;
}

.border-purple-800 {
  border-color: #37003D !important;
}

.border-start-purple-800 {
  border-left-color: #37003D !important;
}

.border-end-purple-800 {
  border-right-color: #37003D !important;
}

.bg-purple-900 {
  background-color: #27002C !important;
}

.text-purple-900 {
  color: #27002C !important;
}

.border-purple-900 {
  border-color: #27002C !important;
}

.border-start-purple-900 {
  border-left-color: #27002C !important;
}

.border-end-purple-900 {
  border-right-color: #27002C !important;
}

.bg-green-100 {
  background-color: #EBFFF5 !important;
}

.text-green-100 {
  color: #EBFFF5 !important;
}

.border-green-100 {
  border-color: #EBFFF5 !important;
}

.border-start-green-100 {
  border-left-color: #EBFFF5 !important;
}

.border-end-green-100 {
  border-right-color: #EBFFF5 !important;
}

.bg-green-200 {
  background-color: #DCFFDE !important;
}

.text-green-200 {
  color: #DCFFDE !important;
}

.border-green-200 {
  border-color: #DCFFDE !important;
}

.border-start-green-200 {
  border-left-color: #DCFFDE !important;
}

.border-end-green-200 {
  border-right-color: #DCFFDE !important;
}

.bg-green-300 {
  background-color: #C2FFE0 !important;
}

.text-green-300 {
  color: #C2FFE0 !important;
}

.border-green-300 {
  border-color: #C2FFE0 !important;
}

.border-start-green-300 {
  border-left-color: #C2FFE0 !important;
}

.border-end-green-300 {
  border-right-color: #C2FFE0 !important;
}

.bg-green-400 {
  background-color: #99FFCC !important;
}

.text-green-400 {
  color: #99FFCC !important;
}

.border-green-400 {
  border-color: #99FFCC !important;
}

.border-start-green-400 {
  border-left-color: #99FFCC !important;
}

.border-end-green-400 {
  border-right-color: #99FFCC !important;
}

.bg-green-500 {
  background-color: #45D98F !important;
}

.text-green-500 {
  color: #45D98F !important;
}

.border-green-500 {
  border-color: #45D98F !important;
}

.border-start-green-500 {
  border-left-color: #45D98F !important;
}

.border-end-green-500 {
  border-right-color: #45D98F !important;
}

.bg-green-600 {
  background-color: #14A85E !important;
}

.text-green-600 {
  color: #14A85E !important;
}

.border-green-600 {
  border-color: #14A85E !important;
}

.border-start-green-600 {
  border-left-color: #14A85E !important;
}

.border-end-green-600 {
  border-right-color: #14A85E !important;
}

.bg-green-700 {
  background-color: #008547 !important;
}

.text-green-700 {
  color: #008547 !important;
}

.border-green-700 {
  border-color: #008547 !important;
}

.border-start-green-700 {
  border-left-color: #008547 !important;
}

.border-end-green-700 {
  border-right-color: #008547 !important;
}

.bg-green-800 {
  background-color: #006636 !important;
}

.text-green-800 {
  color: #006636 !important;
}

.border-green-800 {
  border-color: #006636 !important;
}

.border-start-green-800 {
  border-left-color: #006636 !important;
}

.border-end-green-800 {
  border-right-color: #006636 !important;
}

.bg-green-900 {
  background-color: #00391A !important;
}

.text-green-900 {
  color: #00391A !important;
}

.border-green-900 {
  border-color: #00391A !important;
}

.border-start-green-900 {
  border-left-color: #00391A !important;
}

.border-end-green-900 {
  border-right-color: #00391A !important;
}

.bg-yellow-100 {
  background-color: #FFF9EB !important;
}

.text-yellow-100 {
  color: #FFF9EB !important;
}

.border-yellow-100 {
  border-color: #FFF9EB !important;
}

.border-start-yellow-100 {
  border-left-color: #FFF9EB !important;
}

.border-end-yellow-100 {
  border-right-color: #FFF9EB !important;
}

.bg-yellow-200 {
  background-color: #FFF0D1 !important;
}

.text-yellow-200 {
  color: #FFF0D1 !important;
}

.border-yellow-200 {
  border-color: #FFF0D1 !important;
}

.border-start-yellow-200 {
  border-left-color: #FFF0D1 !important;
}

.border-end-yellow-200 {
  border-right-color: #FFF0D1 !important;
}

.bg-yellow-300 {
  background-color: #FCE5A9 !important;
}

.text-yellow-300 {
  color: #FCE5A9 !important;
}

.border-yellow-300 {
  border-color: #FCE5A9 !important;
}

.border-start-yellow-300 {
  border-left-color: #FCE5A9 !important;
}

.border-end-yellow-300 {
  border-right-color: #FCE5A9 !important;
}

.bg-yellow-400 {
  background-color: #FFD469 !important;
}

.text-yellow-400 {
  color: #FFD469 !important;
}

.border-yellow-400 {
  border-color: #FFD469 !important;
}

.border-start-yellow-400 {
  border-left-color: #FFD469 !important;
}

.border-end-yellow-400 {
  border-right-color: #FFD469 !important;
}

.bg-yellow-500 {
  background-color: #FCC63D !important;
}

.text-yellow-500 {
  color: #FCC63D !important;
}

.border-yellow-500 {
  border-color: #FCC63D !important;
}

.border-start-yellow-500 {
  border-left-color: #FCC63D !important;
}

.border-end-yellow-500 {
  border-right-color: #FCC63D !important;
}

.bg-yellow-600 {
  background-color: #D9A200 !important;
}

.text-yellow-600 {
  color: #D9A200 !important;
}

.border-yellow-600 {
  border-color: #D9A200 !important;
}

.border-start-yellow-600 {
  border-left-color: #D9A200 !important;
}

.border-end-yellow-600 {
  border-right-color: #D9A200 !important;
}

.bg-yellow-700 {
  background-color: #996B00 !important;
}

.text-yellow-700 {
  color: #996B00 !important;
}

.border-yellow-700 {
  border-color: #996B00 !important;
}

.border-start-yellow-700 {
  border-left-color: #996B00 !important;
}

.border-end-yellow-700 {
  border-right-color: #996B00 !important;
}

.bg-yellow-800 {
  background-color: #402D00 !important;
}

.text-yellow-800 {
  color: #402D00 !important;
}

.border-yellow-800 {
  border-color: #402D00 !important;
}

.border-start-yellow-800 {
  border-left-color: #402D00 !important;
}

.border-end-yellow-800 {
  border-right-color: #402D00 !important;
}

.bg-yellow-900 {
  background-color: #402D00 !important;
}

.text-yellow-900 {
  color: #402D00 !important;
}

.border-yellow-900 {
  border-color: #402D00 !important;
}

.border-start-yellow-900 {
  border-left-color: #402D00 !important;
}

.border-end-yellow-900 {
  border-right-color: #402D00 !important;
}

.bg-red-100 {
  background-color: #FFF0ED !important;
}

.text-red-100 {
  color: #FFF0ED !important;
}

.border-red-100 {
  border-color: #FFF0ED !important;
}

.border-start-red-100 {
  border-left-color: #FFF0ED !important;
}

.border-end-red-100 {
  border-right-color: #FFF0ED !important;
}

.bg-red-200 {
  background-color: #FFDCD6 !important;
}

.text-red-200 {
  color: #FFDCD6 !important;
}

.border-red-200 {
  border-color: #FFDCD6 !important;
}

.border-start-red-200 {
  border-left-color: #FFDCD6 !important;
}

.border-end-red-200 {
  border-right-color: #FFDCD6 !important;
}

.bg-red-300 {
  background-color: #FFBFB5 !important;
}

.text-red-300 {
  color: #FFBFB5 !important;
}

.border-red-300 {
  border-color: #FFBFB5 !important;
}

.border-start-red-300 {
  border-left-color: #FFBFB5 !important;
}

.border-end-red-300 {
  border-right-color: #FFBFB5 !important;
}

.bg-red-400 {
  background-color: #FF897A !important;
}

.text-red-400 {
  color: #FF897A !important;
}

.border-red-400 {
  border-color: #FF897A !important;
}

.border-start-red-400 {
  border-left-color: #FF897A !important;
}

.border-end-red-400 {
  border-right-color: #FF897A !important;
}

.bg-red-500 {
  background-color: #E44D47 !important;
}

.text-red-500 {
  color: #E44D47 !important;
}

.border-red-500 {
  border-color: #E44D47 !important;
}

.border-start-red-500 {
  border-left-color: #E44D47 !important;
}

.border-end-red-500 {
  border-right-color: #E44D47 !important;
}

.bg-red-600 {
  background-color: #D61F1F !important;
}

.text-red-600 {
  color: #D61F1F !important;
}

.border-red-600 {
  border-color: #D61F1F !important;
}

.border-start-red-600 {
  border-left-color: #D61F1F !important;
}

.border-end-red-600 {
  border-right-color: #D61F1F !important;
}

.bg-red-700 {
  background-color: #BA1B1B !important;
}

.text-red-700 {
  color: #BA1B1B !important;
}

.border-red-700 {
  border-color: #BA1B1B !important;
}

.border-start-red-700 {
  border-left-color: #BA1B1B !important;
}

.border-end-red-700 {
  border-right-color: #BA1B1B !important;
}

.bg-red-800 {
  background-color: #930006 !important;
}

.text-red-800 {
  color: #930006 !important;
}

.border-red-800 {
  border-color: #930006 !important;
}

.border-start-red-800 {
  border-left-color: #930006 !important;
}

.border-end-red-800 {
  border-right-color: #930006 !important;
}

.bg-red-900 {
  background-color: #680003 !important;
}

.text-red-900 {
  color: #680003 !important;
}

.border-red-900 {
  border-color: #680003 !important;
}

.border-start-red-900 {
  border-left-color: #680003 !important;
}

.border-end-red-900 {
  border-right-color: #680003 !important;
}

.bg-blue-100 {
  background-color: #EBF5FF !important;
}

.text-blue-100 {
  color: #EBF5FF !important;
}

.border-blue-100 {
  border-color: #EBF5FF !important;
}

.border-start-blue-100 {
  border-left-color: #EBF5FF !important;
}

.border-end-blue-100 {
  border-right-color: #EBF5FF !important;
}

.bg-blue-200 {
  background-color: #CFE7FF !important;
}

.text-blue-200 {
  color: #CFE7FF !important;
}

.border-blue-200 {
  border-color: #CFE7FF !important;
}

.border-start-blue-200 {
  border-left-color: #CFE7FF !important;
}

.border-end-blue-200 {
  border-right-color: #CFE7FF !important;
}

.bg-blue-300 {
  background-color: #A3D1FF !important;
}

.text-blue-300 {
  color: #A3D1FF !important;
}

.border-blue-300 {
  border-color: #A3D1FF !important;
}

.border-start-blue-300 {
  border-left-color: #A3D1FF !important;
}

.border-end-blue-300 {
  border-right-color: #A3D1FF !important;
}

.bg-blue-400 {
  background-color: #66B3FF !important;
}

.text-blue-400 {
  color: #66B3FF !important;
}

.border-blue-400 {
  border-color: #66B3FF !important;
}

.border-start-blue-400 {
  border-left-color: #66B3FF !important;
}

.border-end-blue-400 {
  border-right-color: #66B3FF !important;
}

.bg-blue-500 {
  background-color: #2196F3 !important;
}

.text-blue-500 {
  color: #2196F3 !important;
}

.border-blue-500 {
  border-color: #2196F3 !important;
}

.border-start-blue-500 {
  border-left-color: #2196F3 !important;
}

.border-end-blue-500 {
  border-right-color: #2196F3 !important;
}

.bg-blue-600 {
  background-color: #007ACC !important;
}

.text-blue-600 {
  color: #007ACC !important;
}

.border-blue-600 {
  border-color: #007ACC !important;
}

.border-start-blue-600 {
  border-left-color: #007ACC !important;
}

.border-end-blue-600 {
  border-right-color: #007ACC !important;
}

.bg-blue-700 {
  background-color: #005C99 !important;
}

.text-blue-700 {
  color: #005C99 !important;
}

.border-blue-700 {
  border-color: #005C99 !important;
}

.border-start-blue-700 {
  border-left-color: #005C99 !important;
}

.border-end-blue-700 {
  border-right-color: #005C99 !important;
}

.bg-blue-800 {
  background-color: #00325A !important;
}

.text-blue-800 {
  color: #00325A !important;
}

.border-blue-800 {
  border-color: #00325A !important;
}

.border-start-blue-800 {
  border-left-color: #00325A !important;
}

.border-end-blue-800 {
  border-right-color: #00325A !important;
}

.bg-blue-900 {
  background-color: #002644 !important;
}

.text-blue-900 {
  color: #002644 !important;
}

.border-blue-900 {
  border-color: #002644 !important;
}

.border-start-blue-900 {
  border-left-color: #002644 !important;
}

.border-end-blue-900 {
  border-right-color: #002644 !important;
}

.nb__anchor-primary {
  color: #00807D !important;
  text-decoration: underline;
}
.nb__anchor-primary:hover {
  text-decoration: none;
}

.d-flex, .rw__otp, .rw__dynamic-dialog .p-dialog-content, .rw__dialog .p-dialog-content, .rw__notification-toast-error .p-toast-message-content, .rw__notification-error .notification-footer, .rw__notification-error .notification-body, .rw__notification-error, .rw__notification-toast-warning .p-toast-message-content, .rw__notification-warning .notification-footer, .rw__notification-warning .notification-body, .rw__notification-warning, .rw__notification-toast-success .p-toast-message-content, .rw__notification-success .notification-footer, .rw__notification-success .notification-body, .rw__notification-success, .rw__notification-toast-info .p-toast-message-content, .rw__notification-info .notification-footer, .rw__notification-info .notification-body, .rw__notification-info, .rw__datatable-custom-paginator .p-paginator .p-dropdown-label, .rw__datatable-internal-paginator .p-paginator .p-dropdown-label, .rw__accordion .p-accordion-toggle-icon, .rw__accordion .p-accordion-header .rw__tag-circle, .rw__columns, .rw__steps .p-steps-item, .rw__custom-radio-button-block-wrap, .rw__custom-radio-button-inline-wrap, .rw__radio-button-block-wrap, .rw__radio-button-inline-wrap, .rw__button-icon .rw__button, .rw__button-icon, .rw__input-compact .p-dropdown-label, .rw__icon-box-outline-light, .rw__icon-box-white, .rw__icon-box-grey, .rw__icon-box-info, .rw__icon-box-success, .rw__icon-box-warning, .rw__icon-box-danger, .rw__icon-box-teal, .rw__icon-box-primary {
  display: flex;
}

@media (min-width: 1366px) {
  .d-lg-flex {
    display: flex !important;
  }
}
@media (min-width: 960px) {
  .d-md-flex {
    display: flex !important;
  }
}
@media (min-width: 640px) {
  .d-sm-flex {
    display: flex !important;
  }
}
.d-inline-flex {
  display: inline-flex;
}

@media (min-width: 1366px) {
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 960px) {
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 640px) {
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
.d-none, .rw__input .fa-circle-xmark, .rw__input .fa-xmark {
  display: none;
}

@media (min-width: 1366px) {
  .d-lg-none {
    display: none !important;
  }
}
@media (min-width: 960px) {
  .d-md-none {
    display: none !important;
  }
}
@media (min-width: 640px) {
  .d-sm-none {
    display: none !important;
  }
}
.d-block, .rw__tooltip .p-tooltip-arrow, .rw__input-icon-close .fa-xmark, .rw__input {
  display: block;
}

@media (min-width: 1366px) {
  .d-lg-block {
    display: block !important;
  }
}
@media (min-width: 960px) {
  .d-md-block {
    display: block !important;
  }
}
@media (min-width: 640px) {
  .d-sm-block {
    display: block !important;
  }
}
.d-inline-block {
  display: inline-block;
}

@media (min-width: 1366px) {
  .d-lg-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 960px) {
  .d-md-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 640px) {
  .d-sm-inline-block {
    display: inline-block !important;
  }
}
.d-table {
  display: table;
}

@media (min-width: 1366px) {
  .d-lg-table {
    display: table !important;
  }
}
@media (min-width: 960px) {
  .d-md-table {
    display: table !important;
  }
}
@media (min-width: 640px) {
  .d-sm-table {
    display: table !important;
  }
}
.d-table-cell {
  display: table-cell;
}

@media (min-width: 1366px) {
  .d-lg-table-cell {
    display: table-cell !important;
  }
}
@media (min-width: 960px) {
  .d-md-table-cell {
    display: table-cell !important;
  }
}
@media (min-width: 640px) {
  .d-sm-table-cell {
    display: table-cell !important;
  }
}
.flex-row, .rw__steps .p-steps-item {
  flex-direction: row;
}

@media (min-width: 1366px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
}
@media (min-width: 960px) {
  .flex-md-row {
    flex-direction: row !important;
  }
}
@media (min-width: 640px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
}
.flex-row-reverse {
  flex-direction: row-reverse;
}

@media (min-width: 1366px) {
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
}
@media (min-width: 960px) {
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
}
@media (min-width: 640px) {
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
}
.flex-column, .rw__dynamic-dialog .p-dialog-content, .rw__dialog .p-dialog-content, .rw__custom-radio-button-block-wrap, .rw__radio-button-block-wrap {
  flex-direction: column;
}

@media (min-width: 1366px) {
  .flex-lg-column {
    flex-direction: column !important;
  }
}
@media (min-width: 960px) {
  .flex-md-column {
    flex-direction: column !important;
  }
}
@media (min-width: 640px) {
  .flex-sm-column {
    flex-direction: column !important;
  }
}
.flex-column-reverse {
  flex-direction: column-reverse;
}

@media (min-width: 1366px) {
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
}
@media (min-width: 960px) {
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
}
@media (min-width: 640px) {
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
}
.flex-wrap, .rw__columns, .rw__custom-radio-button-block-wrap, .rw__custom-radio-button-inline-wrap, .rw__radio-button-block-wrap, .rw__radio-button-inline-wrap {
  flex-wrap: wrap;
}

@media (min-width: 1366px) {
  .flex-wrap-lg {
    flex-wrap: wrap !important;
  }
}
@media (min-width: 960px) {
  .flex-wrap-md {
    flex-wrap: wrap !important;
  }
}
@media (min-width: 640px) {
  .flex-wrap-sm {
    flex-wrap: wrap !important;
  }
}
.flex-fill {
  flex: 1 1 auto;
}

@media (min-width: 1366px) {
  .flex-fill-lg {
    flex: 1 1 auto !important;
  }
}
@media (min-width: 960px) {
  .flex-fill-md {
    flex: 1 1 auto !important;
  }
}
@media (min-width: 640px) {
  .flex-fill-sm {
    flex: 1 1 auto !important;
  }
}
.flex-1 {
  flex: 1;
}

@media (min-width: 1366px) {
  .flex-1-lg {
    flex: 1 !important;
  }
}
@media (min-width: 960px) {
  .flex-1-md {
    flex: 1 !important;
  }
}
@media (min-width: 640px) {
  .flex-1-sm {
    flex: 1 !important;
  }
}
.flex-grow-0 {
  flex-grow: 0;
}

@media (min-width: 1366px) {
  .flex-grow-lg-0 {
    flex-grow: 0 !important;
  }
}
@media (min-width: 960px) {
  .flex-grow-md-0 {
    flex-grow: 0 !important;
  }
}
@media (min-width: 640px) {
  .flex-grow-sm-0 {
    flex-grow: 0 !important;
  }
}
.flex-grow-1 {
  flex-grow: 1;
}

@media (min-width: 1366px) {
  .flex-grow-lg-1 {
    flex-grow: 1 !important;
  }
}
@media (min-width: 960px) {
  .flex-grow-md-1 {
    flex-grow: 1 !important;
  }
}
@media (min-width: 640px) {
  .flex-grow-sm-1 {
    flex-grow: 1 !important;
  }
}
.flex-shrink-0 {
  flex-shrink: 0;
}

@media (min-width: 1366px) {
  .flex-shrink-lg-0 {
    flex-shrink: 0 !important;
  }
}
@media (min-width: 960px) {
  .flex-shrink-md-0 {
    flex-shrink: 0 !important;
  }
}
@media (min-width: 640px) {
  .flex-shrink-sm-0 {
    flex-shrink: 0 !important;
  }
}
.align-items-center, .rw__otp, .rw__notification-toast-error .p-toast-message-content, .rw__notification-error .notification-footer, .rw__notification-error, .rw__notification-toast-warning .p-toast-message-content, .rw__notification-warning .notification-footer, .rw__notification-warning, .rw__notification-toast-success .p-toast-message-content, .rw__notification-success .notification-footer, .rw__notification-success, .rw__notification-toast-info .p-toast-message-content, .rw__notification-info .notification-footer, .rw__notification-info, .rw__datatable-custom-paginator .p-paginator .p-dropdown-label, .rw__datatable-internal-paginator .p-paginator .p-dropdown-label, .rw__accordion .p-accordion-toggle-icon, .rw__accordion .p-accordion-header .rw__tag-circle, .rw__custom-radio-button-inline-wrap, .rw__radio-button-inline-wrap, .rw__button-icon .rw__button, .rw__button-icon, .rw__input-compact .p-dropdown-label, .rw__icon-box-outline-light, .rw__icon-box-white, .rw__icon-box-grey, .rw__icon-box-info, .rw__icon-box-success, .rw__icon-box-warning, .rw__icon-box-danger, .rw__icon-box-teal, .rw__icon-box-primary {
  align-items: center;
}

@media (min-width: 1366px) {
  .align-items-lg-center {
    align-items: center !important;
  }
}
@media (min-width: 960px) {
  .align-items-md-center {
    align-items: center !important;
  }
}
@media (min-width: 640px) {
  .align-items-sm-center {
    align-items: center !important;
  }
}
.align-items-start {
  align-items: flex-start;
}

@media (min-width: 1366px) {
  .align-items-lg-start {
    align-items: flex-start !important;
  }
}
@media (min-width: 960px) {
  .align-items-md-start {
    align-items: flex-start !important;
  }
}
@media (min-width: 640px) {
  .align-items-sm-start {
    align-items: flex-start !important;
  }
}
.align-items-end {
  align-items: flex-end;
}

@media (min-width: 1366px) {
  .align-items-lg-end {
    align-items: flex-end !important;
  }
}
@media (min-width: 960px) {
  .align-items-md-end {
    align-items: flex-end !important;
  }
}
@media (min-width: 640px) {
  .align-items-sm-end {
    align-items: flex-end !important;
  }
}
.justify-content-center, .rw__accordion .p-accordion-toggle-icon, .rw__accordion .p-accordion-header .rw__tag-circle, .rw__steps .p-steps-item, .rw__button-icon .rw__button, .rw__button-icon, .rw__icon-box-outline-light, .rw__icon-box-white, .rw__icon-box-grey, .rw__icon-box-info, .rw__icon-box-success, .rw__icon-box-warning, .rw__icon-box-danger, .rw__icon-box-teal, .rw__icon-box-primary {
  justify-content: center;
}

@media (min-width: 1366px) {
  .justify-content-lg-center {
    justify-content: center !important;
  }
}
@media (min-width: 960px) {
  .justify-content-md-center {
    justify-content: center !important;
  }
}
@media (min-width: 640px) {
  .justify-content-sm-center {
    justify-content: center !important;
  }
}
.justify-content-start {
  justify-content: flex-start;
}

@media (min-width: 1366px) {
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
}
@media (min-width: 960px) {
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
}
@media (min-width: 640px) {
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
}
.justify-content-end {
  justify-content: flex-end;
}

@media (min-width: 1366px) {
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
}
@media (min-width: 960px) {
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
}
@media (min-width: 640px) {
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
}
.justify-content-between {
  justify-content: space-between;
}

@media (min-width: 1366px) {
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
}
@media (min-width: 960px) {
  .justify-content-md-between {
    justify-content: space-between !important;
  }
}
@media (min-width: 640px) {
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
}
.justify-content-around {
  justify-content: space-around;
}

@media (min-width: 1366px) {
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
}
@media (min-width: 960px) {
  .justify-content-md-around {
    justify-content: space-around !important;
  }
}
@media (min-width: 640px) {
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
}
.justify-content-evenly {
  justify-content: space-evenly;
}

@media (min-width: 1366px) {
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
}
@media (min-width: 960px) {
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
}
@media (min-width: 640px) {
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
}
.position-static {
  position: static !important;
}

.position-absolute, .rw__steps .p-steps-item:last-child:before, .rw__steps .p-steps-item:before, .rw__input-compact.rw__input-icon .fa-regular,
.rw__input-compact.rw__input-icon .fa-solid,
.rw__input-compact.rw__input-icon .fa-lg,
.rw__input-compact.rw__input-icon [class^=fa], .rw__input-compact > label, .rw__input-default.rw__input-icon [class^=fa], .rw__input-float.rw__input-icon [class^=fa] {
  position: absolute !important;
}

.position-relative, .rw__steps .p-steps-item, .rw__input {
  position: relative !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.start-0, .rw__input-compact > label {
  left: 0 !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.end-0 {
  right: 0 !important;
}

.rw__fs-1 {
  font-size: 2.75rem !important;
}

.rw__fs-2 {
  font-size: 2.25rem !important;
}

.rw__fs-3 {
  font-size: 2rem !important;
}

.rw__fs-4 {
  font-size: 1.75rem !important;
}

.rw__fs-5 {
  font-size: 1.5rem !important;
}

.rw__fs-6, .rw__dynamic-dialog .p-dialog-title, .rw__dialog .p-dialog-title, .rw__notification-error .notification-body i, .rw__notification-warning .notification-body i, .rw__notification-success .notification-body i, .rw__notification-info .notification-body i {
  font-size: 1.25rem !important;
}

.rw__fs-7, .rw__datatable-custom-paginator .p-paginator .p-dropdown-trigger-icon, .rw__datatable-custom-paginator .p-paginator .p-link .p-paginator-icon.pi-angle-double-left, .rw__datatable-custom-paginator .p-paginator .p-link .p-paginator-icon.pi-angle-double-right, .rw__datatable-custom-paginator .p-paginator .p-link .p-paginator-icon {
  font-size: 1.125rem !important;
}

.rw__fs-8, .rw__notification-toast-error .p-toast-icon-close-icon, .rw__notification-toast-warning .p-toast-icon-close-icon, .rw__notification-toast-success .p-toast-icon-close-icon, .rw__notification-toast-info .p-toast-icon-close-icon, .rw__steps .p-steps-item .p-menuitem-link, .rw__input-prefix-addon, .rw__input-suffix-addon, .rw__input-default .p-dropdown .p-dropdown-trigger [class^=fa],
.rw__input-default .p-dropdown .p-multiselect-trigger [class^=fa],
.rw__input-default .p-multiselect .p-dropdown-trigger [class^=fa],
.rw__input-default .p-multiselect .p-multiselect-trigger [class^=fa], .rw__input-default.rw__input-icon [class^=fa]:not(.fa-xmark), .rw__input-default input, .rw__input-float.rw__input-icon [class^=fa]:not(.fa-xmark), .rw__input-float textarea, .rw__input-float input {
  font-size: 1rem !important;
}

.rw__fs-9, .rw__dynamic-dialog .p-dialog-header-close-icon, .p-toast .p-toast-message.p-toast-message-error .p-toast-message-text, .rw__notification-error .notification-body p, .p-toast .p-toast-message.p-toast-message-warning .p-toast-message-text, .rw__notification-warning .notification-body p, .p-toast .p-toast-message.p-toast-message-success .p-toast-message-text, .rw__notification-success .notification-body p, .p-toast .p-toast-message.p-toast-message-info .p-toast-message-text, .rw__notification-info .notification-body p, .rw__datatable-custom-paginator .p-paginator .p-dropdown-label, .rw__datatable-custom-paginator .p-paginator .p-link, .rw__datatable-custom-paginator .p-paginator .p-paginator-current, .rw__accordion .p-accordion-header-link, .rw__tooltip .p-tooltip-text, .rw__breadcrumb .p-breadcrumb ul li a, .rw__switch + label, .rw__checkbox + label, .rw__checkbox-md + label, .rw__custom-radio-button .p-radiobutton-label, .rw__radio-button .p-radiobutton-label, .rw__input-compact .p-multiselect-label, .rw__input-compact .p-dropdown-label, .rw__input-compact input {
  font-size: 0.875rem !important;
}

.rw__fs-10, .rw__dialog .p-dialog-header-close-icon, .rw__datatable-internal-paginator .p-paginator .p-dropdown-label, .rw__datatable-internal-paginator .p-paginator .p-link .p-paginator-icon, .rw__datatable-internal-paginator .p-paginator .p-link, .rw__datatable-internal-paginator .p-paginator .p-paginator-current, .rw__datatable-paginator .p-paginator-pages .p-paginator-page, .rw__datatable-paginator .p-paginator-element .p-paginator-icon, .rw__breadcrumb .p-breadcrumb-chevron, .rw__checkbox-sm + label, .rw__input .p-multiselect-token-icon, .rw__input .p-multiselect-token, .rw__input-compact > label, .rw__input-default.rw__input-icon-close .fa-xmark, .rw__input-float.rw__input-icon-close .fa-xmark {
  font-size: 0.75rem !important;
}

.rw__fs-11, .rw__datatable-internal-paginator .p-paginator .p-link .p-paginator-icon.pi-angle-double-left, .rw__datatable-internal-paginator .p-paginator .p-link .p-paginator-icon.pi-angle-double-right, .rw__datatable-paginator .p-paginator-element .p-paginator-icon.pi-angle-double-left, .rw__datatable-paginator .p-paginator-element .p-paginator-icon.pi-angle-double-right, .rw__input-compact.rw__input-icon-close .fa-xmark, .rw__input-compact .p-multiselect-trigger-icon, .rw__input-compact .p-dropdown-trigger-icon {
  font-size: 0.625rem !important;
}

.rw__fs-12, .rw__accordion .p-accordion-toggle-icon {
  font-size: 0.5rem !important;
}

.fw-400, .rw__notification-error .notification-body p, .rw__notification-warning .notification-body p, .rw__notification-success .notification-body p, .rw__notification-info .notification-body p, .rw__datatable-custom-paginator .p-paginator .p-link, .rw__datatable-custom-paginator .p-paginator .p-paginator-current, .rw__p-tabs .p-tabview-nav li.p-highlight.p-disabled .p-tabview-nav-link, .rw__p-tabs .p-tabview-nav li a.p-tabview-nav-link, .rw__tabs li.p-highlight.p-disabled .p-tabview-nav-link, .rw__tabs li a.p-tabview-nav-link, .rw__breadcrumb .p-breadcrumb ul li a, .rw__switch + label, .rw__checkbox + label, .rw__custom-radio-button .p-radiobutton-label, .rw__radio-button .p-radiobutton-label, .rw__button.p-splitbutton .p-splitbutton-defaultbutton, .rw__button {
  font-weight: 400 !important;
}

.fw-500, .rw__dynamic-dialog .p-dialog-title, .rw__dialog .p-dialog-title, .rw__datatable-v3 .p-datatable-thead tr th, .rw__datatable .p-datatable-thead tr th, .rw__accordion .p-accordion-header-link, .rw__p-tabs .p-tabview-nav li.p-highlight a.p-tabview-nav-link, .rw__tabs li.p-highlight .p-tabview-nav-link, .rtl .rw__button, .rw__input .p-multiselect-token {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700, .rw__steps .p-steps-item .p-menuitem-link .p-steps-number, .rw__input-custom-multi-select .p-multiselect .p-multiselect-label {
  font-weight: 700 !important;
}

.rw__h1 {
  font-size: 2.75rem;
  font-weight: 500;
  letter-spacing: 0rem;
  line-height: 3.25rem;
  margin: 0;
}

.rw__h2 {
  font-size: 2rem;
  font-weight: 500;
  letter-spacing: 0rem;
  line-height: 2.5rem;
  margin: 0;
}

.rw__h3 {
  font-size: 1.5rem;
  font-weight: 500;
  letter-spacing: 0rem;
  line-height: 2rem;
  margin: 0;
}

.rw__h4 {
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0rem;
  line-height: 1.75rem;
  margin: 0;
}

.rw__h5 {
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0rem;
  line-height: 1.5rem;
  margin: 0;
}

.rw__h6 {
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0rem;
  line-height: 1.25rem;
  margin: 0;
}

text-start {
  text-align: left;
}

text-center {
  text-align: center;
}

text-end {
  text-align: right;
}

html[dir=rtl] .rw__p-0, html[dir=rtl] .rw__datatable-custom-paginator .p-paginator .p-paginator-current, .rw__datatable-custom-paginator .p-paginator html[dir=rtl] .p-paginator-current, html[dir=rtl] .rw__datatable-internal-paginator .p-paginator .p-paginator-current, .rw__datatable-internal-paginator .p-paginator html[dir=rtl] .p-paginator-current, html[dir=rtl] .rw__datatable-paginator .p-paginator, .rw__datatable-paginator html[dir=rtl] .p-paginator, html[dir=rtl] .rw__steps .p-steps-item .p-menuitem-link .p-steps-title, .rw__steps .p-steps-item .p-menuitem-link html[dir=rtl] .p-steps-title, html[dir=rtl] .rw__button-tooltip,
html[dir=ltr] .rw__p-0,
html[dir=ltr] .rw__datatable-custom-paginator .p-paginator .p-paginator-current,
.rw__datatable-custom-paginator .p-paginator html[dir=ltr] .p-paginator-current,
html[dir=ltr] .rw__datatable-internal-paginator .p-paginator .p-paginator-current,
.rw__datatable-internal-paginator .p-paginator html[dir=ltr] .p-paginator-current,
html[dir=ltr] .rw__datatable-paginator .p-paginator,
.rw__datatable-paginator html[dir=ltr] .p-paginator,
html[dir=ltr] .rw__steps .p-steps-item .p-menuitem-link .p-steps-title,
.rw__steps .p-steps-item .p-menuitem-link html[dir=ltr] .p-steps-title,
html[dir=ltr] .rw__button-tooltip {
  padding: 0rem !important;
}
html[dir=rtl] .rw__px-0,
html[dir=ltr] .rw__px-0 {
  padding-left: 0rem !important;
  padding-right: 0rem !important;
}
html[dir=rtl] .rw__py-0,
html[dir=ltr] .rw__py-0 {
  padding-top: 0rem !important;
  padding-bottom: 0rem !important;
}
html[dir=rtl] .rw__pt-0,
html[dir=ltr] .rw__pt-0 {
  padding-top: 0rem !important;
}
html[dir=rtl] .rw__pb-0,
html[dir=ltr] .rw__pb-0 {
  padding-bottom: 0rem !important;
}
html[dir=rtl] .rw__m-0, html[dir=rtl] .rw__datatable-custom-paginator .p-paginator .p-paginator-current, .rw__datatable-custom-paginator .p-paginator html[dir=rtl] .p-paginator-current, html[dir=rtl] .rw__datatable-internal-paginator .p-paginator .p-paginator-current, .rw__datatable-internal-paginator .p-paginator html[dir=rtl] .p-paginator-current, html[dir=rtl] .rw__datatable-paginator .p-paginator-element, .rw__datatable-paginator html[dir=rtl] .p-paginator-element,
html[dir=ltr] .rw__m-0,
html[dir=ltr] .rw__datatable-custom-paginator .p-paginator .p-paginator-current,
.rw__datatable-custom-paginator .p-paginator html[dir=ltr] .p-paginator-current,
html[dir=ltr] .rw__datatable-internal-paginator .p-paginator .p-paginator-current,
.rw__datatable-internal-paginator .p-paginator html[dir=ltr] .p-paginator-current,
html[dir=ltr] .rw__datatable-paginator .p-paginator-element,
.rw__datatable-paginator html[dir=ltr] .p-paginator-element {
  margin: 0rem !important;
}
html[dir=rtl] .rw__mx-0,
html[dir=ltr] .rw__mx-0 {
  margin-left: 0rem !important;
  margin-right: 0rem !important;
}
html[dir=rtl] .rw__my-0,
html[dir=ltr] .rw__my-0 {
  margin-top: 0rem !important;
  margin-bottom: 0rem !important;
}
html[dir=rtl] .rw__mt-0,
html[dir=ltr] .rw__mt-0 {
  margin-top: 0rem !important;
}
html[dir=rtl] .rw__mb-0, html[dir=rtl] .rw__input-compact > label, html[dir=rtl] .rw__input label, .rw__input html[dir=rtl] label,
html[dir=ltr] .rw__mb-0,
html[dir=ltr] .rw__input-compact > label,
html[dir=ltr] .rw__input label,
.rw__input html[dir=ltr] label {
  margin-bottom: 0rem !important;
}
html[dir=rtl] .ltr .rw__ps-0,
html[dir=ltr] .ltr .rw__ps-0 {
  padding-left: 0rem !important;
}
html[dir=rtl] .ltr .rw__pe-0,
html[dir=ltr] .ltr .rw__pe-0 {
  padding-right: 0rem !important;
}
html[dir=rtl] .ltr .rw__ms-0,
html[dir=ltr] .ltr .rw__ms-0 {
  margin-left: 0rem !important;
}
html[dir=rtl] .ltr .rw__me-0, html[dir=rtl] .ltr .rw__accordion .p-accordion-toggle-icon, .rw__accordion html[dir=rtl] .ltr .p-accordion-toggle-icon,
html[dir=ltr] .ltr .rw__me-0,
html[dir=ltr] .ltr .rw__accordion .p-accordion-toggle-icon,
.rw__accordion html[dir=ltr] .ltr .p-accordion-toggle-icon {
  margin-right: 0rem !important;
}
html[dir=rtl] .rtl .rw__ps-0,
html[dir=ltr] .rtl .rw__ps-0 {
  padding-right: 0rem !important;
}
html[dir=rtl] .rtl .rw__pe-0,
html[dir=ltr] .rtl .rw__pe-0 {
  padding-left: 0rem !important;
}
html[dir=rtl] .rtl .rw__ms-0,
html[dir=ltr] .rtl .rw__ms-0 {
  margin-right: 0rem !important;
}
html[dir=rtl] .rtl .rw__me-0, html[dir=rtl] .rtl .rw__accordion .p-accordion-toggle-icon, .rw__accordion html[dir=rtl] .rtl .p-accordion-toggle-icon,
html[dir=ltr] .rtl .rw__me-0,
html[dir=ltr] .rtl .rw__accordion .p-accordion-toggle-icon,
.rw__accordion html[dir=ltr] .rtl .p-accordion-toggle-icon {
  margin-left: 0rem !important;
}

.sb-show-main .rw__p-0, .sb-show-main .rw__datatable-custom-paginator .p-paginator .p-paginator-current, .rw__datatable-custom-paginator .p-paginator .sb-show-main .p-paginator-current, .sb-show-main .rw__datatable-internal-paginator .p-paginator .p-paginator-current, .rw__datatable-internal-paginator .p-paginator .sb-show-main .p-paginator-current, .sb-show-main .rw__datatable-paginator .p-paginator, .rw__datatable-paginator .sb-show-main .p-paginator, .sb-show-main .rw__steps .p-steps-item .p-menuitem-link .p-steps-title, .rw__steps .p-steps-item .p-menuitem-link .sb-show-main .p-steps-title, .sb-show-main .rw__button-tooltip {
  padding: 0rem !important;
}
.sb-show-main .rw__px-0 {
  padding-left: 0rem !important;
  padding-right: 0rem !important;
}
.sb-show-main .rw__py-0 {
  padding-top: 0rem !important;
  padding-bottom: 0rem !important;
}
.sb-show-main .rw__pt-0 {
  padding-top: 0rem !important;
}
.sb-show-main .rw__pb-0 {
  padding-bottom: 0rem !important;
}
.sb-show-main .rw__m-0, .sb-show-main .rw__datatable-custom-paginator .p-paginator .p-paginator-current, .rw__datatable-custom-paginator .p-paginator .sb-show-main .p-paginator-current, .sb-show-main .rw__datatable-internal-paginator .p-paginator .p-paginator-current, .rw__datatable-internal-paginator .p-paginator .sb-show-main .p-paginator-current, .sb-show-main .rw__datatable-paginator .p-paginator-element, .rw__datatable-paginator .sb-show-main .p-paginator-element {
  margin: 0rem !important;
}
.sb-show-main .rw__mx-0 {
  margin-left: 0rem !important;
  margin-right: 0rem !important;
}
.sb-show-main .rw__my-0 {
  margin-top: 0rem !important;
  margin-bottom: 0rem !important;
}
.sb-show-main .rw__mt-0 {
  margin-top: 0rem !important;
}
.sb-show-main .rw__mb-0, .sb-show-main .rw__input-compact > label, .sb-show-main .rw__input label, .rw__input .sb-show-main label {
  margin-bottom: 0rem !important;
}
.sb-show-main .rw__ps-0 {
  padding-left: 0rem !important;
}
.sb-show-main .rw__pe-0 {
  padding-right: 0rem !important;
}
.sb-show-main .rw__ms-0 {
  margin-left: 0rem !important;
}
.sb-show-main .rw__me-0, .sb-show-main .rw__accordion .p-accordion-toggle-icon, .rw__accordion .sb-show-main .p-accordion-toggle-icon {
  margin-right: 0rem !important;
}

@media (min-width: 1366px) {
  .sb-show-main .ltr .rw__m-lg-0,
  .sb-show-main .rtl .rw__m-lg-0,
  html[dir=rtl] .ltr .rw__m-lg-0,
  html[dir=rtl] .rtl .rw__m-lg-0,
  html[dir=ltr] .ltr .rw__m-lg-0,
  html[dir=ltr] .rtl .rw__m-lg-0 {
    margin: 0rem !important;
  }
  .sb-show-main .ltr .rw__p-lg-0,
  .sb-show-main .rtl .rw__p-lg-0,
  html[dir=rtl] .ltr .rw__p-lg-0,
  html[dir=rtl] .rtl .rw__p-lg-0,
  html[dir=ltr] .ltr .rw__p-lg-0,
  html[dir=ltr] .rtl .rw__p-lg-0 {
    padding: 0rem !important;
  }
  .sb-show-main .ltr .rw__mx-lg-0,
  .sb-show-main .rtl .rw__mx-lg-0,
  html[dir=rtl] .ltr .rw__mx-lg-0,
  html[dir=rtl] .rtl .rw__mx-lg-0,
  html[dir=ltr] .ltr .rw__mx-lg-0,
  html[dir=ltr] .rtl .rw__mx-lg-0 {
    margin-left: 0rem !important;
    margin-right: 0rem !important;
  }
  .sb-show-main .ltr .rw__my-lg-0,
  .sb-show-main .rtl .rw__my-lg-0,
  html[dir=rtl] .ltr .rw__my-lg-0,
  html[dir=rtl] .rtl .rw__my-lg-0,
  html[dir=ltr] .ltr .rw__my-lg-0,
  html[dir=ltr] .rtl .rw__my-lg-0 {
    margin-top: 0rem !important;
    margin-bottom: 0rem !important;
  }
  .sb-show-main .ltr .rw__mt-lg-0,
  .sb-show-main .rtl .rw__mt-lg-0,
  html[dir=rtl] .ltr .rw__mt-lg-0,
  html[dir=rtl] .rtl .rw__mt-lg-0,
  html[dir=ltr] .ltr .rw__mt-lg-0,
  html[dir=ltr] .rtl .rw__mt-lg-0 {
    margin-top: 0rem !important;
  }
  .sb-show-main .ltr .rw__mb-lg-0,
  .sb-show-main .rtl .rw__mb-lg-0,
  html[dir=rtl] .ltr .rw__mb-lg-0,
  html[dir=rtl] .rtl .rw__mb-lg-0,
  html[dir=ltr] .ltr .rw__mb-lg-0,
  html[dir=ltr] .rtl .rw__mb-lg-0 {
    margin-bottom: 0rem !important;
  }
  .sb-show-main .ltr .rw__px-lg-0,
  .sb-show-main .rtl .rw__px-lg-0,
  html[dir=rtl] .ltr .rw__px-lg-0,
  html[dir=rtl] .rtl .rw__px-lg-0,
  html[dir=ltr] .ltr .rw__px-lg-0,
  html[dir=ltr] .rtl .rw__px-lg-0 {
    padding-left: 0rem !important;
    padding-right: 0rem !important;
  }
  .sb-show-main .ltr .rw__py-lg-0,
  .sb-show-main .rtl .rw__py-lg-0,
  html[dir=rtl] .ltr .rw__py-lg-0,
  html[dir=rtl] .rtl .rw__py-lg-0,
  html[dir=ltr] .ltr .rw__py-lg-0,
  html[dir=ltr] .rtl .rw__py-lg-0 {
    padding-top: 0rem !important;
    padding-bottom: 0rem !important;
  }
  .sb-show-main .ltr .rw__pt-lg-0,
  .sb-show-main .rtl .rw__pt-lg-0,
  html[dir=rtl] .ltr .rw__pt-lg-0,
  html[dir=rtl] .rtl .rw__pt-lg-0,
  html[dir=ltr] .ltr .rw__pt-lg-0,
  html[dir=ltr] .rtl .rw__pt-lg-0 {
    padding-top: 0rem !important;
  }
  .sb-show-main .ltr .rw__pb-lg-0,
  .sb-show-main .rtl .rw__pb-lg-0,
  html[dir=rtl] .ltr .rw__pb-lg-0,
  html[dir=rtl] .rtl .rw__pb-lg-0,
  html[dir=ltr] .ltr .rw__pb-lg-0,
  html[dir=ltr] .rtl .rw__pb-lg-0 {
    padding-bottom: 0rem !important;
  }
  .sb-show-main .ltr .rw__ms-lg-0,
  html[dir=rtl] .ltr .rw__ms-lg-0,
  html[dir=ltr] .ltr .rw__ms-lg-0 {
    margin-left: 0rem !important;
  }
  .sb-show-main .ltr .rw__me-lg-0,
  html[dir=rtl] .ltr .rw__me-lg-0,
  html[dir=ltr] .ltr .rw__me-lg-0 {
    margin-right: 0rem !important;
  }
  .sb-show-main .ltr .rw__ps-lg-0,
  html[dir=rtl] .ltr .rw__ps-lg-0,
  html[dir=ltr] .ltr .rw__ps-lg-0 {
    padding-left: 0rem !important;
  }
  .sb-show-main .ltr .rw__pe-lg-0,
  html[dir=rtl] .ltr .rw__pe-lg-0,
  html[dir=ltr] .ltr .rw__pe-lg-0 {
    padding-right: 0rem !important;
  }
  .sb-show-main .rtl .rw__ms-lg-0,
  html[dir=rtl] .rtl .rw__ms-lg-0,
  html[dir=ltr] .rtl .rw__ms-lg-0 {
    margin-right: 0rem !important;
  }
  .sb-show-main .rtl .rw__me-lg-0,
  html[dir=rtl] .rtl .rw__me-lg-0,
  html[dir=ltr] .rtl .rw__me-lg-0 {
    margin-left: 0rem !important;
  }
  .sb-show-main .rtl .rw__ps-lg-0,
  html[dir=rtl] .rtl .rw__ps-lg-0,
  html[dir=ltr] .rtl .rw__ps-lg-0 {
    padding-right: 0rem !important;
  }
  .sb-show-main .rtl .rw__pe-lg-0,
  html[dir=rtl] .rtl .rw__pe-lg-0,
  html[dir=ltr] .rtl .rw__pe-lg-0 {
    padding-left: 0rem !important;
  }
}
@media (min-width: 960px) {
  .sb-show-main .ltr .rw__m-md-0,
  .sb-show-main .rtl .rw__m-md-0,
  html[dir=rtl] .ltr .rw__m-md-0,
  html[dir=rtl] .rtl .rw__m-md-0,
  html[dir=ltr] .ltr .rw__m-md-0,
  html[dir=ltr] .rtl .rw__m-md-0 {
    margin: 0rem !important;
  }
  .sb-show-main .ltr .rw__p-md-0,
  .sb-show-main .rtl .rw__p-md-0,
  html[dir=rtl] .ltr .rw__p-md-0,
  html[dir=rtl] .rtl .rw__p-md-0,
  html[dir=ltr] .ltr .rw__p-md-0,
  html[dir=ltr] .rtl .rw__p-md-0 {
    padding: 0rem !important;
  }
  .sb-show-main .ltr .rw__mx-md-0,
  .sb-show-main .rtl .rw__mx-md-0,
  html[dir=rtl] .ltr .rw__mx-md-0,
  html[dir=rtl] .rtl .rw__mx-md-0,
  html[dir=ltr] .ltr .rw__mx-md-0,
  html[dir=ltr] .rtl .rw__mx-md-0 {
    margin-left: 0rem !important;
    margin-right: 0rem !important;
  }
  .sb-show-main .ltr .rw__my-md-0,
  .sb-show-main .rtl .rw__my-md-0,
  html[dir=rtl] .ltr .rw__my-md-0,
  html[dir=rtl] .rtl .rw__my-md-0,
  html[dir=ltr] .ltr .rw__my-md-0,
  html[dir=ltr] .rtl .rw__my-md-0 {
    margin-top: 0rem !important;
    margin-bottom: 0rem !important;
  }
  .sb-show-main .ltr .rw__mt-md-0,
  .sb-show-main .rtl .rw__mt-md-0,
  html[dir=rtl] .ltr .rw__mt-md-0,
  html[dir=rtl] .rtl .rw__mt-md-0,
  html[dir=ltr] .ltr .rw__mt-md-0,
  html[dir=ltr] .rtl .rw__mt-md-0 {
    margin-top: 0rem !important;
  }
  .sb-show-main .ltr .rw__mb-md-0,
  .sb-show-main .rtl .rw__mb-md-0,
  html[dir=rtl] .ltr .rw__mb-md-0,
  html[dir=rtl] .rtl .rw__mb-md-0,
  html[dir=ltr] .ltr .rw__mb-md-0,
  html[dir=ltr] .rtl .rw__mb-md-0 {
    margin-bottom: 0rem !important;
  }
  .sb-show-main .ltr .rw__px-md-0,
  .sb-show-main .rtl .rw__px-md-0,
  html[dir=rtl] .ltr .rw__px-md-0,
  html[dir=rtl] .rtl .rw__px-md-0,
  html[dir=ltr] .ltr .rw__px-md-0,
  html[dir=ltr] .rtl .rw__px-md-0 {
    padding-left: 0rem !important;
    padding-right: 0rem !important;
  }
  .sb-show-main .ltr .rw__py-md-0,
  .sb-show-main .rtl .rw__py-md-0,
  html[dir=rtl] .ltr .rw__py-md-0,
  html[dir=rtl] .rtl .rw__py-md-0,
  html[dir=ltr] .ltr .rw__py-md-0,
  html[dir=ltr] .rtl .rw__py-md-0 {
    padding-top: 0rem !important;
    padding-bottom: 0rem !important;
  }
  .sb-show-main .ltr .rw__pt-md-0,
  .sb-show-main .rtl .rw__pt-md-0,
  html[dir=rtl] .ltr .rw__pt-md-0,
  html[dir=rtl] .rtl .rw__pt-md-0,
  html[dir=ltr] .ltr .rw__pt-md-0,
  html[dir=ltr] .rtl .rw__pt-md-0 {
    padding-top: 0rem !important;
  }
  .sb-show-main .ltr .rw__pb-md-0,
  .sb-show-main .rtl .rw__pb-md-0,
  html[dir=rtl] .ltr .rw__pb-md-0,
  html[dir=rtl] .rtl .rw__pb-md-0,
  html[dir=ltr] .ltr .rw__pb-md-0,
  html[dir=ltr] .rtl .rw__pb-md-0 {
    padding-bottom: 0rem !important;
  }
  .sb-show-main .ltr .rw__ms-md-0,
  html[dir=rtl] .ltr .rw__ms-md-0,
  html[dir=ltr] .ltr .rw__ms-md-0 {
    margin-left: 0rem !important;
  }
  .sb-show-main .ltr .rw__me-md-0,
  html[dir=rtl] .ltr .rw__me-md-0,
  html[dir=ltr] .ltr .rw__me-md-0 {
    margin-right: 0rem !important;
  }
  .sb-show-main .ltr .rw__ps-md-0,
  html[dir=rtl] .ltr .rw__ps-md-0,
  html[dir=ltr] .ltr .rw__ps-md-0 {
    padding-left: 0rem !important;
  }
  .sb-show-main .ltr .rw__pe-md-0,
  html[dir=rtl] .ltr .rw__pe-md-0,
  html[dir=ltr] .ltr .rw__pe-md-0 {
    padding-right: 0rem !important;
  }
  .sb-show-main .rtl .rw__ms-md-0,
  html[dir=rtl] .rtl .rw__ms-md-0,
  html[dir=ltr] .rtl .rw__ms-md-0 {
    margin-right: 0rem !important;
  }
  .sb-show-main .rtl .rw__me-md-0,
  html[dir=rtl] .rtl .rw__me-md-0,
  html[dir=ltr] .rtl .rw__me-md-0 {
    margin-left: 0rem !important;
  }
  .sb-show-main .rtl .rw__ps-md-0,
  html[dir=rtl] .rtl .rw__ps-md-0,
  html[dir=ltr] .rtl .rw__ps-md-0 {
    padding-right: 0rem !important;
  }
  .sb-show-main .rtl .rw__pe-md-0,
  html[dir=rtl] .rtl .rw__pe-md-0,
  html[dir=ltr] .rtl .rw__pe-md-0 {
    padding-left: 0rem !important;
  }
}
@media (min-width: 640px) {
  html[dir=rtl] .ltr .rw__ms-sm-0,
  html[dir=ltr] .ltr .rw__ms-sm-0 {
    margin-left: 0rem !important;
  }
  html[dir=rtl] .rtl .rw__ms-sm-0,
  html[dir=ltr] .rtl .rw__ms-sm-0 {
    margin-right: 0rem !important;
  }
}
html[dir=rtl] .rw__p-1,
html[dir=ltr] .rw__p-1 {
  padding: 0.125rem !important;
}
html[dir=rtl] .rw__px-1,
html[dir=ltr] .rw__px-1 {
  padding-left: 0.125rem !important;
  padding-right: 0.125rem !important;
}
html[dir=rtl] .rw__py-1,
html[dir=ltr] .rw__py-1 {
  padding-top: 0.125rem !important;
  padding-bottom: 0.125rem !important;
}
html[dir=rtl] .rw__pt-1,
html[dir=ltr] .rw__pt-1 {
  padding-top: 0.125rem !important;
}
html[dir=rtl] .rw__pb-1,
html[dir=ltr] .rw__pb-1 {
  padding-bottom: 0.125rem !important;
}
html[dir=rtl] .rw__m-1,
html[dir=ltr] .rw__m-1 {
  margin: 0.125rem !important;
}
html[dir=rtl] .rw__mx-1,
html[dir=ltr] .rw__mx-1 {
  margin-left: 0.125rem !important;
  margin-right: 0.125rem !important;
}
html[dir=rtl] .rw__my-1,
html[dir=ltr] .rw__my-1 {
  margin-top: 0.125rem !important;
  margin-bottom: 0.125rem !important;
}
html[dir=rtl] .rw__mt-1,
html[dir=ltr] .rw__mt-1 {
  margin-top: 0.125rem !important;
}
html[dir=rtl] .rw__mb-1,
html[dir=ltr] .rw__mb-1 {
  margin-bottom: 0.125rem !important;
}
html[dir=rtl] .ltr .rw__ps-1,
html[dir=ltr] .ltr .rw__ps-1 {
  padding-left: 0.125rem !important;
}
html[dir=rtl] .ltr .rw__pe-1,
html[dir=ltr] .ltr .rw__pe-1 {
  padding-right: 0.125rem !important;
}
html[dir=rtl] .ltr .rw__ms-1,
html[dir=ltr] .ltr .rw__ms-1 {
  margin-left: 0.125rem !important;
}
html[dir=rtl] .ltr .rw__me-1, html[dir=rtl] .ltr .rw__otp .rw__input, .rw__otp html[dir=rtl] .ltr .rw__input,
html[dir=ltr] .ltr .rw__me-1,
html[dir=ltr] .ltr .rw__otp .rw__input,
.rw__otp html[dir=ltr] .ltr .rw__input {
  margin-right: 0.125rem !important;
}
html[dir=rtl] .rtl .rw__ps-1,
html[dir=ltr] .rtl .rw__ps-1 {
  padding-right: 0.125rem !important;
}
html[dir=rtl] .rtl .rw__pe-1,
html[dir=ltr] .rtl .rw__pe-1 {
  padding-left: 0.125rem !important;
}
html[dir=rtl] .rtl .rw__ms-1,
html[dir=ltr] .rtl .rw__ms-1 {
  margin-right: 0.125rem !important;
}
html[dir=rtl] .rtl .rw__me-1, html[dir=rtl] .rtl .rw__otp .rw__input, .rw__otp html[dir=rtl] .rtl .rw__input,
html[dir=ltr] .rtl .rw__me-1,
html[dir=ltr] .rtl .rw__otp .rw__input,
.rw__otp html[dir=ltr] .rtl .rw__input {
  margin-left: 0.125rem !important;
}

.sb-show-main .rw__p-1 {
  padding: 0.125rem !important;
}
.sb-show-main .rw__px-1 {
  padding-left: 0.125rem !important;
  padding-right: 0.125rem !important;
}
.sb-show-main .rw__py-1 {
  padding-top: 0.125rem !important;
  padding-bottom: 0.125rem !important;
}
.sb-show-main .rw__pt-1 {
  padding-top: 0.125rem !important;
}
.sb-show-main .rw__pb-1 {
  padding-bottom: 0.125rem !important;
}
.sb-show-main .rw__m-1 {
  margin: 0.125rem !important;
}
.sb-show-main .rw__mx-1 {
  margin-left: 0.125rem !important;
  margin-right: 0.125rem !important;
}
.sb-show-main .rw__my-1 {
  margin-top: 0.125rem !important;
  margin-bottom: 0.125rem !important;
}
.sb-show-main .rw__mt-1 {
  margin-top: 0.125rem !important;
}
.sb-show-main .rw__mb-1 {
  margin-bottom: 0.125rem !important;
}
.sb-show-main .rw__ps-1 {
  padding-left: 0.125rem !important;
}
.sb-show-main .rw__pe-1 {
  padding-right: 0.125rem !important;
}
.sb-show-main .rw__ms-1 {
  margin-left: 0.125rem !important;
}
.sb-show-main .rw__me-1, .sb-show-main .rw__otp .rw__input, .rw__otp .sb-show-main .rw__input {
  margin-right: 0.125rem !important;
}

@media (min-width: 1366px) {
  .sb-show-main .ltr .rw__m-lg-1,
  .sb-show-main .rtl .rw__m-lg-1,
  html[dir=rtl] .ltr .rw__m-lg-1,
  html[dir=rtl] .rtl .rw__m-lg-1,
  html[dir=ltr] .ltr .rw__m-lg-1,
  html[dir=ltr] .rtl .rw__m-lg-1 {
    margin: 0.125rem !important;
  }
  .sb-show-main .ltr .rw__p-lg-1,
  .sb-show-main .rtl .rw__p-lg-1,
  html[dir=rtl] .ltr .rw__p-lg-1,
  html[dir=rtl] .rtl .rw__p-lg-1,
  html[dir=ltr] .ltr .rw__p-lg-1,
  html[dir=ltr] .rtl .rw__p-lg-1 {
    padding: 0.125rem !important;
  }
  .sb-show-main .ltr .rw__mx-lg-1,
  .sb-show-main .rtl .rw__mx-lg-1,
  html[dir=rtl] .ltr .rw__mx-lg-1,
  html[dir=rtl] .rtl .rw__mx-lg-1,
  html[dir=ltr] .ltr .rw__mx-lg-1,
  html[dir=ltr] .rtl .rw__mx-lg-1 {
    margin-left: 0.125rem !important;
    margin-right: 0.125rem !important;
  }
  .sb-show-main .ltr .rw__my-lg-1,
  .sb-show-main .rtl .rw__my-lg-1,
  html[dir=rtl] .ltr .rw__my-lg-1,
  html[dir=rtl] .rtl .rw__my-lg-1,
  html[dir=ltr] .ltr .rw__my-lg-1,
  html[dir=ltr] .rtl .rw__my-lg-1 {
    margin-top: 0.125rem !important;
    margin-bottom: 0.125rem !important;
  }
  .sb-show-main .ltr .rw__mt-lg-1,
  .sb-show-main .rtl .rw__mt-lg-1,
  html[dir=rtl] .ltr .rw__mt-lg-1,
  html[dir=rtl] .rtl .rw__mt-lg-1,
  html[dir=ltr] .ltr .rw__mt-lg-1,
  html[dir=ltr] .rtl .rw__mt-lg-1 {
    margin-top: 0.125rem !important;
  }
  .sb-show-main .ltr .rw__mb-lg-1,
  .sb-show-main .rtl .rw__mb-lg-1,
  html[dir=rtl] .ltr .rw__mb-lg-1,
  html[dir=rtl] .rtl .rw__mb-lg-1,
  html[dir=ltr] .ltr .rw__mb-lg-1,
  html[dir=ltr] .rtl .rw__mb-lg-1 {
    margin-bottom: 0.125rem !important;
  }
  .sb-show-main .ltr .rw__px-lg-1,
  .sb-show-main .rtl .rw__px-lg-1,
  html[dir=rtl] .ltr .rw__px-lg-1,
  html[dir=rtl] .rtl .rw__px-lg-1,
  html[dir=ltr] .ltr .rw__px-lg-1,
  html[dir=ltr] .rtl .rw__px-lg-1 {
    padding-left: 0.125rem !important;
    padding-right: 0.125rem !important;
  }
  .sb-show-main .ltr .rw__py-lg-1,
  .sb-show-main .rtl .rw__py-lg-1,
  html[dir=rtl] .ltr .rw__py-lg-1,
  html[dir=rtl] .rtl .rw__py-lg-1,
  html[dir=ltr] .ltr .rw__py-lg-1,
  html[dir=ltr] .rtl .rw__py-lg-1 {
    padding-top: 0.125rem !important;
    padding-bottom: 0.125rem !important;
  }
  .sb-show-main .ltr .rw__pt-lg-1,
  .sb-show-main .rtl .rw__pt-lg-1,
  html[dir=rtl] .ltr .rw__pt-lg-1,
  html[dir=rtl] .rtl .rw__pt-lg-1,
  html[dir=ltr] .ltr .rw__pt-lg-1,
  html[dir=ltr] .rtl .rw__pt-lg-1 {
    padding-top: 0.125rem !important;
  }
  .sb-show-main .ltr .rw__pb-lg-1,
  .sb-show-main .rtl .rw__pb-lg-1,
  html[dir=rtl] .ltr .rw__pb-lg-1,
  html[dir=rtl] .rtl .rw__pb-lg-1,
  html[dir=ltr] .ltr .rw__pb-lg-1,
  html[dir=ltr] .rtl .rw__pb-lg-1 {
    padding-bottom: 0.125rem !important;
  }
  .sb-show-main .ltr .rw__ms-lg-1,
  html[dir=rtl] .ltr .rw__ms-lg-1,
  html[dir=ltr] .ltr .rw__ms-lg-1 {
    margin-left: 0.125rem !important;
  }
  .sb-show-main .ltr .rw__me-lg-1,
  html[dir=rtl] .ltr .rw__me-lg-1,
  html[dir=ltr] .ltr .rw__me-lg-1 {
    margin-right: 0.125rem !important;
  }
  .sb-show-main .ltr .rw__ps-lg-1,
  html[dir=rtl] .ltr .rw__ps-lg-1,
  html[dir=ltr] .ltr .rw__ps-lg-1 {
    padding-left: 0.125rem !important;
  }
  .sb-show-main .ltr .rw__pe-lg-1,
  html[dir=rtl] .ltr .rw__pe-lg-1,
  html[dir=ltr] .ltr .rw__pe-lg-1 {
    padding-right: 0.125rem !important;
  }
  .sb-show-main .rtl .rw__ms-lg-1,
  html[dir=rtl] .rtl .rw__ms-lg-1,
  html[dir=ltr] .rtl .rw__ms-lg-1 {
    margin-right: 0.125rem !important;
  }
  .sb-show-main .rtl .rw__me-lg-1,
  html[dir=rtl] .rtl .rw__me-lg-1,
  html[dir=ltr] .rtl .rw__me-lg-1 {
    margin-left: 0.125rem !important;
  }
  .sb-show-main .rtl .rw__ps-lg-1,
  html[dir=rtl] .rtl .rw__ps-lg-1,
  html[dir=ltr] .rtl .rw__ps-lg-1 {
    padding-right: 0.125rem !important;
  }
  .sb-show-main .rtl .rw__pe-lg-1,
  html[dir=rtl] .rtl .rw__pe-lg-1,
  html[dir=ltr] .rtl .rw__pe-lg-1 {
    padding-left: 0.125rem !important;
  }
}
@media (min-width: 960px) {
  .sb-show-main .ltr .rw__m-md-1,
  .sb-show-main .rtl .rw__m-md-1,
  html[dir=rtl] .ltr .rw__m-md-1,
  html[dir=rtl] .rtl .rw__m-md-1,
  html[dir=ltr] .ltr .rw__m-md-1,
  html[dir=ltr] .rtl .rw__m-md-1 {
    margin: 0.125rem !important;
  }
  .sb-show-main .ltr .rw__p-md-1,
  .sb-show-main .rtl .rw__p-md-1,
  html[dir=rtl] .ltr .rw__p-md-1,
  html[dir=rtl] .rtl .rw__p-md-1,
  html[dir=ltr] .ltr .rw__p-md-1,
  html[dir=ltr] .rtl .rw__p-md-1 {
    padding: 0.125rem !important;
  }
  .sb-show-main .ltr .rw__mx-md-1,
  .sb-show-main .rtl .rw__mx-md-1,
  html[dir=rtl] .ltr .rw__mx-md-1,
  html[dir=rtl] .rtl .rw__mx-md-1,
  html[dir=ltr] .ltr .rw__mx-md-1,
  html[dir=ltr] .rtl .rw__mx-md-1 {
    margin-left: 0.125rem !important;
    margin-right: 0.125rem !important;
  }
  .sb-show-main .ltr .rw__my-md-1,
  .sb-show-main .rtl .rw__my-md-1,
  html[dir=rtl] .ltr .rw__my-md-1,
  html[dir=rtl] .rtl .rw__my-md-1,
  html[dir=ltr] .ltr .rw__my-md-1,
  html[dir=ltr] .rtl .rw__my-md-1 {
    margin-top: 0.125rem !important;
    margin-bottom: 0.125rem !important;
  }
  .sb-show-main .ltr .rw__mt-md-1,
  .sb-show-main .rtl .rw__mt-md-1,
  html[dir=rtl] .ltr .rw__mt-md-1,
  html[dir=rtl] .rtl .rw__mt-md-1,
  html[dir=ltr] .ltr .rw__mt-md-1,
  html[dir=ltr] .rtl .rw__mt-md-1 {
    margin-top: 0.125rem !important;
  }
  .sb-show-main .ltr .rw__mb-md-1,
  .sb-show-main .rtl .rw__mb-md-1,
  html[dir=rtl] .ltr .rw__mb-md-1,
  html[dir=rtl] .rtl .rw__mb-md-1,
  html[dir=ltr] .ltr .rw__mb-md-1,
  html[dir=ltr] .rtl .rw__mb-md-1 {
    margin-bottom: 0.125rem !important;
  }
  .sb-show-main .ltr .rw__px-md-1,
  .sb-show-main .rtl .rw__px-md-1,
  html[dir=rtl] .ltr .rw__px-md-1,
  html[dir=rtl] .rtl .rw__px-md-1,
  html[dir=ltr] .ltr .rw__px-md-1,
  html[dir=ltr] .rtl .rw__px-md-1 {
    padding-left: 0.125rem !important;
    padding-right: 0.125rem !important;
  }
  .sb-show-main .ltr .rw__py-md-1,
  .sb-show-main .rtl .rw__py-md-1,
  html[dir=rtl] .ltr .rw__py-md-1,
  html[dir=rtl] .rtl .rw__py-md-1,
  html[dir=ltr] .ltr .rw__py-md-1,
  html[dir=ltr] .rtl .rw__py-md-1 {
    padding-top: 0.125rem !important;
    padding-bottom: 0.125rem !important;
  }
  .sb-show-main .ltr .rw__pt-md-1,
  .sb-show-main .rtl .rw__pt-md-1,
  html[dir=rtl] .ltr .rw__pt-md-1,
  html[dir=rtl] .rtl .rw__pt-md-1,
  html[dir=ltr] .ltr .rw__pt-md-1,
  html[dir=ltr] .rtl .rw__pt-md-1 {
    padding-top: 0.125rem !important;
  }
  .sb-show-main .ltr .rw__pb-md-1,
  .sb-show-main .rtl .rw__pb-md-1,
  html[dir=rtl] .ltr .rw__pb-md-1,
  html[dir=rtl] .rtl .rw__pb-md-1,
  html[dir=ltr] .ltr .rw__pb-md-1,
  html[dir=ltr] .rtl .rw__pb-md-1 {
    padding-bottom: 0.125rem !important;
  }
  .sb-show-main .ltr .rw__ms-md-1,
  html[dir=rtl] .ltr .rw__ms-md-1,
  html[dir=ltr] .ltr .rw__ms-md-1 {
    margin-left: 0.125rem !important;
  }
  .sb-show-main .ltr .rw__me-md-1,
  html[dir=rtl] .ltr .rw__me-md-1,
  html[dir=ltr] .ltr .rw__me-md-1 {
    margin-right: 0.125rem !important;
  }
  .sb-show-main .ltr .rw__ps-md-1,
  html[dir=rtl] .ltr .rw__ps-md-1,
  html[dir=ltr] .ltr .rw__ps-md-1 {
    padding-left: 0.125rem !important;
  }
  .sb-show-main .ltr .rw__pe-md-1,
  html[dir=rtl] .ltr .rw__pe-md-1,
  html[dir=ltr] .ltr .rw__pe-md-1 {
    padding-right: 0.125rem !important;
  }
  .sb-show-main .rtl .rw__ms-md-1,
  html[dir=rtl] .rtl .rw__ms-md-1,
  html[dir=ltr] .rtl .rw__ms-md-1 {
    margin-right: 0.125rem !important;
  }
  .sb-show-main .rtl .rw__me-md-1,
  html[dir=rtl] .rtl .rw__me-md-1,
  html[dir=ltr] .rtl .rw__me-md-1 {
    margin-left: 0.125rem !important;
  }
  .sb-show-main .rtl .rw__ps-md-1,
  html[dir=rtl] .rtl .rw__ps-md-1,
  html[dir=ltr] .rtl .rw__ps-md-1 {
    padding-right: 0.125rem !important;
  }
  .sb-show-main .rtl .rw__pe-md-1,
  html[dir=rtl] .rtl .rw__pe-md-1,
  html[dir=ltr] .rtl .rw__pe-md-1 {
    padding-left: 0.125rem !important;
  }
}
@media (min-width: 640px) {
  html[dir=rtl] .ltr .rw__ms-sm-1,
  html[dir=ltr] .ltr .rw__ms-sm-1 {
    margin-left: 0.125rem !important;
  }
  html[dir=rtl] .rtl .rw__ms-sm-1,
  html[dir=ltr] .rtl .rw__ms-sm-1 {
    margin-right: 0.125rem !important;
  }
}
html[dir=rtl] .rw__p-2,
html[dir=ltr] .rw__p-2 {
  padding: 0.25rem !important;
}
html[dir=rtl] .rw__px-2,
html[dir=ltr] .rw__px-2 {
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
}
html[dir=rtl] .rw__py-2,
html[dir=ltr] .rw__py-2 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}
html[dir=rtl] .rw__pt-2,
html[dir=ltr] .rw__pt-2 {
  padding-top: 0.25rem !important;
}
html[dir=rtl] .rw__pb-2,
html[dir=ltr] .rw__pb-2 {
  padding-bottom: 0.25rem !important;
}
html[dir=rtl] .rw__m-2,
html[dir=ltr] .rw__m-2 {
  margin: 0.25rem !important;
}
html[dir=rtl] .rw__mx-2,
html[dir=ltr] .rw__mx-2 {
  margin-left: 0.25rem !important;
  margin-right: 0.25rem !important;
}
html[dir=rtl] .rw__my-2,
html[dir=ltr] .rw__my-2 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}
html[dir=rtl] .rw__mt-2,
html[dir=ltr] .rw__mt-2 {
  margin-top: 0.25rem !important;
}
html[dir=rtl] .rw__mb-2,
html[dir=ltr] .rw__mb-2 {
  margin-bottom: 0.25rem !important;
}
html[dir=rtl] .ltr .rw__ps-2,
html[dir=ltr] .ltr .rw__ps-2 {
  padding-left: 0.25rem !important;
}
html[dir=rtl] .ltr .rw__pe-2,
html[dir=ltr] .ltr .rw__pe-2 {
  padding-right: 0.25rem !important;
}
html[dir=rtl] .ltr .rw__ms-2,
html[dir=ltr] .ltr .rw__ms-2 {
  margin-left: 0.25rem !important;
}
html[dir=rtl] .ltr .rw__me-2,
html[dir=ltr] .ltr .rw__me-2 {
  margin-right: 0.25rem !important;
}
html[dir=rtl] .rtl .rw__ps-2,
html[dir=ltr] .rtl .rw__ps-2 {
  padding-right: 0.25rem !important;
}
html[dir=rtl] .rtl .rw__pe-2,
html[dir=ltr] .rtl .rw__pe-2 {
  padding-left: 0.25rem !important;
}
html[dir=rtl] .rtl .rw__ms-2,
html[dir=ltr] .rtl .rw__ms-2 {
  margin-right: 0.25rem !important;
}
html[dir=rtl] .rtl .rw__me-2,
html[dir=ltr] .rtl .rw__me-2 {
  margin-left: 0.25rem !important;
}

.sb-show-main .rw__p-2 {
  padding: 0.25rem !important;
}
.sb-show-main .rw__px-2 {
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
}
.sb-show-main .rw__py-2 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}
.sb-show-main .rw__pt-2 {
  padding-top: 0.25rem !important;
}
.sb-show-main .rw__pb-2 {
  padding-bottom: 0.25rem !important;
}
.sb-show-main .rw__m-2 {
  margin: 0.25rem !important;
}
.sb-show-main .rw__mx-2 {
  margin-left: 0.25rem !important;
  margin-right: 0.25rem !important;
}
.sb-show-main .rw__my-2 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}
.sb-show-main .rw__mt-2 {
  margin-top: 0.25rem !important;
}
.sb-show-main .rw__mb-2 {
  margin-bottom: 0.25rem !important;
}
.sb-show-main .rw__ps-2 {
  padding-left: 0.25rem !important;
}
.sb-show-main .rw__pe-2 {
  padding-right: 0.25rem !important;
}
.sb-show-main .rw__ms-2 {
  margin-left: 0.25rem !important;
}
.sb-show-main .rw__me-2 {
  margin-right: 0.25rem !important;
}

@media (min-width: 1366px) {
  .sb-show-main .ltr .rw__m-lg-2,
  .sb-show-main .rtl .rw__m-lg-2,
  html[dir=rtl] .ltr .rw__m-lg-2,
  html[dir=rtl] .rtl .rw__m-lg-2,
  html[dir=ltr] .ltr .rw__m-lg-2,
  html[dir=ltr] .rtl .rw__m-lg-2 {
    margin: 0.25rem !important;
  }
  .sb-show-main .ltr .rw__p-lg-2,
  .sb-show-main .rtl .rw__p-lg-2,
  html[dir=rtl] .ltr .rw__p-lg-2,
  html[dir=rtl] .rtl .rw__p-lg-2,
  html[dir=ltr] .ltr .rw__p-lg-2,
  html[dir=ltr] .rtl .rw__p-lg-2 {
    padding: 0.25rem !important;
  }
  .sb-show-main .ltr .rw__mx-lg-2,
  .sb-show-main .rtl .rw__mx-lg-2,
  html[dir=rtl] .ltr .rw__mx-lg-2,
  html[dir=rtl] .rtl .rw__mx-lg-2,
  html[dir=ltr] .ltr .rw__mx-lg-2,
  html[dir=ltr] .rtl .rw__mx-lg-2 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }
  .sb-show-main .ltr .rw__my-lg-2,
  .sb-show-main .rtl .rw__my-lg-2,
  html[dir=rtl] .ltr .rw__my-lg-2,
  html[dir=rtl] .rtl .rw__my-lg-2,
  html[dir=ltr] .ltr .rw__my-lg-2,
  html[dir=ltr] .rtl .rw__my-lg-2 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .sb-show-main .ltr .rw__mt-lg-2,
  .sb-show-main .rtl .rw__mt-lg-2,
  html[dir=rtl] .ltr .rw__mt-lg-2,
  html[dir=rtl] .rtl .rw__mt-lg-2,
  html[dir=ltr] .ltr .rw__mt-lg-2,
  html[dir=ltr] .rtl .rw__mt-lg-2 {
    margin-top: 0.25rem !important;
  }
  .sb-show-main .ltr .rw__mb-lg-2,
  .sb-show-main .rtl .rw__mb-lg-2,
  html[dir=rtl] .ltr .rw__mb-lg-2,
  html[dir=rtl] .rtl .rw__mb-lg-2,
  html[dir=ltr] .ltr .rw__mb-lg-2,
  html[dir=ltr] .rtl .rw__mb-lg-2 {
    margin-bottom: 0.25rem !important;
  }
  .sb-show-main .ltr .rw__px-lg-2,
  .sb-show-main .rtl .rw__px-lg-2,
  html[dir=rtl] .ltr .rw__px-lg-2,
  html[dir=rtl] .rtl .rw__px-lg-2,
  html[dir=ltr] .ltr .rw__px-lg-2,
  html[dir=ltr] .rtl .rw__px-lg-2 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
  .sb-show-main .ltr .rw__py-lg-2,
  .sb-show-main .rtl .rw__py-lg-2,
  html[dir=rtl] .ltr .rw__py-lg-2,
  html[dir=rtl] .rtl .rw__py-lg-2,
  html[dir=ltr] .ltr .rw__py-lg-2,
  html[dir=ltr] .rtl .rw__py-lg-2 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .sb-show-main .ltr .rw__pt-lg-2,
  .sb-show-main .rtl .rw__pt-lg-2,
  html[dir=rtl] .ltr .rw__pt-lg-2,
  html[dir=rtl] .rtl .rw__pt-lg-2,
  html[dir=ltr] .ltr .rw__pt-lg-2,
  html[dir=ltr] .rtl .rw__pt-lg-2 {
    padding-top: 0.25rem !important;
  }
  .sb-show-main .ltr .rw__pb-lg-2,
  .sb-show-main .rtl .rw__pb-lg-2,
  html[dir=rtl] .ltr .rw__pb-lg-2,
  html[dir=rtl] .rtl .rw__pb-lg-2,
  html[dir=ltr] .ltr .rw__pb-lg-2,
  html[dir=ltr] .rtl .rw__pb-lg-2 {
    padding-bottom: 0.25rem !important;
  }
  .sb-show-main .ltr .rw__ms-lg-2,
  html[dir=rtl] .ltr .rw__ms-lg-2,
  html[dir=ltr] .ltr .rw__ms-lg-2 {
    margin-left: 0.25rem !important;
  }
  .sb-show-main .ltr .rw__me-lg-2,
  html[dir=rtl] .ltr .rw__me-lg-2,
  html[dir=ltr] .ltr .rw__me-lg-2 {
    margin-right: 0.25rem !important;
  }
  .sb-show-main .ltr .rw__ps-lg-2,
  html[dir=rtl] .ltr .rw__ps-lg-2,
  html[dir=ltr] .ltr .rw__ps-lg-2 {
    padding-left: 0.25rem !important;
  }
  .sb-show-main .ltr .rw__pe-lg-2,
  html[dir=rtl] .ltr .rw__pe-lg-2,
  html[dir=ltr] .ltr .rw__pe-lg-2 {
    padding-right: 0.25rem !important;
  }
  .sb-show-main .rtl .rw__ms-lg-2,
  html[dir=rtl] .rtl .rw__ms-lg-2,
  html[dir=ltr] .rtl .rw__ms-lg-2 {
    margin-right: 0.25rem !important;
  }
  .sb-show-main .rtl .rw__me-lg-2,
  html[dir=rtl] .rtl .rw__me-lg-2,
  html[dir=ltr] .rtl .rw__me-lg-2 {
    margin-left: 0.25rem !important;
  }
  .sb-show-main .rtl .rw__ps-lg-2,
  html[dir=rtl] .rtl .rw__ps-lg-2,
  html[dir=ltr] .rtl .rw__ps-lg-2 {
    padding-right: 0.25rem !important;
  }
  .sb-show-main .rtl .rw__pe-lg-2,
  html[dir=rtl] .rtl .rw__pe-lg-2,
  html[dir=ltr] .rtl .rw__pe-lg-2 {
    padding-left: 0.25rem !important;
  }
}
@media (min-width: 960px) {
  .sb-show-main .ltr .rw__m-md-2,
  .sb-show-main .rtl .rw__m-md-2,
  html[dir=rtl] .ltr .rw__m-md-2,
  html[dir=rtl] .rtl .rw__m-md-2,
  html[dir=ltr] .ltr .rw__m-md-2,
  html[dir=ltr] .rtl .rw__m-md-2 {
    margin: 0.25rem !important;
  }
  .sb-show-main .ltr .rw__p-md-2,
  .sb-show-main .rtl .rw__p-md-2,
  html[dir=rtl] .ltr .rw__p-md-2,
  html[dir=rtl] .rtl .rw__p-md-2,
  html[dir=ltr] .ltr .rw__p-md-2,
  html[dir=ltr] .rtl .rw__p-md-2 {
    padding: 0.25rem !important;
  }
  .sb-show-main .ltr .rw__mx-md-2,
  .sb-show-main .rtl .rw__mx-md-2,
  html[dir=rtl] .ltr .rw__mx-md-2,
  html[dir=rtl] .rtl .rw__mx-md-2,
  html[dir=ltr] .ltr .rw__mx-md-2,
  html[dir=ltr] .rtl .rw__mx-md-2 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }
  .sb-show-main .ltr .rw__my-md-2,
  .sb-show-main .rtl .rw__my-md-2,
  html[dir=rtl] .ltr .rw__my-md-2,
  html[dir=rtl] .rtl .rw__my-md-2,
  html[dir=ltr] .ltr .rw__my-md-2,
  html[dir=ltr] .rtl .rw__my-md-2 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .sb-show-main .ltr .rw__mt-md-2,
  .sb-show-main .rtl .rw__mt-md-2,
  html[dir=rtl] .ltr .rw__mt-md-2,
  html[dir=rtl] .rtl .rw__mt-md-2,
  html[dir=ltr] .ltr .rw__mt-md-2,
  html[dir=ltr] .rtl .rw__mt-md-2 {
    margin-top: 0.25rem !important;
  }
  .sb-show-main .ltr .rw__mb-md-2,
  .sb-show-main .rtl .rw__mb-md-2,
  html[dir=rtl] .ltr .rw__mb-md-2,
  html[dir=rtl] .rtl .rw__mb-md-2,
  html[dir=ltr] .ltr .rw__mb-md-2,
  html[dir=ltr] .rtl .rw__mb-md-2 {
    margin-bottom: 0.25rem !important;
  }
  .sb-show-main .ltr .rw__px-md-2,
  .sb-show-main .rtl .rw__px-md-2,
  html[dir=rtl] .ltr .rw__px-md-2,
  html[dir=rtl] .rtl .rw__px-md-2,
  html[dir=ltr] .ltr .rw__px-md-2,
  html[dir=ltr] .rtl .rw__px-md-2 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
  .sb-show-main .ltr .rw__py-md-2,
  .sb-show-main .rtl .rw__py-md-2,
  html[dir=rtl] .ltr .rw__py-md-2,
  html[dir=rtl] .rtl .rw__py-md-2,
  html[dir=ltr] .ltr .rw__py-md-2,
  html[dir=ltr] .rtl .rw__py-md-2 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .sb-show-main .ltr .rw__pt-md-2,
  .sb-show-main .rtl .rw__pt-md-2,
  html[dir=rtl] .ltr .rw__pt-md-2,
  html[dir=rtl] .rtl .rw__pt-md-2,
  html[dir=ltr] .ltr .rw__pt-md-2,
  html[dir=ltr] .rtl .rw__pt-md-2 {
    padding-top: 0.25rem !important;
  }
  .sb-show-main .ltr .rw__pb-md-2,
  .sb-show-main .rtl .rw__pb-md-2,
  html[dir=rtl] .ltr .rw__pb-md-2,
  html[dir=rtl] .rtl .rw__pb-md-2,
  html[dir=ltr] .ltr .rw__pb-md-2,
  html[dir=ltr] .rtl .rw__pb-md-2 {
    padding-bottom: 0.25rem !important;
  }
  .sb-show-main .ltr .rw__ms-md-2,
  html[dir=rtl] .ltr .rw__ms-md-2,
  html[dir=ltr] .ltr .rw__ms-md-2 {
    margin-left: 0.25rem !important;
  }
  .sb-show-main .ltr .rw__me-md-2,
  html[dir=rtl] .ltr .rw__me-md-2,
  html[dir=ltr] .ltr .rw__me-md-2 {
    margin-right: 0.25rem !important;
  }
  .sb-show-main .ltr .rw__ps-md-2,
  html[dir=rtl] .ltr .rw__ps-md-2,
  html[dir=ltr] .ltr .rw__ps-md-2 {
    padding-left: 0.25rem !important;
  }
  .sb-show-main .ltr .rw__pe-md-2,
  html[dir=rtl] .ltr .rw__pe-md-2,
  html[dir=ltr] .ltr .rw__pe-md-2 {
    padding-right: 0.25rem !important;
  }
  .sb-show-main .rtl .rw__ms-md-2,
  html[dir=rtl] .rtl .rw__ms-md-2,
  html[dir=ltr] .rtl .rw__ms-md-2 {
    margin-right: 0.25rem !important;
  }
  .sb-show-main .rtl .rw__me-md-2,
  html[dir=rtl] .rtl .rw__me-md-2,
  html[dir=ltr] .rtl .rw__me-md-2 {
    margin-left: 0.25rem !important;
  }
  .sb-show-main .rtl .rw__ps-md-2,
  html[dir=rtl] .rtl .rw__ps-md-2,
  html[dir=ltr] .rtl .rw__ps-md-2 {
    padding-right: 0.25rem !important;
  }
  .sb-show-main .rtl .rw__pe-md-2,
  html[dir=rtl] .rtl .rw__pe-md-2,
  html[dir=ltr] .rtl .rw__pe-md-2 {
    padding-left: 0.25rem !important;
  }
}
@media (min-width: 640px) {
  html[dir=rtl] .ltr .rw__ms-sm-2,
  html[dir=ltr] .ltr .rw__ms-sm-2 {
    margin-left: 0.25rem !important;
  }
  html[dir=rtl] .rtl .rw__ms-sm-2,
  html[dir=ltr] .rtl .rw__ms-sm-2 {
    margin-right: 0.25rem !important;
  }
}
html[dir=rtl] .rw__p-3,
html[dir=ltr] .rw__p-3 {
  padding: 0.5rem !important;
}
html[dir=rtl] .rw__px-3,
html[dir=ltr] .rw__px-3 {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}
html[dir=rtl] .rw__py-3,
html[dir=ltr] .rw__py-3 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}
html[dir=rtl] .rw__pt-3,
html[dir=ltr] .rw__pt-3 {
  padding-top: 0.5rem !important;
}
html[dir=rtl] .rw__pb-3,
html[dir=ltr] .rw__pb-3 {
  padding-bottom: 0.5rem !important;
}
html[dir=rtl] .rw__m-3,
html[dir=ltr] .rw__m-3 {
  margin: 0.5rem !important;
}
html[dir=rtl] .rw__mx-3,
html[dir=ltr] .rw__mx-3 {
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}
html[dir=rtl] .rw__my-3,
html[dir=ltr] .rw__my-3 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}
html[dir=rtl] .rw__mt-3,
html[dir=ltr] .rw__mt-3 {
  margin-top: 0.5rem !important;
}
html[dir=rtl] .rw__mb-3,
html[dir=ltr] .rw__mb-3 {
  margin-bottom: 0.5rem !important;
}
html[dir=rtl] .ltr .rw__ps-3,
html[dir=ltr] .ltr .rw__ps-3 {
  padding-left: 0.5rem !important;
}
html[dir=rtl] .ltr .rw__pe-3,
html[dir=ltr] .ltr .rw__pe-3 {
  padding-right: 0.5rem !important;
}
html[dir=rtl] .ltr .rw__ms-3,
html[dir=ltr] .ltr .rw__ms-3 {
  margin-left: 0.5rem !important;
}
html[dir=rtl] .ltr .rw__me-3,
html[dir=ltr] .ltr .rw__me-3 {
  margin-right: 0.5rem !important;
}
html[dir=rtl] .rtl .rw__ps-3,
html[dir=ltr] .rtl .rw__ps-3 {
  padding-right: 0.5rem !important;
}
html[dir=rtl] .rtl .rw__pe-3,
html[dir=ltr] .rtl .rw__pe-3 {
  padding-left: 0.5rem !important;
}
html[dir=rtl] .rtl .rw__ms-3,
html[dir=ltr] .rtl .rw__ms-3 {
  margin-right: 0.5rem !important;
}
html[dir=rtl] .rtl .rw__me-3,
html[dir=ltr] .rtl .rw__me-3 {
  margin-left: 0.5rem !important;
}

.sb-show-main .rw__p-3 {
  padding: 0.5rem !important;
}
.sb-show-main .rw__px-3 {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}
.sb-show-main .rw__py-3 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}
.sb-show-main .rw__pt-3 {
  padding-top: 0.5rem !important;
}
.sb-show-main .rw__pb-3 {
  padding-bottom: 0.5rem !important;
}
.sb-show-main .rw__m-3 {
  margin: 0.5rem !important;
}
.sb-show-main .rw__mx-3 {
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}
.sb-show-main .rw__my-3 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}
.sb-show-main .rw__mt-3 {
  margin-top: 0.5rem !important;
}
.sb-show-main .rw__mb-3 {
  margin-bottom: 0.5rem !important;
}
.sb-show-main .rw__ps-3 {
  padding-left: 0.5rem !important;
}
.sb-show-main .rw__pe-3 {
  padding-right: 0.5rem !important;
}
.sb-show-main .rw__ms-3 {
  margin-left: 0.5rem !important;
}
.sb-show-main .rw__me-3 {
  margin-right: 0.5rem !important;
}

@media (min-width: 1366px) {
  .sb-show-main .ltr .rw__m-lg-3,
  .sb-show-main .rtl .rw__m-lg-3,
  html[dir=rtl] .ltr .rw__m-lg-3,
  html[dir=rtl] .rtl .rw__m-lg-3,
  html[dir=ltr] .ltr .rw__m-lg-3,
  html[dir=ltr] .rtl .rw__m-lg-3 {
    margin: 0.5rem !important;
  }
  .sb-show-main .ltr .rw__p-lg-3,
  .sb-show-main .rtl .rw__p-lg-3,
  html[dir=rtl] .ltr .rw__p-lg-3,
  html[dir=rtl] .rtl .rw__p-lg-3,
  html[dir=ltr] .ltr .rw__p-lg-3,
  html[dir=ltr] .rtl .rw__p-lg-3 {
    padding: 0.5rem !important;
  }
  .sb-show-main .ltr .rw__mx-lg-3,
  .sb-show-main .rtl .rw__mx-lg-3,
  html[dir=rtl] .ltr .rw__mx-lg-3,
  html[dir=rtl] .rtl .rw__mx-lg-3,
  html[dir=ltr] .ltr .rw__mx-lg-3,
  html[dir=ltr] .rtl .rw__mx-lg-3 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
  .sb-show-main .ltr .rw__my-lg-3,
  .sb-show-main .rtl .rw__my-lg-3,
  html[dir=rtl] .ltr .rw__my-lg-3,
  html[dir=rtl] .rtl .rw__my-lg-3,
  html[dir=ltr] .ltr .rw__my-lg-3,
  html[dir=ltr] .rtl .rw__my-lg-3 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .sb-show-main .ltr .rw__mt-lg-3,
  .sb-show-main .rtl .rw__mt-lg-3,
  html[dir=rtl] .ltr .rw__mt-lg-3,
  html[dir=rtl] .rtl .rw__mt-lg-3,
  html[dir=ltr] .ltr .rw__mt-lg-3,
  html[dir=ltr] .rtl .rw__mt-lg-3 {
    margin-top: 0.5rem !important;
  }
  .sb-show-main .ltr .rw__mb-lg-3,
  .sb-show-main .rtl .rw__mb-lg-3,
  html[dir=rtl] .ltr .rw__mb-lg-3,
  html[dir=rtl] .rtl .rw__mb-lg-3,
  html[dir=ltr] .ltr .rw__mb-lg-3,
  html[dir=ltr] .rtl .rw__mb-lg-3 {
    margin-bottom: 0.5rem !important;
  }
  .sb-show-main .ltr .rw__px-lg-3,
  .sb-show-main .rtl .rw__px-lg-3,
  html[dir=rtl] .ltr .rw__px-lg-3,
  html[dir=rtl] .rtl .rw__px-lg-3,
  html[dir=ltr] .ltr .rw__px-lg-3,
  html[dir=ltr] .rtl .rw__px-lg-3 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
  .sb-show-main .ltr .rw__py-lg-3,
  .sb-show-main .rtl .rw__py-lg-3,
  html[dir=rtl] .ltr .rw__py-lg-3,
  html[dir=rtl] .rtl .rw__py-lg-3,
  html[dir=ltr] .ltr .rw__py-lg-3,
  html[dir=ltr] .rtl .rw__py-lg-3 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .sb-show-main .ltr .rw__pt-lg-3,
  .sb-show-main .rtl .rw__pt-lg-3,
  html[dir=rtl] .ltr .rw__pt-lg-3,
  html[dir=rtl] .rtl .rw__pt-lg-3,
  html[dir=ltr] .ltr .rw__pt-lg-3,
  html[dir=ltr] .rtl .rw__pt-lg-3 {
    padding-top: 0.5rem !important;
  }
  .sb-show-main .ltr .rw__pb-lg-3,
  .sb-show-main .rtl .rw__pb-lg-3,
  html[dir=rtl] .ltr .rw__pb-lg-3,
  html[dir=rtl] .rtl .rw__pb-lg-3,
  html[dir=ltr] .ltr .rw__pb-lg-3,
  html[dir=ltr] .rtl .rw__pb-lg-3 {
    padding-bottom: 0.5rem !important;
  }
  .sb-show-main .ltr .rw__ms-lg-3,
  html[dir=rtl] .ltr .rw__ms-lg-3,
  html[dir=ltr] .ltr .rw__ms-lg-3 {
    margin-left: 0.5rem !important;
  }
  .sb-show-main .ltr .rw__me-lg-3,
  html[dir=rtl] .ltr .rw__me-lg-3,
  html[dir=ltr] .ltr .rw__me-lg-3 {
    margin-right: 0.5rem !important;
  }
  .sb-show-main .ltr .rw__ps-lg-3,
  html[dir=rtl] .ltr .rw__ps-lg-3,
  html[dir=ltr] .ltr .rw__ps-lg-3 {
    padding-left: 0.5rem !important;
  }
  .sb-show-main .ltr .rw__pe-lg-3,
  html[dir=rtl] .ltr .rw__pe-lg-3,
  html[dir=ltr] .ltr .rw__pe-lg-3 {
    padding-right: 0.5rem !important;
  }
  .sb-show-main .rtl .rw__ms-lg-3,
  html[dir=rtl] .rtl .rw__ms-lg-3,
  html[dir=ltr] .rtl .rw__ms-lg-3 {
    margin-right: 0.5rem !important;
  }
  .sb-show-main .rtl .rw__me-lg-3,
  html[dir=rtl] .rtl .rw__me-lg-3,
  html[dir=ltr] .rtl .rw__me-lg-3 {
    margin-left: 0.5rem !important;
  }
  .sb-show-main .rtl .rw__ps-lg-3,
  html[dir=rtl] .rtl .rw__ps-lg-3,
  html[dir=ltr] .rtl .rw__ps-lg-3 {
    padding-right: 0.5rem !important;
  }
  .sb-show-main .rtl .rw__pe-lg-3,
  html[dir=rtl] .rtl .rw__pe-lg-3,
  html[dir=ltr] .rtl .rw__pe-lg-3 {
    padding-left: 0.5rem !important;
  }
}
@media (min-width: 960px) {
  .sb-show-main .ltr .rw__m-md-3,
  .sb-show-main .rtl .rw__m-md-3,
  html[dir=rtl] .ltr .rw__m-md-3,
  html[dir=rtl] .rtl .rw__m-md-3,
  html[dir=ltr] .ltr .rw__m-md-3,
  html[dir=ltr] .rtl .rw__m-md-3 {
    margin: 0.5rem !important;
  }
  .sb-show-main .ltr .rw__p-md-3,
  .sb-show-main .rtl .rw__p-md-3,
  html[dir=rtl] .ltr .rw__p-md-3,
  html[dir=rtl] .rtl .rw__p-md-3,
  html[dir=ltr] .ltr .rw__p-md-3,
  html[dir=ltr] .rtl .rw__p-md-3 {
    padding: 0.5rem !important;
  }
  .sb-show-main .ltr .rw__mx-md-3,
  .sb-show-main .rtl .rw__mx-md-3,
  html[dir=rtl] .ltr .rw__mx-md-3,
  html[dir=rtl] .rtl .rw__mx-md-3,
  html[dir=ltr] .ltr .rw__mx-md-3,
  html[dir=ltr] .rtl .rw__mx-md-3 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
  .sb-show-main .ltr .rw__my-md-3,
  .sb-show-main .rtl .rw__my-md-3,
  html[dir=rtl] .ltr .rw__my-md-3,
  html[dir=rtl] .rtl .rw__my-md-3,
  html[dir=ltr] .ltr .rw__my-md-3,
  html[dir=ltr] .rtl .rw__my-md-3 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .sb-show-main .ltr .rw__mt-md-3,
  .sb-show-main .rtl .rw__mt-md-3,
  html[dir=rtl] .ltr .rw__mt-md-3,
  html[dir=rtl] .rtl .rw__mt-md-3,
  html[dir=ltr] .ltr .rw__mt-md-3,
  html[dir=ltr] .rtl .rw__mt-md-3 {
    margin-top: 0.5rem !important;
  }
  .sb-show-main .ltr .rw__mb-md-3,
  .sb-show-main .rtl .rw__mb-md-3,
  html[dir=rtl] .ltr .rw__mb-md-3,
  html[dir=rtl] .rtl .rw__mb-md-3,
  html[dir=ltr] .ltr .rw__mb-md-3,
  html[dir=ltr] .rtl .rw__mb-md-3 {
    margin-bottom: 0.5rem !important;
  }
  .sb-show-main .ltr .rw__px-md-3,
  .sb-show-main .rtl .rw__px-md-3,
  html[dir=rtl] .ltr .rw__px-md-3,
  html[dir=rtl] .rtl .rw__px-md-3,
  html[dir=ltr] .ltr .rw__px-md-3,
  html[dir=ltr] .rtl .rw__px-md-3 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
  .sb-show-main .ltr .rw__py-md-3,
  .sb-show-main .rtl .rw__py-md-3,
  html[dir=rtl] .ltr .rw__py-md-3,
  html[dir=rtl] .rtl .rw__py-md-3,
  html[dir=ltr] .ltr .rw__py-md-3,
  html[dir=ltr] .rtl .rw__py-md-3 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .sb-show-main .ltr .rw__pt-md-3,
  .sb-show-main .rtl .rw__pt-md-3,
  html[dir=rtl] .ltr .rw__pt-md-3,
  html[dir=rtl] .rtl .rw__pt-md-3,
  html[dir=ltr] .ltr .rw__pt-md-3,
  html[dir=ltr] .rtl .rw__pt-md-3 {
    padding-top: 0.5rem !important;
  }
  .sb-show-main .ltr .rw__pb-md-3,
  .sb-show-main .rtl .rw__pb-md-3,
  html[dir=rtl] .ltr .rw__pb-md-3,
  html[dir=rtl] .rtl .rw__pb-md-3,
  html[dir=ltr] .ltr .rw__pb-md-3,
  html[dir=ltr] .rtl .rw__pb-md-3 {
    padding-bottom: 0.5rem !important;
  }
  .sb-show-main .ltr .rw__ms-md-3,
  html[dir=rtl] .ltr .rw__ms-md-3,
  html[dir=ltr] .ltr .rw__ms-md-3 {
    margin-left: 0.5rem !important;
  }
  .sb-show-main .ltr .rw__me-md-3,
  html[dir=rtl] .ltr .rw__me-md-3,
  html[dir=ltr] .ltr .rw__me-md-3 {
    margin-right: 0.5rem !important;
  }
  .sb-show-main .ltr .rw__ps-md-3,
  html[dir=rtl] .ltr .rw__ps-md-3,
  html[dir=ltr] .ltr .rw__ps-md-3 {
    padding-left: 0.5rem !important;
  }
  .sb-show-main .ltr .rw__pe-md-3,
  html[dir=rtl] .ltr .rw__pe-md-3,
  html[dir=ltr] .ltr .rw__pe-md-3 {
    padding-right: 0.5rem !important;
  }
  .sb-show-main .rtl .rw__ms-md-3,
  html[dir=rtl] .rtl .rw__ms-md-3,
  html[dir=ltr] .rtl .rw__ms-md-3 {
    margin-right: 0.5rem !important;
  }
  .sb-show-main .rtl .rw__me-md-3,
  html[dir=rtl] .rtl .rw__me-md-3,
  html[dir=ltr] .rtl .rw__me-md-3 {
    margin-left: 0.5rem !important;
  }
  .sb-show-main .rtl .rw__ps-md-3,
  html[dir=rtl] .rtl .rw__ps-md-3,
  html[dir=ltr] .rtl .rw__ps-md-3 {
    padding-right: 0.5rem !important;
  }
  .sb-show-main .rtl .rw__pe-md-3,
  html[dir=rtl] .rtl .rw__pe-md-3,
  html[dir=ltr] .rtl .rw__pe-md-3 {
    padding-left: 0.5rem !important;
  }
}
@media (min-width: 640px) {
  html[dir=rtl] .ltr .rw__ms-sm-3,
  html[dir=ltr] .ltr .rw__ms-sm-3 {
    margin-left: 0.5rem !important;
  }
  html[dir=rtl] .rtl .rw__ms-sm-3,
  html[dir=ltr] .rtl .rw__ms-sm-3 {
    margin-right: 0.5rem !important;
  }
}
html[dir=rtl] .rw__p-4,
html[dir=ltr] .rw__p-4 {
  padding: 0.75rem !important;
}
html[dir=rtl] .rw__px-4,
html[dir=ltr] .rw__px-4 {
  padding-left: 0.75rem !important;
  padding-right: 0.75rem !important;
}
html[dir=rtl] .rw__py-4, html[dir=rtl] .rw__notification-toast-error .p-toast-message-content, .rw__notification-toast-error html[dir=rtl] .p-toast-message-content, html[dir=rtl] .rw__notification-toast-warning .p-toast-message-content, .rw__notification-toast-warning html[dir=rtl] .p-toast-message-content, html[dir=rtl] .rw__notification-toast-success .p-toast-message-content, .rw__notification-toast-success html[dir=rtl] .p-toast-message-content, html[dir=rtl] .rw__notification-toast-info .p-toast-message-content, .rw__notification-toast-info html[dir=rtl] .p-toast-message-content,
html[dir=ltr] .rw__py-4,
html[dir=ltr] .rw__notification-toast-error .p-toast-message-content,
.rw__notification-toast-error html[dir=ltr] .p-toast-message-content,
html[dir=ltr] .rw__notification-toast-warning .p-toast-message-content,
.rw__notification-toast-warning html[dir=ltr] .p-toast-message-content,
html[dir=ltr] .rw__notification-toast-success .p-toast-message-content,
.rw__notification-toast-success html[dir=ltr] .p-toast-message-content,
html[dir=ltr] .rw__notification-toast-info .p-toast-message-content,
.rw__notification-toast-info html[dir=ltr] .p-toast-message-content {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}
html[dir=rtl] .rw__pt-4,
html[dir=ltr] .rw__pt-4 {
  padding-top: 0.75rem !important;
}
html[dir=rtl] .rw__pb-4,
html[dir=ltr] .rw__pb-4 {
  padding-bottom: 0.75rem !important;
}
html[dir=rtl] .rw__m-4,
html[dir=ltr] .rw__m-4 {
  margin: 0.75rem !important;
}
html[dir=rtl] .rw__mx-4, html[dir=rtl] .rw__datatable-custom-paginator .p-paginator .p-dropdown, .rw__datatable-custom-paginator .p-paginator html[dir=rtl] .p-dropdown,
html[dir=ltr] .rw__mx-4,
html[dir=ltr] .rw__datatable-custom-paginator .p-paginator .p-dropdown,
.rw__datatable-custom-paginator .p-paginator html[dir=ltr] .p-dropdown {
  margin-left: 0.75rem !important;
  margin-right: 0.75rem !important;
}
html[dir=rtl] .rw__my-4,
html[dir=ltr] .rw__my-4 {
  margin-top: 0.75rem !important;
  margin-bottom: 0.75rem !important;
}
html[dir=rtl] .rw__mt-4,
html[dir=ltr] .rw__mt-4 {
  margin-top: 0.75rem !important;
}
html[dir=rtl] .rw__mb-4,
html[dir=ltr] .rw__mb-4 {
  margin-bottom: 0.75rem !important;
}
html[dir=rtl] .ltr .rw__ps-4,
html[dir=ltr] .ltr .rw__ps-4 {
  padding-left: 0.75rem !important;
}
html[dir=rtl] .ltr .rw__pe-4,
html[dir=ltr] .ltr .rw__pe-4 {
  padding-right: 0.75rem !important;
}
html[dir=rtl] .ltr .rw__ms-4, html[dir=rtl] .ltr .rw__datatable-paginator .p-paginator-element, .rw__datatable-paginator html[dir=rtl] .ltr .p-paginator-element, html[dir=rtl] .ltr .rw__checkbox + label,
html[dir=ltr] .ltr .rw__ms-4,
html[dir=ltr] .ltr .rw__datatable-paginator .p-paginator-element,
.rw__datatable-paginator html[dir=ltr] .ltr .p-paginator-element,
html[dir=ltr] .ltr .rw__checkbox + label {
  margin-left: 0.75rem !important;
}
html[dir=rtl] .ltr .rw__me-4, html[dir=rtl] .ltr .rw__notification-error .notification-body i, .rw__notification-error .notification-body html[dir=rtl] .ltr i, html[dir=rtl] .ltr .rw__notification-warning .notification-body i, .rw__notification-warning .notification-body html[dir=rtl] .ltr i, html[dir=rtl] .ltr .rw__notification-success .notification-body i, .rw__notification-success .notification-body html[dir=rtl] .ltr i, html[dir=rtl] .ltr .rw__notification-info .notification-body i, .rw__notification-info .notification-body html[dir=rtl] .ltr i,
html[dir=ltr] .ltr .rw__me-4,
html[dir=ltr] .ltr .rw__notification-error .notification-body i,
.rw__notification-error .notification-body html[dir=ltr] .ltr i,
html[dir=ltr] .ltr .rw__notification-warning .notification-body i,
.rw__notification-warning .notification-body html[dir=ltr] .ltr i,
html[dir=ltr] .ltr .rw__notification-success .notification-body i,
.rw__notification-success .notification-body html[dir=ltr] .ltr i,
html[dir=ltr] .ltr .rw__notification-info .notification-body i,
.rw__notification-info .notification-body html[dir=ltr] .ltr i {
  margin-right: 0.75rem !important;
}
html[dir=rtl] .rtl .rw__ps-4,
html[dir=ltr] .rtl .rw__ps-4 {
  padding-right: 0.75rem !important;
}
html[dir=rtl] .rtl .rw__pe-4,
html[dir=ltr] .rtl .rw__pe-4 {
  padding-left: 0.75rem !important;
}
html[dir=rtl] .rtl .rw__ms-4, html[dir=rtl] .rtl .rw__datatable-paginator .p-paginator-element, .rw__datatable-paginator html[dir=rtl] .rtl .p-paginator-element, html[dir=rtl] .rtl .rw__checkbox + label,
html[dir=ltr] .rtl .rw__ms-4,
html[dir=ltr] .rtl .rw__datatable-paginator .p-paginator-element,
.rw__datatable-paginator html[dir=ltr] .rtl .p-paginator-element,
html[dir=ltr] .rtl .rw__checkbox + label {
  margin-right: 0.75rem !important;
}
html[dir=rtl] .rtl .rw__me-4, html[dir=rtl] .rtl .rw__notification-error .notification-body i, .rw__notification-error .notification-body html[dir=rtl] .rtl i, html[dir=rtl] .rtl .rw__notification-warning .notification-body i, .rw__notification-warning .notification-body html[dir=rtl] .rtl i, html[dir=rtl] .rtl .rw__notification-success .notification-body i, .rw__notification-success .notification-body html[dir=rtl] .rtl i, html[dir=rtl] .rtl .rw__notification-info .notification-body i, .rw__notification-info .notification-body html[dir=rtl] .rtl i,
html[dir=ltr] .rtl .rw__me-4,
html[dir=ltr] .rtl .rw__notification-error .notification-body i,
.rw__notification-error .notification-body html[dir=ltr] .rtl i,
html[dir=ltr] .rtl .rw__notification-warning .notification-body i,
.rw__notification-warning .notification-body html[dir=ltr] .rtl i,
html[dir=ltr] .rtl .rw__notification-success .notification-body i,
.rw__notification-success .notification-body html[dir=ltr] .rtl i,
html[dir=ltr] .rtl .rw__notification-info .notification-body i,
.rw__notification-info .notification-body html[dir=ltr] .rtl i {
  margin-left: 0.75rem !important;
}

.sb-show-main .rw__p-4 {
  padding: 0.75rem !important;
}
.sb-show-main .rw__px-4 {
  padding-left: 0.75rem !important;
  padding-right: 0.75rem !important;
}
.sb-show-main .rw__py-4, .sb-show-main .rw__notification-toast-error .p-toast-message-content, .rw__notification-toast-error .sb-show-main .p-toast-message-content, .sb-show-main .rw__notification-toast-warning .p-toast-message-content, .rw__notification-toast-warning .sb-show-main .p-toast-message-content, .sb-show-main .rw__notification-toast-success .p-toast-message-content, .rw__notification-toast-success .sb-show-main .p-toast-message-content, .sb-show-main .rw__notification-toast-info .p-toast-message-content, .rw__notification-toast-info .sb-show-main .p-toast-message-content {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}
.sb-show-main .rw__pt-4 {
  padding-top: 0.75rem !important;
}
.sb-show-main .rw__pb-4 {
  padding-bottom: 0.75rem !important;
}
.sb-show-main .rw__m-4 {
  margin: 0.75rem !important;
}
.sb-show-main .rw__mx-4, .sb-show-main .rw__datatable-custom-paginator .p-paginator .p-dropdown, .rw__datatable-custom-paginator .p-paginator .sb-show-main .p-dropdown {
  margin-left: 0.75rem !important;
  margin-right: 0.75rem !important;
}
.sb-show-main .rw__my-4 {
  margin-top: 0.75rem !important;
  margin-bottom: 0.75rem !important;
}
.sb-show-main .rw__mt-4 {
  margin-top: 0.75rem !important;
}
.sb-show-main .rw__mb-4 {
  margin-bottom: 0.75rem !important;
}
.sb-show-main .rw__ps-4 {
  padding-left: 0.75rem !important;
}
.sb-show-main .rw__pe-4 {
  padding-right: 0.75rem !important;
}
.sb-show-main .rw__ms-4, .sb-show-main .rw__datatable-paginator .p-paginator-element, .rw__datatable-paginator .sb-show-main .p-paginator-element, .sb-show-main .rw__checkbox + label {
  margin-left: 0.75rem !important;
}
.sb-show-main .rw__me-4, .sb-show-main .rw__notification-error .notification-body i, .rw__notification-error .notification-body .sb-show-main i, .sb-show-main .rw__notification-warning .notification-body i, .rw__notification-warning .notification-body .sb-show-main i, .sb-show-main .rw__notification-success .notification-body i, .rw__notification-success .notification-body .sb-show-main i, .sb-show-main .rw__notification-info .notification-body i, .rw__notification-info .notification-body .sb-show-main i {
  margin-right: 0.75rem !important;
}

@media (min-width: 1366px) {
  .sb-show-main .ltr .rw__m-lg-4,
  .sb-show-main .rtl .rw__m-lg-4,
  html[dir=rtl] .ltr .rw__m-lg-4,
  html[dir=rtl] .rtl .rw__m-lg-4,
  html[dir=ltr] .ltr .rw__m-lg-4,
  html[dir=ltr] .rtl .rw__m-lg-4 {
    margin: 0.75rem !important;
  }
  .sb-show-main .ltr .rw__p-lg-4,
  .sb-show-main .rtl .rw__p-lg-4,
  html[dir=rtl] .ltr .rw__p-lg-4,
  html[dir=rtl] .rtl .rw__p-lg-4,
  html[dir=ltr] .ltr .rw__p-lg-4,
  html[dir=ltr] .rtl .rw__p-lg-4 {
    padding: 0.75rem !important;
  }
  .sb-show-main .ltr .rw__mx-lg-4,
  .sb-show-main .rtl .rw__mx-lg-4,
  html[dir=rtl] .ltr .rw__mx-lg-4,
  html[dir=rtl] .rtl .rw__mx-lg-4,
  html[dir=ltr] .ltr .rw__mx-lg-4,
  html[dir=ltr] .rtl .rw__mx-lg-4 {
    margin-left: 0.75rem !important;
    margin-right: 0.75rem !important;
  }
  .sb-show-main .ltr .rw__my-lg-4,
  .sb-show-main .rtl .rw__my-lg-4,
  html[dir=rtl] .ltr .rw__my-lg-4,
  html[dir=rtl] .rtl .rw__my-lg-4,
  html[dir=ltr] .ltr .rw__my-lg-4,
  html[dir=ltr] .rtl .rw__my-lg-4 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }
  .sb-show-main .ltr .rw__mt-lg-4,
  .sb-show-main .rtl .rw__mt-lg-4,
  html[dir=rtl] .ltr .rw__mt-lg-4,
  html[dir=rtl] .rtl .rw__mt-lg-4,
  html[dir=ltr] .ltr .rw__mt-lg-4,
  html[dir=ltr] .rtl .rw__mt-lg-4 {
    margin-top: 0.75rem !important;
  }
  .sb-show-main .ltr .rw__mb-lg-4,
  .sb-show-main .rtl .rw__mb-lg-4,
  html[dir=rtl] .ltr .rw__mb-lg-4,
  html[dir=rtl] .rtl .rw__mb-lg-4,
  html[dir=ltr] .ltr .rw__mb-lg-4,
  html[dir=ltr] .rtl .rw__mb-lg-4 {
    margin-bottom: 0.75rem !important;
  }
  .sb-show-main .ltr .rw__px-lg-4,
  .sb-show-main .rtl .rw__px-lg-4,
  html[dir=rtl] .ltr .rw__px-lg-4,
  html[dir=rtl] .rtl .rw__px-lg-4,
  html[dir=ltr] .ltr .rw__px-lg-4,
  html[dir=ltr] .rtl .rw__px-lg-4 {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
  }
  .sb-show-main .ltr .rw__py-lg-4,
  .sb-show-main .rtl .rw__py-lg-4,
  html[dir=rtl] .ltr .rw__py-lg-4,
  html[dir=rtl] .rtl .rw__py-lg-4,
  html[dir=ltr] .ltr .rw__py-lg-4,
  html[dir=ltr] .rtl .rw__py-lg-4 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }
  .sb-show-main .ltr .rw__pt-lg-4,
  .sb-show-main .rtl .rw__pt-lg-4,
  html[dir=rtl] .ltr .rw__pt-lg-4,
  html[dir=rtl] .rtl .rw__pt-lg-4,
  html[dir=ltr] .ltr .rw__pt-lg-4,
  html[dir=ltr] .rtl .rw__pt-lg-4 {
    padding-top: 0.75rem !important;
  }
  .sb-show-main .ltr .rw__pb-lg-4,
  .sb-show-main .rtl .rw__pb-lg-4,
  html[dir=rtl] .ltr .rw__pb-lg-4,
  html[dir=rtl] .rtl .rw__pb-lg-4,
  html[dir=ltr] .ltr .rw__pb-lg-4,
  html[dir=ltr] .rtl .rw__pb-lg-4 {
    padding-bottom: 0.75rem !important;
  }
  .sb-show-main .ltr .rw__ms-lg-4,
  html[dir=rtl] .ltr .rw__ms-lg-4,
  html[dir=ltr] .ltr .rw__ms-lg-4 {
    margin-left: 0.75rem !important;
  }
  .sb-show-main .ltr .rw__me-lg-4,
  html[dir=rtl] .ltr .rw__me-lg-4,
  html[dir=ltr] .ltr .rw__me-lg-4 {
    margin-right: 0.75rem !important;
  }
  .sb-show-main .ltr .rw__ps-lg-4,
  html[dir=rtl] .ltr .rw__ps-lg-4,
  html[dir=ltr] .ltr .rw__ps-lg-4 {
    padding-left: 0.75rem !important;
  }
  .sb-show-main .ltr .rw__pe-lg-4,
  html[dir=rtl] .ltr .rw__pe-lg-4,
  html[dir=ltr] .ltr .rw__pe-lg-4 {
    padding-right: 0.75rem !important;
  }
  .sb-show-main .rtl .rw__ms-lg-4,
  html[dir=rtl] .rtl .rw__ms-lg-4,
  html[dir=ltr] .rtl .rw__ms-lg-4 {
    margin-right: 0.75rem !important;
  }
  .sb-show-main .rtl .rw__me-lg-4,
  html[dir=rtl] .rtl .rw__me-lg-4,
  html[dir=ltr] .rtl .rw__me-lg-4 {
    margin-left: 0.75rem !important;
  }
  .sb-show-main .rtl .rw__ps-lg-4,
  html[dir=rtl] .rtl .rw__ps-lg-4,
  html[dir=ltr] .rtl .rw__ps-lg-4 {
    padding-right: 0.75rem !important;
  }
  .sb-show-main .rtl .rw__pe-lg-4,
  html[dir=rtl] .rtl .rw__pe-lg-4,
  html[dir=ltr] .rtl .rw__pe-lg-4 {
    padding-left: 0.75rem !important;
  }
}
@media (min-width: 960px) {
  .sb-show-main .ltr .rw__m-md-4,
  .sb-show-main .rtl .rw__m-md-4,
  html[dir=rtl] .ltr .rw__m-md-4,
  html[dir=rtl] .rtl .rw__m-md-4,
  html[dir=ltr] .ltr .rw__m-md-4,
  html[dir=ltr] .rtl .rw__m-md-4 {
    margin: 0.75rem !important;
  }
  .sb-show-main .ltr .rw__p-md-4,
  .sb-show-main .rtl .rw__p-md-4,
  html[dir=rtl] .ltr .rw__p-md-4,
  html[dir=rtl] .rtl .rw__p-md-4,
  html[dir=ltr] .ltr .rw__p-md-4,
  html[dir=ltr] .rtl .rw__p-md-4 {
    padding: 0.75rem !important;
  }
  .sb-show-main .ltr .rw__mx-md-4,
  .sb-show-main .rtl .rw__mx-md-4,
  html[dir=rtl] .ltr .rw__mx-md-4,
  html[dir=rtl] .rtl .rw__mx-md-4,
  html[dir=ltr] .ltr .rw__mx-md-4,
  html[dir=ltr] .rtl .rw__mx-md-4 {
    margin-left: 0.75rem !important;
    margin-right: 0.75rem !important;
  }
  .sb-show-main .ltr .rw__my-md-4,
  .sb-show-main .rtl .rw__my-md-4,
  html[dir=rtl] .ltr .rw__my-md-4,
  html[dir=rtl] .rtl .rw__my-md-4,
  html[dir=ltr] .ltr .rw__my-md-4,
  html[dir=ltr] .rtl .rw__my-md-4 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }
  .sb-show-main .ltr .rw__mt-md-4,
  .sb-show-main .rtl .rw__mt-md-4,
  html[dir=rtl] .ltr .rw__mt-md-4,
  html[dir=rtl] .rtl .rw__mt-md-4,
  html[dir=ltr] .ltr .rw__mt-md-4,
  html[dir=ltr] .rtl .rw__mt-md-4 {
    margin-top: 0.75rem !important;
  }
  .sb-show-main .ltr .rw__mb-md-4,
  .sb-show-main .rtl .rw__mb-md-4,
  html[dir=rtl] .ltr .rw__mb-md-4,
  html[dir=rtl] .rtl .rw__mb-md-4,
  html[dir=ltr] .ltr .rw__mb-md-4,
  html[dir=ltr] .rtl .rw__mb-md-4 {
    margin-bottom: 0.75rem !important;
  }
  .sb-show-main .ltr .rw__px-md-4,
  .sb-show-main .rtl .rw__px-md-4,
  html[dir=rtl] .ltr .rw__px-md-4,
  html[dir=rtl] .rtl .rw__px-md-4,
  html[dir=ltr] .ltr .rw__px-md-4,
  html[dir=ltr] .rtl .rw__px-md-4 {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
  }
  .sb-show-main .ltr .rw__py-md-4,
  .sb-show-main .rtl .rw__py-md-4,
  html[dir=rtl] .ltr .rw__py-md-4,
  html[dir=rtl] .rtl .rw__py-md-4,
  html[dir=ltr] .ltr .rw__py-md-4,
  html[dir=ltr] .rtl .rw__py-md-4 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }
  .sb-show-main .ltr .rw__pt-md-4,
  .sb-show-main .rtl .rw__pt-md-4,
  html[dir=rtl] .ltr .rw__pt-md-4,
  html[dir=rtl] .rtl .rw__pt-md-4,
  html[dir=ltr] .ltr .rw__pt-md-4,
  html[dir=ltr] .rtl .rw__pt-md-4 {
    padding-top: 0.75rem !important;
  }
  .sb-show-main .ltr .rw__pb-md-4,
  .sb-show-main .rtl .rw__pb-md-4,
  html[dir=rtl] .ltr .rw__pb-md-4,
  html[dir=rtl] .rtl .rw__pb-md-4,
  html[dir=ltr] .ltr .rw__pb-md-4,
  html[dir=ltr] .rtl .rw__pb-md-4 {
    padding-bottom: 0.75rem !important;
  }
  .sb-show-main .ltr .rw__ms-md-4,
  html[dir=rtl] .ltr .rw__ms-md-4,
  html[dir=ltr] .ltr .rw__ms-md-4 {
    margin-left: 0.75rem !important;
  }
  .sb-show-main .ltr .rw__me-md-4,
  html[dir=rtl] .ltr .rw__me-md-4,
  html[dir=ltr] .ltr .rw__me-md-4 {
    margin-right: 0.75rem !important;
  }
  .sb-show-main .ltr .rw__ps-md-4,
  html[dir=rtl] .ltr .rw__ps-md-4,
  html[dir=ltr] .ltr .rw__ps-md-4 {
    padding-left: 0.75rem !important;
  }
  .sb-show-main .ltr .rw__pe-md-4,
  html[dir=rtl] .ltr .rw__pe-md-4,
  html[dir=ltr] .ltr .rw__pe-md-4 {
    padding-right: 0.75rem !important;
  }
  .sb-show-main .rtl .rw__ms-md-4,
  html[dir=rtl] .rtl .rw__ms-md-4,
  html[dir=ltr] .rtl .rw__ms-md-4 {
    margin-right: 0.75rem !important;
  }
  .sb-show-main .rtl .rw__me-md-4,
  html[dir=rtl] .rtl .rw__me-md-4,
  html[dir=ltr] .rtl .rw__me-md-4 {
    margin-left: 0.75rem !important;
  }
  .sb-show-main .rtl .rw__ps-md-4,
  html[dir=rtl] .rtl .rw__ps-md-4,
  html[dir=ltr] .rtl .rw__ps-md-4 {
    padding-right: 0.75rem !important;
  }
  .sb-show-main .rtl .rw__pe-md-4,
  html[dir=rtl] .rtl .rw__pe-md-4,
  html[dir=ltr] .rtl .rw__pe-md-4 {
    padding-left: 0.75rem !important;
  }
}
@media (min-width: 640px) {
  html[dir=rtl] .ltr .rw__ms-sm-4,
  html[dir=ltr] .ltr .rw__ms-sm-4 {
    margin-left: 0.75rem !important;
  }
  html[dir=rtl] .rtl .rw__ms-sm-4,
  html[dir=ltr] .rtl .rw__ms-sm-4 {
    margin-right: 0.75rem !important;
  }
}
html[dir=rtl] .rw__p-5, html[dir=rtl] .rw__tooltip .p-tooltip-text, .rw__tooltip html[dir=rtl] .p-tooltip-text,
html[dir=ltr] .rw__p-5,
html[dir=ltr] .rw__tooltip .p-tooltip-text,
.rw__tooltip html[dir=ltr] .p-tooltip-text {
  padding: 1rem !important;
}
html[dir=rtl] .rw__px-5, html[dir=rtl] .rw__notification-toast-error .p-toast-message-content, .rw__notification-toast-error html[dir=rtl] .p-toast-message-content, html[dir=rtl] .rw__notification-toast-warning .p-toast-message-content, .rw__notification-toast-warning html[dir=rtl] .p-toast-message-content, html[dir=rtl] .rw__notification-toast-success .p-toast-message-content, .rw__notification-toast-success html[dir=rtl] .p-toast-message-content, html[dir=rtl] .rw__notification-toast-info .p-toast-message-content, .rw__notification-toast-info html[dir=rtl] .p-toast-message-content,
html[dir=ltr] .rw__px-5,
html[dir=ltr] .rw__notification-toast-error .p-toast-message-content,
.rw__notification-toast-error html[dir=ltr] .p-toast-message-content,
html[dir=ltr] .rw__notification-toast-warning .p-toast-message-content,
.rw__notification-toast-warning html[dir=ltr] .p-toast-message-content,
html[dir=ltr] .rw__notification-toast-success .p-toast-message-content,
.rw__notification-toast-success html[dir=ltr] .p-toast-message-content,
html[dir=ltr] .rw__notification-toast-info .p-toast-message-content,
.rw__notification-toast-info html[dir=ltr] .p-toast-message-content {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}
html[dir=rtl] .rw__py-5,
html[dir=ltr] .rw__py-5 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}
html[dir=rtl] .rw__pt-5,
html[dir=ltr] .rw__pt-5 {
  padding-top: 1rem !important;
}
html[dir=rtl] .rw__pb-5,
html[dir=ltr] .rw__pb-5 {
  padding-bottom: 1rem !important;
}
html[dir=rtl] .rw__m-5,
html[dir=ltr] .rw__m-5 {
  margin: 1rem !important;
}
html[dir=rtl] .rw__mx-5,
html[dir=ltr] .rw__mx-5 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}
html[dir=rtl] .rw__my-5,
html[dir=ltr] .rw__my-5 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}
html[dir=rtl] .rw__mt-5,
html[dir=ltr] .rw__mt-5 {
  margin-top: 1rem !important;
}
html[dir=rtl] .rw__mb-5,
html[dir=ltr] .rw__mb-5 {
  margin-bottom: 1rem !important;
}
html[dir=rtl] .ltr .rw__ps-5,
html[dir=ltr] .ltr .rw__ps-5 {
  padding-left: 1rem !important;
}
html[dir=rtl] .ltr .rw__pe-5,
html[dir=ltr] .ltr .rw__pe-5 {
  padding-right: 1rem !important;
}
html[dir=rtl] .ltr .rw__ms-5, html[dir=rtl] .ltr .rw__dynamic-dialog .p-dialog-footer button:not(:first-child), .rw__dynamic-dialog .p-dialog-footer html[dir=rtl] .ltr button:not(:first-child), html[dir=rtl] .ltr .rw__notification-error .notification-footer .fa-xmark, .rw__notification-error .notification-footer html[dir=rtl] .ltr .fa-xmark, html[dir=rtl] .ltr .rw__notification-warning .notification-footer .fa-xmark, .rw__notification-warning .notification-footer html[dir=rtl] .ltr .fa-xmark, html[dir=rtl] .ltr .rw__notification-success .notification-footer .fa-xmark, .rw__notification-success .notification-footer html[dir=rtl] .ltr .fa-xmark, html[dir=rtl] .ltr .rw__notification-info .notification-footer .fa-xmark, .rw__notification-info .notification-footer html[dir=rtl] .ltr .fa-xmark,
html[dir=ltr] .ltr .rw__ms-5,
html[dir=ltr] .ltr .rw__dynamic-dialog .p-dialog-footer button:not(:first-child),
.rw__dynamic-dialog .p-dialog-footer html[dir=ltr] .ltr button:not(:first-child),
html[dir=ltr] .ltr .rw__notification-error .notification-footer .fa-xmark,
.rw__notification-error .notification-footer html[dir=ltr] .ltr .fa-xmark,
html[dir=ltr] .ltr .rw__notification-warning .notification-footer .fa-xmark,
.rw__notification-warning .notification-footer html[dir=ltr] .ltr .fa-xmark,
html[dir=ltr] .ltr .rw__notification-success .notification-footer .fa-xmark,
.rw__notification-success .notification-footer html[dir=ltr] .ltr .fa-xmark,
html[dir=ltr] .ltr .rw__notification-info .notification-footer .fa-xmark,
.rw__notification-info .notification-footer html[dir=ltr] .ltr .fa-xmark {
  margin-left: 1rem !important;
}
html[dir=rtl] .ltr .rw__me-5,
html[dir=ltr] .ltr .rw__me-5 {
  margin-right: 1rem !important;
}
html[dir=rtl] .rtl .rw__ps-5,
html[dir=ltr] .rtl .rw__ps-5 {
  padding-right: 1rem !important;
}
html[dir=rtl] .rtl .rw__pe-5,
html[dir=ltr] .rtl .rw__pe-5 {
  padding-left: 1rem !important;
}
html[dir=rtl] .rtl .rw__ms-5, html[dir=rtl] .rtl .rw__dynamic-dialog .p-dialog-footer button:not(:first-child), .rw__dynamic-dialog .p-dialog-footer html[dir=rtl] .rtl button:not(:first-child), html[dir=rtl] .rtl .rw__notification-error .notification-footer .fa-xmark, .rw__notification-error .notification-footer html[dir=rtl] .rtl .fa-xmark, html[dir=rtl] .rtl .rw__notification-warning .notification-footer .fa-xmark, .rw__notification-warning .notification-footer html[dir=rtl] .rtl .fa-xmark, html[dir=rtl] .rtl .rw__notification-success .notification-footer .fa-xmark, .rw__notification-success .notification-footer html[dir=rtl] .rtl .fa-xmark, html[dir=rtl] .rtl .rw__notification-info .notification-footer .fa-xmark, .rw__notification-info .notification-footer html[dir=rtl] .rtl .fa-xmark,
html[dir=ltr] .rtl .rw__ms-5,
html[dir=ltr] .rtl .rw__dynamic-dialog .p-dialog-footer button:not(:first-child),
.rw__dynamic-dialog .p-dialog-footer html[dir=ltr] .rtl button:not(:first-child),
html[dir=ltr] .rtl .rw__notification-error .notification-footer .fa-xmark,
.rw__notification-error .notification-footer html[dir=ltr] .rtl .fa-xmark,
html[dir=ltr] .rtl .rw__notification-warning .notification-footer .fa-xmark,
.rw__notification-warning .notification-footer html[dir=ltr] .rtl .fa-xmark,
html[dir=ltr] .rtl .rw__notification-success .notification-footer .fa-xmark,
.rw__notification-success .notification-footer html[dir=ltr] .rtl .fa-xmark,
html[dir=ltr] .rtl .rw__notification-info .notification-footer .fa-xmark,
.rw__notification-info .notification-footer html[dir=ltr] .rtl .fa-xmark {
  margin-right: 1rem !important;
}
html[dir=rtl] .rtl .rw__me-5,
html[dir=ltr] .rtl .rw__me-5 {
  margin-left: 1rem !important;
}

.sb-show-main .rw__p-5, .sb-show-main .rw__tooltip .p-tooltip-text, .rw__tooltip .sb-show-main .p-tooltip-text {
  padding: 1rem !important;
}
.sb-show-main .rw__px-5, .sb-show-main .rw__notification-toast-error .p-toast-message-content, .rw__notification-toast-error .sb-show-main .p-toast-message-content, .sb-show-main .rw__notification-toast-warning .p-toast-message-content, .rw__notification-toast-warning .sb-show-main .p-toast-message-content, .sb-show-main .rw__notification-toast-success .p-toast-message-content, .rw__notification-toast-success .sb-show-main .p-toast-message-content, .sb-show-main .rw__notification-toast-info .p-toast-message-content, .rw__notification-toast-info .sb-show-main .p-toast-message-content {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}
.sb-show-main .rw__py-5 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}
.sb-show-main .rw__pt-5 {
  padding-top: 1rem !important;
}
.sb-show-main .rw__pb-5 {
  padding-bottom: 1rem !important;
}
.sb-show-main .rw__m-5 {
  margin: 1rem !important;
}
.sb-show-main .rw__mx-5 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}
.sb-show-main .rw__my-5 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}
.sb-show-main .rw__mt-5 {
  margin-top: 1rem !important;
}
.sb-show-main .rw__mb-5 {
  margin-bottom: 1rem !important;
}
.sb-show-main .rw__ps-5 {
  padding-left: 1rem !important;
}
.sb-show-main .rw__pe-5 {
  padding-right: 1rem !important;
}
.sb-show-main .rw__ms-5, .sb-show-main .rw__dynamic-dialog .p-dialog-footer button:not(:first-child), .rw__dynamic-dialog .p-dialog-footer .sb-show-main button:not(:first-child), .sb-show-main .rw__notification-error .notification-footer .fa-xmark, .rw__notification-error .notification-footer .sb-show-main .fa-xmark, .sb-show-main .rw__notification-warning .notification-footer .fa-xmark, .rw__notification-warning .notification-footer .sb-show-main .fa-xmark, .sb-show-main .rw__notification-success .notification-footer .fa-xmark, .rw__notification-success .notification-footer .sb-show-main .fa-xmark, .sb-show-main .rw__notification-info .notification-footer .fa-xmark, .rw__notification-info .notification-footer .sb-show-main .fa-xmark {
  margin-left: 1rem !important;
}
.sb-show-main .rw__me-5 {
  margin-right: 1rem !important;
}

@media (min-width: 1366px) {
  .sb-show-main .ltr .rw__m-lg-5,
  .sb-show-main .rtl .rw__m-lg-5,
  html[dir=rtl] .ltr .rw__m-lg-5,
  html[dir=rtl] .rtl .rw__m-lg-5,
  html[dir=ltr] .ltr .rw__m-lg-5,
  html[dir=ltr] .rtl .rw__m-lg-5 {
    margin: 1rem !important;
  }
  .sb-show-main .ltr .rw__p-lg-5,
  .sb-show-main .rtl .rw__p-lg-5,
  html[dir=rtl] .ltr .rw__p-lg-5,
  html[dir=rtl] .rtl .rw__p-lg-5,
  html[dir=ltr] .ltr .rw__p-lg-5,
  html[dir=ltr] .rtl .rw__p-lg-5 {
    padding: 1rem !important;
  }
  .sb-show-main .ltr .rw__mx-lg-5,
  .sb-show-main .rtl .rw__mx-lg-5,
  html[dir=rtl] .ltr .rw__mx-lg-5,
  html[dir=rtl] .rtl .rw__mx-lg-5,
  html[dir=ltr] .ltr .rw__mx-lg-5,
  html[dir=ltr] .rtl .rw__mx-lg-5 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
  .sb-show-main .ltr .rw__my-lg-5,
  .sb-show-main .rtl .rw__my-lg-5,
  html[dir=rtl] .ltr .rw__my-lg-5,
  html[dir=rtl] .rtl .rw__my-lg-5,
  html[dir=ltr] .ltr .rw__my-lg-5,
  html[dir=ltr] .rtl .rw__my-lg-5 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .sb-show-main .ltr .rw__mt-lg-5,
  .sb-show-main .rtl .rw__mt-lg-5,
  html[dir=rtl] .ltr .rw__mt-lg-5,
  html[dir=rtl] .rtl .rw__mt-lg-5,
  html[dir=ltr] .ltr .rw__mt-lg-5,
  html[dir=ltr] .rtl .rw__mt-lg-5 {
    margin-top: 1rem !important;
  }
  .sb-show-main .ltr .rw__mb-lg-5,
  .sb-show-main .rtl .rw__mb-lg-5,
  html[dir=rtl] .ltr .rw__mb-lg-5,
  html[dir=rtl] .rtl .rw__mb-lg-5,
  html[dir=ltr] .ltr .rw__mb-lg-5,
  html[dir=ltr] .rtl .rw__mb-lg-5 {
    margin-bottom: 1rem !important;
  }
  .sb-show-main .ltr .rw__px-lg-5,
  .sb-show-main .rtl .rw__px-lg-5,
  html[dir=rtl] .ltr .rw__px-lg-5,
  html[dir=rtl] .rtl .rw__px-lg-5,
  html[dir=ltr] .ltr .rw__px-lg-5,
  html[dir=ltr] .rtl .rw__px-lg-5 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .sb-show-main .ltr .rw__py-lg-5,
  .sb-show-main .rtl .rw__py-lg-5,
  html[dir=rtl] .ltr .rw__py-lg-5,
  html[dir=rtl] .rtl .rw__py-lg-5,
  html[dir=ltr] .ltr .rw__py-lg-5,
  html[dir=ltr] .rtl .rw__py-lg-5 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .sb-show-main .ltr .rw__pt-lg-5,
  .sb-show-main .rtl .rw__pt-lg-5,
  html[dir=rtl] .ltr .rw__pt-lg-5,
  html[dir=rtl] .rtl .rw__pt-lg-5,
  html[dir=ltr] .ltr .rw__pt-lg-5,
  html[dir=ltr] .rtl .rw__pt-lg-5 {
    padding-top: 1rem !important;
  }
  .sb-show-main .ltr .rw__pb-lg-5,
  .sb-show-main .rtl .rw__pb-lg-5,
  html[dir=rtl] .ltr .rw__pb-lg-5,
  html[dir=rtl] .rtl .rw__pb-lg-5,
  html[dir=ltr] .ltr .rw__pb-lg-5,
  html[dir=ltr] .rtl .rw__pb-lg-5 {
    padding-bottom: 1rem !important;
  }
  .sb-show-main .ltr .rw__ms-lg-5,
  html[dir=rtl] .ltr .rw__ms-lg-5,
  html[dir=ltr] .ltr .rw__ms-lg-5 {
    margin-left: 1rem !important;
  }
  .sb-show-main .ltr .rw__me-lg-5,
  html[dir=rtl] .ltr .rw__me-lg-5,
  html[dir=ltr] .ltr .rw__me-lg-5 {
    margin-right: 1rem !important;
  }
  .sb-show-main .ltr .rw__ps-lg-5,
  html[dir=rtl] .ltr .rw__ps-lg-5,
  html[dir=ltr] .ltr .rw__ps-lg-5 {
    padding-left: 1rem !important;
  }
  .sb-show-main .ltr .rw__pe-lg-5,
  html[dir=rtl] .ltr .rw__pe-lg-5,
  html[dir=ltr] .ltr .rw__pe-lg-5 {
    padding-right: 1rem !important;
  }
  .sb-show-main .rtl .rw__ms-lg-5,
  html[dir=rtl] .rtl .rw__ms-lg-5,
  html[dir=ltr] .rtl .rw__ms-lg-5 {
    margin-right: 1rem !important;
  }
  .sb-show-main .rtl .rw__me-lg-5,
  html[dir=rtl] .rtl .rw__me-lg-5,
  html[dir=ltr] .rtl .rw__me-lg-5 {
    margin-left: 1rem !important;
  }
  .sb-show-main .rtl .rw__ps-lg-5,
  html[dir=rtl] .rtl .rw__ps-lg-5,
  html[dir=ltr] .rtl .rw__ps-lg-5 {
    padding-right: 1rem !important;
  }
  .sb-show-main .rtl .rw__pe-lg-5,
  html[dir=rtl] .rtl .rw__pe-lg-5,
  html[dir=ltr] .rtl .rw__pe-lg-5 {
    padding-left: 1rem !important;
  }
}
@media (min-width: 960px) {
  .sb-show-main .ltr .rw__m-md-5,
  .sb-show-main .rtl .rw__m-md-5,
  html[dir=rtl] .ltr .rw__m-md-5,
  html[dir=rtl] .rtl .rw__m-md-5,
  html[dir=ltr] .ltr .rw__m-md-5,
  html[dir=ltr] .rtl .rw__m-md-5 {
    margin: 1rem !important;
  }
  .sb-show-main .ltr .rw__p-md-5,
  .sb-show-main .rtl .rw__p-md-5,
  html[dir=rtl] .ltr .rw__p-md-5,
  html[dir=rtl] .rtl .rw__p-md-5,
  html[dir=ltr] .ltr .rw__p-md-5,
  html[dir=ltr] .rtl .rw__p-md-5 {
    padding: 1rem !important;
  }
  .sb-show-main .ltr .rw__mx-md-5,
  .sb-show-main .rtl .rw__mx-md-5,
  html[dir=rtl] .ltr .rw__mx-md-5,
  html[dir=rtl] .rtl .rw__mx-md-5,
  html[dir=ltr] .ltr .rw__mx-md-5,
  html[dir=ltr] .rtl .rw__mx-md-5 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
  .sb-show-main .ltr .rw__my-md-5,
  .sb-show-main .rtl .rw__my-md-5,
  html[dir=rtl] .ltr .rw__my-md-5,
  html[dir=rtl] .rtl .rw__my-md-5,
  html[dir=ltr] .ltr .rw__my-md-5,
  html[dir=ltr] .rtl .rw__my-md-5 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .sb-show-main .ltr .rw__mt-md-5,
  .sb-show-main .rtl .rw__mt-md-5,
  html[dir=rtl] .ltr .rw__mt-md-5,
  html[dir=rtl] .rtl .rw__mt-md-5,
  html[dir=ltr] .ltr .rw__mt-md-5,
  html[dir=ltr] .rtl .rw__mt-md-5 {
    margin-top: 1rem !important;
  }
  .sb-show-main .ltr .rw__mb-md-5,
  .sb-show-main .rtl .rw__mb-md-5,
  html[dir=rtl] .ltr .rw__mb-md-5,
  html[dir=rtl] .rtl .rw__mb-md-5,
  html[dir=ltr] .ltr .rw__mb-md-5,
  html[dir=ltr] .rtl .rw__mb-md-5 {
    margin-bottom: 1rem !important;
  }
  .sb-show-main .ltr .rw__px-md-5,
  .sb-show-main .rtl .rw__px-md-5,
  html[dir=rtl] .ltr .rw__px-md-5,
  html[dir=rtl] .rtl .rw__px-md-5,
  html[dir=ltr] .ltr .rw__px-md-5,
  html[dir=ltr] .rtl .rw__px-md-5 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .sb-show-main .ltr .rw__py-md-5,
  .sb-show-main .rtl .rw__py-md-5,
  html[dir=rtl] .ltr .rw__py-md-5,
  html[dir=rtl] .rtl .rw__py-md-5,
  html[dir=ltr] .ltr .rw__py-md-5,
  html[dir=ltr] .rtl .rw__py-md-5 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .sb-show-main .ltr .rw__pt-md-5,
  .sb-show-main .rtl .rw__pt-md-5,
  html[dir=rtl] .ltr .rw__pt-md-5,
  html[dir=rtl] .rtl .rw__pt-md-5,
  html[dir=ltr] .ltr .rw__pt-md-5,
  html[dir=ltr] .rtl .rw__pt-md-5 {
    padding-top: 1rem !important;
  }
  .sb-show-main .ltr .rw__pb-md-5,
  .sb-show-main .rtl .rw__pb-md-5,
  html[dir=rtl] .ltr .rw__pb-md-5,
  html[dir=rtl] .rtl .rw__pb-md-5,
  html[dir=ltr] .ltr .rw__pb-md-5,
  html[dir=ltr] .rtl .rw__pb-md-5 {
    padding-bottom: 1rem !important;
  }
  .sb-show-main .ltr .rw__ms-md-5,
  html[dir=rtl] .ltr .rw__ms-md-5,
  html[dir=ltr] .ltr .rw__ms-md-5 {
    margin-left: 1rem !important;
  }
  .sb-show-main .ltr .rw__me-md-5,
  html[dir=rtl] .ltr .rw__me-md-5,
  html[dir=ltr] .ltr .rw__me-md-5 {
    margin-right: 1rem !important;
  }
  .sb-show-main .ltr .rw__ps-md-5,
  html[dir=rtl] .ltr .rw__ps-md-5,
  html[dir=ltr] .ltr .rw__ps-md-5 {
    padding-left: 1rem !important;
  }
  .sb-show-main .ltr .rw__pe-md-5,
  html[dir=rtl] .ltr .rw__pe-md-5,
  html[dir=ltr] .ltr .rw__pe-md-5 {
    padding-right: 1rem !important;
  }
  .sb-show-main .rtl .rw__ms-md-5,
  html[dir=rtl] .rtl .rw__ms-md-5,
  html[dir=ltr] .rtl .rw__ms-md-5 {
    margin-right: 1rem !important;
  }
  .sb-show-main .rtl .rw__me-md-5,
  html[dir=rtl] .rtl .rw__me-md-5,
  html[dir=ltr] .rtl .rw__me-md-5 {
    margin-left: 1rem !important;
  }
  .sb-show-main .rtl .rw__ps-md-5,
  html[dir=rtl] .rtl .rw__ps-md-5,
  html[dir=ltr] .rtl .rw__ps-md-5 {
    padding-right: 1rem !important;
  }
  .sb-show-main .rtl .rw__pe-md-5,
  html[dir=rtl] .rtl .rw__pe-md-5,
  html[dir=ltr] .rtl .rw__pe-md-5 {
    padding-left: 1rem !important;
  }
}
@media (min-width: 640px) {
  html[dir=rtl] .ltr .rw__ms-sm-5,
  html[dir=ltr] .ltr .rw__ms-sm-5 {
    margin-left: 1rem !important;
  }
  html[dir=rtl] .rtl .rw__ms-sm-5,
  html[dir=ltr] .rtl .rw__ms-sm-5 {
    margin-right: 1rem !important;
  }
}
html[dir=rtl] .rw__p-6,
html[dir=ltr] .rw__p-6 {
  padding: 1.5rem !important;
}
html[dir=rtl] .rw__px-6,
html[dir=ltr] .rw__px-6 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}
html[dir=rtl] .rw__py-6,
html[dir=ltr] .rw__py-6 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}
html[dir=rtl] .rw__pt-6,
html[dir=ltr] .rw__pt-6 {
  padding-top: 1.5rem !important;
}
html[dir=rtl] .rw__pb-6,
html[dir=ltr] .rw__pb-6 {
  padding-bottom: 1.5rem !important;
}
html[dir=rtl] .rw__m-6,
html[dir=ltr] .rw__m-6 {
  margin: 1.5rem !important;
}
html[dir=rtl] .rw__mx-6,
html[dir=ltr] .rw__mx-6 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}
html[dir=rtl] .rw__my-6,
html[dir=ltr] .rw__my-6 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}
html[dir=rtl] .rw__mt-6,
html[dir=ltr] .rw__mt-6 {
  margin-top: 1.5rem !important;
}
html[dir=rtl] .rw__mb-6,
html[dir=ltr] .rw__mb-6 {
  margin-bottom: 1.5rem !important;
}
html[dir=rtl] .ltr .rw__ps-6,
html[dir=ltr] .ltr .rw__ps-6 {
  padding-left: 1.5rem !important;
}
html[dir=rtl] .ltr .rw__pe-6,
html[dir=ltr] .ltr .rw__pe-6 {
  padding-right: 1.5rem !important;
}
html[dir=rtl] .ltr .rw__ms-6,
html[dir=ltr] .ltr .rw__ms-6 {
  margin-left: 1.5rem !important;
}
html[dir=rtl] .ltr .rw__me-6,
html[dir=ltr] .ltr .rw__me-6 {
  margin-right: 1.5rem !important;
}
html[dir=rtl] .rtl .rw__ps-6,
html[dir=ltr] .rtl .rw__ps-6 {
  padding-right: 1.5rem !important;
}
html[dir=rtl] .rtl .rw__pe-6,
html[dir=ltr] .rtl .rw__pe-6 {
  padding-left: 1.5rem !important;
}
html[dir=rtl] .rtl .rw__ms-6,
html[dir=ltr] .rtl .rw__ms-6 {
  margin-right: 1.5rem !important;
}
html[dir=rtl] .rtl .rw__me-6,
html[dir=ltr] .rtl .rw__me-6 {
  margin-left: 1.5rem !important;
}

.sb-show-main .rw__p-6 {
  padding: 1.5rem !important;
}
.sb-show-main .rw__px-6 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}
.sb-show-main .rw__py-6 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}
.sb-show-main .rw__pt-6 {
  padding-top: 1.5rem !important;
}
.sb-show-main .rw__pb-6 {
  padding-bottom: 1.5rem !important;
}
.sb-show-main .rw__m-6 {
  margin: 1.5rem !important;
}
.sb-show-main .rw__mx-6 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}
.sb-show-main .rw__my-6 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}
.sb-show-main .rw__mt-6 {
  margin-top: 1.5rem !important;
}
.sb-show-main .rw__mb-6 {
  margin-bottom: 1.5rem !important;
}
.sb-show-main .rw__ps-6 {
  padding-left: 1.5rem !important;
}
.sb-show-main .rw__pe-6 {
  padding-right: 1.5rem !important;
}
.sb-show-main .rw__ms-6 {
  margin-left: 1.5rem !important;
}
.sb-show-main .rw__me-6 {
  margin-right: 1.5rem !important;
}

@media (min-width: 1366px) {
  .sb-show-main .ltr .rw__m-lg-6,
  .sb-show-main .rtl .rw__m-lg-6,
  html[dir=rtl] .ltr .rw__m-lg-6,
  html[dir=rtl] .rtl .rw__m-lg-6,
  html[dir=ltr] .ltr .rw__m-lg-6,
  html[dir=ltr] .rtl .rw__m-lg-6 {
    margin: 1.5rem !important;
  }
  .sb-show-main .ltr .rw__p-lg-6,
  .sb-show-main .rtl .rw__p-lg-6,
  html[dir=rtl] .ltr .rw__p-lg-6,
  html[dir=rtl] .rtl .rw__p-lg-6,
  html[dir=ltr] .ltr .rw__p-lg-6,
  html[dir=ltr] .rtl .rw__p-lg-6 {
    padding: 1.5rem !important;
  }
  .sb-show-main .ltr .rw__mx-lg-6,
  .sb-show-main .rtl .rw__mx-lg-6,
  html[dir=rtl] .ltr .rw__mx-lg-6,
  html[dir=rtl] .rtl .rw__mx-lg-6,
  html[dir=ltr] .ltr .rw__mx-lg-6,
  html[dir=ltr] .rtl .rw__mx-lg-6 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
  .sb-show-main .ltr .rw__my-lg-6,
  .sb-show-main .rtl .rw__my-lg-6,
  html[dir=rtl] .ltr .rw__my-lg-6,
  html[dir=rtl] .rtl .rw__my-lg-6,
  html[dir=ltr] .ltr .rw__my-lg-6,
  html[dir=ltr] .rtl .rw__my-lg-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .sb-show-main .ltr .rw__mt-lg-6,
  .sb-show-main .rtl .rw__mt-lg-6,
  html[dir=rtl] .ltr .rw__mt-lg-6,
  html[dir=rtl] .rtl .rw__mt-lg-6,
  html[dir=ltr] .ltr .rw__mt-lg-6,
  html[dir=ltr] .rtl .rw__mt-lg-6 {
    margin-top: 1.5rem !important;
  }
  .sb-show-main .ltr .rw__mb-lg-6,
  .sb-show-main .rtl .rw__mb-lg-6,
  html[dir=rtl] .ltr .rw__mb-lg-6,
  html[dir=rtl] .rtl .rw__mb-lg-6,
  html[dir=ltr] .ltr .rw__mb-lg-6,
  html[dir=ltr] .rtl .rw__mb-lg-6 {
    margin-bottom: 1.5rem !important;
  }
  .sb-show-main .ltr .rw__px-lg-6,
  .sb-show-main .rtl .rw__px-lg-6,
  html[dir=rtl] .ltr .rw__px-lg-6,
  html[dir=rtl] .rtl .rw__px-lg-6,
  html[dir=ltr] .ltr .rw__px-lg-6,
  html[dir=ltr] .rtl .rw__px-lg-6 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
  .sb-show-main .ltr .rw__py-lg-6,
  .sb-show-main .rtl .rw__py-lg-6,
  html[dir=rtl] .ltr .rw__py-lg-6,
  html[dir=rtl] .rtl .rw__py-lg-6,
  html[dir=ltr] .ltr .rw__py-lg-6,
  html[dir=ltr] .rtl .rw__py-lg-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .sb-show-main .ltr .rw__pt-lg-6,
  .sb-show-main .rtl .rw__pt-lg-6,
  html[dir=rtl] .ltr .rw__pt-lg-6,
  html[dir=rtl] .rtl .rw__pt-lg-6,
  html[dir=ltr] .ltr .rw__pt-lg-6,
  html[dir=ltr] .rtl .rw__pt-lg-6 {
    padding-top: 1.5rem !important;
  }
  .sb-show-main .ltr .rw__pb-lg-6,
  .sb-show-main .rtl .rw__pb-lg-6,
  html[dir=rtl] .ltr .rw__pb-lg-6,
  html[dir=rtl] .rtl .rw__pb-lg-6,
  html[dir=ltr] .ltr .rw__pb-lg-6,
  html[dir=ltr] .rtl .rw__pb-lg-6 {
    padding-bottom: 1.5rem !important;
  }
  .sb-show-main .ltr .rw__ms-lg-6,
  html[dir=rtl] .ltr .rw__ms-lg-6,
  html[dir=ltr] .ltr .rw__ms-lg-6 {
    margin-left: 1.5rem !important;
  }
  .sb-show-main .ltr .rw__me-lg-6,
  html[dir=rtl] .ltr .rw__me-lg-6,
  html[dir=ltr] .ltr .rw__me-lg-6 {
    margin-right: 1.5rem !important;
  }
  .sb-show-main .ltr .rw__ps-lg-6,
  html[dir=rtl] .ltr .rw__ps-lg-6,
  html[dir=ltr] .ltr .rw__ps-lg-6 {
    padding-left: 1.5rem !important;
  }
  .sb-show-main .ltr .rw__pe-lg-6,
  html[dir=rtl] .ltr .rw__pe-lg-6,
  html[dir=ltr] .ltr .rw__pe-lg-6 {
    padding-right: 1.5rem !important;
  }
  .sb-show-main .rtl .rw__ms-lg-6,
  html[dir=rtl] .rtl .rw__ms-lg-6,
  html[dir=ltr] .rtl .rw__ms-lg-6 {
    margin-right: 1.5rem !important;
  }
  .sb-show-main .rtl .rw__me-lg-6,
  html[dir=rtl] .rtl .rw__me-lg-6,
  html[dir=ltr] .rtl .rw__me-lg-6 {
    margin-left: 1.5rem !important;
  }
  .sb-show-main .rtl .rw__ps-lg-6,
  html[dir=rtl] .rtl .rw__ps-lg-6,
  html[dir=ltr] .rtl .rw__ps-lg-6 {
    padding-right: 1.5rem !important;
  }
  .sb-show-main .rtl .rw__pe-lg-6,
  html[dir=rtl] .rtl .rw__pe-lg-6,
  html[dir=ltr] .rtl .rw__pe-lg-6 {
    padding-left: 1.5rem !important;
  }
}
@media (min-width: 960px) {
  .sb-show-main .ltr .rw__m-md-6,
  .sb-show-main .rtl .rw__m-md-6,
  html[dir=rtl] .ltr .rw__m-md-6,
  html[dir=rtl] .rtl .rw__m-md-6,
  html[dir=ltr] .ltr .rw__m-md-6,
  html[dir=ltr] .rtl .rw__m-md-6 {
    margin: 1.5rem !important;
  }
  .sb-show-main .ltr .rw__p-md-6,
  .sb-show-main .rtl .rw__p-md-6,
  html[dir=rtl] .ltr .rw__p-md-6,
  html[dir=rtl] .rtl .rw__p-md-6,
  html[dir=ltr] .ltr .rw__p-md-6,
  html[dir=ltr] .rtl .rw__p-md-6 {
    padding: 1.5rem !important;
  }
  .sb-show-main .ltr .rw__mx-md-6,
  .sb-show-main .rtl .rw__mx-md-6,
  html[dir=rtl] .ltr .rw__mx-md-6,
  html[dir=rtl] .rtl .rw__mx-md-6,
  html[dir=ltr] .ltr .rw__mx-md-6,
  html[dir=ltr] .rtl .rw__mx-md-6 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
  .sb-show-main .ltr .rw__my-md-6,
  .sb-show-main .rtl .rw__my-md-6,
  html[dir=rtl] .ltr .rw__my-md-6,
  html[dir=rtl] .rtl .rw__my-md-6,
  html[dir=ltr] .ltr .rw__my-md-6,
  html[dir=ltr] .rtl .rw__my-md-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .sb-show-main .ltr .rw__mt-md-6,
  .sb-show-main .rtl .rw__mt-md-6,
  html[dir=rtl] .ltr .rw__mt-md-6,
  html[dir=rtl] .rtl .rw__mt-md-6,
  html[dir=ltr] .ltr .rw__mt-md-6,
  html[dir=ltr] .rtl .rw__mt-md-6 {
    margin-top: 1.5rem !important;
  }
  .sb-show-main .ltr .rw__mb-md-6,
  .sb-show-main .rtl .rw__mb-md-6,
  html[dir=rtl] .ltr .rw__mb-md-6,
  html[dir=rtl] .rtl .rw__mb-md-6,
  html[dir=ltr] .ltr .rw__mb-md-6,
  html[dir=ltr] .rtl .rw__mb-md-6 {
    margin-bottom: 1.5rem !important;
  }
  .sb-show-main .ltr .rw__px-md-6,
  .sb-show-main .rtl .rw__px-md-6,
  html[dir=rtl] .ltr .rw__px-md-6,
  html[dir=rtl] .rtl .rw__px-md-6,
  html[dir=ltr] .ltr .rw__px-md-6,
  html[dir=ltr] .rtl .rw__px-md-6 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
  .sb-show-main .ltr .rw__py-md-6,
  .sb-show-main .rtl .rw__py-md-6,
  html[dir=rtl] .ltr .rw__py-md-6,
  html[dir=rtl] .rtl .rw__py-md-6,
  html[dir=ltr] .ltr .rw__py-md-6,
  html[dir=ltr] .rtl .rw__py-md-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .sb-show-main .ltr .rw__pt-md-6,
  .sb-show-main .rtl .rw__pt-md-6,
  html[dir=rtl] .ltr .rw__pt-md-6,
  html[dir=rtl] .rtl .rw__pt-md-6,
  html[dir=ltr] .ltr .rw__pt-md-6,
  html[dir=ltr] .rtl .rw__pt-md-6 {
    padding-top: 1.5rem !important;
  }
  .sb-show-main .ltr .rw__pb-md-6,
  .sb-show-main .rtl .rw__pb-md-6,
  html[dir=rtl] .ltr .rw__pb-md-6,
  html[dir=rtl] .rtl .rw__pb-md-6,
  html[dir=ltr] .ltr .rw__pb-md-6,
  html[dir=ltr] .rtl .rw__pb-md-6 {
    padding-bottom: 1.5rem !important;
  }
  .sb-show-main .ltr .rw__ms-md-6,
  html[dir=rtl] .ltr .rw__ms-md-6,
  html[dir=ltr] .ltr .rw__ms-md-6 {
    margin-left: 1.5rem !important;
  }
  .sb-show-main .ltr .rw__me-md-6,
  html[dir=rtl] .ltr .rw__me-md-6,
  html[dir=ltr] .ltr .rw__me-md-6 {
    margin-right: 1.5rem !important;
  }
  .sb-show-main .ltr .rw__ps-md-6,
  html[dir=rtl] .ltr .rw__ps-md-6,
  html[dir=ltr] .ltr .rw__ps-md-6 {
    padding-left: 1.5rem !important;
  }
  .sb-show-main .ltr .rw__pe-md-6,
  html[dir=rtl] .ltr .rw__pe-md-6,
  html[dir=ltr] .ltr .rw__pe-md-6 {
    padding-right: 1.5rem !important;
  }
  .sb-show-main .rtl .rw__ms-md-6,
  html[dir=rtl] .rtl .rw__ms-md-6,
  html[dir=ltr] .rtl .rw__ms-md-6 {
    margin-right: 1.5rem !important;
  }
  .sb-show-main .rtl .rw__me-md-6,
  html[dir=rtl] .rtl .rw__me-md-6,
  html[dir=ltr] .rtl .rw__me-md-6 {
    margin-left: 1.5rem !important;
  }
  .sb-show-main .rtl .rw__ps-md-6,
  html[dir=rtl] .rtl .rw__ps-md-6,
  html[dir=ltr] .rtl .rw__ps-md-6 {
    padding-right: 1.5rem !important;
  }
  .sb-show-main .rtl .rw__pe-md-6,
  html[dir=rtl] .rtl .rw__pe-md-6,
  html[dir=ltr] .rtl .rw__pe-md-6 {
    padding-left: 1.5rem !important;
  }
}
@media (min-width: 640px) {
  html[dir=rtl] .ltr .rw__ms-sm-6,
  html[dir=ltr] .ltr .rw__ms-sm-6 {
    margin-left: 1.5rem !important;
  }
  html[dir=rtl] .rtl .rw__ms-sm-6,
  html[dir=ltr] .rtl .rw__ms-sm-6 {
    margin-right: 1.5rem !important;
  }
}
html[dir=rtl] .rw__p-7,
html[dir=ltr] .rw__p-7 {
  padding: 2rem !important;
}
html[dir=rtl] .rw__px-7,
html[dir=ltr] .rw__px-7 {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}
html[dir=rtl] .rw__py-7,
html[dir=ltr] .rw__py-7 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}
html[dir=rtl] .rw__pt-7,
html[dir=ltr] .rw__pt-7 {
  padding-top: 2rem !important;
}
html[dir=rtl] .rw__pb-7,
html[dir=ltr] .rw__pb-7 {
  padding-bottom: 2rem !important;
}
html[dir=rtl] .rw__m-7,
html[dir=ltr] .rw__m-7 {
  margin: 2rem !important;
}
html[dir=rtl] .rw__mx-7,
html[dir=ltr] .rw__mx-7 {
  margin-left: 2rem !important;
  margin-right: 2rem !important;
}
html[dir=rtl] .rw__my-7,
html[dir=ltr] .rw__my-7 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}
html[dir=rtl] .rw__mt-7,
html[dir=ltr] .rw__mt-7 {
  margin-top: 2rem !important;
}
html[dir=rtl] .rw__mb-7,
html[dir=ltr] .rw__mb-7 {
  margin-bottom: 2rem !important;
}
html[dir=rtl] .ltr .rw__ps-7, html[dir=rtl] .ltr .rw__p-tabs .p-tabview-nav li:first-child, .rw__p-tabs .p-tabview-nav html[dir=rtl] .ltr li:first-child,
html[dir=ltr] .ltr .rw__ps-7,
html[dir=ltr] .ltr .rw__p-tabs .p-tabview-nav li:first-child,
.rw__p-tabs .p-tabview-nav html[dir=ltr] .ltr li:first-child {
  padding-left: 2rem !important;
}
html[dir=rtl] .ltr .rw__pe-7,
html[dir=ltr] .ltr .rw__pe-7 {
  padding-right: 2rem !important;
}
html[dir=rtl] .ltr .rw__ms-7,
html[dir=ltr] .ltr .rw__ms-7 {
  margin-left: 2rem !important;
}
html[dir=rtl] .ltr .rw__me-7,
html[dir=ltr] .ltr .rw__me-7 {
  margin-right: 2rem !important;
}
html[dir=rtl] .rtl .rw__ps-7, html[dir=rtl] .rtl .rw__p-tabs .p-tabview-nav li:first-child, .rw__p-tabs .p-tabview-nav html[dir=rtl] .rtl li:first-child,
html[dir=ltr] .rtl .rw__ps-7,
html[dir=ltr] .rtl .rw__p-tabs .p-tabview-nav li:first-child,
.rw__p-tabs .p-tabview-nav html[dir=ltr] .rtl li:first-child {
  padding-right: 2rem !important;
}
html[dir=rtl] .rtl .rw__pe-7,
html[dir=ltr] .rtl .rw__pe-7 {
  padding-left: 2rem !important;
}
html[dir=rtl] .rtl .rw__ms-7,
html[dir=ltr] .rtl .rw__ms-7 {
  margin-right: 2rem !important;
}
html[dir=rtl] .rtl .rw__me-7,
html[dir=ltr] .rtl .rw__me-7 {
  margin-left: 2rem !important;
}

.sb-show-main .rw__p-7 {
  padding: 2rem !important;
}
.sb-show-main .rw__px-7 {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}
.sb-show-main .rw__py-7 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}
.sb-show-main .rw__pt-7 {
  padding-top: 2rem !important;
}
.sb-show-main .rw__pb-7 {
  padding-bottom: 2rem !important;
}
.sb-show-main .rw__m-7 {
  margin: 2rem !important;
}
.sb-show-main .rw__mx-7 {
  margin-left: 2rem !important;
  margin-right: 2rem !important;
}
.sb-show-main .rw__my-7 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}
.sb-show-main .rw__mt-7 {
  margin-top: 2rem !important;
}
.sb-show-main .rw__mb-7 {
  margin-bottom: 2rem !important;
}
.sb-show-main .rw__ps-7, .sb-show-main .rw__p-tabs .p-tabview-nav li:first-child, .rw__p-tabs .p-tabview-nav .sb-show-main li:first-child {
  padding-left: 2rem !important;
}
.sb-show-main .rw__pe-7 {
  padding-right: 2rem !important;
}
.sb-show-main .rw__ms-7 {
  margin-left: 2rem !important;
}
.sb-show-main .rw__me-7 {
  margin-right: 2rem !important;
}

@media (min-width: 1366px) {
  .sb-show-main .ltr .rw__m-lg-7,
  .sb-show-main .rtl .rw__m-lg-7,
  html[dir=rtl] .ltr .rw__m-lg-7,
  html[dir=rtl] .rtl .rw__m-lg-7,
  html[dir=ltr] .ltr .rw__m-lg-7,
  html[dir=ltr] .rtl .rw__m-lg-7 {
    margin: 2rem !important;
  }
  .sb-show-main .ltr .rw__p-lg-7,
  .sb-show-main .rtl .rw__p-lg-7,
  html[dir=rtl] .ltr .rw__p-lg-7,
  html[dir=rtl] .rtl .rw__p-lg-7,
  html[dir=ltr] .ltr .rw__p-lg-7,
  html[dir=ltr] .rtl .rw__p-lg-7 {
    padding: 2rem !important;
  }
  .sb-show-main .ltr .rw__mx-lg-7,
  .sb-show-main .rtl .rw__mx-lg-7,
  html[dir=rtl] .ltr .rw__mx-lg-7,
  html[dir=rtl] .rtl .rw__mx-lg-7,
  html[dir=ltr] .ltr .rw__mx-lg-7,
  html[dir=ltr] .rtl .rw__mx-lg-7 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
  .sb-show-main .ltr .rw__my-lg-7,
  .sb-show-main .rtl .rw__my-lg-7,
  html[dir=rtl] .ltr .rw__my-lg-7,
  html[dir=rtl] .rtl .rw__my-lg-7,
  html[dir=ltr] .ltr .rw__my-lg-7,
  html[dir=ltr] .rtl .rw__my-lg-7 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .sb-show-main .ltr .rw__mt-lg-7,
  .sb-show-main .rtl .rw__mt-lg-7,
  html[dir=rtl] .ltr .rw__mt-lg-7,
  html[dir=rtl] .rtl .rw__mt-lg-7,
  html[dir=ltr] .ltr .rw__mt-lg-7,
  html[dir=ltr] .rtl .rw__mt-lg-7 {
    margin-top: 2rem !important;
  }
  .sb-show-main .ltr .rw__mb-lg-7,
  .sb-show-main .rtl .rw__mb-lg-7,
  html[dir=rtl] .ltr .rw__mb-lg-7,
  html[dir=rtl] .rtl .rw__mb-lg-7,
  html[dir=ltr] .ltr .rw__mb-lg-7,
  html[dir=ltr] .rtl .rw__mb-lg-7 {
    margin-bottom: 2rem !important;
  }
  .sb-show-main .ltr .rw__px-lg-7,
  .sb-show-main .rtl .rw__px-lg-7,
  html[dir=rtl] .ltr .rw__px-lg-7,
  html[dir=rtl] .rtl .rw__px-lg-7,
  html[dir=ltr] .ltr .rw__px-lg-7,
  html[dir=ltr] .rtl .rw__px-lg-7 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
  .sb-show-main .ltr .rw__py-lg-7,
  .sb-show-main .rtl .rw__py-lg-7,
  html[dir=rtl] .ltr .rw__py-lg-7,
  html[dir=rtl] .rtl .rw__py-lg-7,
  html[dir=ltr] .ltr .rw__py-lg-7,
  html[dir=ltr] .rtl .rw__py-lg-7 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .sb-show-main .ltr .rw__pt-lg-7,
  .sb-show-main .rtl .rw__pt-lg-7,
  html[dir=rtl] .ltr .rw__pt-lg-7,
  html[dir=rtl] .rtl .rw__pt-lg-7,
  html[dir=ltr] .ltr .rw__pt-lg-7,
  html[dir=ltr] .rtl .rw__pt-lg-7 {
    padding-top: 2rem !important;
  }
  .sb-show-main .ltr .rw__pb-lg-7,
  .sb-show-main .rtl .rw__pb-lg-7,
  html[dir=rtl] .ltr .rw__pb-lg-7,
  html[dir=rtl] .rtl .rw__pb-lg-7,
  html[dir=ltr] .ltr .rw__pb-lg-7,
  html[dir=ltr] .rtl .rw__pb-lg-7 {
    padding-bottom: 2rem !important;
  }
  .sb-show-main .ltr .rw__ms-lg-7,
  html[dir=rtl] .ltr .rw__ms-lg-7,
  html[dir=ltr] .ltr .rw__ms-lg-7 {
    margin-left: 2rem !important;
  }
  .sb-show-main .ltr .rw__me-lg-7,
  html[dir=rtl] .ltr .rw__me-lg-7,
  html[dir=ltr] .ltr .rw__me-lg-7 {
    margin-right: 2rem !important;
  }
  .sb-show-main .ltr .rw__ps-lg-7,
  html[dir=rtl] .ltr .rw__ps-lg-7,
  html[dir=ltr] .ltr .rw__ps-lg-7 {
    padding-left: 2rem !important;
  }
  .sb-show-main .ltr .rw__pe-lg-7,
  html[dir=rtl] .ltr .rw__pe-lg-7,
  html[dir=ltr] .ltr .rw__pe-lg-7 {
    padding-right: 2rem !important;
  }
  .sb-show-main .rtl .rw__ms-lg-7,
  html[dir=rtl] .rtl .rw__ms-lg-7,
  html[dir=ltr] .rtl .rw__ms-lg-7 {
    margin-right: 2rem !important;
  }
  .sb-show-main .rtl .rw__me-lg-7,
  html[dir=rtl] .rtl .rw__me-lg-7,
  html[dir=ltr] .rtl .rw__me-lg-7 {
    margin-left: 2rem !important;
  }
  .sb-show-main .rtl .rw__ps-lg-7,
  html[dir=rtl] .rtl .rw__ps-lg-7,
  html[dir=ltr] .rtl .rw__ps-lg-7 {
    padding-right: 2rem !important;
  }
  .sb-show-main .rtl .rw__pe-lg-7,
  html[dir=rtl] .rtl .rw__pe-lg-7,
  html[dir=ltr] .rtl .rw__pe-lg-7 {
    padding-left: 2rem !important;
  }
}
@media (min-width: 960px) {
  .sb-show-main .ltr .rw__m-md-7,
  .sb-show-main .rtl .rw__m-md-7,
  html[dir=rtl] .ltr .rw__m-md-7,
  html[dir=rtl] .rtl .rw__m-md-7,
  html[dir=ltr] .ltr .rw__m-md-7,
  html[dir=ltr] .rtl .rw__m-md-7 {
    margin: 2rem !important;
  }
  .sb-show-main .ltr .rw__p-md-7,
  .sb-show-main .rtl .rw__p-md-7,
  html[dir=rtl] .ltr .rw__p-md-7,
  html[dir=rtl] .rtl .rw__p-md-7,
  html[dir=ltr] .ltr .rw__p-md-7,
  html[dir=ltr] .rtl .rw__p-md-7 {
    padding: 2rem !important;
  }
  .sb-show-main .ltr .rw__mx-md-7,
  .sb-show-main .rtl .rw__mx-md-7,
  html[dir=rtl] .ltr .rw__mx-md-7,
  html[dir=rtl] .rtl .rw__mx-md-7,
  html[dir=ltr] .ltr .rw__mx-md-7,
  html[dir=ltr] .rtl .rw__mx-md-7 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
  .sb-show-main .ltr .rw__my-md-7,
  .sb-show-main .rtl .rw__my-md-7,
  html[dir=rtl] .ltr .rw__my-md-7,
  html[dir=rtl] .rtl .rw__my-md-7,
  html[dir=ltr] .ltr .rw__my-md-7,
  html[dir=ltr] .rtl .rw__my-md-7 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .sb-show-main .ltr .rw__mt-md-7,
  .sb-show-main .rtl .rw__mt-md-7,
  html[dir=rtl] .ltr .rw__mt-md-7,
  html[dir=rtl] .rtl .rw__mt-md-7,
  html[dir=ltr] .ltr .rw__mt-md-7,
  html[dir=ltr] .rtl .rw__mt-md-7 {
    margin-top: 2rem !important;
  }
  .sb-show-main .ltr .rw__mb-md-7,
  .sb-show-main .rtl .rw__mb-md-7,
  html[dir=rtl] .ltr .rw__mb-md-7,
  html[dir=rtl] .rtl .rw__mb-md-7,
  html[dir=ltr] .ltr .rw__mb-md-7,
  html[dir=ltr] .rtl .rw__mb-md-7 {
    margin-bottom: 2rem !important;
  }
  .sb-show-main .ltr .rw__px-md-7,
  .sb-show-main .rtl .rw__px-md-7,
  html[dir=rtl] .ltr .rw__px-md-7,
  html[dir=rtl] .rtl .rw__px-md-7,
  html[dir=ltr] .ltr .rw__px-md-7,
  html[dir=ltr] .rtl .rw__px-md-7 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
  .sb-show-main .ltr .rw__py-md-7,
  .sb-show-main .rtl .rw__py-md-7,
  html[dir=rtl] .ltr .rw__py-md-7,
  html[dir=rtl] .rtl .rw__py-md-7,
  html[dir=ltr] .ltr .rw__py-md-7,
  html[dir=ltr] .rtl .rw__py-md-7 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .sb-show-main .ltr .rw__pt-md-7,
  .sb-show-main .rtl .rw__pt-md-7,
  html[dir=rtl] .ltr .rw__pt-md-7,
  html[dir=rtl] .rtl .rw__pt-md-7,
  html[dir=ltr] .ltr .rw__pt-md-7,
  html[dir=ltr] .rtl .rw__pt-md-7 {
    padding-top: 2rem !important;
  }
  .sb-show-main .ltr .rw__pb-md-7,
  .sb-show-main .rtl .rw__pb-md-7,
  html[dir=rtl] .ltr .rw__pb-md-7,
  html[dir=rtl] .rtl .rw__pb-md-7,
  html[dir=ltr] .ltr .rw__pb-md-7,
  html[dir=ltr] .rtl .rw__pb-md-7 {
    padding-bottom: 2rem !important;
  }
  .sb-show-main .ltr .rw__ms-md-7,
  html[dir=rtl] .ltr .rw__ms-md-7,
  html[dir=ltr] .ltr .rw__ms-md-7 {
    margin-left: 2rem !important;
  }
  .sb-show-main .ltr .rw__me-md-7,
  html[dir=rtl] .ltr .rw__me-md-7,
  html[dir=ltr] .ltr .rw__me-md-7 {
    margin-right: 2rem !important;
  }
  .sb-show-main .ltr .rw__ps-md-7,
  html[dir=rtl] .ltr .rw__ps-md-7,
  html[dir=ltr] .ltr .rw__ps-md-7 {
    padding-left: 2rem !important;
  }
  .sb-show-main .ltr .rw__pe-md-7,
  html[dir=rtl] .ltr .rw__pe-md-7,
  html[dir=ltr] .ltr .rw__pe-md-7 {
    padding-right: 2rem !important;
  }
  .sb-show-main .rtl .rw__ms-md-7,
  html[dir=rtl] .rtl .rw__ms-md-7,
  html[dir=ltr] .rtl .rw__ms-md-7 {
    margin-right: 2rem !important;
  }
  .sb-show-main .rtl .rw__me-md-7,
  html[dir=rtl] .rtl .rw__me-md-7,
  html[dir=ltr] .rtl .rw__me-md-7 {
    margin-left: 2rem !important;
  }
  .sb-show-main .rtl .rw__ps-md-7,
  html[dir=rtl] .rtl .rw__ps-md-7,
  html[dir=ltr] .rtl .rw__ps-md-7 {
    padding-right: 2rem !important;
  }
  .sb-show-main .rtl .rw__pe-md-7,
  html[dir=rtl] .rtl .rw__pe-md-7,
  html[dir=ltr] .rtl .rw__pe-md-7 {
    padding-left: 2rem !important;
  }
}
@media (min-width: 640px) {
  html[dir=rtl] .ltr .rw__ms-sm-7,
  html[dir=ltr] .ltr .rw__ms-sm-7 {
    margin-left: 2rem !important;
  }
  html[dir=rtl] .rtl .rw__ms-sm-7,
  html[dir=ltr] .rtl .rw__ms-sm-7 {
    margin-right: 2rem !important;
  }
}
html[dir=rtl] .rw__p-8,
html[dir=ltr] .rw__p-8 {
  padding: 2.25rem !important;
}
html[dir=rtl] .rw__px-8,
html[dir=ltr] .rw__px-8 {
  padding-left: 2.25rem !important;
  padding-right: 2.25rem !important;
}
html[dir=rtl] .rw__py-8,
html[dir=ltr] .rw__py-8 {
  padding-top: 2.25rem !important;
  padding-bottom: 2.25rem !important;
}
html[dir=rtl] .rw__pt-8,
html[dir=ltr] .rw__pt-8 {
  padding-top: 2.25rem !important;
}
html[dir=rtl] .rw__pb-8,
html[dir=ltr] .rw__pb-8 {
  padding-bottom: 2.25rem !important;
}
html[dir=rtl] .rw__m-8,
html[dir=ltr] .rw__m-8 {
  margin: 2.25rem !important;
}
html[dir=rtl] .rw__mx-8,
html[dir=ltr] .rw__mx-8 {
  margin-left: 2.25rem !important;
  margin-right: 2.25rem !important;
}
html[dir=rtl] .rw__my-8,
html[dir=ltr] .rw__my-8 {
  margin-top: 2.25rem !important;
  margin-bottom: 2.25rem !important;
}
html[dir=rtl] .rw__mt-8,
html[dir=ltr] .rw__mt-8 {
  margin-top: 2.25rem !important;
}
html[dir=rtl] .rw__mb-8,
html[dir=ltr] .rw__mb-8 {
  margin-bottom: 2.25rem !important;
}
html[dir=rtl] .ltr .rw__ps-8,
html[dir=ltr] .ltr .rw__ps-8 {
  padding-left: 2.25rem !important;
}
html[dir=rtl] .ltr .rw__pe-8,
html[dir=ltr] .ltr .rw__pe-8 {
  padding-right: 2.25rem !important;
}
html[dir=rtl] .ltr .rw__ms-8,
html[dir=ltr] .ltr .rw__ms-8 {
  margin-left: 2.25rem !important;
}
html[dir=rtl] .ltr .rw__me-8, html[dir=rtl] .ltr .rw__custom-radio-button-inline-wrap p-radiobutton:not(:last-child), .rw__custom-radio-button-inline-wrap html[dir=rtl] .ltr p-radiobutton:not(:last-child), html[dir=rtl] .ltr .rw__radio-button-inline-wrap p-radiobutton:not(:last-child), .rw__radio-button-inline-wrap html[dir=rtl] .ltr p-radiobutton:not(:last-child),
html[dir=ltr] .ltr .rw__me-8,
html[dir=ltr] .ltr .rw__custom-radio-button-inline-wrap p-radiobutton:not(:last-child),
.rw__custom-radio-button-inline-wrap html[dir=ltr] .ltr p-radiobutton:not(:last-child),
html[dir=ltr] .ltr .rw__radio-button-inline-wrap p-radiobutton:not(:last-child),
.rw__radio-button-inline-wrap html[dir=ltr] .ltr p-radiobutton:not(:last-child) {
  margin-right: 2.25rem !important;
}
html[dir=rtl] .rtl .rw__ps-8,
html[dir=ltr] .rtl .rw__ps-8 {
  padding-right: 2.25rem !important;
}
html[dir=rtl] .rtl .rw__pe-8,
html[dir=ltr] .rtl .rw__pe-8 {
  padding-left: 2.25rem !important;
}
html[dir=rtl] .rtl .rw__ms-8,
html[dir=ltr] .rtl .rw__ms-8 {
  margin-right: 2.25rem !important;
}
html[dir=rtl] .rtl .rw__me-8, html[dir=rtl] .rtl .rw__custom-radio-button-inline-wrap p-radiobutton:not(:last-child), .rw__custom-radio-button-inline-wrap html[dir=rtl] .rtl p-radiobutton:not(:last-child), html[dir=rtl] .rtl .rw__radio-button-inline-wrap p-radiobutton:not(:last-child), .rw__radio-button-inline-wrap html[dir=rtl] .rtl p-radiobutton:not(:last-child),
html[dir=ltr] .rtl .rw__me-8,
html[dir=ltr] .rtl .rw__custom-radio-button-inline-wrap p-radiobutton:not(:last-child),
.rw__custom-radio-button-inline-wrap html[dir=ltr] .rtl p-radiobutton:not(:last-child),
html[dir=ltr] .rtl .rw__radio-button-inline-wrap p-radiobutton:not(:last-child),
.rw__radio-button-inline-wrap html[dir=ltr] .rtl p-radiobutton:not(:last-child) {
  margin-left: 2.25rem !important;
}

.sb-show-main .rw__p-8 {
  padding: 2.25rem !important;
}
.sb-show-main .rw__px-8 {
  padding-left: 2.25rem !important;
  padding-right: 2.25rem !important;
}
.sb-show-main .rw__py-8 {
  padding-top: 2.25rem !important;
  padding-bottom: 2.25rem !important;
}
.sb-show-main .rw__pt-8 {
  padding-top: 2.25rem !important;
}
.sb-show-main .rw__pb-8 {
  padding-bottom: 2.25rem !important;
}
.sb-show-main .rw__m-8 {
  margin: 2.25rem !important;
}
.sb-show-main .rw__mx-8 {
  margin-left: 2.25rem !important;
  margin-right: 2.25rem !important;
}
.sb-show-main .rw__my-8 {
  margin-top: 2.25rem !important;
  margin-bottom: 2.25rem !important;
}
.sb-show-main .rw__mt-8 {
  margin-top: 2.25rem !important;
}
.sb-show-main .rw__mb-8 {
  margin-bottom: 2.25rem !important;
}
.sb-show-main .rw__ps-8 {
  padding-left: 2.25rem !important;
}
.sb-show-main .rw__pe-8 {
  padding-right: 2.25rem !important;
}
.sb-show-main .rw__ms-8 {
  margin-left: 2.25rem !important;
}
.sb-show-main .rw__me-8, .sb-show-main .rw__custom-radio-button-inline-wrap p-radiobutton:not(:last-child), .rw__custom-radio-button-inline-wrap .sb-show-main p-radiobutton:not(:last-child), .sb-show-main .rw__radio-button-inline-wrap p-radiobutton:not(:last-child), .rw__radio-button-inline-wrap .sb-show-main p-radiobutton:not(:last-child) {
  margin-right: 2.25rem !important;
}

@media (min-width: 1366px) {
  .sb-show-main .ltr .rw__m-lg-8,
  .sb-show-main .rtl .rw__m-lg-8,
  html[dir=rtl] .ltr .rw__m-lg-8,
  html[dir=rtl] .rtl .rw__m-lg-8,
  html[dir=ltr] .ltr .rw__m-lg-8,
  html[dir=ltr] .rtl .rw__m-lg-8 {
    margin: 2.25rem !important;
  }
  .sb-show-main .ltr .rw__p-lg-8,
  .sb-show-main .rtl .rw__p-lg-8,
  html[dir=rtl] .ltr .rw__p-lg-8,
  html[dir=rtl] .rtl .rw__p-lg-8,
  html[dir=ltr] .ltr .rw__p-lg-8,
  html[dir=ltr] .rtl .rw__p-lg-8 {
    padding: 2.25rem !important;
  }
  .sb-show-main .ltr .rw__mx-lg-8,
  .sb-show-main .rtl .rw__mx-lg-8,
  html[dir=rtl] .ltr .rw__mx-lg-8,
  html[dir=rtl] .rtl .rw__mx-lg-8,
  html[dir=ltr] .ltr .rw__mx-lg-8,
  html[dir=ltr] .rtl .rw__mx-lg-8 {
    margin-left: 2.25rem !important;
    margin-right: 2.25rem !important;
  }
  .sb-show-main .ltr .rw__my-lg-8,
  .sb-show-main .rtl .rw__my-lg-8,
  html[dir=rtl] .ltr .rw__my-lg-8,
  html[dir=rtl] .rtl .rw__my-lg-8,
  html[dir=ltr] .ltr .rw__my-lg-8,
  html[dir=ltr] .rtl .rw__my-lg-8 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }
  .sb-show-main .ltr .rw__mt-lg-8,
  .sb-show-main .rtl .rw__mt-lg-8,
  html[dir=rtl] .ltr .rw__mt-lg-8,
  html[dir=rtl] .rtl .rw__mt-lg-8,
  html[dir=ltr] .ltr .rw__mt-lg-8,
  html[dir=ltr] .rtl .rw__mt-lg-8 {
    margin-top: 2.25rem !important;
  }
  .sb-show-main .ltr .rw__mb-lg-8,
  .sb-show-main .rtl .rw__mb-lg-8,
  html[dir=rtl] .ltr .rw__mb-lg-8,
  html[dir=rtl] .rtl .rw__mb-lg-8,
  html[dir=ltr] .ltr .rw__mb-lg-8,
  html[dir=ltr] .rtl .rw__mb-lg-8 {
    margin-bottom: 2.25rem !important;
  }
  .sb-show-main .ltr .rw__px-lg-8,
  .sb-show-main .rtl .rw__px-lg-8,
  html[dir=rtl] .ltr .rw__px-lg-8,
  html[dir=rtl] .rtl .rw__px-lg-8,
  html[dir=ltr] .ltr .rw__px-lg-8,
  html[dir=ltr] .rtl .rw__px-lg-8 {
    padding-left: 2.25rem !important;
    padding-right: 2.25rem !important;
  }
  .sb-show-main .ltr .rw__py-lg-8,
  .sb-show-main .rtl .rw__py-lg-8,
  html[dir=rtl] .ltr .rw__py-lg-8,
  html[dir=rtl] .rtl .rw__py-lg-8,
  html[dir=ltr] .ltr .rw__py-lg-8,
  html[dir=ltr] .rtl .rw__py-lg-8 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }
  .sb-show-main .ltr .rw__pt-lg-8,
  .sb-show-main .rtl .rw__pt-lg-8,
  html[dir=rtl] .ltr .rw__pt-lg-8,
  html[dir=rtl] .rtl .rw__pt-lg-8,
  html[dir=ltr] .ltr .rw__pt-lg-8,
  html[dir=ltr] .rtl .rw__pt-lg-8 {
    padding-top: 2.25rem !important;
  }
  .sb-show-main .ltr .rw__pb-lg-8,
  .sb-show-main .rtl .rw__pb-lg-8,
  html[dir=rtl] .ltr .rw__pb-lg-8,
  html[dir=rtl] .rtl .rw__pb-lg-8,
  html[dir=ltr] .ltr .rw__pb-lg-8,
  html[dir=ltr] .rtl .rw__pb-lg-8 {
    padding-bottom: 2.25rem !important;
  }
  .sb-show-main .ltr .rw__ms-lg-8,
  html[dir=rtl] .ltr .rw__ms-lg-8,
  html[dir=ltr] .ltr .rw__ms-lg-8 {
    margin-left: 2.25rem !important;
  }
  .sb-show-main .ltr .rw__me-lg-8,
  html[dir=rtl] .ltr .rw__me-lg-8,
  html[dir=ltr] .ltr .rw__me-lg-8 {
    margin-right: 2.25rem !important;
  }
  .sb-show-main .ltr .rw__ps-lg-8,
  html[dir=rtl] .ltr .rw__ps-lg-8,
  html[dir=ltr] .ltr .rw__ps-lg-8 {
    padding-left: 2.25rem !important;
  }
  .sb-show-main .ltr .rw__pe-lg-8,
  html[dir=rtl] .ltr .rw__pe-lg-8,
  html[dir=ltr] .ltr .rw__pe-lg-8 {
    padding-right: 2.25rem !important;
  }
  .sb-show-main .rtl .rw__ms-lg-8,
  html[dir=rtl] .rtl .rw__ms-lg-8,
  html[dir=ltr] .rtl .rw__ms-lg-8 {
    margin-right: 2.25rem !important;
  }
  .sb-show-main .rtl .rw__me-lg-8,
  html[dir=rtl] .rtl .rw__me-lg-8,
  html[dir=ltr] .rtl .rw__me-lg-8 {
    margin-left: 2.25rem !important;
  }
  .sb-show-main .rtl .rw__ps-lg-8,
  html[dir=rtl] .rtl .rw__ps-lg-8,
  html[dir=ltr] .rtl .rw__ps-lg-8 {
    padding-right: 2.25rem !important;
  }
  .sb-show-main .rtl .rw__pe-lg-8,
  html[dir=rtl] .rtl .rw__pe-lg-8,
  html[dir=ltr] .rtl .rw__pe-lg-8 {
    padding-left: 2.25rem !important;
  }
}
@media (min-width: 960px) {
  .sb-show-main .ltr .rw__m-md-8,
  .sb-show-main .rtl .rw__m-md-8,
  html[dir=rtl] .ltr .rw__m-md-8,
  html[dir=rtl] .rtl .rw__m-md-8,
  html[dir=ltr] .ltr .rw__m-md-8,
  html[dir=ltr] .rtl .rw__m-md-8 {
    margin: 2.25rem !important;
  }
  .sb-show-main .ltr .rw__p-md-8,
  .sb-show-main .rtl .rw__p-md-8,
  html[dir=rtl] .ltr .rw__p-md-8,
  html[dir=rtl] .rtl .rw__p-md-8,
  html[dir=ltr] .ltr .rw__p-md-8,
  html[dir=ltr] .rtl .rw__p-md-8 {
    padding: 2.25rem !important;
  }
  .sb-show-main .ltr .rw__mx-md-8,
  .sb-show-main .rtl .rw__mx-md-8,
  html[dir=rtl] .ltr .rw__mx-md-8,
  html[dir=rtl] .rtl .rw__mx-md-8,
  html[dir=ltr] .ltr .rw__mx-md-8,
  html[dir=ltr] .rtl .rw__mx-md-8 {
    margin-left: 2.25rem !important;
    margin-right: 2.25rem !important;
  }
  .sb-show-main .ltr .rw__my-md-8,
  .sb-show-main .rtl .rw__my-md-8,
  html[dir=rtl] .ltr .rw__my-md-8,
  html[dir=rtl] .rtl .rw__my-md-8,
  html[dir=ltr] .ltr .rw__my-md-8,
  html[dir=ltr] .rtl .rw__my-md-8 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }
  .sb-show-main .ltr .rw__mt-md-8,
  .sb-show-main .rtl .rw__mt-md-8,
  html[dir=rtl] .ltr .rw__mt-md-8,
  html[dir=rtl] .rtl .rw__mt-md-8,
  html[dir=ltr] .ltr .rw__mt-md-8,
  html[dir=ltr] .rtl .rw__mt-md-8 {
    margin-top: 2.25rem !important;
  }
  .sb-show-main .ltr .rw__mb-md-8,
  .sb-show-main .rtl .rw__mb-md-8,
  html[dir=rtl] .ltr .rw__mb-md-8,
  html[dir=rtl] .rtl .rw__mb-md-8,
  html[dir=ltr] .ltr .rw__mb-md-8,
  html[dir=ltr] .rtl .rw__mb-md-8 {
    margin-bottom: 2.25rem !important;
  }
  .sb-show-main .ltr .rw__px-md-8,
  .sb-show-main .rtl .rw__px-md-8,
  html[dir=rtl] .ltr .rw__px-md-8,
  html[dir=rtl] .rtl .rw__px-md-8,
  html[dir=ltr] .ltr .rw__px-md-8,
  html[dir=ltr] .rtl .rw__px-md-8 {
    padding-left: 2.25rem !important;
    padding-right: 2.25rem !important;
  }
  .sb-show-main .ltr .rw__py-md-8,
  .sb-show-main .rtl .rw__py-md-8,
  html[dir=rtl] .ltr .rw__py-md-8,
  html[dir=rtl] .rtl .rw__py-md-8,
  html[dir=ltr] .ltr .rw__py-md-8,
  html[dir=ltr] .rtl .rw__py-md-8 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }
  .sb-show-main .ltr .rw__pt-md-8,
  .sb-show-main .rtl .rw__pt-md-8,
  html[dir=rtl] .ltr .rw__pt-md-8,
  html[dir=rtl] .rtl .rw__pt-md-8,
  html[dir=ltr] .ltr .rw__pt-md-8,
  html[dir=ltr] .rtl .rw__pt-md-8 {
    padding-top: 2.25rem !important;
  }
  .sb-show-main .ltr .rw__pb-md-8,
  .sb-show-main .rtl .rw__pb-md-8,
  html[dir=rtl] .ltr .rw__pb-md-8,
  html[dir=rtl] .rtl .rw__pb-md-8,
  html[dir=ltr] .ltr .rw__pb-md-8,
  html[dir=ltr] .rtl .rw__pb-md-8 {
    padding-bottom: 2.25rem !important;
  }
  .sb-show-main .ltr .rw__ms-md-8,
  html[dir=rtl] .ltr .rw__ms-md-8,
  html[dir=ltr] .ltr .rw__ms-md-8 {
    margin-left: 2.25rem !important;
  }
  .sb-show-main .ltr .rw__me-md-8,
  html[dir=rtl] .ltr .rw__me-md-8,
  html[dir=ltr] .ltr .rw__me-md-8 {
    margin-right: 2.25rem !important;
  }
  .sb-show-main .ltr .rw__ps-md-8,
  html[dir=rtl] .ltr .rw__ps-md-8,
  html[dir=ltr] .ltr .rw__ps-md-8 {
    padding-left: 2.25rem !important;
  }
  .sb-show-main .ltr .rw__pe-md-8,
  html[dir=rtl] .ltr .rw__pe-md-8,
  html[dir=ltr] .ltr .rw__pe-md-8 {
    padding-right: 2.25rem !important;
  }
  .sb-show-main .rtl .rw__ms-md-8,
  html[dir=rtl] .rtl .rw__ms-md-8,
  html[dir=ltr] .rtl .rw__ms-md-8 {
    margin-right: 2.25rem !important;
  }
  .sb-show-main .rtl .rw__me-md-8,
  html[dir=rtl] .rtl .rw__me-md-8,
  html[dir=ltr] .rtl .rw__me-md-8 {
    margin-left: 2.25rem !important;
  }
  .sb-show-main .rtl .rw__ps-md-8,
  html[dir=rtl] .rtl .rw__ps-md-8,
  html[dir=ltr] .rtl .rw__ps-md-8 {
    padding-right: 2.25rem !important;
  }
  .sb-show-main .rtl .rw__pe-md-8,
  html[dir=rtl] .rtl .rw__pe-md-8,
  html[dir=ltr] .rtl .rw__pe-md-8 {
    padding-left: 2.25rem !important;
  }
}
@media (min-width: 640px) {
  html[dir=rtl] .ltr .rw__ms-sm-8,
  html[dir=ltr] .ltr .rw__ms-sm-8 {
    margin-left: 2.25rem !important;
  }
  html[dir=rtl] .rtl .rw__ms-sm-8,
  html[dir=ltr] .rtl .rw__ms-sm-8 {
    margin-right: 2.25rem !important;
  }
}
html[dir=rtl] .rw__p-9,
html[dir=ltr] .rw__p-9 {
  padding: 2.5rem !important;
}
html[dir=rtl] .rw__px-9,
html[dir=ltr] .rw__px-9 {
  padding-left: 2.5rem !important;
  padding-right: 2.5rem !important;
}
html[dir=rtl] .rw__py-9,
html[dir=ltr] .rw__py-9 {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}
html[dir=rtl] .rw__pt-9,
html[dir=ltr] .rw__pt-9 {
  padding-top: 2.5rem !important;
}
html[dir=rtl] .rw__pb-9,
html[dir=ltr] .rw__pb-9 {
  padding-bottom: 2.5rem !important;
}
html[dir=rtl] .rw__m-9,
html[dir=ltr] .rw__m-9 {
  margin: 2.5rem !important;
}
html[dir=rtl] .rw__mx-9,
html[dir=ltr] .rw__mx-9 {
  margin-left: 2.5rem !important;
  margin-right: 2.5rem !important;
}
html[dir=rtl] .rw__my-9,
html[dir=ltr] .rw__my-9 {
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important;
}
html[dir=rtl] .rw__mt-9,
html[dir=ltr] .rw__mt-9 {
  margin-top: 2.5rem !important;
}
html[dir=rtl] .rw__mb-9,
html[dir=ltr] .rw__mb-9 {
  margin-bottom: 2.5rem !important;
}
html[dir=rtl] .ltr .rw__ps-9,
html[dir=ltr] .ltr .rw__ps-9 {
  padding-left: 2.5rem !important;
}
html[dir=rtl] .ltr .rw__pe-9,
html[dir=ltr] .ltr .rw__pe-9 {
  padding-right: 2.5rem !important;
}
html[dir=rtl] .ltr .rw__ms-9,
html[dir=ltr] .ltr .rw__ms-9 {
  margin-left: 2.5rem !important;
}
html[dir=rtl] .ltr .rw__me-9,
html[dir=ltr] .ltr .rw__me-9 {
  margin-right: 2.5rem !important;
}
html[dir=rtl] .rtl .rw__ps-9,
html[dir=ltr] .rtl .rw__ps-9 {
  padding-right: 2.5rem !important;
}
html[dir=rtl] .rtl .rw__pe-9,
html[dir=ltr] .rtl .rw__pe-9 {
  padding-left: 2.5rem !important;
}
html[dir=rtl] .rtl .rw__ms-9,
html[dir=ltr] .rtl .rw__ms-9 {
  margin-right: 2.5rem !important;
}
html[dir=rtl] .rtl .rw__me-9,
html[dir=ltr] .rtl .rw__me-9 {
  margin-left: 2.5rem !important;
}

.sb-show-main .rw__p-9 {
  padding: 2.5rem !important;
}
.sb-show-main .rw__px-9 {
  padding-left: 2.5rem !important;
  padding-right: 2.5rem !important;
}
.sb-show-main .rw__py-9 {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}
.sb-show-main .rw__pt-9 {
  padding-top: 2.5rem !important;
}
.sb-show-main .rw__pb-9 {
  padding-bottom: 2.5rem !important;
}
.sb-show-main .rw__m-9 {
  margin: 2.5rem !important;
}
.sb-show-main .rw__mx-9 {
  margin-left: 2.5rem !important;
  margin-right: 2.5rem !important;
}
.sb-show-main .rw__my-9 {
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important;
}
.sb-show-main .rw__mt-9 {
  margin-top: 2.5rem !important;
}
.sb-show-main .rw__mb-9 {
  margin-bottom: 2.5rem !important;
}
.sb-show-main .rw__ps-9 {
  padding-left: 2.5rem !important;
}
.sb-show-main .rw__pe-9 {
  padding-right: 2.5rem !important;
}
.sb-show-main .rw__ms-9 {
  margin-left: 2.5rem !important;
}
.sb-show-main .rw__me-9 {
  margin-right: 2.5rem !important;
}

@media (min-width: 1366px) {
  .sb-show-main .ltr .rw__m-lg-9,
  .sb-show-main .rtl .rw__m-lg-9,
  html[dir=rtl] .ltr .rw__m-lg-9,
  html[dir=rtl] .rtl .rw__m-lg-9,
  html[dir=ltr] .ltr .rw__m-lg-9,
  html[dir=ltr] .rtl .rw__m-lg-9 {
    margin: 2.5rem !important;
  }
  .sb-show-main .ltr .rw__p-lg-9,
  .sb-show-main .rtl .rw__p-lg-9,
  html[dir=rtl] .ltr .rw__p-lg-9,
  html[dir=rtl] .rtl .rw__p-lg-9,
  html[dir=ltr] .ltr .rw__p-lg-9,
  html[dir=ltr] .rtl .rw__p-lg-9 {
    padding: 2.5rem !important;
  }
  .sb-show-main .ltr .rw__mx-lg-9,
  .sb-show-main .rtl .rw__mx-lg-9,
  html[dir=rtl] .ltr .rw__mx-lg-9,
  html[dir=rtl] .rtl .rw__mx-lg-9,
  html[dir=ltr] .ltr .rw__mx-lg-9,
  html[dir=ltr] .rtl .rw__mx-lg-9 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }
  .sb-show-main .ltr .rw__my-lg-9,
  .sb-show-main .rtl .rw__my-lg-9,
  html[dir=rtl] .ltr .rw__my-lg-9,
  html[dir=rtl] .rtl .rw__my-lg-9,
  html[dir=ltr] .ltr .rw__my-lg-9,
  html[dir=ltr] .rtl .rw__my-lg-9 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .sb-show-main .ltr .rw__mt-lg-9,
  .sb-show-main .rtl .rw__mt-lg-9,
  html[dir=rtl] .ltr .rw__mt-lg-9,
  html[dir=rtl] .rtl .rw__mt-lg-9,
  html[dir=ltr] .ltr .rw__mt-lg-9,
  html[dir=ltr] .rtl .rw__mt-lg-9 {
    margin-top: 2.5rem !important;
  }
  .sb-show-main .ltr .rw__mb-lg-9,
  .sb-show-main .rtl .rw__mb-lg-9,
  html[dir=rtl] .ltr .rw__mb-lg-9,
  html[dir=rtl] .rtl .rw__mb-lg-9,
  html[dir=ltr] .ltr .rw__mb-lg-9,
  html[dir=ltr] .rtl .rw__mb-lg-9 {
    margin-bottom: 2.5rem !important;
  }
  .sb-show-main .ltr .rw__px-lg-9,
  .sb-show-main .rtl .rw__px-lg-9,
  html[dir=rtl] .ltr .rw__px-lg-9,
  html[dir=rtl] .rtl .rw__px-lg-9,
  html[dir=ltr] .ltr .rw__px-lg-9,
  html[dir=ltr] .rtl .rw__px-lg-9 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }
  .sb-show-main .ltr .rw__py-lg-9,
  .sb-show-main .rtl .rw__py-lg-9,
  html[dir=rtl] .ltr .rw__py-lg-9,
  html[dir=rtl] .rtl .rw__py-lg-9,
  html[dir=ltr] .ltr .rw__py-lg-9,
  html[dir=ltr] .rtl .rw__py-lg-9 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .sb-show-main .ltr .rw__pt-lg-9,
  .sb-show-main .rtl .rw__pt-lg-9,
  html[dir=rtl] .ltr .rw__pt-lg-9,
  html[dir=rtl] .rtl .rw__pt-lg-9,
  html[dir=ltr] .ltr .rw__pt-lg-9,
  html[dir=ltr] .rtl .rw__pt-lg-9 {
    padding-top: 2.5rem !important;
  }
  .sb-show-main .ltr .rw__pb-lg-9,
  .sb-show-main .rtl .rw__pb-lg-9,
  html[dir=rtl] .ltr .rw__pb-lg-9,
  html[dir=rtl] .rtl .rw__pb-lg-9,
  html[dir=ltr] .ltr .rw__pb-lg-9,
  html[dir=ltr] .rtl .rw__pb-lg-9 {
    padding-bottom: 2.5rem !important;
  }
  .sb-show-main .ltr .rw__ms-lg-9,
  html[dir=rtl] .ltr .rw__ms-lg-9,
  html[dir=ltr] .ltr .rw__ms-lg-9 {
    margin-left: 2.5rem !important;
  }
  .sb-show-main .ltr .rw__me-lg-9,
  html[dir=rtl] .ltr .rw__me-lg-9,
  html[dir=ltr] .ltr .rw__me-lg-9 {
    margin-right: 2.5rem !important;
  }
  .sb-show-main .ltr .rw__ps-lg-9,
  html[dir=rtl] .ltr .rw__ps-lg-9,
  html[dir=ltr] .ltr .rw__ps-lg-9 {
    padding-left: 2.5rem !important;
  }
  .sb-show-main .ltr .rw__pe-lg-9,
  html[dir=rtl] .ltr .rw__pe-lg-9,
  html[dir=ltr] .ltr .rw__pe-lg-9 {
    padding-right: 2.5rem !important;
  }
  .sb-show-main .rtl .rw__ms-lg-9,
  html[dir=rtl] .rtl .rw__ms-lg-9,
  html[dir=ltr] .rtl .rw__ms-lg-9 {
    margin-right: 2.5rem !important;
  }
  .sb-show-main .rtl .rw__me-lg-9,
  html[dir=rtl] .rtl .rw__me-lg-9,
  html[dir=ltr] .rtl .rw__me-lg-9 {
    margin-left: 2.5rem !important;
  }
  .sb-show-main .rtl .rw__ps-lg-9,
  html[dir=rtl] .rtl .rw__ps-lg-9,
  html[dir=ltr] .rtl .rw__ps-lg-9 {
    padding-right: 2.5rem !important;
  }
  .sb-show-main .rtl .rw__pe-lg-9,
  html[dir=rtl] .rtl .rw__pe-lg-9,
  html[dir=ltr] .rtl .rw__pe-lg-9 {
    padding-left: 2.5rem !important;
  }
}
@media (min-width: 960px) {
  .sb-show-main .ltr .rw__m-md-9,
  .sb-show-main .rtl .rw__m-md-9,
  html[dir=rtl] .ltr .rw__m-md-9,
  html[dir=rtl] .rtl .rw__m-md-9,
  html[dir=ltr] .ltr .rw__m-md-9,
  html[dir=ltr] .rtl .rw__m-md-9 {
    margin: 2.5rem !important;
  }
  .sb-show-main .ltr .rw__p-md-9,
  .sb-show-main .rtl .rw__p-md-9,
  html[dir=rtl] .ltr .rw__p-md-9,
  html[dir=rtl] .rtl .rw__p-md-9,
  html[dir=ltr] .ltr .rw__p-md-9,
  html[dir=ltr] .rtl .rw__p-md-9 {
    padding: 2.5rem !important;
  }
  .sb-show-main .ltr .rw__mx-md-9,
  .sb-show-main .rtl .rw__mx-md-9,
  html[dir=rtl] .ltr .rw__mx-md-9,
  html[dir=rtl] .rtl .rw__mx-md-9,
  html[dir=ltr] .ltr .rw__mx-md-9,
  html[dir=ltr] .rtl .rw__mx-md-9 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }
  .sb-show-main .ltr .rw__my-md-9,
  .sb-show-main .rtl .rw__my-md-9,
  html[dir=rtl] .ltr .rw__my-md-9,
  html[dir=rtl] .rtl .rw__my-md-9,
  html[dir=ltr] .ltr .rw__my-md-9,
  html[dir=ltr] .rtl .rw__my-md-9 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .sb-show-main .ltr .rw__mt-md-9,
  .sb-show-main .rtl .rw__mt-md-9,
  html[dir=rtl] .ltr .rw__mt-md-9,
  html[dir=rtl] .rtl .rw__mt-md-9,
  html[dir=ltr] .ltr .rw__mt-md-9,
  html[dir=ltr] .rtl .rw__mt-md-9 {
    margin-top: 2.5rem !important;
  }
  .sb-show-main .ltr .rw__mb-md-9,
  .sb-show-main .rtl .rw__mb-md-9,
  html[dir=rtl] .ltr .rw__mb-md-9,
  html[dir=rtl] .rtl .rw__mb-md-9,
  html[dir=ltr] .ltr .rw__mb-md-9,
  html[dir=ltr] .rtl .rw__mb-md-9 {
    margin-bottom: 2.5rem !important;
  }
  .sb-show-main .ltr .rw__px-md-9,
  .sb-show-main .rtl .rw__px-md-9,
  html[dir=rtl] .ltr .rw__px-md-9,
  html[dir=rtl] .rtl .rw__px-md-9,
  html[dir=ltr] .ltr .rw__px-md-9,
  html[dir=ltr] .rtl .rw__px-md-9 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }
  .sb-show-main .ltr .rw__py-md-9,
  .sb-show-main .rtl .rw__py-md-9,
  html[dir=rtl] .ltr .rw__py-md-9,
  html[dir=rtl] .rtl .rw__py-md-9,
  html[dir=ltr] .ltr .rw__py-md-9,
  html[dir=ltr] .rtl .rw__py-md-9 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .sb-show-main .ltr .rw__pt-md-9,
  .sb-show-main .rtl .rw__pt-md-9,
  html[dir=rtl] .ltr .rw__pt-md-9,
  html[dir=rtl] .rtl .rw__pt-md-9,
  html[dir=ltr] .ltr .rw__pt-md-9,
  html[dir=ltr] .rtl .rw__pt-md-9 {
    padding-top: 2.5rem !important;
  }
  .sb-show-main .ltr .rw__pb-md-9,
  .sb-show-main .rtl .rw__pb-md-9,
  html[dir=rtl] .ltr .rw__pb-md-9,
  html[dir=rtl] .rtl .rw__pb-md-9,
  html[dir=ltr] .ltr .rw__pb-md-9,
  html[dir=ltr] .rtl .rw__pb-md-9 {
    padding-bottom: 2.5rem !important;
  }
  .sb-show-main .ltr .rw__ms-md-9,
  html[dir=rtl] .ltr .rw__ms-md-9,
  html[dir=ltr] .ltr .rw__ms-md-9 {
    margin-left: 2.5rem !important;
  }
  .sb-show-main .ltr .rw__me-md-9,
  html[dir=rtl] .ltr .rw__me-md-9,
  html[dir=ltr] .ltr .rw__me-md-9 {
    margin-right: 2.5rem !important;
  }
  .sb-show-main .ltr .rw__ps-md-9,
  html[dir=rtl] .ltr .rw__ps-md-9,
  html[dir=ltr] .ltr .rw__ps-md-9 {
    padding-left: 2.5rem !important;
  }
  .sb-show-main .ltr .rw__pe-md-9,
  html[dir=rtl] .ltr .rw__pe-md-9,
  html[dir=ltr] .ltr .rw__pe-md-9 {
    padding-right: 2.5rem !important;
  }
  .sb-show-main .rtl .rw__ms-md-9,
  html[dir=rtl] .rtl .rw__ms-md-9,
  html[dir=ltr] .rtl .rw__ms-md-9 {
    margin-right: 2.5rem !important;
  }
  .sb-show-main .rtl .rw__me-md-9,
  html[dir=rtl] .rtl .rw__me-md-9,
  html[dir=ltr] .rtl .rw__me-md-9 {
    margin-left: 2.5rem !important;
  }
  .sb-show-main .rtl .rw__ps-md-9,
  html[dir=rtl] .rtl .rw__ps-md-9,
  html[dir=ltr] .rtl .rw__ps-md-9 {
    padding-right: 2.5rem !important;
  }
  .sb-show-main .rtl .rw__pe-md-9,
  html[dir=rtl] .rtl .rw__pe-md-9,
  html[dir=ltr] .rtl .rw__pe-md-9 {
    padding-left: 2.5rem !important;
  }
}
@media (min-width: 640px) {
  html[dir=rtl] .ltr .rw__ms-sm-9,
  html[dir=ltr] .ltr .rw__ms-sm-9 {
    margin-left: 2.5rem !important;
  }
  html[dir=rtl] .rtl .rw__ms-sm-9,
  html[dir=ltr] .rtl .rw__ms-sm-9 {
    margin-right: 2.5rem !important;
  }
}
html[dir=rtl] .rw__p-10,
html[dir=ltr] .rw__p-10 {
  padding: 2.625rem !important;
}
html[dir=rtl] .rw__px-10,
html[dir=ltr] .rw__px-10 {
  padding-left: 2.625rem !important;
  padding-right: 2.625rem !important;
}
html[dir=rtl] .rw__py-10,
html[dir=ltr] .rw__py-10 {
  padding-top: 2.625rem !important;
  padding-bottom: 2.625rem !important;
}
html[dir=rtl] .rw__pt-10,
html[dir=ltr] .rw__pt-10 {
  padding-top: 2.625rem !important;
}
html[dir=rtl] .rw__pb-10,
html[dir=ltr] .rw__pb-10 {
  padding-bottom: 2.625rem !important;
}
html[dir=rtl] .rw__m-10,
html[dir=ltr] .rw__m-10 {
  margin: 2.625rem !important;
}
html[dir=rtl] .rw__mx-10,
html[dir=ltr] .rw__mx-10 {
  margin-left: 2.625rem !important;
  margin-right: 2.625rem !important;
}
html[dir=rtl] .rw__my-10,
html[dir=ltr] .rw__my-10 {
  margin-top: 2.625rem !important;
  margin-bottom: 2.625rem !important;
}
html[dir=rtl] .rw__mt-10,
html[dir=ltr] .rw__mt-10 {
  margin-top: 2.625rem !important;
}
html[dir=rtl] .rw__mb-10,
html[dir=ltr] .rw__mb-10 {
  margin-bottom: 2.625rem !important;
}
html[dir=rtl] .ltr .rw__ps-10,
html[dir=ltr] .ltr .rw__ps-10 {
  padding-left: 2.625rem !important;
}
html[dir=rtl] .ltr .rw__pe-10,
html[dir=ltr] .ltr .rw__pe-10 {
  padding-right: 2.625rem !important;
}
html[dir=rtl] .ltr .rw__ms-10,
html[dir=ltr] .ltr .rw__ms-10 {
  margin-left: 2.625rem !important;
}
html[dir=rtl] .ltr .rw__me-10,
html[dir=ltr] .ltr .rw__me-10 {
  margin-right: 2.625rem !important;
}
html[dir=rtl] .rtl .rw__ps-10,
html[dir=ltr] .rtl .rw__ps-10 {
  padding-right: 2.625rem !important;
}
html[dir=rtl] .rtl .rw__pe-10,
html[dir=ltr] .rtl .rw__pe-10 {
  padding-left: 2.625rem !important;
}
html[dir=rtl] .rtl .rw__ms-10,
html[dir=ltr] .rtl .rw__ms-10 {
  margin-right: 2.625rem !important;
}
html[dir=rtl] .rtl .rw__me-10,
html[dir=ltr] .rtl .rw__me-10 {
  margin-left: 2.625rem !important;
}

.sb-show-main .rw__p-10 {
  padding: 2.625rem !important;
}
.sb-show-main .rw__px-10 {
  padding-left: 2.625rem !important;
  padding-right: 2.625rem !important;
}
.sb-show-main .rw__py-10 {
  padding-top: 2.625rem !important;
  padding-bottom: 2.625rem !important;
}
.sb-show-main .rw__pt-10 {
  padding-top: 2.625rem !important;
}
.sb-show-main .rw__pb-10 {
  padding-bottom: 2.625rem !important;
}
.sb-show-main .rw__m-10 {
  margin: 2.625rem !important;
}
.sb-show-main .rw__mx-10 {
  margin-left: 2.625rem !important;
  margin-right: 2.625rem !important;
}
.sb-show-main .rw__my-10 {
  margin-top: 2.625rem !important;
  margin-bottom: 2.625rem !important;
}
.sb-show-main .rw__mt-10 {
  margin-top: 2.625rem !important;
}
.sb-show-main .rw__mb-10 {
  margin-bottom: 2.625rem !important;
}
.sb-show-main .rw__ps-10 {
  padding-left: 2.625rem !important;
}
.sb-show-main .rw__pe-10 {
  padding-right: 2.625rem !important;
}
.sb-show-main .rw__ms-10 {
  margin-left: 2.625rem !important;
}
.sb-show-main .rw__me-10 {
  margin-right: 2.625rem !important;
}

@media (min-width: 1366px) {
  .sb-show-main .ltr .rw__m-lg-10,
  .sb-show-main .rtl .rw__m-lg-10,
  html[dir=rtl] .ltr .rw__m-lg-10,
  html[dir=rtl] .rtl .rw__m-lg-10,
  html[dir=ltr] .ltr .rw__m-lg-10,
  html[dir=ltr] .rtl .rw__m-lg-10 {
    margin: 2.625rem !important;
  }
  .sb-show-main .ltr .rw__p-lg-10,
  .sb-show-main .rtl .rw__p-lg-10,
  html[dir=rtl] .ltr .rw__p-lg-10,
  html[dir=rtl] .rtl .rw__p-lg-10,
  html[dir=ltr] .ltr .rw__p-lg-10,
  html[dir=ltr] .rtl .rw__p-lg-10 {
    padding: 2.625rem !important;
  }
  .sb-show-main .ltr .rw__mx-lg-10,
  .sb-show-main .rtl .rw__mx-lg-10,
  html[dir=rtl] .ltr .rw__mx-lg-10,
  html[dir=rtl] .rtl .rw__mx-lg-10,
  html[dir=ltr] .ltr .rw__mx-lg-10,
  html[dir=ltr] .rtl .rw__mx-lg-10 {
    margin-left: 2.625rem !important;
    margin-right: 2.625rem !important;
  }
  .sb-show-main .ltr .rw__my-lg-10,
  .sb-show-main .rtl .rw__my-lg-10,
  html[dir=rtl] .ltr .rw__my-lg-10,
  html[dir=rtl] .rtl .rw__my-lg-10,
  html[dir=ltr] .ltr .rw__my-lg-10,
  html[dir=ltr] .rtl .rw__my-lg-10 {
    margin-top: 2.625rem !important;
    margin-bottom: 2.625rem !important;
  }
  .sb-show-main .ltr .rw__mt-lg-10,
  .sb-show-main .rtl .rw__mt-lg-10,
  html[dir=rtl] .ltr .rw__mt-lg-10,
  html[dir=rtl] .rtl .rw__mt-lg-10,
  html[dir=ltr] .ltr .rw__mt-lg-10,
  html[dir=ltr] .rtl .rw__mt-lg-10 {
    margin-top: 2.625rem !important;
  }
  .sb-show-main .ltr .rw__mb-lg-10,
  .sb-show-main .rtl .rw__mb-lg-10,
  html[dir=rtl] .ltr .rw__mb-lg-10,
  html[dir=rtl] .rtl .rw__mb-lg-10,
  html[dir=ltr] .ltr .rw__mb-lg-10,
  html[dir=ltr] .rtl .rw__mb-lg-10 {
    margin-bottom: 2.625rem !important;
  }
  .sb-show-main .ltr .rw__px-lg-10,
  .sb-show-main .rtl .rw__px-lg-10,
  html[dir=rtl] .ltr .rw__px-lg-10,
  html[dir=rtl] .rtl .rw__px-lg-10,
  html[dir=ltr] .ltr .rw__px-lg-10,
  html[dir=ltr] .rtl .rw__px-lg-10 {
    padding-left: 2.625rem !important;
    padding-right: 2.625rem !important;
  }
  .sb-show-main .ltr .rw__py-lg-10,
  .sb-show-main .rtl .rw__py-lg-10,
  html[dir=rtl] .ltr .rw__py-lg-10,
  html[dir=rtl] .rtl .rw__py-lg-10,
  html[dir=ltr] .ltr .rw__py-lg-10,
  html[dir=ltr] .rtl .rw__py-lg-10 {
    padding-top: 2.625rem !important;
    padding-bottom: 2.625rem !important;
  }
  .sb-show-main .ltr .rw__pt-lg-10,
  .sb-show-main .rtl .rw__pt-lg-10,
  html[dir=rtl] .ltr .rw__pt-lg-10,
  html[dir=rtl] .rtl .rw__pt-lg-10,
  html[dir=ltr] .ltr .rw__pt-lg-10,
  html[dir=ltr] .rtl .rw__pt-lg-10 {
    padding-top: 2.625rem !important;
  }
  .sb-show-main .ltr .rw__pb-lg-10,
  .sb-show-main .rtl .rw__pb-lg-10,
  html[dir=rtl] .ltr .rw__pb-lg-10,
  html[dir=rtl] .rtl .rw__pb-lg-10,
  html[dir=ltr] .ltr .rw__pb-lg-10,
  html[dir=ltr] .rtl .rw__pb-lg-10 {
    padding-bottom: 2.625rem !important;
  }
  .sb-show-main .ltr .rw__ms-lg-10,
  html[dir=rtl] .ltr .rw__ms-lg-10,
  html[dir=ltr] .ltr .rw__ms-lg-10 {
    margin-left: 2.625rem !important;
  }
  .sb-show-main .ltr .rw__me-lg-10,
  html[dir=rtl] .ltr .rw__me-lg-10,
  html[dir=ltr] .ltr .rw__me-lg-10 {
    margin-right: 2.625rem !important;
  }
  .sb-show-main .ltr .rw__ps-lg-10,
  html[dir=rtl] .ltr .rw__ps-lg-10,
  html[dir=ltr] .ltr .rw__ps-lg-10 {
    padding-left: 2.625rem !important;
  }
  .sb-show-main .ltr .rw__pe-lg-10,
  html[dir=rtl] .ltr .rw__pe-lg-10,
  html[dir=ltr] .ltr .rw__pe-lg-10 {
    padding-right: 2.625rem !important;
  }
  .sb-show-main .rtl .rw__ms-lg-10,
  html[dir=rtl] .rtl .rw__ms-lg-10,
  html[dir=ltr] .rtl .rw__ms-lg-10 {
    margin-right: 2.625rem !important;
  }
  .sb-show-main .rtl .rw__me-lg-10,
  html[dir=rtl] .rtl .rw__me-lg-10,
  html[dir=ltr] .rtl .rw__me-lg-10 {
    margin-left: 2.625rem !important;
  }
  .sb-show-main .rtl .rw__ps-lg-10,
  html[dir=rtl] .rtl .rw__ps-lg-10,
  html[dir=ltr] .rtl .rw__ps-lg-10 {
    padding-right: 2.625rem !important;
  }
  .sb-show-main .rtl .rw__pe-lg-10,
  html[dir=rtl] .rtl .rw__pe-lg-10,
  html[dir=ltr] .rtl .rw__pe-lg-10 {
    padding-left: 2.625rem !important;
  }
}
@media (min-width: 960px) {
  .sb-show-main .ltr .rw__m-md-10,
  .sb-show-main .rtl .rw__m-md-10,
  html[dir=rtl] .ltr .rw__m-md-10,
  html[dir=rtl] .rtl .rw__m-md-10,
  html[dir=ltr] .ltr .rw__m-md-10,
  html[dir=ltr] .rtl .rw__m-md-10 {
    margin: 2.625rem !important;
  }
  .sb-show-main .ltr .rw__p-md-10,
  .sb-show-main .rtl .rw__p-md-10,
  html[dir=rtl] .ltr .rw__p-md-10,
  html[dir=rtl] .rtl .rw__p-md-10,
  html[dir=ltr] .ltr .rw__p-md-10,
  html[dir=ltr] .rtl .rw__p-md-10 {
    padding: 2.625rem !important;
  }
  .sb-show-main .ltr .rw__mx-md-10,
  .sb-show-main .rtl .rw__mx-md-10,
  html[dir=rtl] .ltr .rw__mx-md-10,
  html[dir=rtl] .rtl .rw__mx-md-10,
  html[dir=ltr] .ltr .rw__mx-md-10,
  html[dir=ltr] .rtl .rw__mx-md-10 {
    margin-left: 2.625rem !important;
    margin-right: 2.625rem !important;
  }
  .sb-show-main .ltr .rw__my-md-10,
  .sb-show-main .rtl .rw__my-md-10,
  html[dir=rtl] .ltr .rw__my-md-10,
  html[dir=rtl] .rtl .rw__my-md-10,
  html[dir=ltr] .ltr .rw__my-md-10,
  html[dir=ltr] .rtl .rw__my-md-10 {
    margin-top: 2.625rem !important;
    margin-bottom: 2.625rem !important;
  }
  .sb-show-main .ltr .rw__mt-md-10,
  .sb-show-main .rtl .rw__mt-md-10,
  html[dir=rtl] .ltr .rw__mt-md-10,
  html[dir=rtl] .rtl .rw__mt-md-10,
  html[dir=ltr] .ltr .rw__mt-md-10,
  html[dir=ltr] .rtl .rw__mt-md-10 {
    margin-top: 2.625rem !important;
  }
  .sb-show-main .ltr .rw__mb-md-10,
  .sb-show-main .rtl .rw__mb-md-10,
  html[dir=rtl] .ltr .rw__mb-md-10,
  html[dir=rtl] .rtl .rw__mb-md-10,
  html[dir=ltr] .ltr .rw__mb-md-10,
  html[dir=ltr] .rtl .rw__mb-md-10 {
    margin-bottom: 2.625rem !important;
  }
  .sb-show-main .ltr .rw__px-md-10,
  .sb-show-main .rtl .rw__px-md-10,
  html[dir=rtl] .ltr .rw__px-md-10,
  html[dir=rtl] .rtl .rw__px-md-10,
  html[dir=ltr] .ltr .rw__px-md-10,
  html[dir=ltr] .rtl .rw__px-md-10 {
    padding-left: 2.625rem !important;
    padding-right: 2.625rem !important;
  }
  .sb-show-main .ltr .rw__py-md-10,
  .sb-show-main .rtl .rw__py-md-10,
  html[dir=rtl] .ltr .rw__py-md-10,
  html[dir=rtl] .rtl .rw__py-md-10,
  html[dir=ltr] .ltr .rw__py-md-10,
  html[dir=ltr] .rtl .rw__py-md-10 {
    padding-top: 2.625rem !important;
    padding-bottom: 2.625rem !important;
  }
  .sb-show-main .ltr .rw__pt-md-10,
  .sb-show-main .rtl .rw__pt-md-10,
  html[dir=rtl] .ltr .rw__pt-md-10,
  html[dir=rtl] .rtl .rw__pt-md-10,
  html[dir=ltr] .ltr .rw__pt-md-10,
  html[dir=ltr] .rtl .rw__pt-md-10 {
    padding-top: 2.625rem !important;
  }
  .sb-show-main .ltr .rw__pb-md-10,
  .sb-show-main .rtl .rw__pb-md-10,
  html[dir=rtl] .ltr .rw__pb-md-10,
  html[dir=rtl] .rtl .rw__pb-md-10,
  html[dir=ltr] .ltr .rw__pb-md-10,
  html[dir=ltr] .rtl .rw__pb-md-10 {
    padding-bottom: 2.625rem !important;
  }
  .sb-show-main .ltr .rw__ms-md-10,
  html[dir=rtl] .ltr .rw__ms-md-10,
  html[dir=ltr] .ltr .rw__ms-md-10 {
    margin-left: 2.625rem !important;
  }
  .sb-show-main .ltr .rw__me-md-10,
  html[dir=rtl] .ltr .rw__me-md-10,
  html[dir=ltr] .ltr .rw__me-md-10 {
    margin-right: 2.625rem !important;
  }
  .sb-show-main .ltr .rw__ps-md-10,
  html[dir=rtl] .ltr .rw__ps-md-10,
  html[dir=ltr] .ltr .rw__ps-md-10 {
    padding-left: 2.625rem !important;
  }
  .sb-show-main .ltr .rw__pe-md-10,
  html[dir=rtl] .ltr .rw__pe-md-10,
  html[dir=ltr] .ltr .rw__pe-md-10 {
    padding-right: 2.625rem !important;
  }
  .sb-show-main .rtl .rw__ms-md-10,
  html[dir=rtl] .rtl .rw__ms-md-10,
  html[dir=ltr] .rtl .rw__ms-md-10 {
    margin-right: 2.625rem !important;
  }
  .sb-show-main .rtl .rw__me-md-10,
  html[dir=rtl] .rtl .rw__me-md-10,
  html[dir=ltr] .rtl .rw__me-md-10 {
    margin-left: 2.625rem !important;
  }
  .sb-show-main .rtl .rw__ps-md-10,
  html[dir=rtl] .rtl .rw__ps-md-10,
  html[dir=ltr] .rtl .rw__ps-md-10 {
    padding-right: 2.625rem !important;
  }
  .sb-show-main .rtl .rw__pe-md-10,
  html[dir=rtl] .rtl .rw__pe-md-10,
  html[dir=ltr] .rtl .rw__pe-md-10 {
    padding-left: 2.625rem !important;
  }
}
@media (min-width: 640px) {
  html[dir=rtl] .ltr .rw__ms-sm-10,
  html[dir=ltr] .ltr .rw__ms-sm-10 {
    margin-left: 2.625rem !important;
  }
  html[dir=rtl] .rtl .rw__ms-sm-10,
  html[dir=ltr] .rtl .rw__ms-sm-10 {
    margin-right: 2.625rem !important;
  }
}
html[dir=rtl] .rw__p-11,
html[dir=ltr] .rw__p-11 {
  padding: 3rem !important;
}
html[dir=rtl] .rw__px-11,
html[dir=ltr] .rw__px-11 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}
html[dir=rtl] .rw__py-11,
html[dir=ltr] .rw__py-11 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}
html[dir=rtl] .rw__pt-11,
html[dir=ltr] .rw__pt-11 {
  padding-top: 3rem !important;
}
html[dir=rtl] .rw__pb-11,
html[dir=ltr] .rw__pb-11 {
  padding-bottom: 3rem !important;
}
html[dir=rtl] .rw__m-11,
html[dir=ltr] .rw__m-11 {
  margin: 3rem !important;
}
html[dir=rtl] .rw__mx-11,
html[dir=ltr] .rw__mx-11 {
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}
html[dir=rtl] .rw__my-11,
html[dir=ltr] .rw__my-11 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}
html[dir=rtl] .rw__mt-11,
html[dir=ltr] .rw__mt-11 {
  margin-top: 3rem !important;
}
html[dir=rtl] .rw__mb-11,
html[dir=ltr] .rw__mb-11 {
  margin-bottom: 3rem !important;
}
html[dir=rtl] .ltr .rw__ps-11,
html[dir=ltr] .ltr .rw__ps-11 {
  padding-left: 3rem !important;
}
html[dir=rtl] .ltr .rw__pe-11,
html[dir=ltr] .ltr .rw__pe-11 {
  padding-right: 3rem !important;
}
html[dir=rtl] .ltr .rw__ms-11,
html[dir=ltr] .ltr .rw__ms-11 {
  margin-left: 3rem !important;
}
html[dir=rtl] .ltr .rw__me-11,
html[dir=ltr] .ltr .rw__me-11 {
  margin-right: 3rem !important;
}
html[dir=rtl] .rtl .rw__ps-11,
html[dir=ltr] .rtl .rw__ps-11 {
  padding-right: 3rem !important;
}
html[dir=rtl] .rtl .rw__pe-11,
html[dir=ltr] .rtl .rw__pe-11 {
  padding-left: 3rem !important;
}
html[dir=rtl] .rtl .rw__ms-11,
html[dir=ltr] .rtl .rw__ms-11 {
  margin-right: 3rem !important;
}
html[dir=rtl] .rtl .rw__me-11,
html[dir=ltr] .rtl .rw__me-11 {
  margin-left: 3rem !important;
}

.sb-show-main .rw__p-11 {
  padding: 3rem !important;
}
.sb-show-main .rw__px-11 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}
.sb-show-main .rw__py-11 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}
.sb-show-main .rw__pt-11 {
  padding-top: 3rem !important;
}
.sb-show-main .rw__pb-11 {
  padding-bottom: 3rem !important;
}
.sb-show-main .rw__m-11 {
  margin: 3rem !important;
}
.sb-show-main .rw__mx-11 {
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}
.sb-show-main .rw__my-11 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}
.sb-show-main .rw__mt-11 {
  margin-top: 3rem !important;
}
.sb-show-main .rw__mb-11 {
  margin-bottom: 3rem !important;
}
.sb-show-main .rw__ps-11 {
  padding-left: 3rem !important;
}
.sb-show-main .rw__pe-11 {
  padding-right: 3rem !important;
}
.sb-show-main .rw__ms-11 {
  margin-left: 3rem !important;
}
.sb-show-main .rw__me-11 {
  margin-right: 3rem !important;
}

@media (min-width: 1366px) {
  .sb-show-main .ltr .rw__m-lg-11,
  .sb-show-main .rtl .rw__m-lg-11,
  html[dir=rtl] .ltr .rw__m-lg-11,
  html[dir=rtl] .rtl .rw__m-lg-11,
  html[dir=ltr] .ltr .rw__m-lg-11,
  html[dir=ltr] .rtl .rw__m-lg-11 {
    margin: 3rem !important;
  }
  .sb-show-main .ltr .rw__p-lg-11,
  .sb-show-main .rtl .rw__p-lg-11,
  html[dir=rtl] .ltr .rw__p-lg-11,
  html[dir=rtl] .rtl .rw__p-lg-11,
  html[dir=ltr] .ltr .rw__p-lg-11,
  html[dir=ltr] .rtl .rw__p-lg-11 {
    padding: 3rem !important;
  }
  .sb-show-main .ltr .rw__mx-lg-11,
  .sb-show-main .rtl .rw__mx-lg-11,
  html[dir=rtl] .ltr .rw__mx-lg-11,
  html[dir=rtl] .rtl .rw__mx-lg-11,
  html[dir=ltr] .ltr .rw__mx-lg-11,
  html[dir=ltr] .rtl .rw__mx-lg-11 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }
  .sb-show-main .ltr .rw__my-lg-11,
  .sb-show-main .rtl .rw__my-lg-11,
  html[dir=rtl] .ltr .rw__my-lg-11,
  html[dir=rtl] .rtl .rw__my-lg-11,
  html[dir=ltr] .ltr .rw__my-lg-11,
  html[dir=ltr] .rtl .rw__my-lg-11 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .sb-show-main .ltr .rw__mt-lg-11,
  .sb-show-main .rtl .rw__mt-lg-11,
  html[dir=rtl] .ltr .rw__mt-lg-11,
  html[dir=rtl] .rtl .rw__mt-lg-11,
  html[dir=ltr] .ltr .rw__mt-lg-11,
  html[dir=ltr] .rtl .rw__mt-lg-11 {
    margin-top: 3rem !important;
  }
  .sb-show-main .ltr .rw__mb-lg-11,
  .sb-show-main .rtl .rw__mb-lg-11,
  html[dir=rtl] .ltr .rw__mb-lg-11,
  html[dir=rtl] .rtl .rw__mb-lg-11,
  html[dir=ltr] .ltr .rw__mb-lg-11,
  html[dir=ltr] .rtl .rw__mb-lg-11 {
    margin-bottom: 3rem !important;
  }
  .sb-show-main .ltr .rw__px-lg-11,
  .sb-show-main .rtl .rw__px-lg-11,
  html[dir=rtl] .ltr .rw__px-lg-11,
  html[dir=rtl] .rtl .rw__px-lg-11,
  html[dir=ltr] .ltr .rw__px-lg-11,
  html[dir=ltr] .rtl .rw__px-lg-11 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
  .sb-show-main .ltr .rw__py-lg-11,
  .sb-show-main .rtl .rw__py-lg-11,
  html[dir=rtl] .ltr .rw__py-lg-11,
  html[dir=rtl] .rtl .rw__py-lg-11,
  html[dir=ltr] .ltr .rw__py-lg-11,
  html[dir=ltr] .rtl .rw__py-lg-11 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .sb-show-main .ltr .rw__pt-lg-11,
  .sb-show-main .rtl .rw__pt-lg-11,
  html[dir=rtl] .ltr .rw__pt-lg-11,
  html[dir=rtl] .rtl .rw__pt-lg-11,
  html[dir=ltr] .ltr .rw__pt-lg-11,
  html[dir=ltr] .rtl .rw__pt-lg-11 {
    padding-top: 3rem !important;
  }
  .sb-show-main .ltr .rw__pb-lg-11,
  .sb-show-main .rtl .rw__pb-lg-11,
  html[dir=rtl] .ltr .rw__pb-lg-11,
  html[dir=rtl] .rtl .rw__pb-lg-11,
  html[dir=ltr] .ltr .rw__pb-lg-11,
  html[dir=ltr] .rtl .rw__pb-lg-11 {
    padding-bottom: 3rem !important;
  }
  .sb-show-main .ltr .rw__ms-lg-11,
  html[dir=rtl] .ltr .rw__ms-lg-11,
  html[dir=ltr] .ltr .rw__ms-lg-11 {
    margin-left: 3rem !important;
  }
  .sb-show-main .ltr .rw__me-lg-11,
  html[dir=rtl] .ltr .rw__me-lg-11,
  html[dir=ltr] .ltr .rw__me-lg-11 {
    margin-right: 3rem !important;
  }
  .sb-show-main .ltr .rw__ps-lg-11,
  html[dir=rtl] .ltr .rw__ps-lg-11,
  html[dir=ltr] .ltr .rw__ps-lg-11 {
    padding-left: 3rem !important;
  }
  .sb-show-main .ltr .rw__pe-lg-11,
  html[dir=rtl] .ltr .rw__pe-lg-11,
  html[dir=ltr] .ltr .rw__pe-lg-11 {
    padding-right: 3rem !important;
  }
  .sb-show-main .rtl .rw__ms-lg-11,
  html[dir=rtl] .rtl .rw__ms-lg-11,
  html[dir=ltr] .rtl .rw__ms-lg-11 {
    margin-right: 3rem !important;
  }
  .sb-show-main .rtl .rw__me-lg-11,
  html[dir=rtl] .rtl .rw__me-lg-11,
  html[dir=ltr] .rtl .rw__me-lg-11 {
    margin-left: 3rem !important;
  }
  .sb-show-main .rtl .rw__ps-lg-11,
  html[dir=rtl] .rtl .rw__ps-lg-11,
  html[dir=ltr] .rtl .rw__ps-lg-11 {
    padding-right: 3rem !important;
  }
  .sb-show-main .rtl .rw__pe-lg-11,
  html[dir=rtl] .rtl .rw__pe-lg-11,
  html[dir=ltr] .rtl .rw__pe-lg-11 {
    padding-left: 3rem !important;
  }
}
@media (min-width: 960px) {
  .sb-show-main .ltr .rw__m-md-11,
  .sb-show-main .rtl .rw__m-md-11,
  html[dir=rtl] .ltr .rw__m-md-11,
  html[dir=rtl] .rtl .rw__m-md-11,
  html[dir=ltr] .ltr .rw__m-md-11,
  html[dir=ltr] .rtl .rw__m-md-11 {
    margin: 3rem !important;
  }
  .sb-show-main .ltr .rw__p-md-11,
  .sb-show-main .rtl .rw__p-md-11,
  html[dir=rtl] .ltr .rw__p-md-11,
  html[dir=rtl] .rtl .rw__p-md-11,
  html[dir=ltr] .ltr .rw__p-md-11,
  html[dir=ltr] .rtl .rw__p-md-11 {
    padding: 3rem !important;
  }
  .sb-show-main .ltr .rw__mx-md-11,
  .sb-show-main .rtl .rw__mx-md-11,
  html[dir=rtl] .ltr .rw__mx-md-11,
  html[dir=rtl] .rtl .rw__mx-md-11,
  html[dir=ltr] .ltr .rw__mx-md-11,
  html[dir=ltr] .rtl .rw__mx-md-11 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }
  .sb-show-main .ltr .rw__my-md-11,
  .sb-show-main .rtl .rw__my-md-11,
  html[dir=rtl] .ltr .rw__my-md-11,
  html[dir=rtl] .rtl .rw__my-md-11,
  html[dir=ltr] .ltr .rw__my-md-11,
  html[dir=ltr] .rtl .rw__my-md-11 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .sb-show-main .ltr .rw__mt-md-11,
  .sb-show-main .rtl .rw__mt-md-11,
  html[dir=rtl] .ltr .rw__mt-md-11,
  html[dir=rtl] .rtl .rw__mt-md-11,
  html[dir=ltr] .ltr .rw__mt-md-11,
  html[dir=ltr] .rtl .rw__mt-md-11 {
    margin-top: 3rem !important;
  }
  .sb-show-main .ltr .rw__mb-md-11,
  .sb-show-main .rtl .rw__mb-md-11,
  html[dir=rtl] .ltr .rw__mb-md-11,
  html[dir=rtl] .rtl .rw__mb-md-11,
  html[dir=ltr] .ltr .rw__mb-md-11,
  html[dir=ltr] .rtl .rw__mb-md-11 {
    margin-bottom: 3rem !important;
  }
  .sb-show-main .ltr .rw__px-md-11,
  .sb-show-main .rtl .rw__px-md-11,
  html[dir=rtl] .ltr .rw__px-md-11,
  html[dir=rtl] .rtl .rw__px-md-11,
  html[dir=ltr] .ltr .rw__px-md-11,
  html[dir=ltr] .rtl .rw__px-md-11 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
  .sb-show-main .ltr .rw__py-md-11,
  .sb-show-main .rtl .rw__py-md-11,
  html[dir=rtl] .ltr .rw__py-md-11,
  html[dir=rtl] .rtl .rw__py-md-11,
  html[dir=ltr] .ltr .rw__py-md-11,
  html[dir=ltr] .rtl .rw__py-md-11 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .sb-show-main .ltr .rw__pt-md-11,
  .sb-show-main .rtl .rw__pt-md-11,
  html[dir=rtl] .ltr .rw__pt-md-11,
  html[dir=rtl] .rtl .rw__pt-md-11,
  html[dir=ltr] .ltr .rw__pt-md-11,
  html[dir=ltr] .rtl .rw__pt-md-11 {
    padding-top: 3rem !important;
  }
  .sb-show-main .ltr .rw__pb-md-11,
  .sb-show-main .rtl .rw__pb-md-11,
  html[dir=rtl] .ltr .rw__pb-md-11,
  html[dir=rtl] .rtl .rw__pb-md-11,
  html[dir=ltr] .ltr .rw__pb-md-11,
  html[dir=ltr] .rtl .rw__pb-md-11 {
    padding-bottom: 3rem !important;
  }
  .sb-show-main .ltr .rw__ms-md-11,
  html[dir=rtl] .ltr .rw__ms-md-11,
  html[dir=ltr] .ltr .rw__ms-md-11 {
    margin-left: 3rem !important;
  }
  .sb-show-main .ltr .rw__me-md-11,
  html[dir=rtl] .ltr .rw__me-md-11,
  html[dir=ltr] .ltr .rw__me-md-11 {
    margin-right: 3rem !important;
  }
  .sb-show-main .ltr .rw__ps-md-11,
  html[dir=rtl] .ltr .rw__ps-md-11,
  html[dir=ltr] .ltr .rw__ps-md-11 {
    padding-left: 3rem !important;
  }
  .sb-show-main .ltr .rw__pe-md-11,
  html[dir=rtl] .ltr .rw__pe-md-11,
  html[dir=ltr] .ltr .rw__pe-md-11 {
    padding-right: 3rem !important;
  }
  .sb-show-main .rtl .rw__ms-md-11,
  html[dir=rtl] .rtl .rw__ms-md-11,
  html[dir=ltr] .rtl .rw__ms-md-11 {
    margin-right: 3rem !important;
  }
  .sb-show-main .rtl .rw__me-md-11,
  html[dir=rtl] .rtl .rw__me-md-11,
  html[dir=ltr] .rtl .rw__me-md-11 {
    margin-left: 3rem !important;
  }
  .sb-show-main .rtl .rw__ps-md-11,
  html[dir=rtl] .rtl .rw__ps-md-11,
  html[dir=ltr] .rtl .rw__ps-md-11 {
    padding-right: 3rem !important;
  }
  .sb-show-main .rtl .rw__pe-md-11,
  html[dir=rtl] .rtl .rw__pe-md-11,
  html[dir=ltr] .rtl .rw__pe-md-11 {
    padding-left: 3rem !important;
  }
}
@media (min-width: 640px) {
  html[dir=rtl] .ltr .rw__ms-sm-11,
  html[dir=ltr] .ltr .rw__ms-sm-11 {
    margin-left: 3rem !important;
  }
  html[dir=rtl] .rtl .rw__ms-sm-11,
  html[dir=ltr] .rtl .rw__ms-sm-11 {
    margin-right: 3rem !important;
  }
}
html[dir=rtl] .rw__p-12,
html[dir=ltr] .rw__p-12 {
  padding: 4rem !important;
}
html[dir=rtl] .rw__px-12,
html[dir=ltr] .rw__px-12 {
  padding-left: 4rem !important;
  padding-right: 4rem !important;
}
html[dir=rtl] .rw__py-12,
html[dir=ltr] .rw__py-12 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}
html[dir=rtl] .rw__pt-12,
html[dir=ltr] .rw__pt-12 {
  padding-top: 4rem !important;
}
html[dir=rtl] .rw__pb-12,
html[dir=ltr] .rw__pb-12 {
  padding-bottom: 4rem !important;
}
html[dir=rtl] .rw__m-12,
html[dir=ltr] .rw__m-12 {
  margin: 4rem !important;
}
html[dir=rtl] .rw__mx-12,
html[dir=ltr] .rw__mx-12 {
  margin-left: 4rem !important;
  margin-right: 4rem !important;
}
html[dir=rtl] .rw__my-12,
html[dir=ltr] .rw__my-12 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}
html[dir=rtl] .rw__mt-12,
html[dir=ltr] .rw__mt-12 {
  margin-top: 4rem !important;
}
html[dir=rtl] .rw__mb-12,
html[dir=ltr] .rw__mb-12 {
  margin-bottom: 4rem !important;
}
html[dir=rtl] .ltr .rw__ps-12,
html[dir=ltr] .ltr .rw__ps-12 {
  padding-left: 4rem !important;
}
html[dir=rtl] .ltr .rw__pe-12,
html[dir=ltr] .ltr .rw__pe-12 {
  padding-right: 4rem !important;
}
html[dir=rtl] .ltr .rw__ms-12,
html[dir=ltr] .ltr .rw__ms-12 {
  margin-left: 4rem !important;
}
html[dir=rtl] .ltr .rw__me-12,
html[dir=ltr] .ltr .rw__me-12 {
  margin-right: 4rem !important;
}
html[dir=rtl] .rtl .rw__ps-12,
html[dir=ltr] .rtl .rw__ps-12 {
  padding-right: 4rem !important;
}
html[dir=rtl] .rtl .rw__pe-12,
html[dir=ltr] .rtl .rw__pe-12 {
  padding-left: 4rem !important;
}
html[dir=rtl] .rtl .rw__ms-12,
html[dir=ltr] .rtl .rw__ms-12 {
  margin-right: 4rem !important;
}
html[dir=rtl] .rtl .rw__me-12,
html[dir=ltr] .rtl .rw__me-12 {
  margin-left: 4rem !important;
}

.sb-show-main .rw__p-12 {
  padding: 4rem !important;
}
.sb-show-main .rw__px-12 {
  padding-left: 4rem !important;
  padding-right: 4rem !important;
}
.sb-show-main .rw__py-12 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}
.sb-show-main .rw__pt-12 {
  padding-top: 4rem !important;
}
.sb-show-main .rw__pb-12 {
  padding-bottom: 4rem !important;
}
.sb-show-main .rw__m-12 {
  margin: 4rem !important;
}
.sb-show-main .rw__mx-12 {
  margin-left: 4rem !important;
  margin-right: 4rem !important;
}
.sb-show-main .rw__my-12 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}
.sb-show-main .rw__mt-12 {
  margin-top: 4rem !important;
}
.sb-show-main .rw__mb-12 {
  margin-bottom: 4rem !important;
}
.sb-show-main .rw__ps-12 {
  padding-left: 4rem !important;
}
.sb-show-main .rw__pe-12 {
  padding-right: 4rem !important;
}
.sb-show-main .rw__ms-12 {
  margin-left: 4rem !important;
}
.sb-show-main .rw__me-12 {
  margin-right: 4rem !important;
}

@media (min-width: 1366px) {
  .sb-show-main .ltr .rw__m-lg-12,
  .sb-show-main .rtl .rw__m-lg-12,
  html[dir=rtl] .ltr .rw__m-lg-12,
  html[dir=rtl] .rtl .rw__m-lg-12,
  html[dir=ltr] .ltr .rw__m-lg-12,
  html[dir=ltr] .rtl .rw__m-lg-12 {
    margin: 4rem !important;
  }
  .sb-show-main .ltr .rw__p-lg-12,
  .sb-show-main .rtl .rw__p-lg-12,
  html[dir=rtl] .ltr .rw__p-lg-12,
  html[dir=rtl] .rtl .rw__p-lg-12,
  html[dir=ltr] .ltr .rw__p-lg-12,
  html[dir=ltr] .rtl .rw__p-lg-12 {
    padding: 4rem !important;
  }
  .sb-show-main .ltr .rw__mx-lg-12,
  .sb-show-main .rtl .rw__mx-lg-12,
  html[dir=rtl] .ltr .rw__mx-lg-12,
  html[dir=rtl] .rtl .rw__mx-lg-12,
  html[dir=ltr] .ltr .rw__mx-lg-12,
  html[dir=ltr] .rtl .rw__mx-lg-12 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }
  .sb-show-main .ltr .rw__my-lg-12,
  .sb-show-main .rtl .rw__my-lg-12,
  html[dir=rtl] .ltr .rw__my-lg-12,
  html[dir=rtl] .rtl .rw__my-lg-12,
  html[dir=ltr] .ltr .rw__my-lg-12,
  html[dir=ltr] .rtl .rw__my-lg-12 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .sb-show-main .ltr .rw__mt-lg-12,
  .sb-show-main .rtl .rw__mt-lg-12,
  html[dir=rtl] .ltr .rw__mt-lg-12,
  html[dir=rtl] .rtl .rw__mt-lg-12,
  html[dir=ltr] .ltr .rw__mt-lg-12,
  html[dir=ltr] .rtl .rw__mt-lg-12 {
    margin-top: 4rem !important;
  }
  .sb-show-main .ltr .rw__mb-lg-12,
  .sb-show-main .rtl .rw__mb-lg-12,
  html[dir=rtl] .ltr .rw__mb-lg-12,
  html[dir=rtl] .rtl .rw__mb-lg-12,
  html[dir=ltr] .ltr .rw__mb-lg-12,
  html[dir=ltr] .rtl .rw__mb-lg-12 {
    margin-bottom: 4rem !important;
  }
  .sb-show-main .ltr .rw__px-lg-12,
  .sb-show-main .rtl .rw__px-lg-12,
  html[dir=rtl] .ltr .rw__px-lg-12,
  html[dir=rtl] .rtl .rw__px-lg-12,
  html[dir=ltr] .ltr .rw__px-lg-12,
  html[dir=ltr] .rtl .rw__px-lg-12 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }
  .sb-show-main .ltr .rw__py-lg-12,
  .sb-show-main .rtl .rw__py-lg-12,
  html[dir=rtl] .ltr .rw__py-lg-12,
  html[dir=rtl] .rtl .rw__py-lg-12,
  html[dir=ltr] .ltr .rw__py-lg-12,
  html[dir=ltr] .rtl .rw__py-lg-12 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .sb-show-main .ltr .rw__pt-lg-12,
  .sb-show-main .rtl .rw__pt-lg-12,
  html[dir=rtl] .ltr .rw__pt-lg-12,
  html[dir=rtl] .rtl .rw__pt-lg-12,
  html[dir=ltr] .ltr .rw__pt-lg-12,
  html[dir=ltr] .rtl .rw__pt-lg-12 {
    padding-top: 4rem !important;
  }
  .sb-show-main .ltr .rw__pb-lg-12,
  .sb-show-main .rtl .rw__pb-lg-12,
  html[dir=rtl] .ltr .rw__pb-lg-12,
  html[dir=rtl] .rtl .rw__pb-lg-12,
  html[dir=ltr] .ltr .rw__pb-lg-12,
  html[dir=ltr] .rtl .rw__pb-lg-12 {
    padding-bottom: 4rem !important;
  }
  .sb-show-main .ltr .rw__ms-lg-12,
  html[dir=rtl] .ltr .rw__ms-lg-12,
  html[dir=ltr] .ltr .rw__ms-lg-12 {
    margin-left: 4rem !important;
  }
  .sb-show-main .ltr .rw__me-lg-12,
  html[dir=rtl] .ltr .rw__me-lg-12,
  html[dir=ltr] .ltr .rw__me-lg-12 {
    margin-right: 4rem !important;
  }
  .sb-show-main .ltr .rw__ps-lg-12,
  html[dir=rtl] .ltr .rw__ps-lg-12,
  html[dir=ltr] .ltr .rw__ps-lg-12 {
    padding-left: 4rem !important;
  }
  .sb-show-main .ltr .rw__pe-lg-12,
  html[dir=rtl] .ltr .rw__pe-lg-12,
  html[dir=ltr] .ltr .rw__pe-lg-12 {
    padding-right: 4rem !important;
  }
  .sb-show-main .rtl .rw__ms-lg-12,
  html[dir=rtl] .rtl .rw__ms-lg-12,
  html[dir=ltr] .rtl .rw__ms-lg-12 {
    margin-right: 4rem !important;
  }
  .sb-show-main .rtl .rw__me-lg-12,
  html[dir=rtl] .rtl .rw__me-lg-12,
  html[dir=ltr] .rtl .rw__me-lg-12 {
    margin-left: 4rem !important;
  }
  .sb-show-main .rtl .rw__ps-lg-12,
  html[dir=rtl] .rtl .rw__ps-lg-12,
  html[dir=ltr] .rtl .rw__ps-lg-12 {
    padding-right: 4rem !important;
  }
  .sb-show-main .rtl .rw__pe-lg-12,
  html[dir=rtl] .rtl .rw__pe-lg-12,
  html[dir=ltr] .rtl .rw__pe-lg-12 {
    padding-left: 4rem !important;
  }
}
@media (min-width: 960px) {
  .sb-show-main .ltr .rw__m-md-12,
  .sb-show-main .rtl .rw__m-md-12,
  html[dir=rtl] .ltr .rw__m-md-12,
  html[dir=rtl] .rtl .rw__m-md-12,
  html[dir=ltr] .ltr .rw__m-md-12,
  html[dir=ltr] .rtl .rw__m-md-12 {
    margin: 4rem !important;
  }
  .sb-show-main .ltr .rw__p-md-12,
  .sb-show-main .rtl .rw__p-md-12,
  html[dir=rtl] .ltr .rw__p-md-12,
  html[dir=rtl] .rtl .rw__p-md-12,
  html[dir=ltr] .ltr .rw__p-md-12,
  html[dir=ltr] .rtl .rw__p-md-12 {
    padding: 4rem !important;
  }
  .sb-show-main .ltr .rw__mx-md-12,
  .sb-show-main .rtl .rw__mx-md-12,
  html[dir=rtl] .ltr .rw__mx-md-12,
  html[dir=rtl] .rtl .rw__mx-md-12,
  html[dir=ltr] .ltr .rw__mx-md-12,
  html[dir=ltr] .rtl .rw__mx-md-12 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }
  .sb-show-main .ltr .rw__my-md-12,
  .sb-show-main .rtl .rw__my-md-12,
  html[dir=rtl] .ltr .rw__my-md-12,
  html[dir=rtl] .rtl .rw__my-md-12,
  html[dir=ltr] .ltr .rw__my-md-12,
  html[dir=ltr] .rtl .rw__my-md-12 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .sb-show-main .ltr .rw__mt-md-12,
  .sb-show-main .rtl .rw__mt-md-12,
  html[dir=rtl] .ltr .rw__mt-md-12,
  html[dir=rtl] .rtl .rw__mt-md-12,
  html[dir=ltr] .ltr .rw__mt-md-12,
  html[dir=ltr] .rtl .rw__mt-md-12 {
    margin-top: 4rem !important;
  }
  .sb-show-main .ltr .rw__mb-md-12,
  .sb-show-main .rtl .rw__mb-md-12,
  html[dir=rtl] .ltr .rw__mb-md-12,
  html[dir=rtl] .rtl .rw__mb-md-12,
  html[dir=ltr] .ltr .rw__mb-md-12,
  html[dir=ltr] .rtl .rw__mb-md-12 {
    margin-bottom: 4rem !important;
  }
  .sb-show-main .ltr .rw__px-md-12,
  .sb-show-main .rtl .rw__px-md-12,
  html[dir=rtl] .ltr .rw__px-md-12,
  html[dir=rtl] .rtl .rw__px-md-12,
  html[dir=ltr] .ltr .rw__px-md-12,
  html[dir=ltr] .rtl .rw__px-md-12 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }
  .sb-show-main .ltr .rw__py-md-12,
  .sb-show-main .rtl .rw__py-md-12,
  html[dir=rtl] .ltr .rw__py-md-12,
  html[dir=rtl] .rtl .rw__py-md-12,
  html[dir=ltr] .ltr .rw__py-md-12,
  html[dir=ltr] .rtl .rw__py-md-12 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .sb-show-main .ltr .rw__pt-md-12,
  .sb-show-main .rtl .rw__pt-md-12,
  html[dir=rtl] .ltr .rw__pt-md-12,
  html[dir=rtl] .rtl .rw__pt-md-12,
  html[dir=ltr] .ltr .rw__pt-md-12,
  html[dir=ltr] .rtl .rw__pt-md-12 {
    padding-top: 4rem !important;
  }
  .sb-show-main .ltr .rw__pb-md-12,
  .sb-show-main .rtl .rw__pb-md-12,
  html[dir=rtl] .ltr .rw__pb-md-12,
  html[dir=rtl] .rtl .rw__pb-md-12,
  html[dir=ltr] .ltr .rw__pb-md-12,
  html[dir=ltr] .rtl .rw__pb-md-12 {
    padding-bottom: 4rem !important;
  }
  .sb-show-main .ltr .rw__ms-md-12,
  html[dir=rtl] .ltr .rw__ms-md-12,
  html[dir=ltr] .ltr .rw__ms-md-12 {
    margin-left: 4rem !important;
  }
  .sb-show-main .ltr .rw__me-md-12,
  html[dir=rtl] .ltr .rw__me-md-12,
  html[dir=ltr] .ltr .rw__me-md-12 {
    margin-right: 4rem !important;
  }
  .sb-show-main .ltr .rw__ps-md-12,
  html[dir=rtl] .ltr .rw__ps-md-12,
  html[dir=ltr] .ltr .rw__ps-md-12 {
    padding-left: 4rem !important;
  }
  .sb-show-main .ltr .rw__pe-md-12,
  html[dir=rtl] .ltr .rw__pe-md-12,
  html[dir=ltr] .ltr .rw__pe-md-12 {
    padding-right: 4rem !important;
  }
  .sb-show-main .rtl .rw__ms-md-12,
  html[dir=rtl] .rtl .rw__ms-md-12,
  html[dir=ltr] .rtl .rw__ms-md-12 {
    margin-right: 4rem !important;
  }
  .sb-show-main .rtl .rw__me-md-12,
  html[dir=rtl] .rtl .rw__me-md-12,
  html[dir=ltr] .rtl .rw__me-md-12 {
    margin-left: 4rem !important;
  }
  .sb-show-main .rtl .rw__ps-md-12,
  html[dir=rtl] .rtl .rw__ps-md-12,
  html[dir=ltr] .rtl .rw__ps-md-12 {
    padding-right: 4rem !important;
  }
  .sb-show-main .rtl .rw__pe-md-12,
  html[dir=rtl] .rtl .rw__pe-md-12,
  html[dir=ltr] .rtl .rw__pe-md-12 {
    padding-left: 4rem !important;
  }
}
@media (min-width: 640px) {
  html[dir=rtl] .ltr .rw__ms-sm-12,
  html[dir=ltr] .ltr .rw__ms-sm-12 {
    margin-left: 4rem !important;
  }
  html[dir=rtl] .rtl .rw__ms-sm-12,
  html[dir=ltr] .rtl .rw__ms-sm-12 {
    margin-right: 4rem !important;
  }
}
html[dir=rtl] .rw__p-13,
html[dir=ltr] .rw__p-13 {
  padding: 5rem !important;
}
html[dir=rtl] .rw__px-13,
html[dir=ltr] .rw__px-13 {
  padding-left: 5rem !important;
  padding-right: 5rem !important;
}
html[dir=rtl] .rw__py-13,
html[dir=ltr] .rw__py-13 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}
html[dir=rtl] .rw__pt-13,
html[dir=ltr] .rw__pt-13 {
  padding-top: 5rem !important;
}
html[dir=rtl] .rw__pb-13,
html[dir=ltr] .rw__pb-13 {
  padding-bottom: 5rem !important;
}
html[dir=rtl] .rw__m-13,
html[dir=ltr] .rw__m-13 {
  margin: 5rem !important;
}
html[dir=rtl] .rw__mx-13,
html[dir=ltr] .rw__mx-13 {
  margin-left: 5rem !important;
  margin-right: 5rem !important;
}
html[dir=rtl] .rw__my-13,
html[dir=ltr] .rw__my-13 {
  margin-top: 5rem !important;
  margin-bottom: 5rem !important;
}
html[dir=rtl] .rw__mt-13,
html[dir=ltr] .rw__mt-13 {
  margin-top: 5rem !important;
}
html[dir=rtl] .rw__mb-13,
html[dir=ltr] .rw__mb-13 {
  margin-bottom: 5rem !important;
}
html[dir=rtl] .ltr .rw__ps-13,
html[dir=ltr] .ltr .rw__ps-13 {
  padding-left: 5rem !important;
}
html[dir=rtl] .ltr .rw__pe-13,
html[dir=ltr] .ltr .rw__pe-13 {
  padding-right: 5rem !important;
}
html[dir=rtl] .ltr .rw__ms-13,
html[dir=ltr] .ltr .rw__ms-13 {
  margin-left: 5rem !important;
}
html[dir=rtl] .ltr .rw__me-13,
html[dir=ltr] .ltr .rw__me-13 {
  margin-right: 5rem !important;
}
html[dir=rtl] .rtl .rw__ps-13,
html[dir=ltr] .rtl .rw__ps-13 {
  padding-right: 5rem !important;
}
html[dir=rtl] .rtl .rw__pe-13,
html[dir=ltr] .rtl .rw__pe-13 {
  padding-left: 5rem !important;
}
html[dir=rtl] .rtl .rw__ms-13,
html[dir=ltr] .rtl .rw__ms-13 {
  margin-right: 5rem !important;
}
html[dir=rtl] .rtl .rw__me-13,
html[dir=ltr] .rtl .rw__me-13 {
  margin-left: 5rem !important;
}

.sb-show-main .rw__p-13 {
  padding: 5rem !important;
}
.sb-show-main .rw__px-13 {
  padding-left: 5rem !important;
  padding-right: 5rem !important;
}
.sb-show-main .rw__py-13 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}
.sb-show-main .rw__pt-13 {
  padding-top: 5rem !important;
}
.sb-show-main .rw__pb-13 {
  padding-bottom: 5rem !important;
}
.sb-show-main .rw__m-13 {
  margin: 5rem !important;
}
.sb-show-main .rw__mx-13 {
  margin-left: 5rem !important;
  margin-right: 5rem !important;
}
.sb-show-main .rw__my-13 {
  margin-top: 5rem !important;
  margin-bottom: 5rem !important;
}
.sb-show-main .rw__mt-13 {
  margin-top: 5rem !important;
}
.sb-show-main .rw__mb-13 {
  margin-bottom: 5rem !important;
}
.sb-show-main .rw__ps-13 {
  padding-left: 5rem !important;
}
.sb-show-main .rw__pe-13 {
  padding-right: 5rem !important;
}
.sb-show-main .rw__ms-13 {
  margin-left: 5rem !important;
}
.sb-show-main .rw__me-13 {
  margin-right: 5rem !important;
}

@media (min-width: 1366px) {
  .sb-show-main .ltr .rw__m-lg-13,
  .sb-show-main .rtl .rw__m-lg-13,
  html[dir=rtl] .ltr .rw__m-lg-13,
  html[dir=rtl] .rtl .rw__m-lg-13,
  html[dir=ltr] .ltr .rw__m-lg-13,
  html[dir=ltr] .rtl .rw__m-lg-13 {
    margin: 5rem !important;
  }
  .sb-show-main .ltr .rw__p-lg-13,
  .sb-show-main .rtl .rw__p-lg-13,
  html[dir=rtl] .ltr .rw__p-lg-13,
  html[dir=rtl] .rtl .rw__p-lg-13,
  html[dir=ltr] .ltr .rw__p-lg-13,
  html[dir=ltr] .rtl .rw__p-lg-13 {
    padding: 5rem !important;
  }
  .sb-show-main .ltr .rw__mx-lg-13,
  .sb-show-main .rtl .rw__mx-lg-13,
  html[dir=rtl] .ltr .rw__mx-lg-13,
  html[dir=rtl] .rtl .rw__mx-lg-13,
  html[dir=ltr] .ltr .rw__mx-lg-13,
  html[dir=ltr] .rtl .rw__mx-lg-13 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }
  .sb-show-main .ltr .rw__my-lg-13,
  .sb-show-main .rtl .rw__my-lg-13,
  html[dir=rtl] .ltr .rw__my-lg-13,
  html[dir=rtl] .rtl .rw__my-lg-13,
  html[dir=ltr] .ltr .rw__my-lg-13,
  html[dir=ltr] .rtl .rw__my-lg-13 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .sb-show-main .ltr .rw__mt-lg-13,
  .sb-show-main .rtl .rw__mt-lg-13,
  html[dir=rtl] .ltr .rw__mt-lg-13,
  html[dir=rtl] .rtl .rw__mt-lg-13,
  html[dir=ltr] .ltr .rw__mt-lg-13,
  html[dir=ltr] .rtl .rw__mt-lg-13 {
    margin-top: 5rem !important;
  }
  .sb-show-main .ltr .rw__mb-lg-13,
  .sb-show-main .rtl .rw__mb-lg-13,
  html[dir=rtl] .ltr .rw__mb-lg-13,
  html[dir=rtl] .rtl .rw__mb-lg-13,
  html[dir=ltr] .ltr .rw__mb-lg-13,
  html[dir=ltr] .rtl .rw__mb-lg-13 {
    margin-bottom: 5rem !important;
  }
  .sb-show-main .ltr .rw__px-lg-13,
  .sb-show-main .rtl .rw__px-lg-13,
  html[dir=rtl] .ltr .rw__px-lg-13,
  html[dir=rtl] .rtl .rw__px-lg-13,
  html[dir=ltr] .ltr .rw__px-lg-13,
  html[dir=ltr] .rtl .rw__px-lg-13 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }
  .sb-show-main .ltr .rw__py-lg-13,
  .sb-show-main .rtl .rw__py-lg-13,
  html[dir=rtl] .ltr .rw__py-lg-13,
  html[dir=rtl] .rtl .rw__py-lg-13,
  html[dir=ltr] .ltr .rw__py-lg-13,
  html[dir=ltr] .rtl .rw__py-lg-13 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .sb-show-main .ltr .rw__pt-lg-13,
  .sb-show-main .rtl .rw__pt-lg-13,
  html[dir=rtl] .ltr .rw__pt-lg-13,
  html[dir=rtl] .rtl .rw__pt-lg-13,
  html[dir=ltr] .ltr .rw__pt-lg-13,
  html[dir=ltr] .rtl .rw__pt-lg-13 {
    padding-top: 5rem !important;
  }
  .sb-show-main .ltr .rw__pb-lg-13,
  .sb-show-main .rtl .rw__pb-lg-13,
  html[dir=rtl] .ltr .rw__pb-lg-13,
  html[dir=rtl] .rtl .rw__pb-lg-13,
  html[dir=ltr] .ltr .rw__pb-lg-13,
  html[dir=ltr] .rtl .rw__pb-lg-13 {
    padding-bottom: 5rem !important;
  }
  .sb-show-main .ltr .rw__ms-lg-13,
  html[dir=rtl] .ltr .rw__ms-lg-13,
  html[dir=ltr] .ltr .rw__ms-lg-13 {
    margin-left: 5rem !important;
  }
  .sb-show-main .ltr .rw__me-lg-13,
  html[dir=rtl] .ltr .rw__me-lg-13,
  html[dir=ltr] .ltr .rw__me-lg-13 {
    margin-right: 5rem !important;
  }
  .sb-show-main .ltr .rw__ps-lg-13,
  html[dir=rtl] .ltr .rw__ps-lg-13,
  html[dir=ltr] .ltr .rw__ps-lg-13 {
    padding-left: 5rem !important;
  }
  .sb-show-main .ltr .rw__pe-lg-13,
  html[dir=rtl] .ltr .rw__pe-lg-13,
  html[dir=ltr] .ltr .rw__pe-lg-13 {
    padding-right: 5rem !important;
  }
  .sb-show-main .rtl .rw__ms-lg-13,
  html[dir=rtl] .rtl .rw__ms-lg-13,
  html[dir=ltr] .rtl .rw__ms-lg-13 {
    margin-right: 5rem !important;
  }
  .sb-show-main .rtl .rw__me-lg-13,
  html[dir=rtl] .rtl .rw__me-lg-13,
  html[dir=ltr] .rtl .rw__me-lg-13 {
    margin-left: 5rem !important;
  }
  .sb-show-main .rtl .rw__ps-lg-13,
  html[dir=rtl] .rtl .rw__ps-lg-13,
  html[dir=ltr] .rtl .rw__ps-lg-13 {
    padding-right: 5rem !important;
  }
  .sb-show-main .rtl .rw__pe-lg-13,
  html[dir=rtl] .rtl .rw__pe-lg-13,
  html[dir=ltr] .rtl .rw__pe-lg-13 {
    padding-left: 5rem !important;
  }
}
@media (min-width: 960px) {
  .sb-show-main .ltr .rw__m-md-13,
  .sb-show-main .rtl .rw__m-md-13,
  html[dir=rtl] .ltr .rw__m-md-13,
  html[dir=rtl] .rtl .rw__m-md-13,
  html[dir=ltr] .ltr .rw__m-md-13,
  html[dir=ltr] .rtl .rw__m-md-13 {
    margin: 5rem !important;
  }
  .sb-show-main .ltr .rw__p-md-13,
  .sb-show-main .rtl .rw__p-md-13,
  html[dir=rtl] .ltr .rw__p-md-13,
  html[dir=rtl] .rtl .rw__p-md-13,
  html[dir=ltr] .ltr .rw__p-md-13,
  html[dir=ltr] .rtl .rw__p-md-13 {
    padding: 5rem !important;
  }
  .sb-show-main .ltr .rw__mx-md-13,
  .sb-show-main .rtl .rw__mx-md-13,
  html[dir=rtl] .ltr .rw__mx-md-13,
  html[dir=rtl] .rtl .rw__mx-md-13,
  html[dir=ltr] .ltr .rw__mx-md-13,
  html[dir=ltr] .rtl .rw__mx-md-13 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }
  .sb-show-main .ltr .rw__my-md-13,
  .sb-show-main .rtl .rw__my-md-13,
  html[dir=rtl] .ltr .rw__my-md-13,
  html[dir=rtl] .rtl .rw__my-md-13,
  html[dir=ltr] .ltr .rw__my-md-13,
  html[dir=ltr] .rtl .rw__my-md-13 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .sb-show-main .ltr .rw__mt-md-13,
  .sb-show-main .rtl .rw__mt-md-13,
  html[dir=rtl] .ltr .rw__mt-md-13,
  html[dir=rtl] .rtl .rw__mt-md-13,
  html[dir=ltr] .ltr .rw__mt-md-13,
  html[dir=ltr] .rtl .rw__mt-md-13 {
    margin-top: 5rem !important;
  }
  .sb-show-main .ltr .rw__mb-md-13,
  .sb-show-main .rtl .rw__mb-md-13,
  html[dir=rtl] .ltr .rw__mb-md-13,
  html[dir=rtl] .rtl .rw__mb-md-13,
  html[dir=ltr] .ltr .rw__mb-md-13,
  html[dir=ltr] .rtl .rw__mb-md-13 {
    margin-bottom: 5rem !important;
  }
  .sb-show-main .ltr .rw__px-md-13,
  .sb-show-main .rtl .rw__px-md-13,
  html[dir=rtl] .ltr .rw__px-md-13,
  html[dir=rtl] .rtl .rw__px-md-13,
  html[dir=ltr] .ltr .rw__px-md-13,
  html[dir=ltr] .rtl .rw__px-md-13 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }
  .sb-show-main .ltr .rw__py-md-13,
  .sb-show-main .rtl .rw__py-md-13,
  html[dir=rtl] .ltr .rw__py-md-13,
  html[dir=rtl] .rtl .rw__py-md-13,
  html[dir=ltr] .ltr .rw__py-md-13,
  html[dir=ltr] .rtl .rw__py-md-13 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .sb-show-main .ltr .rw__pt-md-13,
  .sb-show-main .rtl .rw__pt-md-13,
  html[dir=rtl] .ltr .rw__pt-md-13,
  html[dir=rtl] .rtl .rw__pt-md-13,
  html[dir=ltr] .ltr .rw__pt-md-13,
  html[dir=ltr] .rtl .rw__pt-md-13 {
    padding-top: 5rem !important;
  }
  .sb-show-main .ltr .rw__pb-md-13,
  .sb-show-main .rtl .rw__pb-md-13,
  html[dir=rtl] .ltr .rw__pb-md-13,
  html[dir=rtl] .rtl .rw__pb-md-13,
  html[dir=ltr] .ltr .rw__pb-md-13,
  html[dir=ltr] .rtl .rw__pb-md-13 {
    padding-bottom: 5rem !important;
  }
  .sb-show-main .ltr .rw__ms-md-13,
  html[dir=rtl] .ltr .rw__ms-md-13,
  html[dir=ltr] .ltr .rw__ms-md-13 {
    margin-left: 5rem !important;
  }
  .sb-show-main .ltr .rw__me-md-13,
  html[dir=rtl] .ltr .rw__me-md-13,
  html[dir=ltr] .ltr .rw__me-md-13 {
    margin-right: 5rem !important;
  }
  .sb-show-main .ltr .rw__ps-md-13,
  html[dir=rtl] .ltr .rw__ps-md-13,
  html[dir=ltr] .ltr .rw__ps-md-13 {
    padding-left: 5rem !important;
  }
  .sb-show-main .ltr .rw__pe-md-13,
  html[dir=rtl] .ltr .rw__pe-md-13,
  html[dir=ltr] .ltr .rw__pe-md-13 {
    padding-right: 5rem !important;
  }
  .sb-show-main .rtl .rw__ms-md-13,
  html[dir=rtl] .rtl .rw__ms-md-13,
  html[dir=ltr] .rtl .rw__ms-md-13 {
    margin-right: 5rem !important;
  }
  .sb-show-main .rtl .rw__me-md-13,
  html[dir=rtl] .rtl .rw__me-md-13,
  html[dir=ltr] .rtl .rw__me-md-13 {
    margin-left: 5rem !important;
  }
  .sb-show-main .rtl .rw__ps-md-13,
  html[dir=rtl] .rtl .rw__ps-md-13,
  html[dir=ltr] .rtl .rw__ps-md-13 {
    padding-right: 5rem !important;
  }
  .sb-show-main .rtl .rw__pe-md-13,
  html[dir=rtl] .rtl .rw__pe-md-13,
  html[dir=ltr] .rtl .rw__pe-md-13 {
    padding-left: 5rem !important;
  }
}
@media (min-width: 640px) {
  html[dir=rtl] .ltr .rw__ms-sm-13,
  html[dir=ltr] .ltr .rw__ms-sm-13 {
    margin-left: 5rem !important;
  }
  html[dir=rtl] .rtl .rw__ms-sm-13,
  html[dir=ltr] .rtl .rw__ms-sm-13 {
    margin-right: 5rem !important;
  }
}
html[dir=rtl] .rw__p-14,
html[dir=ltr] .rw__p-14 {
  padding: 6rem !important;
}
html[dir=rtl] .rw__px-14,
html[dir=ltr] .rw__px-14 {
  padding-left: 6rem !important;
  padding-right: 6rem !important;
}
html[dir=rtl] .rw__py-14,
html[dir=ltr] .rw__py-14 {
  padding-top: 6rem !important;
  padding-bottom: 6rem !important;
}
html[dir=rtl] .rw__pt-14,
html[dir=ltr] .rw__pt-14 {
  padding-top: 6rem !important;
}
html[dir=rtl] .rw__pb-14,
html[dir=ltr] .rw__pb-14 {
  padding-bottom: 6rem !important;
}
html[dir=rtl] .rw__m-14,
html[dir=ltr] .rw__m-14 {
  margin: 6rem !important;
}
html[dir=rtl] .rw__mx-14,
html[dir=ltr] .rw__mx-14 {
  margin-left: 6rem !important;
  margin-right: 6rem !important;
}
html[dir=rtl] .rw__my-14,
html[dir=ltr] .rw__my-14 {
  margin-top: 6rem !important;
  margin-bottom: 6rem !important;
}
html[dir=rtl] .rw__mt-14,
html[dir=ltr] .rw__mt-14 {
  margin-top: 6rem !important;
}
html[dir=rtl] .rw__mb-14,
html[dir=ltr] .rw__mb-14 {
  margin-bottom: 6rem !important;
}
html[dir=rtl] .ltr .rw__ps-14,
html[dir=ltr] .ltr .rw__ps-14 {
  padding-left: 6rem !important;
}
html[dir=rtl] .ltr .rw__pe-14,
html[dir=ltr] .ltr .rw__pe-14 {
  padding-right: 6rem !important;
}
html[dir=rtl] .ltr .rw__ms-14,
html[dir=ltr] .ltr .rw__ms-14 {
  margin-left: 6rem !important;
}
html[dir=rtl] .ltr .rw__me-14,
html[dir=ltr] .ltr .rw__me-14 {
  margin-right: 6rem !important;
}
html[dir=rtl] .rtl .rw__ps-14,
html[dir=ltr] .rtl .rw__ps-14 {
  padding-right: 6rem !important;
}
html[dir=rtl] .rtl .rw__pe-14,
html[dir=ltr] .rtl .rw__pe-14 {
  padding-left: 6rem !important;
}
html[dir=rtl] .rtl .rw__ms-14,
html[dir=ltr] .rtl .rw__ms-14 {
  margin-right: 6rem !important;
}
html[dir=rtl] .rtl .rw__me-14,
html[dir=ltr] .rtl .rw__me-14 {
  margin-left: 6rem !important;
}

.sb-show-main .rw__p-14 {
  padding: 6rem !important;
}
.sb-show-main .rw__px-14 {
  padding-left: 6rem !important;
  padding-right: 6rem !important;
}
.sb-show-main .rw__py-14 {
  padding-top: 6rem !important;
  padding-bottom: 6rem !important;
}
.sb-show-main .rw__pt-14 {
  padding-top: 6rem !important;
}
.sb-show-main .rw__pb-14 {
  padding-bottom: 6rem !important;
}
.sb-show-main .rw__m-14 {
  margin: 6rem !important;
}
.sb-show-main .rw__mx-14 {
  margin-left: 6rem !important;
  margin-right: 6rem !important;
}
.sb-show-main .rw__my-14 {
  margin-top: 6rem !important;
  margin-bottom: 6rem !important;
}
.sb-show-main .rw__mt-14 {
  margin-top: 6rem !important;
}
.sb-show-main .rw__mb-14 {
  margin-bottom: 6rem !important;
}
.sb-show-main .rw__ps-14 {
  padding-left: 6rem !important;
}
.sb-show-main .rw__pe-14 {
  padding-right: 6rem !important;
}
.sb-show-main .rw__ms-14 {
  margin-left: 6rem !important;
}
.sb-show-main .rw__me-14 {
  margin-right: 6rem !important;
}

@media (min-width: 1366px) {
  .sb-show-main .ltr .rw__m-lg-14,
  .sb-show-main .rtl .rw__m-lg-14,
  html[dir=rtl] .ltr .rw__m-lg-14,
  html[dir=rtl] .rtl .rw__m-lg-14,
  html[dir=ltr] .ltr .rw__m-lg-14,
  html[dir=ltr] .rtl .rw__m-lg-14 {
    margin: 6rem !important;
  }
  .sb-show-main .ltr .rw__p-lg-14,
  .sb-show-main .rtl .rw__p-lg-14,
  html[dir=rtl] .ltr .rw__p-lg-14,
  html[dir=rtl] .rtl .rw__p-lg-14,
  html[dir=ltr] .ltr .rw__p-lg-14,
  html[dir=ltr] .rtl .rw__p-lg-14 {
    padding: 6rem !important;
  }
  .sb-show-main .ltr .rw__mx-lg-14,
  .sb-show-main .rtl .rw__mx-lg-14,
  html[dir=rtl] .ltr .rw__mx-lg-14,
  html[dir=rtl] .rtl .rw__mx-lg-14,
  html[dir=ltr] .ltr .rw__mx-lg-14,
  html[dir=ltr] .rtl .rw__mx-lg-14 {
    margin-left: 6rem !important;
    margin-right: 6rem !important;
  }
  .sb-show-main .ltr .rw__my-lg-14,
  .sb-show-main .rtl .rw__my-lg-14,
  html[dir=rtl] .ltr .rw__my-lg-14,
  html[dir=rtl] .rtl .rw__my-lg-14,
  html[dir=ltr] .ltr .rw__my-lg-14,
  html[dir=ltr] .rtl .rw__my-lg-14 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .sb-show-main .ltr .rw__mt-lg-14,
  .sb-show-main .rtl .rw__mt-lg-14,
  html[dir=rtl] .ltr .rw__mt-lg-14,
  html[dir=rtl] .rtl .rw__mt-lg-14,
  html[dir=ltr] .ltr .rw__mt-lg-14,
  html[dir=ltr] .rtl .rw__mt-lg-14 {
    margin-top: 6rem !important;
  }
  .sb-show-main .ltr .rw__mb-lg-14,
  .sb-show-main .rtl .rw__mb-lg-14,
  html[dir=rtl] .ltr .rw__mb-lg-14,
  html[dir=rtl] .rtl .rw__mb-lg-14,
  html[dir=ltr] .ltr .rw__mb-lg-14,
  html[dir=ltr] .rtl .rw__mb-lg-14 {
    margin-bottom: 6rem !important;
  }
  .sb-show-main .ltr .rw__px-lg-14,
  .sb-show-main .rtl .rw__px-lg-14,
  html[dir=rtl] .ltr .rw__px-lg-14,
  html[dir=rtl] .rtl .rw__px-lg-14,
  html[dir=ltr] .ltr .rw__px-lg-14,
  html[dir=ltr] .rtl .rw__px-lg-14 {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }
  .sb-show-main .ltr .rw__py-lg-14,
  .sb-show-main .rtl .rw__py-lg-14,
  html[dir=rtl] .ltr .rw__py-lg-14,
  html[dir=rtl] .rtl .rw__py-lg-14,
  html[dir=ltr] .ltr .rw__py-lg-14,
  html[dir=ltr] .rtl .rw__py-lg-14 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .sb-show-main .ltr .rw__pt-lg-14,
  .sb-show-main .rtl .rw__pt-lg-14,
  html[dir=rtl] .ltr .rw__pt-lg-14,
  html[dir=rtl] .rtl .rw__pt-lg-14,
  html[dir=ltr] .ltr .rw__pt-lg-14,
  html[dir=ltr] .rtl .rw__pt-lg-14 {
    padding-top: 6rem !important;
  }
  .sb-show-main .ltr .rw__pb-lg-14,
  .sb-show-main .rtl .rw__pb-lg-14,
  html[dir=rtl] .ltr .rw__pb-lg-14,
  html[dir=rtl] .rtl .rw__pb-lg-14,
  html[dir=ltr] .ltr .rw__pb-lg-14,
  html[dir=ltr] .rtl .rw__pb-lg-14 {
    padding-bottom: 6rem !important;
  }
  .sb-show-main .ltr .rw__ms-lg-14,
  html[dir=rtl] .ltr .rw__ms-lg-14,
  html[dir=ltr] .ltr .rw__ms-lg-14 {
    margin-left: 6rem !important;
  }
  .sb-show-main .ltr .rw__me-lg-14,
  html[dir=rtl] .ltr .rw__me-lg-14,
  html[dir=ltr] .ltr .rw__me-lg-14 {
    margin-right: 6rem !important;
  }
  .sb-show-main .ltr .rw__ps-lg-14,
  html[dir=rtl] .ltr .rw__ps-lg-14,
  html[dir=ltr] .ltr .rw__ps-lg-14 {
    padding-left: 6rem !important;
  }
  .sb-show-main .ltr .rw__pe-lg-14,
  html[dir=rtl] .ltr .rw__pe-lg-14,
  html[dir=ltr] .ltr .rw__pe-lg-14 {
    padding-right: 6rem !important;
  }
  .sb-show-main .rtl .rw__ms-lg-14,
  html[dir=rtl] .rtl .rw__ms-lg-14,
  html[dir=ltr] .rtl .rw__ms-lg-14 {
    margin-right: 6rem !important;
  }
  .sb-show-main .rtl .rw__me-lg-14,
  html[dir=rtl] .rtl .rw__me-lg-14,
  html[dir=ltr] .rtl .rw__me-lg-14 {
    margin-left: 6rem !important;
  }
  .sb-show-main .rtl .rw__ps-lg-14,
  html[dir=rtl] .rtl .rw__ps-lg-14,
  html[dir=ltr] .rtl .rw__ps-lg-14 {
    padding-right: 6rem !important;
  }
  .sb-show-main .rtl .rw__pe-lg-14,
  html[dir=rtl] .rtl .rw__pe-lg-14,
  html[dir=ltr] .rtl .rw__pe-lg-14 {
    padding-left: 6rem !important;
  }
}
@media (min-width: 960px) {
  .sb-show-main .ltr .rw__m-md-14,
  .sb-show-main .rtl .rw__m-md-14,
  html[dir=rtl] .ltr .rw__m-md-14,
  html[dir=rtl] .rtl .rw__m-md-14,
  html[dir=ltr] .ltr .rw__m-md-14,
  html[dir=ltr] .rtl .rw__m-md-14 {
    margin: 6rem !important;
  }
  .sb-show-main .ltr .rw__p-md-14,
  .sb-show-main .rtl .rw__p-md-14,
  html[dir=rtl] .ltr .rw__p-md-14,
  html[dir=rtl] .rtl .rw__p-md-14,
  html[dir=ltr] .ltr .rw__p-md-14,
  html[dir=ltr] .rtl .rw__p-md-14 {
    padding: 6rem !important;
  }
  .sb-show-main .ltr .rw__mx-md-14,
  .sb-show-main .rtl .rw__mx-md-14,
  html[dir=rtl] .ltr .rw__mx-md-14,
  html[dir=rtl] .rtl .rw__mx-md-14,
  html[dir=ltr] .ltr .rw__mx-md-14,
  html[dir=ltr] .rtl .rw__mx-md-14 {
    margin-left: 6rem !important;
    margin-right: 6rem !important;
  }
  .sb-show-main .ltr .rw__my-md-14,
  .sb-show-main .rtl .rw__my-md-14,
  html[dir=rtl] .ltr .rw__my-md-14,
  html[dir=rtl] .rtl .rw__my-md-14,
  html[dir=ltr] .ltr .rw__my-md-14,
  html[dir=ltr] .rtl .rw__my-md-14 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .sb-show-main .ltr .rw__mt-md-14,
  .sb-show-main .rtl .rw__mt-md-14,
  html[dir=rtl] .ltr .rw__mt-md-14,
  html[dir=rtl] .rtl .rw__mt-md-14,
  html[dir=ltr] .ltr .rw__mt-md-14,
  html[dir=ltr] .rtl .rw__mt-md-14 {
    margin-top: 6rem !important;
  }
  .sb-show-main .ltr .rw__mb-md-14,
  .sb-show-main .rtl .rw__mb-md-14,
  html[dir=rtl] .ltr .rw__mb-md-14,
  html[dir=rtl] .rtl .rw__mb-md-14,
  html[dir=ltr] .ltr .rw__mb-md-14,
  html[dir=ltr] .rtl .rw__mb-md-14 {
    margin-bottom: 6rem !important;
  }
  .sb-show-main .ltr .rw__px-md-14,
  .sb-show-main .rtl .rw__px-md-14,
  html[dir=rtl] .ltr .rw__px-md-14,
  html[dir=rtl] .rtl .rw__px-md-14,
  html[dir=ltr] .ltr .rw__px-md-14,
  html[dir=ltr] .rtl .rw__px-md-14 {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }
  .sb-show-main .ltr .rw__py-md-14,
  .sb-show-main .rtl .rw__py-md-14,
  html[dir=rtl] .ltr .rw__py-md-14,
  html[dir=rtl] .rtl .rw__py-md-14,
  html[dir=ltr] .ltr .rw__py-md-14,
  html[dir=ltr] .rtl .rw__py-md-14 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .sb-show-main .ltr .rw__pt-md-14,
  .sb-show-main .rtl .rw__pt-md-14,
  html[dir=rtl] .ltr .rw__pt-md-14,
  html[dir=rtl] .rtl .rw__pt-md-14,
  html[dir=ltr] .ltr .rw__pt-md-14,
  html[dir=ltr] .rtl .rw__pt-md-14 {
    padding-top: 6rem !important;
  }
  .sb-show-main .ltr .rw__pb-md-14,
  .sb-show-main .rtl .rw__pb-md-14,
  html[dir=rtl] .ltr .rw__pb-md-14,
  html[dir=rtl] .rtl .rw__pb-md-14,
  html[dir=ltr] .ltr .rw__pb-md-14,
  html[dir=ltr] .rtl .rw__pb-md-14 {
    padding-bottom: 6rem !important;
  }
  .sb-show-main .ltr .rw__ms-md-14,
  html[dir=rtl] .ltr .rw__ms-md-14,
  html[dir=ltr] .ltr .rw__ms-md-14 {
    margin-left: 6rem !important;
  }
  .sb-show-main .ltr .rw__me-md-14,
  html[dir=rtl] .ltr .rw__me-md-14,
  html[dir=ltr] .ltr .rw__me-md-14 {
    margin-right: 6rem !important;
  }
  .sb-show-main .ltr .rw__ps-md-14,
  html[dir=rtl] .ltr .rw__ps-md-14,
  html[dir=ltr] .ltr .rw__ps-md-14 {
    padding-left: 6rem !important;
  }
  .sb-show-main .ltr .rw__pe-md-14,
  html[dir=rtl] .ltr .rw__pe-md-14,
  html[dir=ltr] .ltr .rw__pe-md-14 {
    padding-right: 6rem !important;
  }
  .sb-show-main .rtl .rw__ms-md-14,
  html[dir=rtl] .rtl .rw__ms-md-14,
  html[dir=ltr] .rtl .rw__ms-md-14 {
    margin-right: 6rem !important;
  }
  .sb-show-main .rtl .rw__me-md-14,
  html[dir=rtl] .rtl .rw__me-md-14,
  html[dir=ltr] .rtl .rw__me-md-14 {
    margin-left: 6rem !important;
  }
  .sb-show-main .rtl .rw__ps-md-14,
  html[dir=rtl] .rtl .rw__ps-md-14,
  html[dir=ltr] .rtl .rw__ps-md-14 {
    padding-right: 6rem !important;
  }
  .sb-show-main .rtl .rw__pe-md-14,
  html[dir=rtl] .rtl .rw__pe-md-14,
  html[dir=ltr] .rtl .rw__pe-md-14 {
    padding-left: 6rem !important;
  }
}
@media (min-width: 640px) {
  html[dir=rtl] .ltr .rw__ms-sm-14,
  html[dir=ltr] .ltr .rw__ms-sm-14 {
    margin-left: 6rem !important;
  }
  html[dir=rtl] .rtl .rw__ms-sm-14,
  html[dir=ltr] .rtl .rw__ms-sm-14 {
    margin-right: 6rem !important;
  }
}
html[dir=rtl] .rw__p-15,
html[dir=ltr] .rw__p-15 {
  padding: 10rem !important;
}
html[dir=rtl] .rw__px-15,
html[dir=ltr] .rw__px-15 {
  padding-left: 10rem !important;
  padding-right: 10rem !important;
}
html[dir=rtl] .rw__py-15,
html[dir=ltr] .rw__py-15 {
  padding-top: 10rem !important;
  padding-bottom: 10rem !important;
}
html[dir=rtl] .rw__pt-15,
html[dir=ltr] .rw__pt-15 {
  padding-top: 10rem !important;
}
html[dir=rtl] .rw__pb-15,
html[dir=ltr] .rw__pb-15 {
  padding-bottom: 10rem !important;
}
html[dir=rtl] .rw__m-15,
html[dir=ltr] .rw__m-15 {
  margin: 10rem !important;
}
html[dir=rtl] .rw__mx-15,
html[dir=ltr] .rw__mx-15 {
  margin-left: 10rem !important;
  margin-right: 10rem !important;
}
html[dir=rtl] .rw__my-15,
html[dir=ltr] .rw__my-15 {
  margin-top: 10rem !important;
  margin-bottom: 10rem !important;
}
html[dir=rtl] .rw__mt-15,
html[dir=ltr] .rw__mt-15 {
  margin-top: 10rem !important;
}
html[dir=rtl] .rw__mb-15,
html[dir=ltr] .rw__mb-15 {
  margin-bottom: 10rem !important;
}
html[dir=rtl] .ltr .rw__ps-15,
html[dir=ltr] .ltr .rw__ps-15 {
  padding-left: 10rem !important;
}
html[dir=rtl] .ltr .rw__pe-15,
html[dir=ltr] .ltr .rw__pe-15 {
  padding-right: 10rem !important;
}
html[dir=rtl] .ltr .rw__ms-15,
html[dir=ltr] .ltr .rw__ms-15 {
  margin-left: 10rem !important;
}
html[dir=rtl] .ltr .rw__me-15,
html[dir=ltr] .ltr .rw__me-15 {
  margin-right: 10rem !important;
}
html[dir=rtl] .rtl .rw__ps-15,
html[dir=ltr] .rtl .rw__ps-15 {
  padding-right: 10rem !important;
}
html[dir=rtl] .rtl .rw__pe-15,
html[dir=ltr] .rtl .rw__pe-15 {
  padding-left: 10rem !important;
}
html[dir=rtl] .rtl .rw__ms-15,
html[dir=ltr] .rtl .rw__ms-15 {
  margin-right: 10rem !important;
}
html[dir=rtl] .rtl .rw__me-15,
html[dir=ltr] .rtl .rw__me-15 {
  margin-left: 10rem !important;
}

.sb-show-main .rw__p-15 {
  padding: 10rem !important;
}
.sb-show-main .rw__px-15 {
  padding-left: 10rem !important;
  padding-right: 10rem !important;
}
.sb-show-main .rw__py-15 {
  padding-top: 10rem !important;
  padding-bottom: 10rem !important;
}
.sb-show-main .rw__pt-15 {
  padding-top: 10rem !important;
}
.sb-show-main .rw__pb-15 {
  padding-bottom: 10rem !important;
}
.sb-show-main .rw__m-15 {
  margin: 10rem !important;
}
.sb-show-main .rw__mx-15 {
  margin-left: 10rem !important;
  margin-right: 10rem !important;
}
.sb-show-main .rw__my-15 {
  margin-top: 10rem !important;
  margin-bottom: 10rem !important;
}
.sb-show-main .rw__mt-15 {
  margin-top: 10rem !important;
}
.sb-show-main .rw__mb-15 {
  margin-bottom: 10rem !important;
}
.sb-show-main .rw__ps-15 {
  padding-left: 10rem !important;
}
.sb-show-main .rw__pe-15 {
  padding-right: 10rem !important;
}
.sb-show-main .rw__ms-15 {
  margin-left: 10rem !important;
}
.sb-show-main .rw__me-15 {
  margin-right: 10rem !important;
}

@media (min-width: 1366px) {
  .sb-show-main .ltr .rw__m-lg-15,
  .sb-show-main .rtl .rw__m-lg-15,
  html[dir=rtl] .ltr .rw__m-lg-15,
  html[dir=rtl] .rtl .rw__m-lg-15,
  html[dir=ltr] .ltr .rw__m-lg-15,
  html[dir=ltr] .rtl .rw__m-lg-15 {
    margin: 10rem !important;
  }
  .sb-show-main .ltr .rw__p-lg-15,
  .sb-show-main .rtl .rw__p-lg-15,
  html[dir=rtl] .ltr .rw__p-lg-15,
  html[dir=rtl] .rtl .rw__p-lg-15,
  html[dir=ltr] .ltr .rw__p-lg-15,
  html[dir=ltr] .rtl .rw__p-lg-15 {
    padding: 10rem !important;
  }
  .sb-show-main .ltr .rw__mx-lg-15,
  .sb-show-main .rtl .rw__mx-lg-15,
  html[dir=rtl] .ltr .rw__mx-lg-15,
  html[dir=rtl] .rtl .rw__mx-lg-15,
  html[dir=ltr] .ltr .rw__mx-lg-15,
  html[dir=ltr] .rtl .rw__mx-lg-15 {
    margin-left: 10rem !important;
    margin-right: 10rem !important;
  }
  .sb-show-main .ltr .rw__my-lg-15,
  .sb-show-main .rtl .rw__my-lg-15,
  html[dir=rtl] .ltr .rw__my-lg-15,
  html[dir=rtl] .rtl .rw__my-lg-15,
  html[dir=ltr] .ltr .rw__my-lg-15,
  html[dir=ltr] .rtl .rw__my-lg-15 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }
  .sb-show-main .ltr .rw__mt-lg-15,
  .sb-show-main .rtl .rw__mt-lg-15,
  html[dir=rtl] .ltr .rw__mt-lg-15,
  html[dir=rtl] .rtl .rw__mt-lg-15,
  html[dir=ltr] .ltr .rw__mt-lg-15,
  html[dir=ltr] .rtl .rw__mt-lg-15 {
    margin-top: 10rem !important;
  }
  .sb-show-main .ltr .rw__mb-lg-15,
  .sb-show-main .rtl .rw__mb-lg-15,
  html[dir=rtl] .ltr .rw__mb-lg-15,
  html[dir=rtl] .rtl .rw__mb-lg-15,
  html[dir=ltr] .ltr .rw__mb-lg-15,
  html[dir=ltr] .rtl .rw__mb-lg-15 {
    margin-bottom: 10rem !important;
  }
  .sb-show-main .ltr .rw__px-lg-15,
  .sb-show-main .rtl .rw__px-lg-15,
  html[dir=rtl] .ltr .rw__px-lg-15,
  html[dir=rtl] .rtl .rw__px-lg-15,
  html[dir=ltr] .ltr .rw__px-lg-15,
  html[dir=ltr] .rtl .rw__px-lg-15 {
    padding-left: 10rem !important;
    padding-right: 10rem !important;
  }
  .sb-show-main .ltr .rw__py-lg-15,
  .sb-show-main .rtl .rw__py-lg-15,
  html[dir=rtl] .ltr .rw__py-lg-15,
  html[dir=rtl] .rtl .rw__py-lg-15,
  html[dir=ltr] .ltr .rw__py-lg-15,
  html[dir=ltr] .rtl .rw__py-lg-15 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  .sb-show-main .ltr .rw__pt-lg-15,
  .sb-show-main .rtl .rw__pt-lg-15,
  html[dir=rtl] .ltr .rw__pt-lg-15,
  html[dir=rtl] .rtl .rw__pt-lg-15,
  html[dir=ltr] .ltr .rw__pt-lg-15,
  html[dir=ltr] .rtl .rw__pt-lg-15 {
    padding-top: 10rem !important;
  }
  .sb-show-main .ltr .rw__pb-lg-15,
  .sb-show-main .rtl .rw__pb-lg-15,
  html[dir=rtl] .ltr .rw__pb-lg-15,
  html[dir=rtl] .rtl .rw__pb-lg-15,
  html[dir=ltr] .ltr .rw__pb-lg-15,
  html[dir=ltr] .rtl .rw__pb-lg-15 {
    padding-bottom: 10rem !important;
  }
  .sb-show-main .ltr .rw__ms-lg-15,
  html[dir=rtl] .ltr .rw__ms-lg-15,
  html[dir=ltr] .ltr .rw__ms-lg-15 {
    margin-left: 10rem !important;
  }
  .sb-show-main .ltr .rw__me-lg-15,
  html[dir=rtl] .ltr .rw__me-lg-15,
  html[dir=ltr] .ltr .rw__me-lg-15 {
    margin-right: 10rem !important;
  }
  .sb-show-main .ltr .rw__ps-lg-15,
  html[dir=rtl] .ltr .rw__ps-lg-15,
  html[dir=ltr] .ltr .rw__ps-lg-15 {
    padding-left: 10rem !important;
  }
  .sb-show-main .ltr .rw__pe-lg-15,
  html[dir=rtl] .ltr .rw__pe-lg-15,
  html[dir=ltr] .ltr .rw__pe-lg-15 {
    padding-right: 10rem !important;
  }
  .sb-show-main .rtl .rw__ms-lg-15,
  html[dir=rtl] .rtl .rw__ms-lg-15,
  html[dir=ltr] .rtl .rw__ms-lg-15 {
    margin-right: 10rem !important;
  }
  .sb-show-main .rtl .rw__me-lg-15,
  html[dir=rtl] .rtl .rw__me-lg-15,
  html[dir=ltr] .rtl .rw__me-lg-15 {
    margin-left: 10rem !important;
  }
  .sb-show-main .rtl .rw__ps-lg-15,
  html[dir=rtl] .rtl .rw__ps-lg-15,
  html[dir=ltr] .rtl .rw__ps-lg-15 {
    padding-right: 10rem !important;
  }
  .sb-show-main .rtl .rw__pe-lg-15,
  html[dir=rtl] .rtl .rw__pe-lg-15,
  html[dir=ltr] .rtl .rw__pe-lg-15 {
    padding-left: 10rem !important;
  }
}
@media (min-width: 960px) {
  .sb-show-main .ltr .rw__m-md-15,
  .sb-show-main .rtl .rw__m-md-15,
  html[dir=rtl] .ltr .rw__m-md-15,
  html[dir=rtl] .rtl .rw__m-md-15,
  html[dir=ltr] .ltr .rw__m-md-15,
  html[dir=ltr] .rtl .rw__m-md-15 {
    margin: 10rem !important;
  }
  .sb-show-main .ltr .rw__p-md-15,
  .sb-show-main .rtl .rw__p-md-15,
  html[dir=rtl] .ltr .rw__p-md-15,
  html[dir=rtl] .rtl .rw__p-md-15,
  html[dir=ltr] .ltr .rw__p-md-15,
  html[dir=ltr] .rtl .rw__p-md-15 {
    padding: 10rem !important;
  }
  .sb-show-main .ltr .rw__mx-md-15,
  .sb-show-main .rtl .rw__mx-md-15,
  html[dir=rtl] .ltr .rw__mx-md-15,
  html[dir=rtl] .rtl .rw__mx-md-15,
  html[dir=ltr] .ltr .rw__mx-md-15,
  html[dir=ltr] .rtl .rw__mx-md-15 {
    margin-left: 10rem !important;
    margin-right: 10rem !important;
  }
  .sb-show-main .ltr .rw__my-md-15,
  .sb-show-main .rtl .rw__my-md-15,
  html[dir=rtl] .ltr .rw__my-md-15,
  html[dir=rtl] .rtl .rw__my-md-15,
  html[dir=ltr] .ltr .rw__my-md-15,
  html[dir=ltr] .rtl .rw__my-md-15 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }
  .sb-show-main .ltr .rw__mt-md-15,
  .sb-show-main .rtl .rw__mt-md-15,
  html[dir=rtl] .ltr .rw__mt-md-15,
  html[dir=rtl] .rtl .rw__mt-md-15,
  html[dir=ltr] .ltr .rw__mt-md-15,
  html[dir=ltr] .rtl .rw__mt-md-15 {
    margin-top: 10rem !important;
  }
  .sb-show-main .ltr .rw__mb-md-15,
  .sb-show-main .rtl .rw__mb-md-15,
  html[dir=rtl] .ltr .rw__mb-md-15,
  html[dir=rtl] .rtl .rw__mb-md-15,
  html[dir=ltr] .ltr .rw__mb-md-15,
  html[dir=ltr] .rtl .rw__mb-md-15 {
    margin-bottom: 10rem !important;
  }
  .sb-show-main .ltr .rw__px-md-15,
  .sb-show-main .rtl .rw__px-md-15,
  html[dir=rtl] .ltr .rw__px-md-15,
  html[dir=rtl] .rtl .rw__px-md-15,
  html[dir=ltr] .ltr .rw__px-md-15,
  html[dir=ltr] .rtl .rw__px-md-15 {
    padding-left: 10rem !important;
    padding-right: 10rem !important;
  }
  .sb-show-main .ltr .rw__py-md-15,
  .sb-show-main .rtl .rw__py-md-15,
  html[dir=rtl] .ltr .rw__py-md-15,
  html[dir=rtl] .rtl .rw__py-md-15,
  html[dir=ltr] .ltr .rw__py-md-15,
  html[dir=ltr] .rtl .rw__py-md-15 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  .sb-show-main .ltr .rw__pt-md-15,
  .sb-show-main .rtl .rw__pt-md-15,
  html[dir=rtl] .ltr .rw__pt-md-15,
  html[dir=rtl] .rtl .rw__pt-md-15,
  html[dir=ltr] .ltr .rw__pt-md-15,
  html[dir=ltr] .rtl .rw__pt-md-15 {
    padding-top: 10rem !important;
  }
  .sb-show-main .ltr .rw__pb-md-15,
  .sb-show-main .rtl .rw__pb-md-15,
  html[dir=rtl] .ltr .rw__pb-md-15,
  html[dir=rtl] .rtl .rw__pb-md-15,
  html[dir=ltr] .ltr .rw__pb-md-15,
  html[dir=ltr] .rtl .rw__pb-md-15 {
    padding-bottom: 10rem !important;
  }
  .sb-show-main .ltr .rw__ms-md-15,
  html[dir=rtl] .ltr .rw__ms-md-15,
  html[dir=ltr] .ltr .rw__ms-md-15 {
    margin-left: 10rem !important;
  }
  .sb-show-main .ltr .rw__me-md-15,
  html[dir=rtl] .ltr .rw__me-md-15,
  html[dir=ltr] .ltr .rw__me-md-15 {
    margin-right: 10rem !important;
  }
  .sb-show-main .ltr .rw__ps-md-15,
  html[dir=rtl] .ltr .rw__ps-md-15,
  html[dir=ltr] .ltr .rw__ps-md-15 {
    padding-left: 10rem !important;
  }
  .sb-show-main .ltr .rw__pe-md-15,
  html[dir=rtl] .ltr .rw__pe-md-15,
  html[dir=ltr] .ltr .rw__pe-md-15 {
    padding-right: 10rem !important;
  }
  .sb-show-main .rtl .rw__ms-md-15,
  html[dir=rtl] .rtl .rw__ms-md-15,
  html[dir=ltr] .rtl .rw__ms-md-15 {
    margin-right: 10rem !important;
  }
  .sb-show-main .rtl .rw__me-md-15,
  html[dir=rtl] .rtl .rw__me-md-15,
  html[dir=ltr] .rtl .rw__me-md-15 {
    margin-left: 10rem !important;
  }
  .sb-show-main .rtl .rw__ps-md-15,
  html[dir=rtl] .rtl .rw__ps-md-15,
  html[dir=ltr] .rtl .rw__ps-md-15 {
    padding-right: 10rem !important;
  }
  .sb-show-main .rtl .rw__pe-md-15,
  html[dir=rtl] .rtl .rw__pe-md-15,
  html[dir=ltr] .rtl .rw__pe-md-15 {
    padding-left: 10rem !important;
  }
}
@media (min-width: 640px) {
  html[dir=rtl] .ltr .rw__ms-sm-15,
  html[dir=ltr] .ltr .rw__ms-sm-15 {
    margin-left: 10rem !important;
  }
  html[dir=rtl] .rtl .rw__ms-sm-15,
  html[dir=ltr] .rtl .rw__ms-sm-15 {
    margin-right: 10rem !important;
  }
}
.rw__mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.rounded-0 {
  border-radius: 0rem !important;
}

.rounded-top-0 {
  border-top-left-radius: 0rem !important;
  border-top-right-radius: 0rem !important;
}

.rounded-bottom-0 {
  border-bottom-left-radius: 0rem !important;
  border-bottom-right-radius: 0rem !important;
}

.rounded-1 {
  border-radius: 0.0625rem !important;
}

.rounded-top-1 {
  border-top-left-radius: 0.0625rem !important;
  border-top-right-radius: 0.0625rem !important;
}

.rounded-bottom-1 {
  border-bottom-left-radius: 0.0625rem !important;
  border-bottom-right-radius: 0.0625rem !important;
}

.rounded-2 {
  border-radius: 0.125rem !important;
}

.rounded-top-2 {
  border-top-left-radius: 0.125rem !important;
  border-top-right-radius: 0.125rem !important;
}

.rounded-bottom-2 {
  border-bottom-left-radius: 0.125rem !important;
  border-bottom-right-radius: 0.125rem !important;
}

.rounded-3 {
  border-radius: 0.1875rem !important;
}

.rounded-top-3 {
  border-top-left-radius: 0.1875rem !important;
  border-top-right-radius: 0.1875rem !important;
}

.rounded-bottom-3 {
  border-bottom-left-radius: 0.1875rem !important;
  border-bottom-right-radius: 0.1875rem !important;
}

.rounded-4, .rw__notification-error, .rw__notification-warning, .rw__notification-success, .rw__notification-info, .rw__datatable .p-datatable-wrapper, .rw__tabs-box ul li, .rw__button {
  border-radius: 0.25rem !important;
}

.rounded-top-4, .rw__datatable .p-datatable-header {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-bottom-4, .rw__datatable-v3-paginator, .rw__datatable-custom-paginator .p-paginator, .rw__datatable-internal-paginator .p-paginator, .rw__datatable-paginator {
  border-bottom-left-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-5, .rw__input .rw__input-search-with-button, .rw__input .dropdown-menu, .rw__input input,
.rw__input .p-dropdown,
.rw__input .p-multiselect,
.rw__input .rw__autocomplete-dropdown .p-autocomplete,
.rw__input textarea {
  border-radius: 0.3125rem !important;
}

.rounded-top-5, .rw__input .dropdown-menu .dropdown-item:first-child {
  border-top-left-radius: 0.3125rem !important;
  border-top-right-radius: 0.3125rem !important;
}

.rounded-bottom-5, .rw__input .dropdown-menu .dropdown-item:last-child {
  border-bottom-left-radius: 0.3125rem !important;
  border-bottom-right-radius: 0.3125rem !important;
}

.rounded-6, .rw__datatable-v3 .p-datatable-wrapper, .rw__datatable-v3, .rw__accordion .p-accordion-tab, .rw__tooltip .p-tooltip-text, .rw__icon-box-outline-light, .rw__icon-box-white, .rw__icon-box-grey, .rw__icon-box-info, .rw__icon-box-success, .rw__icon-box-warning, .rw__icon-box-danger, .rw__icon-box-teal, .rw__icon-box-primary {
  border-radius: 0.375rem !important;
}

.rounded-top-6 {
  border-top-left-radius: 0.375rem !important;
  border-top-right-radius: 0.375rem !important;
}

.rounded-bottom-6 {
  border-bottom-left-radius: 0.375rem !important;
  border-bottom-right-radius: 0.375rem !important;
}

.rounded-7 {
  border-radius: 0.4375rem !important;
}

.rounded-top-7 {
  border-top-left-radius: 0.4375rem !important;
  border-top-right-radius: 0.4375rem !important;
}

.rounded-bottom-7 {
  border-bottom-left-radius: 0.4375rem !important;
  border-bottom-right-radius: 0.4375rem !important;
}

.rounded-8 {
  border-radius: 0.5rem !important;
}

.rounded-top-8 {
  border-top-left-radius: 0.5rem !important;
  border-top-right-radius: 0.5rem !important;
}

.rounded-bottom-8 {
  border-bottom-left-radius: 0.5rem !important;
  border-bottom-right-radius: 0.5rem !important;
}

.rounded-9 {
  border-radius: 0.5625rem !important;
}

.rounded-top-9 {
  border-top-left-radius: 0.5625rem !important;
  border-top-right-radius: 0.5625rem !important;
}

.rounded-bottom-9 {
  border-bottom-left-radius: 0.5625rem !important;
  border-bottom-right-radius: 0.5625rem !important;
}

.rounded-10 {
  border-radius: 0.625rem !important;
}

.rounded-top-10 {
  border-top-left-radius: 0.625rem !important;
  border-top-right-radius: 0.625rem !important;
}

.rounded-bottom-10 {
  border-bottom-left-radius: 0.625rem !important;
  border-bottom-right-radius: 0.625rem !important;
}

.rounded-12 {
  border-radius: 0.75rem !important;
}

.rounded-top-12 {
  border-top-left-radius: 0.75rem !important;
  border-top-right-radius: 0.75rem !important;
}

.rounded-bottom-12 {
  border-bottom-left-radius: 0.75rem !important;
  border-bottom-right-radius: 0.75rem !important;
}

.rounded-14 {
  border-radius: 0.875rem !important;
}

.rounded-top-14 {
  border-top-left-radius: 0.875rem !important;
  border-top-right-radius: 0.875rem !important;
}

.rounded-bottom-14 {
  border-bottom-left-radius: 0.875rem !important;
  border-bottom-right-radius: 0.875rem !important;
}

.rounded-16 {
  border-radius: 1rem !important;
}

.rounded-top-16 {
  border-top-left-radius: 1rem !important;
  border-top-right-radius: 1rem !important;
}

.rounded-bottom-16 {
  border-bottom-left-radius: 1rem !important;
  border-bottom-right-radius: 1rem !important;
}

.rounded-circle, .list-style-disc-internal:before, .rw__datatable-v3 .p-datatable-thead tr th p-sorticon, .rw__datatable-custom-paginator .p-paginator .p-link, .rw__datatable-internal-paginator .p-paginator .p-link, .rw__datatable-paginator .p-paginator-pages .p-paginator-page, .rw__datatable-paginator .p-paginator-element, .rw__accordion .p-accordion-toggle-icon, .rw__steps .p-steps-item .p-menuitem-link .p-steps-number, .rw__custom-radio-button .p-radiobutton, .rw__radio-button .p-radiobutton .p-radiobutton-box, .rw__radio-button .p-radiobutton, .rw__input-compact.rw__input-icon-close .fa-xmark, .rw__input-default.rw__input-icon-close .fa-xmark, .rw__input-float.rw__input-icon-close .fa-xmark {
  border-radius: 3.125rem !important;
}

.rounded-top-circle {
  border-top-left-radius: 3.125rem !important;
  border-top-right-radius: 3.125rem !important;
}

.rounded-bottom-circle {
  border-bottom-left-radius: 3.125rem !important;
  border-bottom-right-radius: 3.125rem !important;
}

.ltr .rounded-start-0 {
  border-bottom-left-radius: 0rem !important;
  border-top-left-radius: 0rem !important;
}
.ltr .rounded-end-0 {
  border-bottom-right-radius: 0rem !important;
  border-top-right-radius: 0rem !important;
}
.ltr .rounded-bottom-start-0 {
  border-bottom-left-radius: 0rem !important;
}
.ltr .rounded-bottom-end-0 {
  border-bottom-right-radius: 0rem !important;
}
.ltr .rounded-start-1 {
  border-bottom-left-radius: 0.0625rem !important;
  border-top-left-radius: 0.0625rem !important;
}
.ltr .rounded-end-1 {
  border-bottom-right-radius: 0.0625rem !important;
  border-top-right-radius: 0.0625rem !important;
}
.ltr .rounded-bottom-start-1 {
  border-bottom-left-radius: 0.0625rem !important;
}
.ltr .rounded-bottom-end-1 {
  border-bottom-right-radius: 0.0625rem !important;
}
.ltr .rounded-start-2 {
  border-bottom-left-radius: 0.125rem !important;
  border-top-left-radius: 0.125rem !important;
}
.ltr .rounded-end-2 {
  border-bottom-right-radius: 0.125rem !important;
  border-top-right-radius: 0.125rem !important;
}
.ltr .rounded-bottom-start-2 {
  border-bottom-left-radius: 0.125rem !important;
}
.ltr .rounded-bottom-end-2 {
  border-bottom-right-radius: 0.125rem !important;
}
.ltr .rounded-start-3 {
  border-bottom-left-radius: 0.1875rem !important;
  border-top-left-radius: 0.1875rem !important;
}
.ltr .rounded-end-3 {
  border-bottom-right-radius: 0.1875rem !important;
  border-top-right-radius: 0.1875rem !important;
}
.ltr .rounded-bottom-start-3 {
  border-bottom-left-radius: 0.1875rem !important;
}
.ltr .rounded-bottom-end-3 {
  border-bottom-right-radius: 0.1875rem !important;
}
.ltr .rounded-start-4 {
  border-bottom-left-radius: 0.25rem !important;
  border-top-left-radius: 0.25rem !important;
}
.ltr .rounded-end-4 {
  border-bottom-right-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}
.ltr .rounded-bottom-start-4 {
  border-bottom-left-radius: 0.25rem !important;
}
.ltr .rounded-bottom-end-4 {
  border-bottom-right-radius: 0.25rem !important;
}
.ltr .rounded-start-5 {
  border-bottom-left-radius: 0.3125rem !important;
  border-top-left-radius: 0.3125rem !important;
}
.ltr .rounded-end-5 {
  border-bottom-right-radius: 0.3125rem !important;
  border-top-right-radius: 0.3125rem !important;
}
.ltr .rounded-bottom-start-5 {
  border-bottom-left-radius: 0.3125rem !important;
}
.ltr .rounded-bottom-end-5 {
  border-bottom-right-radius: 0.3125rem !important;
}
.ltr .rounded-start-6 {
  border-bottom-left-radius: 0.375rem !important;
  border-top-left-radius: 0.375rem !important;
}
.ltr .rounded-end-6 {
  border-bottom-right-radius: 0.375rem !important;
  border-top-right-radius: 0.375rem !important;
}
.ltr .rounded-bottom-start-6 {
  border-bottom-left-radius: 0.375rem !important;
}
.ltr .rounded-bottom-end-6 {
  border-bottom-right-radius: 0.375rem !important;
}
.ltr .rounded-start-7 {
  border-bottom-left-radius: 0.4375rem !important;
  border-top-left-radius: 0.4375rem !important;
}
.ltr .rounded-end-7 {
  border-bottom-right-radius: 0.4375rem !important;
  border-top-right-radius: 0.4375rem !important;
}
.ltr .rounded-bottom-start-7 {
  border-bottom-left-radius: 0.4375rem !important;
}
.ltr .rounded-bottom-end-7 {
  border-bottom-right-radius: 0.4375rem !important;
}
.ltr .rounded-start-8 {
  border-bottom-left-radius: 0.5rem !important;
  border-top-left-radius: 0.5rem !important;
}
.ltr .rounded-end-8 {
  border-bottom-right-radius: 0.5rem !important;
  border-top-right-radius: 0.5rem !important;
}
.ltr .rounded-bottom-start-8 {
  border-bottom-left-radius: 0.5rem !important;
}
.ltr .rounded-bottom-end-8 {
  border-bottom-right-radius: 0.5rem !important;
}
.ltr .rounded-start-9 {
  border-bottom-left-radius: 0.5625rem !important;
  border-top-left-radius: 0.5625rem !important;
}
.ltr .rounded-end-9 {
  border-bottom-right-radius: 0.5625rem !important;
  border-top-right-radius: 0.5625rem !important;
}
.ltr .rounded-bottom-start-9 {
  border-bottom-left-radius: 0.5625rem !important;
}
.ltr .rounded-bottom-end-9 {
  border-bottom-right-radius: 0.5625rem !important;
}
.ltr .rounded-start-10 {
  border-bottom-left-radius: 0.625rem !important;
  border-top-left-radius: 0.625rem !important;
}
.ltr .rounded-end-10 {
  border-bottom-right-radius: 0.625rem !important;
  border-top-right-radius: 0.625rem !important;
}
.ltr .rounded-bottom-start-10 {
  border-bottom-left-radius: 0.625rem !important;
}
.ltr .rounded-bottom-end-10 {
  border-bottom-right-radius: 0.625rem !important;
}
.ltr .rounded-start-12 {
  border-bottom-left-radius: 0.75rem !important;
  border-top-left-radius: 0.75rem !important;
}
.ltr .rounded-end-12 {
  border-bottom-right-radius: 0.75rem !important;
  border-top-right-radius: 0.75rem !important;
}
.ltr .rounded-bottom-start-12 {
  border-bottom-left-radius: 0.75rem !important;
}
.ltr .rounded-bottom-end-12 {
  border-bottom-right-radius: 0.75rem !important;
}
.ltr .rounded-start-14 {
  border-bottom-left-radius: 0.875rem !important;
  border-top-left-radius: 0.875rem !important;
}
.ltr .rounded-end-14 {
  border-bottom-right-radius: 0.875rem !important;
  border-top-right-radius: 0.875rem !important;
}
.ltr .rounded-bottom-start-14 {
  border-bottom-left-radius: 0.875rem !important;
}
.ltr .rounded-bottom-end-14 {
  border-bottom-right-radius: 0.875rem !important;
}
.ltr .rounded-start-16 {
  border-bottom-left-radius: 1rem !important;
  border-top-left-radius: 1rem !important;
}
.ltr .rounded-end-16 {
  border-bottom-right-radius: 1rem !important;
  border-top-right-radius: 1rem !important;
}
.ltr .rounded-bottom-start-16 {
  border-bottom-left-radius: 1rem !important;
}
.ltr .rounded-bottom-end-16 {
  border-bottom-right-radius: 1rem !important;
}
.ltr .rounded-start-circle {
  border-bottom-left-radius: 3.125rem !important;
  border-top-left-radius: 3.125rem !important;
}
.ltr .rounded-end-circle {
  border-bottom-right-radius: 3.125rem !important;
  border-top-right-radius: 3.125rem !important;
}
.ltr .rounded-bottom-start-circle {
  border-bottom-left-radius: 3.125rem !important;
}
.ltr .rounded-bottom-end-circle {
  border-bottom-right-radius: 3.125rem !important;
}

.rtl .rounded-start-0 {
  border-bottom-right-radius: 0rem !important;
  border-top-right-radius: 0rem !important;
}
.rtl .rounded-end-0 {
  border-bottom-left-radius: 0rem !important;
  border-top-left-radius: 0rem !important;
}
.rtl .rounded-bottom-start-0 {
  border-bottom-right-radius: 0rem !important;
}
.rtl .rounded-bottom-end-0 {
  border-bottom-left-radius: 0rem !important;
}
.rtl .rounded-start-1 {
  border-bottom-right-radius: 0.0625rem !important;
  border-top-right-radius: 0.0625rem !important;
}
.rtl .rounded-end-1 {
  border-bottom-left-radius: 0.0625rem !important;
  border-top-left-radius: 0.0625rem !important;
}
.rtl .rounded-bottom-start-1 {
  border-bottom-right-radius: 0.0625rem !important;
}
.rtl .rounded-bottom-end-1 {
  border-bottom-left-radius: 0.0625rem !important;
}
.rtl .rounded-start-2 {
  border-bottom-right-radius: 0.125rem !important;
  border-top-right-radius: 0.125rem !important;
}
.rtl .rounded-end-2 {
  border-bottom-left-radius: 0.125rem !important;
  border-top-left-radius: 0.125rem !important;
}
.rtl .rounded-bottom-start-2 {
  border-bottom-right-radius: 0.125rem !important;
}
.rtl .rounded-bottom-end-2 {
  border-bottom-left-radius: 0.125rem !important;
}
.rtl .rounded-start-3 {
  border-bottom-right-radius: 0.1875rem !important;
  border-top-right-radius: 0.1875rem !important;
}
.rtl .rounded-end-3 {
  border-bottom-left-radius: 0.1875rem !important;
  border-top-left-radius: 0.1875rem !important;
}
.rtl .rounded-bottom-start-3 {
  border-bottom-right-radius: 0.1875rem !important;
}
.rtl .rounded-bottom-end-3 {
  border-bottom-left-radius: 0.1875rem !important;
}
.rtl .rounded-start-4 {
  border-bottom-right-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}
.rtl .rounded-end-4 {
  border-bottom-left-radius: 0.25rem !important;
  border-top-left-radius: 0.25rem !important;
}
.rtl .rounded-bottom-start-4 {
  border-bottom-right-radius: 0.25rem !important;
}
.rtl .rounded-bottom-end-4 {
  border-bottom-left-radius: 0.25rem !important;
}
.rtl .rounded-start-5 {
  border-bottom-right-radius: 0.3125rem !important;
  border-top-right-radius: 0.3125rem !important;
}
.rtl .rounded-end-5 {
  border-bottom-left-radius: 0.3125rem !important;
  border-top-left-radius: 0.3125rem !important;
}
.rtl .rounded-bottom-start-5 {
  border-bottom-right-radius: 0.3125rem !important;
}
.rtl .rounded-bottom-end-5 {
  border-bottom-left-radius: 0.3125rem !important;
}
.rtl .rounded-start-6 {
  border-bottom-right-radius: 0.375rem !important;
  border-top-right-radius: 0.375rem !important;
}
.rtl .rounded-end-6 {
  border-bottom-left-radius: 0.375rem !important;
  border-top-left-radius: 0.375rem !important;
}
.rtl .rounded-bottom-start-6 {
  border-bottom-right-radius: 0.375rem !important;
}
.rtl .rounded-bottom-end-6 {
  border-bottom-left-radius: 0.375rem !important;
}
.rtl .rounded-start-7 {
  border-bottom-right-radius: 0.4375rem !important;
  border-top-right-radius: 0.4375rem !important;
}
.rtl .rounded-end-7 {
  border-bottom-left-radius: 0.4375rem !important;
  border-top-left-radius: 0.4375rem !important;
}
.rtl .rounded-bottom-start-7 {
  border-bottom-right-radius: 0.4375rem !important;
}
.rtl .rounded-bottom-end-7 {
  border-bottom-left-radius: 0.4375rem !important;
}
.rtl .rounded-start-8 {
  border-bottom-right-radius: 0.5rem !important;
  border-top-right-radius: 0.5rem !important;
}
.rtl .rounded-end-8 {
  border-bottom-left-radius: 0.5rem !important;
  border-top-left-radius: 0.5rem !important;
}
.rtl .rounded-bottom-start-8 {
  border-bottom-right-radius: 0.5rem !important;
}
.rtl .rounded-bottom-end-8 {
  border-bottom-left-radius: 0.5rem !important;
}
.rtl .rounded-start-9 {
  border-bottom-right-radius: 0.5625rem !important;
  border-top-right-radius: 0.5625rem !important;
}
.rtl .rounded-end-9 {
  border-bottom-left-radius: 0.5625rem !important;
  border-top-left-radius: 0.5625rem !important;
}
.rtl .rounded-bottom-start-9 {
  border-bottom-right-radius: 0.5625rem !important;
}
.rtl .rounded-bottom-end-9 {
  border-bottom-left-radius: 0.5625rem !important;
}
.rtl .rounded-start-10 {
  border-bottom-right-radius: 0.625rem !important;
  border-top-right-radius: 0.625rem !important;
}
.rtl .rounded-end-10 {
  border-bottom-left-radius: 0.625rem !important;
  border-top-left-radius: 0.625rem !important;
}
.rtl .rounded-bottom-start-10 {
  border-bottom-right-radius: 0.625rem !important;
}
.rtl .rounded-bottom-end-10 {
  border-bottom-left-radius: 0.625rem !important;
}
.rtl .rounded-start-12 {
  border-bottom-right-radius: 0.75rem !important;
  border-top-right-radius: 0.75rem !important;
}
.rtl .rounded-end-12 {
  border-bottom-left-radius: 0.75rem !important;
  border-top-left-radius: 0.75rem !important;
}
.rtl .rounded-bottom-start-12 {
  border-bottom-right-radius: 0.75rem !important;
}
.rtl .rounded-bottom-end-12 {
  border-bottom-left-radius: 0.75rem !important;
}
.rtl .rounded-start-14 {
  border-bottom-right-radius: 0.875rem !important;
  border-top-right-radius: 0.875rem !important;
}
.rtl .rounded-end-14 {
  border-bottom-left-radius: 0.875rem !important;
  border-top-left-radius: 0.875rem !important;
}
.rtl .rounded-bottom-start-14 {
  border-bottom-right-radius: 0.875rem !important;
}
.rtl .rounded-bottom-end-14 {
  border-bottom-left-radius: 0.875rem !important;
}
.rtl .rounded-start-16 {
  border-bottom-right-radius: 1rem !important;
  border-top-right-radius: 1rem !important;
}
.rtl .rounded-end-16 {
  border-bottom-left-radius: 1rem !important;
  border-top-left-radius: 1rem !important;
}
.rtl .rounded-bottom-start-16 {
  border-bottom-right-radius: 1rem !important;
}
.rtl .rounded-bottom-end-16 {
  border-bottom-left-radius: 1rem !important;
}
.rtl .rounded-start-circle {
  border-bottom-right-radius: 3.125rem !important;
  border-top-right-radius: 3.125rem !important;
}
.rtl .rounded-end-circle {
  border-bottom-left-radius: 3.125rem !important;
  border-top-left-radius: 3.125rem !important;
}
.rtl .rounded-bottom-start-circle {
  border-bottom-right-radius: 3.125rem !important;
}
.rtl .rounded-bottom-end-circle {
  border-bottom-left-radius: 3.125rem !important;
}

.rw__icon-box-primary {
  background-color: rgba(0, 128, 125, 0.12);
  flex-shrink: 0;
  height: 3.25rem;
  width: 3.25rem;
}
@media (max-width: 959px) {
  .rw__icon-box-primary {
    height: 2.5rem;
    width: 2.5rem;
  }
}
.rw__icon-box-primary i {
  color: #00807D;
  font-size: 1.625rem;
}
@media (max-width: 959px) {
  .rw__icon-box-primary i {
    font-size: 1.125rem !important;
  }
}

.rw__icon-box-teal {
  background-color: #EEF8F8;
  flex-shrink: 0;
  height: 3.25rem;
  width: 3.25rem;
}
@media (max-width: 959px) {
  .rw__icon-box-teal {
    height: 2.5rem;
    width: 2.5rem;
  }
}
.rw__icon-box-teal i {
  color: #00807D;
  font-size: 1.625rem;
}
@media (max-width: 959px) {
  .rw__icon-box-teal i {
    font-size: 1.125rem !important;
  }
}

.rw__icon-box-danger {
  background-color: rgba(186, 27, 27, 0.12);
  flex-shrink: 0;
  height: 3.25rem;
  width: 3.25rem;
}
@media (max-width: 959px) {
  .rw__icon-box-danger {
    height: 2.5rem;
    width: 2.5rem;
  }
}
.rw__icon-box-danger i {
  color: #BA1B1B;
  font-size: 1.625rem;
}
@media (max-width: 959px) {
  .rw__icon-box-danger i {
    font-size: 1.125rem !important;
  }
}

.rw__icon-box-warning {
  background-color: rgba(217, 162, 0, 0.12);
  flex-shrink: 0;
  height: 3.25rem;
  width: 3.25rem;
}
@media (max-width: 959px) {
  .rw__icon-box-warning {
    height: 2.5rem;
    width: 2.5rem;
  }
}
.rw__icon-box-warning i {
  color: #D9A200;
  font-size: 1.625rem;
}
@media (max-width: 959px) {
  .rw__icon-box-warning i {
    font-size: 1.125rem !important;
  }
}

.rw__icon-box-success {
  background-color: rgba(0, 133, 71, 0.12);
  flex-shrink: 0;
  height: 3.25rem;
  width: 3.25rem;
}
@media (max-width: 959px) {
  .rw__icon-box-success {
    height: 2.5rem;
    width: 2.5rem;
  }
}
.rw__icon-box-success i {
  color: #008547;
  font-size: 1.625rem;
}
@media (max-width: 959px) {
  .rw__icon-box-success i {
    font-size: 1.125rem !important;
  }
}

.rw__icon-box-info {
  background-color: rgba(0, 122, 204, 0.12);
  flex-shrink: 0;
  height: 3.25rem;
  width: 3.25rem;
}
@media (max-width: 959px) {
  .rw__icon-box-info {
    height: 2.5rem;
    width: 2.5rem;
  }
}
.rw__icon-box-info i {
  color: #007ACC;
  font-size: 1.625rem;
}
@media (max-width: 959px) {
  .rw__icon-box-info i {
    font-size: 1.125rem !important;
  }
}

.rw__icon-box-grey {
  background-color: #f7f8f8;
  flex-shrink: 0;
  height: 3.25rem;
  width: 3.25rem;
}
@media (max-width: 959px) {
  .rw__icon-box-grey {
    height: 2.5rem;
    width: 2.5rem;
  }
}
.rw__icon-box-grey i {
  color: #5B6565;
  font-size: 1.625rem;
}
@media (max-width: 959px) {
  .rw__icon-box-grey i {
    font-size: 1.125rem !important;
  }
}

.rw__icon-box-white {
  background-color: rgba(255, 255, 255, 0.12);
  flex-shrink: 0;
  height: 3.25rem;
  width: 3.25rem;
}
@media (max-width: 959px) {
  .rw__icon-box-white {
    height: 2.5rem;
    width: 2.5rem;
  }
}
.rw__icon-box-white i {
  color: rgba(255, 255, 255, 0.67);
  font-size: 1.625rem;
}
@media (max-width: 959px) {
  .rw__icon-box-white i {
    font-size: 1.125rem !important;
  }
}

.rw__icon-box-lg {
  height: 5rem !important;
  width: 5rem !important;
}
.rw__icon-box-lg i {
  font-size: 1.6875rem !important;
}
.rw__icon-box-md {
  height: 3.25rem !important;
  width: 3.25rem !important;
}
.rw__icon-box-md i {
  font-size: 1.6875rem !important;
}
.rw__icon-box-sm {
  height: 2.1875rem !important;
  width: 2.1875rem !important;
}
.rw__icon-box-sm i {
  font-size: 1.125rem !important;
}
.rw__icon-box-xs {
  height: 1.5rem !important;
  width: 1.5rem !important;
}
.rw__icon-box-xs i {
  font-size: 1rem !important;
}

.rw__icon-box-sm {
  height: 2.1875rem !important;
  width: 2.1875rem !important;
}
.rw__icon-box-sm i {
  font-size: 1.125rem !important;
}

.rw__icon-box-outline-light {
  border-color: #fff;
  background-color: transparent;
  flex-shrink: 0;
  height: 3.25rem;
  width: 3.25rem;
}
@media (max-width: 959px) {
  .rw__icon-box-outline-light {
    height: 2.5rem;
    width: 2.5rem;
  }
}
.rw__icon-box-outline-light i {
  font-size: 1.625rem;
}
@media (max-width: 959px) {
  .rw__icon-box-outline-light i {
    font-size: 1.125rem !important;
  }
}

.p-button-icon {
  font-size: 18px;
}

.fa-solid,
.fa-regular,
.fa-lg {
  line-height: 1;
  vertical-align: middle;
}

.rw__input input,
.rw__input .p-dropdown,
.rw__input .p-multiselect,
.rw__input .rw__autocomplete-dropdown .p-autocomplete,
.rw__input textarea {
  border-color: #DFE2E2;
  border-width: 0.0625rem;
  border-style: solid;
  box-shadow: none !important;
  color: #313D3D;
  outline: none;
}
.rw__input input,
.rw__input p-dropdown,
.rw__input .p-dropdown,
.rw__input p-multiselect,
.rw__input .p-multiselect,
.rw__input p-inputtext,
.rw__input .p-inputtext,
.rw__input .rw__autocomplete-dropdown .p-autocomplete,
.rw__input textarea {
  font-weight: 400;
}
.rw__input input:focus,
.rw__input p-dropdown:focus,
.rw__input .p-dropdown:focus,
.rw__input p-multiselect:focus,
.rw__input .p-multiselect:focus,
.rw__input p-inputtext:focus,
.rw__input .p-inputtext:focus,
.rw__input .rw__autocomplete-dropdown .p-autocomplete:focus,
.rw__input textarea:focus {
  border-color: #00A5A3 !important;
}
.rw__input input:focus ~ label,
.rw__input p-dropdown:focus ~ label,
.rw__input .p-dropdown:focus ~ label,
.rw__input p-multiselect:focus ~ label,
.rw__input .p-multiselect:focus ~ label,
.rw__input p-inputtext:focus ~ label,
.rw__input .p-inputtext:focus ~ label,
.rw__input .rw__autocomplete-dropdown .p-autocomplete:focus ~ label,
.rw__input textarea:focus ~ label {
  font-size: 0.75rem;
}
.rw__input input:hover,
.rw__input p-dropdown:hover,
.rw__input .p-dropdown:hover,
.rw__input p-multiselect:hover,
.rw__input .p-multiselect:hover,
.rw__input p-inputtext:hover,
.rw__input .p-inputtext:hover,
.rw__input .rw__autocomplete-dropdown .p-autocomplete:hover,
.rw__input textarea:hover {
  border-color: #C7CCCC;
}
.rw__input input:active,
.rw__input p-dropdown:active,
.rw__input .p-dropdown:active,
.rw__input p-multiselect:active,
.rw__input .p-multiselect:active,
.rw__input p-inputtext:active,
.rw__input .p-inputtext:active,
.rw__input .rw__autocomplete-dropdown .p-autocomplete:active,
.rw__input textarea:active {
  border-color: #00A5A3;
}
.rw__input input.p-filled ~ label, .rw__input input.p-inputwrapper-filled ~ label,
.rw__input p-dropdown.p-filled ~ label,
.rw__input p-dropdown.p-inputwrapper-filled ~ label,
.rw__input .p-dropdown.p-filled ~ label,
.rw__input .p-dropdown.p-inputwrapper-filled ~ label,
.rw__input p-multiselect.p-filled ~ label,
.rw__input p-multiselect.p-inputwrapper-filled ~ label,
.rw__input .p-multiselect.p-filled ~ label,
.rw__input .p-multiselect.p-inputwrapper-filled ~ label,
.rw__input p-inputtext.p-filled ~ label,
.rw__input p-inputtext.p-inputwrapper-filled ~ label,
.rw__input .p-inputtext.p-filled ~ label,
.rw__input .p-inputtext.p-inputwrapper-filled ~ label,
.rw__input .rw__autocomplete-dropdown .p-autocomplete.p-filled ~ label,
.rw__input .rw__autocomplete-dropdown .p-autocomplete.p-inputwrapper-filled ~ label,
.rw__input textarea.p-filled ~ label,
.rw__input textarea.p-inputwrapper-filled ~ label {
  color: #5B6565 !important;
  font-size: 0.75rem;
}
.rw__input .rw__autocomplete-dropdown.p-inputwrapper-focus .p-autocomplete {
  border-color: #00A5A3;
}
.rw__input .p-dropdown-open {
  border-color: #00A5A3 !important;
}
.rw__input .p-dropdown-open ~ label {
  font-size: 0.75rem;
}
.rw__input label {
  color: #313D3D;
  font-size: 1rem;
  margin-top: -0.625rem;
}
.rw__input .fa {
  background-size: contain !important;
}
.rw__input .p-inputnumber {
  width: 100%;
}
.rw__input .p-inputnumber-buttons-stacked .p-inputnumber-button-group {
  border: 0.0625rem solid #DFE2E2;
}
.rw__input .p-inputnumber-buttons-stacked .p-inputnumber-button-group button {
  width: 2.25rem;
}
.rw__input .p-inputnumber-buttons-stacked .p-inputnumber-button-group button span {
  font-size: 0.75rem;
}
.rw__input .dropdown-menu {
  padding: 0;
  width: 100%;
}
.rw__input .dropdown-menu .dropdown-item {
  font-size: 0.875rem;
  height: 2.5rem;
}
.rw__input .dropdown-menu .dropdown-item .rw__add-option i {
  font-size: 1rem !important;
}
.rw__input .dropdown-menu .dropdown-item.active {
  background-color: #EEF8F8;
  color: #00807D;
}
.rw__input .p-dropdown-panel {
  width: 100%;
}
.rw__input .p-calendar {
  width: 100%;
}
.rw__input .p-calendar-clearable .p-calendar-clear-icon {
  right: 0.875rem;
}
.rw__input .p-calendar-w-btn {
  border-color: #DFE2E2;
  box-shadow: none !important;
}
.rw__input .p-calendar-w-btn .p-datepicker-trigger {
  background-color: #EEF8F8 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}
.rw__input .p-calendar-w-btn .p-datepicker-trigger .p-button-icon {
  color: #00A5A3;
}
.rw__input .p-calendar-w-btn .p-calendar-clear-icon {
  right: 3.75rem;
}
.rw__input .p-calendar .p-datepicker {
  border-radius: 0.25rem !important;
  border: 0.0625rem solid #E3E8E8 !important;
  background-color: #fff !important;
  box-shadow: 0 0.25rem 0.375rem rgba(0, 0, 0, 0.1) !important;
}
.rw__input .p-calendar .p-datepicker-header .p-datepicker-prev-icon, .rw__input .p-calendar .p-datepicker-header .p-datepicker-next-icon {
  font-size: 0.75rem;
}
.rw__input .p-calendar .p-datepicker-header .p-datepicker-prev-enabled, .rw__input .p-calendar .p-datepicker-header .p-datepicker-next-enabled {
  color: #5C7070;
}
.rw__input .p-calendar .p-datepicker-header .p-datepicker-prev-enabled:hover, .rw__input .p-calendar .p-datepicker-header .p-datepicker-next-enabled:hover {
  background-color: #F4F6F6;
}
.rw__input .p-calendar .p-datepicker-header .p-datepicker-title .p-link {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
  color: #445555;
  background-color: #F4F6F6;
  border-radius: 0.25rem;
}
.rw__input .p-calendar .p-datepicker-header .p-datepicker-title .p-link:hover {
  color: #445555;
}
.rw__input .p-calendar .p-datepicker-header .p-datepicker-title .p-datepicker-month {
  padding: 6px 16px;
}
.rw__input .p-calendar .p-datepicker-header .p-datepicker-title .p-datepicker-year {
  padding: 6px 12px;
}
.rw__input .p-calendar .p-datepicker-calendar-container {
  padding-inline: 8px;
}
.rw__input .p-calendar .p-datepicker-calendar-container .p-datepicker-calendar th, .rw__input .p-calendar .p-datepicker-calendar-container .p-datepicker-calendar td {
  font-size: 0.875rem;
  font-weight: 400;
  text-align: center;
  color: #5C7070;
}
.rw__input .p-calendar .p-datepicker-calendar-container .p-datepicker-calendar td {
  padding: 0.25rem 0.5rem !important;
}
.rw__input .p-calendar .p-datepicker-calendar-container .p-datepicker-calendar tbody .p-datepicker-today span {
  box-shadow: none;
  border-color: #561157;
  color: #561157;
}
.rw__input .p-calendar .p-datepicker-calendar-container .p-datepicker-calendar tbody .p-highlight {
  background-color: #D5ECEC;
  color: #005E5D;
}
.rw__input .p-calendar .p-monthpicker-month {
  font-size: 0.875rem;
  font-weight: 400;
  text-align: center;
  color: #5C7070;
}
.rw__input .p-calendar .p-monthpicker-month:hover {
  background-color: #F4F6F6 !important;
}
.rw__input .rw__autocomplete-dropdown .p-autocomplete {
  background: white;
  display: flex;
}
.rw__input .rw__autocomplete-dropdown .p-autocomplete input.p-autocomplete-input {
  border: none !important;
  height: auto;
}
.rw__input .rw__autocomplete-dropdown .p-autocomplete .p-autocomplete-dropdown {
  background: none;
  color: rgba(0, 0, 0, 0.6);
}
.rw__input .rw__autocomplete-dropdown .p-autocomplete .p-autocomplete-dropdown .p-button-icon {
  font-size: 1rem !important;
}
.rw__input .rw__input-search-with-button {
  border: 1px solid #DFE2E2;
  display: flex;
  align-items: center;
}
.rw__input .rw__input-search-with-button button {
  min-width: 3.25rem;
  padding: 0.75rem 0.9375rem;
  background-color: #EEF8F8 !important;
}
.rw__input .rw__input-search-with-button button:hover, .rw__input .rw__input-search-with-button button:focus {
  background-color: #EEF8F8 !important;
}
.rw__input .rw__input-search-with-button button:hover span, .rw__input .rw__input-search-with-button button:focus span {
  transform: scale(1.1);
}
.rw__input .rw__input-search-with-button:focus-within {
  border-color: #00807D !important;
}
.rw__input .fa {
  background-size: contain !important;
}
.rw__input .p-password-mask .pi-eye,
.rw__input .p-password-mask .pi-eye-slash {
  color: #313D3D;
  position: absolute;
  right: 0.75rem;
  top: 1rem;
  margin-top: 0;
}
.rw__input .p-password-input {
  padding-inline-end: 3.25rem !important;
}
.rw__input .p-password-clear-icon {
  right: 2rem;
  left: auto;
  color: #8D9696;
}
.rw__input-float {
  min-width: 17.5rem;
}
@media (max-width: 959px) {
  .rw__input-float {
    min-width: auto;
  }
}
.rw__input-float input {
  background-color: white;
  padding: 0.6875rem 0.6875rem 0.6875rem 0.9375rem !important;
  height: 3rem;
  width: 100%;
}
.rw__input-float input:focus ~ label {
  color: #00A5A3 !important;
}
.rw__input-float .p-dropdown-label {
  padding: 0.6875rem 0.6875rem 0.6875rem 0.9375rem !important;
}
.rw__input-float .p-multiselect-label {
  padding: 0.6875rem 0.6875rem 0.6875rem 0.9375rem !important;
}
.rw__input-float .p-multiselect-label-container {
  padding: 0 !important;
}
.rw__input-float textarea {
  background-color: white;
  padding: 0.75rem 1rem !important;
  height: 7.5625rem;
  margin-bottom: 0.5rem;
  width: 100%;
}
.rw__input-float textarea:focus ~ label {
  color: #00A5A3 !important;
}
.rw__input-float > label {
  top: 1.625rem;
  left: 1rem;
}
.rw__input-float.rw__input-icon [class^=fa] {
  z-index: 1;
}
.rw__input-float.rw__input-icon [class^=fa]:not(.fa-chevron-down) {
  top: 1rem;
}
.rw__input-float.rw__input-icon-left [class^=fa]:not(.fa-xmark) {
  left: 1rem;
}
.rw__input-float.rw__input-icon-left > label {
  left: 2.9375rem;
}
.rw__input-float.rw__input-icon-left > label i {
  color: #BA1B1B !important;
}
.rw__input-float.rw__input-icon-left input,
.rw__input-float.rw__input-icon-left .p-dropdown,
.rw__input-float.rw__input-icon-left .p-multiselect {
  padding-left: 2.9375rem !important;
}
.rw__input-float.rw__input-icon-left .p-dropdown-label,
.rw__input-float.rw__input-icon-left .p-multiselect-label {
  padding-left: 0 !important;
}
.rw__input-float.rw__input-icon-right [class^=fa]:not(.fa-xmark) {
  right: 0.75rem;
}
.rw__input-float.rw__input-icon-right input,
.rw__input-float.rw__input-icon-right .p-dropdown,
.rw__input-float.rw__input-icon-right .p-multiselect {
  padding-right: 3.1875rem !important;
}
.rw__input-float.rw__input-icon-right .p-dropdown-label,
.rw__input-float.rw__input-icon-right .p-multiselect-label {
  padding-right: 0 !important;
}
.rw__input-float.rw__input-icon-close .fa-xmark {
  background-color: #DFE2E2;
  cursor: pointer;
  padding: 0.375rem 0.5rem;
  right: 0.75rem;
  height: 1.5rem;
  width: 1.5rem;
}
.rw__input-float.rw__input-icon-close input,
.rw__input-float.rw__input-icon-close .p-dropdown,
.rw__input-float.rw__input-icon-close .p-multiselect {
  padding-right: 3.1875rem !important;
}
.rw__input-float.rw__input-icon-close .p-dropdown-label,
.rw__input-float.rw__input-icon-close .p-multiselect-label {
  padding-right: 0 !important;
}
.rw__input-float-md input {
  height: 2.25rem !important;
  padding: 0.4375rem 0.4375rem 0.4375rem 0.625rem !important;
}
.rw__input-float-md .p-inputtext {
  font-size: 0.875rem !important;
}
.rw__input-float-md i {
  font-size: 0.875rem !important;
  top: 0.5625rem !important;
}
.rw__input-float-md .p-dropdown-label,
.rw__input-float-md .p-multiselect-label {
  height: 2.25rem !important;
  padding: 0.4375rem 0 0.4375rem 0.625rem !important;
}
.rw__input-float-md label {
  font-size: 0.875rem !important;
  top: 1.3125rem;
}
.rw__input-float-md .p-inputwrapper-filled ~ label {
  font-size: 0.75rem !important;
}
.rw__input-float-fixed {
  width: 17.5rem;
}
.rw__input-float .p-calendar input {
  height: 2.875rem;
}
.rw__input-float .p-calendar-w-btn .p-datepicker-trigger {
  font-size: 1.25rem !important;
  min-width: 2.875rem;
}
.rw__input-default {
  min-width: 17.5rem;
}
@media (max-width: 959px) {
  .rw__input-default {
    min-width: auto;
  }
}
.rw__input-default input {
  background-color: white;
  padding: 0.6875rem 0.6875rem 0.6875rem 0.9375rem !important;
  height: 3rem;
  width: 100%;
}
.rw__input-default .p-dropdown-label,
.rw__input-default .p-multiselect-label {
  padding: 0.6875rem 0.6875rem 0.6875rem 0.9375rem !important;
}
.rw__input-default.rw__input-icon [class^=fa] {
  z-index: 1;
}
.rw__input-default.rw__input-icon [class^=fa]:not(.fa-chevron-down) {
  top: 1rem;
}
.rw__input-default.rw__input-icon-left [class^=fa]:not(.fa-xmark):not(.fa-chevron-down) {
  left: 1rem;
}
.rw__input-default.rw__input-icon-left input,
.rw__input-default.rw__input-icon-left .p-dropdown,
.rw__input-default.rw__input-icon-left .p-multiselect {
  padding-left: 2.9375rem !important;
}
.rw__input-default.rw__input-icon-left input:focus + label {
  margin: 0;
}
.rw__input-default.rw__input-icon-left label {
  margin: -0.5rem 0 0 1.625rem;
}
.rw__input-default.rw__input-icon-left .p-dropdown-label,
.rw__input-default.rw__input-icon-left .p-multiselect-label {
  padding-left: 0 !important;
}
.rw__input-default.rw__input-icon-right [class^=fa]:not(.fa-xmark):not(.fa-chevron-down) {
  right: 0.75rem;
}
.rw__input-default.rw__input-icon-right input,
.rw__input-default.rw__input-icon-right .p-dropdown,
.rw__input-default.rw__input-icon-right .p-multiselect {
  padding-right: 3.1875rem !important;
}
.rw__input-default.rw__input-icon-right .p-dropdown-label,
.rw__input-default.rw__input-icon-right .p-multiselect-label {
  padding-right: 0 !important;
}
.rw__input-default.rw__input-icon-close .fa-xmark {
  background-color: #DFE2E2;
  cursor: pointer;
  padding: 0.375rem 0.5rem;
  right: 0.75rem;
  height: 1.5rem;
  width: 1.5rem;
}
.rw__input-default.rw__input-icon-close input,
.rw__input-default.rw__input-icon-close .p-dropdown,
.rw__input-default.rw__input-icon-close .p-multiselect {
  padding-right: 3.1875rem !important;
}
.rw__input-default.rw__input-icon-close .p-dropdown-label,
.rw__input-default.rw__input-icon-close .p-multiselect-label {
  padding-right: 0 !important;
}
.rw__input-default-fixed {
  width: 17.5rem;
}
.rw__input-default .p-calendar input {
  height: 2.875rem;
}
.rw__input-default .p-calendar-w-btn .p-datepicker-trigger {
  font-size: 1.25rem !important;
  min-width: 2.875rem;
}
.rw__input-default .p-inputmask input {
  color: #313D3D;
}
.rw__input-default .p-inputmask-clear-icon {
  right: 0.9375rem;
}
.rw__input-compact {
  min-width: 11.25rem;
}
@media (max-width: 959px) {
  .rw__input-compact {
    min-width: auto;
  }
}
.rw__input-compact input {
  background-color: white;
  padding: 0.4375rem 0.4375rem 0.4375rem 0.75rem !important;
  height: 2.25rem;
  width: 100%;
}
.rw__input-compact .p-inputnumber-button-group button {
  height: 1rem;
}
.rw__input-compact .p-dropdown-label {
  padding: 0.25rem 0.3125rem 0.3125rem 0.75rem !important;
  height: 2.125rem;
}
.rw__input-compact .p-dropdown-trigger {
  width: 1.875rem;
}
.rw__input-compact .p-dropdown .p-dropdown-clear-icon {
  font-size: 0.75rem;
  top: 1.125rem;
  right: 2.25rem;
}
.rw__input-compact .p-multiselect-label {
  padding: 0.4375rem 0.3125rem 0.3125rem 0.75rem !important;
  height: 2.125rem;
}
.rw__input-compact .p-multiselect-trigger {
  width: 1.875rem;
}
.rw__input-compact > label {
  top: 0.3125rem;
}
.rw__input-compact > label + .fa {
  top: 2rem !important;
}
.rw__input-compact.rw__input-icon .fa-regular,
.rw__input-compact.rw__input-icon .fa-solid,
.rw__input-compact.rw__input-icon .fa-lg,
.rw__input-compact.rw__input-icon [class^=fa] {
  z-index: 1;
}
.rw__input-compact.rw__input-icon .fa-regular:not(.fa-chevron-down),
.rw__input-compact.rw__input-icon .fa-solid:not(.fa-chevron-down),
.rw__input-compact.rw__input-icon .fa-lg:not(.fa-chevron-down),
.rw__input-compact.rw__input-icon [class^=fa]:not(.fa-chevron-down) {
  top: 50%;
  transform: translateY(-50%);
  color: #5C7070;
}
.rw__input-compact.rw__input-icon .fa-regular:not(.fa-xmark),
.rw__input-compact.rw__input-icon .fa-solid:not(.fa-xmark),
.rw__input-compact.rw__input-icon .fa-lg:not(.fa-xmark),
.rw__input-compact.rw__input-icon [class^=fa]:not(.fa-xmark) {
  font-size: 1rem;
}
.rw__input-compact.rw__input-icon-left .fa-regular,
.rw__input-compact.rw__input-icon-left .fa-solid,
.rw__input-compact.rw__input-icon-left .fa-lg,
.rw__input-compact.rw__input-icon-left [class^=fa],
.rw__input-compact.rw__input-icon-left .fa:not(.fa-xmark) {
  left: 0.75rem;
}
.rw__input-compact.rw__input-icon-left input,
.rw__input-compact.rw__input-icon-left .p-dropdown,
.rw__input-compact.rw__input-icon-left .p-multiselect {
  padding-left: 2.4375rem !important;
}
.rw__input-compact.rw__input-icon-left .p-dropdown-label,
.rw__input-compact.rw__input-icon-left .p-multiselect-label {
  padding-left: 0 !important;
}
.rw__input-compact.rw__input-icon-right .fa-regular,
.rw__input-compact.rw__input-icon-right .fa-solid,
.rw__input-compact.rw__input-icon-right .fa-lg,
.rw__input-compact.rw__input-icon-right [class^=fa]:not(.fa-xmark) {
  right: 0.5rem;
}
.rw__input-compact.rw__input-icon-right input,
.rw__input-compact.rw__input-icon-right .p-dropdown,
.rw__input-compact.rw__input-icon-right .p-multiselect {
  padding-right: 2.6875rem !important;
}
.rw__input-compact.rw__input-icon-right .p-dropdown-label,
.rw__input-compact.rw__input-icon-right .p-multiselect-label {
  padding-right: 0 !important;
}
.rw__input-compact.rw__input-icon-close .fa-xmark {
  background-color: #DFE2E2;
  cursor: pointer;
  padding: 0.3125rem 0.375rem;
  right: 0.5rem;
  height: 1.375rem;
  width: 1.375rem;
}
.rw__input-compact.rw__input-icon-close input,
.rw__input-compact.rw__input-icon-close .p-dropdown,
.rw__input-compact.rw__input-icon-close .p-multiselect {
  padding-right: 2.6875rem !important;
}
.rw__input-compact.rw__input-icon-close .p-dropdown-label,
.rw__input-compact.rw__input-icon-close .p-multiselect-label {
  padding-right: 0 !important;
}
.rw__input-compact-fixed {
  width: 11.25rem;
}
.rw__input-compact-md input {
  height: 2rem;
  padding: 0.4375rem 0.4375rem 0.4375rem 0.4375rem !important;
}
.rw__input-compact-md i {
  font-size: 0.875rem !important;
  top: 0.5625rem !important;
}
.rw__input-compact-md .p-dropdown-label,
.rw__input-compact-md .p-multiselect-label {
  height: 1.875rem;
  padding: 0.4375rem 0 0.4375rem 0.3125rem !important;
}
.rw__input-compact-sm input {
  height: 1.5rem;
  padding: 0.4375rem 0.4375rem 0.4375rem 0.4375rem !important;
}
.rw__input-compact-sm .p-dropdown-label,
.rw__input-compact-sm .p-multiselect-label {
  height: 1.375rem;
  padding: 0.4375rem 0.3125rem 0.3125rem 0.3125rem !important;
}
.rw__input-compact.rw__input-suffix .rw__input-suffix-addon {
  top: 0.4375rem;
  right: 0.625rem;
}
.rw__input-compact .p-calendar input {
  height: 2.125rem;
}
.rw__input-compact .p-calendar-w-btn .p-datepicker-trigger {
  font-size: 1.25rem !important;
  min-width: 2.25rem;
}
.rw__input-compact .p-calendar-w-btn .p-datepicker-trigger .pi-calendar {
  background-image: none !important;
  display: flex;
  align-items: center;
}
.rw__input-primary-light .p-dropdown,
.rw__input-primary-light .p-multiselect,
.rw__input-primary-light .p-calendar {
  background-color: #ebf1f1;
  border-color: #ebf1f1;
}
.rw__input-primary-light .p-dropdown-label,
.rw__input-primary-light .p-multiselect-label,
.rw__input-primary-light .p-calendar-label {
  color: #094040 !important;
}
.rw__input-primary-light .p-dropdown-trigger-icon,
.rw__input-primary-light .p-multiselect-trigger-icon,
.rw__input-primary-light .p-calendar-trigger-icon {
  color: #094040 !important;
}
.rw__input-primary-light .p-dropdown .p-datepicker-trigger,
.rw__input-primary-light .p-multiselect .p-datepicker-trigger,
.rw__input-primary-light .p-calendar .p-datepicker-trigger {
  background-color: transparent !important;
}
.rw__input-primary-light .p-dropdown:hover,
.rw__input-primary-light .p-multiselect:hover,
.rw__input-primary-light .p-calendar:hover {
  border-color: #00A5A3;
}
.rw__input-primary-light .p-inputnumber-buttons-stacked .p-inputnumber-button-group button {
  background-color: #DAF5F5;
}
.rw__input-primary-light .p-inputnumber-buttons-stacked .p-inputnumber-button-group button span {
  color: #094040 !important;
}
.rw__input-suffix input {
  padding-right: 3.375rem !important;
}
.rw__input-suffix-addon {
  color: #8D9696;
  position: absolute;
  top: 0.75rem;
  right: 1rem;
}
.rw__input-prefix input {
  padding-left: 3.75rem !important;
}
.rw__input-prefix-addon {
  color: #8D9696;
  position: absolute;
  top: 0.8125rem;
  left: 1rem;
}
.rw__input-custom-multi-select .p-multiselect {
  background: transparent;
}
.rw__input-custom-multi-select .p-multiselect .p-multiselect-label {
  background-image: url("../../assets/images/view_column.svg");
  background-repeat: no-repeat;
  background-position-y: 0.8125rem;
  background-color: transparent;
  color: #0D2222 !important;
  padding: 0.625rem 0.625rem 0.625rem 1.875rem;
}
.rw__input-custom-multi-select .p-multiselect .p-multiselect-trigger {
  width: 0.75rem;
}
.rw__input-custom-multi-select .p-multiselect .p-multiselect-trigger .fa-chevron-down {
  font-size: 0.75rem;
}
.rw__input-custom-multi-select .p-multiselect .p-multiselect-open {
  background-color: #f0fafa;
}
.rw__input-custom-multi-select .p-multiselect .p-multiselect-open .p-multiselect-label {
  background-image: url("../../assets/images/view_column_green.svg");
  color: #00807D !important;
}
.rw__input-custom-multi-select .p-multiselect .p-multiselect-open .p-multiselect-trigger {
  color: #00807D;
}
.rw__input-hide-number {
  /* Chrome, Safari, Edge, Opera */
  /* Firefox */
}
.rw__input-hide-number input::-webkit-outer-spin-button,
.rw__input-hide-number input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.rw__input-hide-number input[type=number] {
  -moz-appearance: textfield;
}
.rw__input-icon-clickable {
  background-color: white;
}
.rw__input-icon-clickable input {
  background-color: transparent;
  position: relative;
  z-index: 1;
}
.rw__input-icon-clickable label {
  z-index: 1;
}
.rw__input-icon-clickable i {
  z-index: 0 !important;
}
.rw__input .ng-touched.ng-invalid {
  border-color: #BA1B1B;
}
.rw__input-transparent .p-dropdown {
  border: 0;
}
.rw__input-transparent .p-dropdown-label {
  font-weight: 500;
  padding: 0;
}
.rw__input-transparent .p-dropdown-trigger {
  width: auto;
}
.rw__input-transparent .p-dropdown-trigger-icon {
  font-size: 0.75rem;
  margin-left: 0.5rem;
}
.rw__input-checkbox p-checkbox {
  margin-right: 0.3125rem;
}
.rw__input-checkbox label {
  position: relative;
  top: 0.125rem;
}
.rw__input-countdown input {
  border: 0;
  text-align: center;
  width: 3.875rem;
}
@media (max-width: 639px) {
  .rw__input-countdown input {
    max-width: none;
    width: 100%;
  }
}
@media (max-width: 639px) {
  .rw__input-countdown .p-inputnumber {
    width: 100%;
  }
}
.rw__input-countdown .p-inputnumber-button {
  height: 3rem;
  width: 3rem;
}
.rw__input + p-selectbutton {
  display: block;
  margin-top: 0.5rem;
}
.rw__input + p-selectbutton .p-button {
  padding: 0.0625rem 0.5rem;
  margin-right: 0.5rem;
}
.rw__input .p-multiselect-token {
  padding: 0.125rem 0.3125rem 0.125rem 0.4375rem !important;
}
@media (max-width: 959px) {
  .rw__input-responsive {
    height: 100%;
  }
  .rw__input-responsive .p-dropdown {
    height: 100%;
  }
  .rw__input-responsive .p-dropdown-trigger,
  .rw__input-responsive .p-dropdown span:not(.p-dropdown-label) {
    display: none;
  }
  .rw__input-responsive .p-dropdown-label span {
    display: none;
  }
  .rw__input-responsive.rw__input-compact-md.rw__input-icon-left .p-dropdown {
    padding-left: 1.875rem !important;
  }
  .rw__input-responsive.rw__input-compact-md.rw__input-icon-left i {
    font-size: 0.6875rem !important;
    left: 0.625rem !important;
    top: 0.625rem !important;
  }
}

.ltr .p-inputgroup .rw__input input {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.ltr .p-inputgroup .rw__button {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.ltr .p-inputnumber-buttons-stacked .p-inputtext {
  border-right: 0;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.ltr .p-inputnumber-buttons-stacked .p-inputnumber-button-group {
  border-left: 0;
  border-top-right-radius: 0.3125rem !important;
  border-bottom-right-radius: 0.3125rem !important;
}

.rtl input,
.rtl p-dropdown,
.rtl .p-dropdown,
.rtl p-multiselect,
.rtl .p-multiselect,
.rtl textarea {
  font-weight: 600;
}
.rtl .p-dropdown-label, .rtl .p-dropdown-label span,
.rtl .p-multiselect-label,
.rtl .p-multiselect-label span {
  font-weight: 600;
}
.rtl .p-dropdown-token span.p-multiselect-token-icon,
.rtl .p-multiselect-token span.p-multiselect-token-icon {
  font-family: "primeicons" !important;
  margin: 0 0.5rem 0 0 !important;
}
.rtl .p-dropdown-header .p-dropdown-filter {
  margin-right: 0;
  margin-left: -1.5rem;
  padding-left: 0.9375rem !important;
  padding-right: 0.6875rem;
}
.rtl .p-dropdown-header .p-dropdown-filter-icon {
  right: auto;
  left: 0.75rem;
}
.rtl .p-dropdown-clear-icon {
  right: auto !important;
  left: 2.25rem;
}
.rtl .rw__input .p-password-input.p-password-input {
  padding-inline-end: 3.25rem !important;
}
.rtl .rw__input .p-password-clear-icon {
  left: 2rem;
  right: auto !important;
}
.rtl .rw__input-float input {
  padding: 0.6875rem 0.9375rem 0.6875rem 0.6875rem !important;
}
.rtl .rw__input-float .p-password-input {
  padding-left: 3.25rem !important;
}
.rtl .rw__input-float .p-dropdown-label,
.rtl .rw__input-float .p-multiselect-label {
  padding: 0.6875rem 0.9375rem 0.6875rem 0.6875rem !important;
}
.rtl .rw__input-float.rw__input-icon-left > label {
  right: 2.9375rem;
}
.rtl .rw__input-float.rw__input-icon-left input,
.rtl .rw__input-float.rw__input-icon-left .p-dropdown,
.rtl .rw__input-float.rw__input-icon-left .p-multiselect {
  padding-left: 0.6875rem !important;
  padding-right: 2.9375rem !important;
}
.rtl .rw__input-float.rw__input-icon-left .p-dropdown-label,
.rtl .rw__input-float.rw__input-icon-left .p-multiselect-label {
  padding-right: 0 !important;
}
.rtl .rw__input-float.rw__input-icon-right input,
.rtl .rw__input-float.rw__input-icon-right .p-dropdown,
.rtl .rw__input-float.rw__input-icon-right .p-multiselect {
  padding-left: 3.25rem !important;
  padding-right: 0.6875rem !important;
}
.rtl .rw__input-float.rw__input-icon-right .p-dropdown-label,
.rtl .rw__input-float.rw__input-icon-right .p-multiselect-label {
  padding-left: 0 !important;
}
.rtl .rw__input-float.rw__input-icon .p-password-clear-icon {
  left: 2rem !important;
  right: auto;
}
.rtl .rw__input-compact input {
  padding: 0.4375rem 0.75rem 0.4375rem 0.4375rem !important;
}
.rtl .rw__input-compact .p-inputnumber-button-group button {
  height: 1rem;
}
.rtl .rw__input-compact .p-dropdown-label,
.rtl .rw__input-compact .p-multiselect-label {
  padding: 0.4375rem 0.75rem 0.4375rem 0.4375rem !important;
}
.rtl .rw__input-compact.rw__input-icon-left .fa-regular,
.rtl .rw__input-compact.rw__input-icon-left .fa-solid,
.rtl .rw__input-compact.rw__input-icon-left .fa-lg,
.rtl .rw__input-compact.rw__input-icon-left [class^=fa],
.rtl .rw__input-compact.rw__input-icon-left .fa:not(.fa-xmark) {
  right: 0.75rem;
}
.rtl .rw__input-compact.rw__input-icon-left input,
.rtl .rw__input-compact.rw__input-icon-left .p-dropdown,
.rtl .rw__input-compact.rw__input-icon-left .p-multiselect {
  padding-left: 0.75rem !important;
  padding-right: 2.4375rem !important;
}
.rtl .rw__input-compact.rw__input-icon-left .p-dropdown-label,
.rtl .rw__input-compact.rw__input-icon-left .p-multiselect-label {
  padding-right: 0 !important;
}
.rtl .rw__input-compact.rw__input-icon-right .fa-regular,
.rtl .rw__input-compact.rw__input-icon-right .fa-solid,
.rtl .rw__input-compact.rw__input-icon-right .fa-lg,
.rtl .rw__input-compact.rw__input-icon-right [class^=fa]:not(.fa-xmark) {
  left: 0.5rem;
}
.rtl .rw__input-compact.rw__input-icon-right input,
.rtl .rw__input-compact.rw__input-icon-right .p-dropdown,
.rtl .rw__input-compact.rw__input-icon-right .p-multiselect {
  padding-left: 2.6875rem !important;
  padding-right: 0.75rem !important;
}
.rtl .rw__input-compact.rw__input-icon-right .p-dropdown-label,
.rtl .rw__input-compact.rw__input-icon-right .p-multiselect-label {
  padding-left: 0 !important;
}
.rtl .rw__input-default input {
  padding: 0.6875rem 0.9375rem 0.6875rem 0.6875rem !important;
}
.rtl .rw__input-default.rw__input-icon-close input,
.rtl .rw__input-default.rw__input-icon-close .p-dropdown,
.rtl .rw__input-default.rw__input-icon-close .p-multiselect {
  padding-left: 3.1875rem !important;
  padding-right: 0 !important;
}
.rtl .rw__input-default.rw__input-icon-close .p-dropdown-label,
.rtl .rw__input-default.rw__input-icon-close .p-multiselect-label {
  padding-left: 0 !important;
}
.rtl .rw__input-default.rw__input-icon-left input,
.rtl .rw__input-default.rw__input-icon-left .p-dropdown,
.rtl .rw__input-default.rw__input-icon-left .p-multiselect {
  padding-left: 0 !important;
  padding-right: 2.9375rem !important;
}
.rtl .rw__input-default.rw__input-icon-left .p-dropdown-label,
.rtl .rw__input-default.rw__input-icon-left .p-multiselect-label {
  padding-right: 0 !important;
}
.rtl .rw__input-default.rw__input-icon-right input,
.rtl .rw__input-default.rw__input-icon-right .p-dropdown,
.rtl .rw__input-default.rw__input-icon-right .p-multiselect {
  padding-left: 2.9375rem !important;
  padding-right: 0 !important;
}
.rtl .rw__input-default.rw__input-icon-right .p-dropdown-label,
.rtl .rw__input-default.rw__input-icon-right .p-multiselect-label {
  padding-left: 0 !important;
}
.rtl .rw__input-default .p-inputmask-clear-icon {
  left: 0.9375rem;
  right: auto;
}
.rtl .rw__input-icon-close .fa-xmark {
  left: 0.75rem;
  right: auto;
}
.rtl .rw__input-icon-left [class^=fa]:not(.fa-xmark):not(.fa-chevron-down) {
  left: auto;
  right: 1rem;
}
.rtl .rw__input-icon-right [class^=fa]:not(.fa-xmark):not(.fa-chevron-down) {
  right: auto;
  left: 0.75rem;
}
.rtl .rw__input-custom-multi-select .p-multiselect .p-multiselect-label {
  padding: 0.625rem 1.875rem 0.625rem 0.625rem !important;
  background-position-x: 100%;
}
.rtl .rw__input-suffix input {
  padding-left: 3.375rem !important;
  padding-right: 0.9375rem !important;
}
.rtl .rw__input-suffix-addon {
  right: auto;
  left: 1rem;
}
.rtl .rw__input-prefix input {
  padding-left: 0.9375rem !important;
  padding-right: 3.75rem !important;
}
.rtl .rw__input-prefix-addon {
  left: auto;
  right: 1rem;
  top: 0.75rem;
}
.rtl .rw__input-checkbox p-checkbox {
  margin-left: 0.3125rem;
  margin-right: 0;
}
.rtl .p-inputgroup .rw__input input {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.rtl .p-inputgroup .rw__button {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.rtl .p-calendar-clearable .p-calendar-clear-icon {
  left: 0.875rem;
  right: auto !important;
}
.rtl .p-calendar-w-btn .p-calendar-clear-icon {
  left: 3.75rem;
  right: auto !important;
}
.rtl .p-calendar-w-btn .p-datepicker-trigger {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.rtl .p-inputnumber-buttons-stacked .p-inputtext {
  border-left: 0;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.rtl .p-inputnumber-buttons-stacked .p-inputnumber-button-group {
  border-right: 0;
  border-top-left-radius: 0.3125rem !important;
  border-bottom-left-radius: 0.3125rem !important;
}
.rtl .p-inputnumber-buttons-stacked .p-inputnumber-button-group .p-inputnumber-button {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.rtl .p-inputnumber-buttons-stacked .p-inputnumber-button-group .p-inputnumber-button:first-child {
  border-top-left-radius: 4px !important;
}
.rtl .p-inputnumber-buttons-stacked .p-inputnumber-button-group .p-inputnumber-button:last-child {
  border-top-left-radius: 0 !important;
}
.rtl .p-password-mask .pi-eye,
.rtl .p-password-mask .pi-eye-slash {
  right: auto;
  left: 0.75rem;
}

.rw__dropdown-primary-list li.p-highlight {
  background-color: #DAF5F5 !important;
}

.p-overlay-content .p-dropdown-panel {
  border-radius: 0.25rem;
  border: 0.0625rem solid #E3E8E8;
  background-color: #fff;
  box-shadow: 0 0.25rem 0.375rem rgba(0, 0, 0, 0.1);
}

.grid > .col, .grid > [class*=col],
.form-row > .col, .form-row > [class*=col-] {
  position: relative;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row .form-row > .col, .form-row .form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.rw-input-sku {
  position: relative;
}
.rw-input-sku rw-input {
  width: 100%;
  display: block;
}
.rw-input-sku rw-input .p-float-label input {
  padding-right: 60px;
}
.rw-input-sku rw-input .p-float-label .reset-button {
  right: 60px;
}
.rw-input-sku rw-sku-generator {
  position: absolute;
  right: 0;
  bottom: 0;
  height: 100%;
  z-index: 1;
  border: 1px solid transparent;
}
.rw-input-sku rw-sku-generator .p-button {
  border: 0 !important;
  padding: 0;
  min-width: 0;
  width: 50px;
  height: 50px;
  background-color: #EEF8F8;
}
.rw-input-sku rw-sku-generator .p-button:enabled:hover, .rw-input-sku rw-sku-generator .p-button:enabled:focus, .rw-input-sku rw-sku-generator .p-button:enabled:active {
  background-color: #DAF5F5 !important;
}

.rtl .p-float-label > label {
  right: 0.75rem;
}
.rtl .rw-input-sku rw-input .p-float-label input {
  padding-left: 60px;
  padding-right: 0.75rem;
}
.rtl .rw-input-sku rw-input .p-float-label .reset-button {
  left: 60px !important;
  right: auto;
}
.rtl .rw-input-sku rw-sku-generator {
  left: 0;
  right: auto;
}

.rw__button {
  box-shadow: none !important;
  font-size: 1rem;
  height: 2.625rem;
  min-width: 6rem;
  padding: 0 1.25rem;
  text-decoration: none;
}
.rw__button-xss {
  font-size: 0.75rem !important;
  height: 1.5rem !important;
  min-width: 0 !important;
  padding: 0 !important;
}
.rw__button-xss i {
  font-size: 0.75rem !important;
}
.rw__button-xss.rw__button-icon {
  min-width: auto !important;
  width: 1.5rem !important;
}
.rw__button-xss.rw__button-icon-fluid {
  width: 100% !important;
}
.rw__button-xss .p-splitbutton-defaultbutton {
  font-size: 0.75rem !important;
  padding: 0 0 0 0.375rem !important;
}
.rw__button-xss .p-splitbutton-menubutton {
  padding: 0 0.375rem 0 0.625rem !important;
  width: auto !important;
}
.rw__button-xss .p-splitbutton-menubutton .pi {
  font-size: 0.75rem !important;
}
.rw__button-xs {
  font-size: 0.875rem !important;
  height: 2rem !important;
  min-width: 5rem !important;
  padding: 0 0.75rem !important;
}
.rw__button-xs i,
.rw__button-xs .p-button-icon {
  font-size: 0.875rem !important;
}
.rw__button-xs.rw__button-icon {
  min-width: auto !important;
  width: 2rem !important;
}
.rw__button-xs.rw__button-icon-fluid {
  width: 100% !important;
}
.rw__button-xs .p-splitbutton-defaultbutton {
  font-size: 0.875rem !important;
  padding-left: 0.75rem !important;
}
.rw__button-xs .p-splitbutton-defaultbutton span.p-button-icon {
  font-size: 0.875rem;
}
.rw__button-xs .p-splitbutton-menubutton {
  padding: 0.5rem !important;
  width: auto !important;
}
.rw__button-xs .p-splitbutton-menubutton .pi {
  font-size: 0.75rem !important;
}
.rw__button-sm {
  font-size: 0.875rem !important;
  height: 2.25rem !important;
  min-width: 6rem !important;
  padding: 0 0.9375rem !important;
}
.rw__button-sm i {
  font-size: 1rem !important;
}
.rw__button-sm.rw__button-icon {
  min-width: auto !important;
  width: 2.25rem !important;
}
.rw__button-sm.rw__button-icon-fluid {
  width: 100% !important;
}
.rw__button-sm .p-splitbutton-defaultbutton {
  font-size: 0.875rem !important;
  padding-left: 0.9375rem !important;
  padding-right: 0 !important;
}
.rw__button-sm .p-splitbutton-menubutton {
  padding-right: 0.9375rem !important;
}
.rw__button-sm .p-splitbutton-menubutton .pi {
  font-size: 0.875rem !important;
}
.rw__button-md {
  height: 2.625rem !important;
  min-width: 6rem;
  padding: 0 0.9375rem;
}
@media (max-width: 959px) {
  .rw__button-md {
    height: 100% !important;
    min-height: 2.625rem;
  }
}
.rw__button-lg {
  height: 3rem !important;
  min-width: 7rem !important;
  padding: 0 1.5625rem !important;
}
.rw__button-lg.rw__button-icon {
  min-width: auto !important;
  width: 3rem !important;
}
.rw__button-lg.rw__button-icon-fluid {
  width: 100% !important;
}
.rw__button-lg .p-splitbutton-defaultbutton {
  padding-left: 1.5625rem !important;
}
.rw__button-lg .p-splitbutton-menubutton {
  padding-right: 1.5625rem !important;
}
.rw__button-icon {
  padding: 0 !important;
  min-width: auto !important;
  width: 2.625rem;
}
.rw__button-icon .p-button-label {
  display: none;
}
.rw__button-icon .rw__button {
  padding: 0 !important;
  min-width: auto !important;
  width: 2.625rem;
}
.rw__button-icon i {
  font-size: 1.125rem;
  margin: 0 !important;
}
.rw__button-icon-fluid {
  width: 100% !important;
}
@media (max-width: 959px) {
  .rw__button-icon-only-resp {
    justify-content: center;
    min-width: 2rem !important;
    padding: 0 !important;
  }
  .rw__button-icon-only-resp button {
    min-width: 2rem !important;
  }
  .rw__button-icon-only-resp i {
    font-size: 0.75rem !important;
    margin-right: 0 !important;
  }
  .rw__button-icon-only-resp .p-button-icon {
    margin-inline: 0 !important;
  }
  .rw__button-icon-only-resp .p-button-label, .rw__button-icon-only-resp .custom-label {
    display: none;
  }
  .rw__button-icon-only-resp .p-splitbutton-defaultbutton {
    padding-right: 0.75rem !important;
  }
}
.rw__button.p-button-raised.p-button-white {
  background-color: white !important;
  color: #00807D !important;
}
.rw__button.p-button-raised.p-button-white:focus, .rw__button.p-button-raised.p-button-white:hover {
  background-color: #E3E8E8 !important;
}
.rw__button.p-button-raised.p-button-white:active {
  background-color: #D5ECEC !important;
}
.rw__button.p-button-raised.p-button-white .p-splitbutton-defaultbutton,
.rw__button.p-button-raised.p-button-white .p-splitbutton-menubutton {
  background-color: white !important;
  color: #00807D !important;
}
.rw__button.p-button-raised.p-button-primary {
  background-color: #00807D !important;
}
.rw__button.p-button-raised.p-button-primary:focus, .rw__button.p-button-raised.p-button-primary:hover {
  background-color: #00A5A3 !important;
}
.rw__button.p-button-raised.p-button-primary:active {
  background-color: #094040 !important;
}
.rw__button.p-button-raised.p-button-primary .p-splitbutton-defaultbutton,
.rw__button.p-button-raised.p-button-primary .p-splitbutton-menubutton {
  background-color: #00807D !important;
}
.rw__button.p-button-raised.p-button-primary .p-splitbutton-defaultbutton:disabled,
.rw__button.p-button-raised.p-button-primary .p-splitbutton-menubutton:disabled {
  background-color: #DFE2E2 !important;
}
.rw__button.p-button-raised.p-button-primary-light {
  background-color: #D5ECEC !important;
  color: #00807D !important;
  border: 1px solid transparent;
}
.rw__button.p-button-raised.p-button-primary-light:focus, .rw__button.p-button-raised.p-button-primary-light:hover {
  border-color: #00807D !important;
}
.rw__button.p-button-raised.p-button-primary-light:active {
  background-color: #DAF5F5 !important;
  border-color: #00807D !important;
}
.rw__button.p-button-raised.p-button-primary-light .p-splitbutton-defaultbutton,
.rw__button.p-button-raised.p-button-primary-light .p-splitbutton-menubutton {
  background-color: #D5ECEC !important;
  color: #00807D !important;
}
.rw__button.p-button-raised.p-button-primary-light:disabled {
  background-color: #D2DADA !important;
}
.rw__button.p-button-raised.p-button-primary-light:disabled img {
  filter: grayscale(1);
}
.rw__button.p-button-raised.p-button-danger {
  background-color: #BA1B1B !important;
}
.rw__button.p-button-raised.p-button-danger:focus, .rw__button.p-button-raised.p-button-danger:hover {
  background-color: #D61F1F !important;
}
.rw__button.p-button-raised.p-button-danger:active {
  background-color: #930006 !important;
}
.rw__button.p-button-raised.p-button-danger .p-splitbutton-defaultbutton,
.rw__button.p-button-raised.p-button-danger .p-splitbutton-menubutton {
  background-color: #BA1B1B !important;
}
.rw__button.p-button-raised.p-button-purple {
  background-color: white !important;
  color: #561157 !important;
}
.rw__button.p-button-raised.p-button-purple:focus, .rw__button.p-button-raised.p-button-purple:hover {
  background-color: #F4F5F5 !important;
}
.rw__button.p-button-raised.p-button-purple:active {
  background-color: #E3E8E8 !important;
}
.rw__button.p-button-raised.p-button-purple .p-splitbutton-defaultbutton,
.rw__button.p-button-raised.p-button-purple .p-splitbutton-menubutton {
  background-color: white !important;
  color: #561157 !important;
}
.rw__button.p-button-raised:disabled, .rw__button.p-button-raised.rw__button-loading {
  background-color: #E3E8E8 !important;
  color: #AFB6B6 !important;
  pointer-events: none;
}
.rw__button.p-button-outlined.p-button-white {
  border-color: white !important;
  color: white !important;
}
.rw__button.p-button-outlined.p-button-white:focus, .rw__button.p-button-outlined.p-button-white:hover {
  background-color: #F4F6F6 !important;
  color: #00807D !important;
}
.rw__button.p-button-outlined.p-button-white:active {
  background-color: #D5ECEC !important;
  color: #00807D !important;
}
.rw__button.p-button-outlined.p-button-white .p-splitbutton-defaultbutton,
.rw__button.p-button-outlined.p-button-white .p-splitbutton-menubutton {
  border-color: white !important;
  color: white !important;
}
.rw__button.p-button-outlined.p-button-primary {
  border-color: #00807D !important;
  color: #00807D !important;
}
.rw__button.p-button-outlined.p-button-primary:focus, .rw__button.p-button-outlined.p-button-primary:hover {
  background-color: #E3E8E8 !important;
}
.rw__button.p-button-outlined.p-button-primary:active {
  background-color: #D2DADA !important;
}
.rw__button.p-button-outlined.p-button-primary .p-splitbutton-defaultbutton,
.rw__button.p-button-outlined.p-button-primary .p-splitbutton-menubutton {
  border-color: #00807D !important;
  color: #00807D !important;
}
.rw__button.p-button-outlined.p-button-primary-fill-light {
  background-color: #EDFAFA !important;
  border-color: #00807D !important;
}
.rw__button.p-button-outlined.p-button-primary-fill-light:focus, .rw__button.p-button-outlined.p-button-primary-fill-light:hover {
  background-color: #F4F5F5 !important;
}
.rw__button.p-button-outlined.p-button-primary-fill-light:active {
  background-color: #EEF8F8 !important;
}
.rw__button.p-button-outlined.p-button-primary-fill-light .p-splitbutton-defaultbutton,
.rw__button.p-button-outlined.p-button-primary-fill-light .p-splitbutton-menubutton {
  background-color: #EDFAFA !important;
  border-color: #00807D !important;
}
.rw__button.p-button-outlined.p-button-primary-fill-light .p-splitbutton-defaultbutton,
.rw__button.p-button-outlined.p-button-primary-fill-light .p-splitbutton-menubutton {
  border-color: #00807D !important;
}
.rw__button.p-button-outlined.p-button-danger {
  border-color: #BA1B1B !important;
  color: #BA1B1B !important;
}
.rw__button.p-button-outlined.p-button-danger:focus, .rw__button.p-button-outlined.p-button-danger:hover {
  background-color: #FFF0ED !important;
}
.rw__button.p-button-outlined.p-button-danger:active {
  background-color: #FFDCD6 !important;
}
.rw__button.p-button-outlined.p-button-danger .p-splitbutton-defaultbutton,
.rw__button.p-button-outlined.p-button-danger .p-splitbutton-menubutton {
  border-color: #BA1B1B !important;
  color: #BA1B1B !important;
}
.rw__button.p-button-outlined:disabled, .rw__button.p-button-outlined.rw__button-loading,
.rw__button.p-button-outlined .p-splitbutton-defaultbutton:disabled,
.rw__button.p-button-outlined .p-splitbutton-menubutton:disabled {
  border-color: #D2DADA !important;
  color: #AFB6B6 !important;
  pointer-events: none;
}
.rw__button.p-button-text.p-button-white {
  color: white !important;
}
.rw__button.p-button-text.p-button-white:focus, .rw__button.p-button-text.p-button-white:hover {
  background-color: #F4F5F5 !important;
  color: #5B6565 !important;
}
.rw__button.p-button-text.p-button-white:active {
  background-color: #E3E8E8 !important;
  color: #5B6565 !important;
}
.rw__button.p-button-text.p-button-white .p-splitbutton-defaultbutton,
.rw__button.p-button-text.p-button-white .p-splitbutton-menubutton {
  color: white !important;
}
.rw__button.p-button-text.p-button-primary {
  color: #00807D !important;
}
.rw__button.p-button-text.p-button-primary:focus, .rw__button.p-button-text.p-button-primary:hover {
  background-color: #E3E8E8 !important;
}
.rw__button.p-button-text.p-button-primary:active {
  background-color: #BDEFEF !important;
}
.rw__button.p-button-text.p-button-primary .p-splitbutton-defaultbutton,
.rw__button.p-button-text.p-button-primary .p-splitbutton-menubutton {
  color: #00807D !important;
}
.rw__button.p-button-text.p-button-danger {
  color: #BA1B1B !important;
}
.rw__button.p-button-text.p-button-danger:focus, .rw__button.p-button-text.p-button-danger:hover {
  background-color: #FFF0ED !important;
}
.rw__button.p-button-text.p-button-danger:active {
  background-color: #FFDCD6 !important;
}
.rw__button.p-button-text.p-button-danger .p-splitbutton-defaultbutton,
.rw__button.p-button-text.p-button-danger .p-splitbutton-menubutton {
  color: #BA1B1B !important;
}
.rw__button.p-button-text.p-button-plain {
  color: #5C7070 !important;
}
.rw__button.p-button-text.p-button-plain:focus, .rw__button.p-button-text.p-button-plain:hover {
  background-color: #E3E8E8 !important;
}
.rw__button.p-button-text.p-button-plain:active {
  background-color: #D2DADA !important;
}
.rw__button.p-button-text.p-button-plain .p-splitbutton-defaultbutton,
.rw__button.p-button-text.p-button-plain .p-splitbutton-menubutton {
  color: #5C7070 !important;
}
.rw__button.p-button-text:disabled, .rw__button.p-button-text.rw__button-loading {
  color: #AFB6B6 !important;
  pointer-events: none;
}
.rw__button.p-button-text.rw__tooltip-button[disabled], .rw__button.p-button-text.rw__tooltip-button:disabled {
  pointer-events: auto !important;
}
.rw__button.p-button-text.rw__tooltip-button[disabled]:hover, .rw__button.p-button-text.rw__tooltip-button:disabled:hover {
  background-color: transparent !important;
}
.rw__button i {
  font-size: 1.125rem;
  margin-right: 0.625rem;
}
.rw__button.p-splitbutton {
  padding: 0 !important;
}
.rw__button.p-splitbutton .p-splitbutton-defaultbutton {
  background-color: transparent !important;
  padding-left: 1.25rem;
  font-size: 1rem;
}
.rw__button.p-splitbutton .p-splitbutton-menubutton {
  background-color: transparent !important;
  padding-right: 1.25rem;
}
.rw__button.p-splitbutton .p-splitbutton-menubutton .p-button-label {
  display: none;
}
.rw__button.p-splitbutton .p-splitbutton-menubutton + p-tieredmenu .p-tieredmenu {
  width: 100%;
}
.rw__button-hide-dropdown.p-splitbutton .p-splitbutton-defaultbutton {
  border-right: 0.0625rem solid !important;
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.rw__button-hide-dropdown.p-splitbutton .p-splitbutton-menubutton {
  display: none !important;
}
.rw__button.disabled {
  opacity: 1 !important;
}
.rw__button-tooltip {
  background-color: transparent;
}
.rw__button-tooltip:hover {
  opacity: 1 !important;
}
.rw__button-hide-button-label .p-button-label, .rw__button-hide-default-label .p-button-label {
  display: none;
}
.rw__button .p-badge {
  font-size: 0.75rem;
  font-weight: normal;
  height: 1.25rem;
  min-width: 1.25rem;
  line-height: 1.25rem;
}
.rw__button-select .p-button {
  font-size: 1rem;
  font-weight: 400;
  height: 2.625rem;
  padding-left: 1.5625rem;
  padding-right: 1.5625rem;
}
.rw__button-select .p-button.p-highlight, .rw__button-select .p-button.p-highlight:hover, .rw__button-select .p-button.p-highlight:focus {
  background-color: #00807D;
  color: white;
}
.rw__button-select-lg .p-button {
  height: 3rem;
}

.rtl .rw__button i {
  margin-left: 0.625rem;
  margin-right: 0 !important;
}
.rtl .rw__button .p-button-icon-left {
  margin-left: 0.5rem;
  margin-right: 0;
}
.rtl .rw__button.p-button-icon-only .p-button-icon {
  margin: 0 !important;
}
.rtl .rw__button.p-splitbutton .p-splitbutton-defaultbutton {
  border-radius: 0 0.25rem 0.25rem 0 !important;
  padding-right: 1.25rem;
  padding-left: 0.75rem;
}
.rtl .rw__button.p-splitbutton .p-splitbutton-menubutton {
  border-radius: 0.25rem 0 0 0.25rem !important;
  padding-right: 0.75rem;
  padding-left: 1.25rem;
}
.rtl .rw__button.p-button-outlined.p-button-white .p-splitbutton-defaultbutton {
  border-right: 0.0625rem solid white !important;
}
.rtl .rw__button.p-button-outlined.p-button-white .p-splitbutton-menubutton {
  border-left: 0.0625rem solid white !important;
}
.rtl .rw__button.p-button-outlined.p-button-primary .p-splitbutton-defaultbutton, .rtl .rw__button.p-button-outlined.p-button-primary-fill-light .p-splitbutton-defaultbutton {
  border-right: 0.0625rem solid #00807D !important;
}
.rtl .rw__button.p-button-outlined.p-button-primary .p-splitbutton-menubutton, .rtl .rw__button.p-button-outlined.p-button-primary-fill-light .p-splitbutton-menubutton {
  border-left: 0.0625rem solid #00807D !important;
}
.rtl .rw__button.p-button-outlined.p-button-danger .p-splitbutton-defaultbutton {
  border-right: 0.0625rem solid #BA1B1B !important;
  border-left: 0 !important;
}
.rtl .rw__button.p-button-outlined.p-button-danger .p-splitbutton-menubutton {
  border-left: 0.0625rem solid #BA1B1B !important;
  border-right: 0 !important;
}
.rtl .rw__button.p-button-outlined .p-splitbutton-defaultbutton {
  border-left: 0 !important;
}
.rtl .rw__button.p-button-outlined .p-splitbutton-menubutton {
  border-right: 0 !important;
}
.rtl .rw__button.p-button-outlined .p-splitbutton-defaultbutton:disabled,
.rtl .rw__button.p-button-outlined .p-splitbutton-menubutton:disabled {
  border-color: #DFE2E2 !important;
}

.rw__radio-button-block-wrap p-radiobutton:not(:last-child) {
  margin-bottom: 1.125rem;
}
.rw__radio-button .p-radiobutton {
  width: 24px;
  height: 24px;
}
.rw__radio-button .p-radiobutton:not(.p-radiobutton-disabled):not(.p-radiobutton-focused):hover {
  box-shadow: none;
}
.rw__radio-button .p-radiobutton .p-radiobutton-box {
  width: 100%;
  height: 100%;
  border: 2px solid #5B6565;
  background: transparent;
}
.rw__radio-button .p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
  border-color: #00807D;
}
.rw__radio-button .p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
  width: 16px;
  height: 16px;
  transition: transition(0.2s);
  background-color: #00807D;
}
.rw__radio-button .p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: #00807D;
  background: transparent;
}
.rw__radio-button .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
  border-color: transparent;
  background: transparent;
}
.rw__radio-button .p-radiobutton-checked:not(.p-radiobutton-disabled):hover {
  box-shadow: 0 0 1px 10px rgba(0, 128, 125, 0.12);
}
.rw__radio-button .p-radiobutton-label {
  line-height: 1.25rem;
  margin-bottom: 0;
  color: #5B6565;
}
.rw__radio-button .p-radiobutton-label-p-disabled {
  color: #8D9696;
}
.rw__radio-button .p-radiobutton-disabled .p-radiobutton-box {
  border-color: #8D9696;
}

.rw__custom-radio-button-block-wrap p-radiobutton:not(:last-child) {
  margin-bottom: 1.125rem;
}
.rw__custom-radio-button .p-radiobutton {
  width: 20px;
  height: 20px;
}
.rw__custom-radio-button .p-radiobutton:not(.p-radiobutton-disabled):not(.p-radiobutton-focused):hover {
  box-shadow: none;
}
.rw__custom-radio-button .p-radiobutton .p-radiobutton-box {
  border-width: 0 !important;
  border-radius: 50% !important;
  background: transparent;
}
.rw__custom-radio-button .p-radiobutton .p-radiobutton-box:before {
  content: "";
  background: white;
  border-radius: 100%;
  border: 2px solid;
  border-color: #5B6565;
  display: block;
  width: 20px;
  height: 20px;
  position: relative;
  vertical-align: top;
  cursor: pointer;
  text-align: center;
}
.rw__custom-radio-button .p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
  display: none;
}
.rw__custom-radio-button .p-radiobutton .p-radiobutton-box.p-highlight:before {
  background-color: #00807D;
  border-color: #00807D;
  box-shadow: inset 0 0 0 3px #fff;
}
.rw__custom-radio-button .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
  box-shadow: 0 0 1px 10px rgba(0, 128, 125, 0.12);
}
.rw__custom-radio-button .p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
  box-shadow: none;
}
.rw__custom-radio-button .p-radiobutton:not(.p-radiobutton-disabled):not(.p-radiobutton-focused):hover .p-radiobutton-box:before {
  border-color: #00807D;
}
.rw__custom-radio-button .p-radiobutton-checked:not(.p-radiobutton-disabled):hover {
  box-shadow: none;
}
.rw__custom-radio-button .p-radiobutton-label {
  line-height: 1.25rem;
  margin-bottom: 0;
  color: #5B6565;
}
.rw__custom-radio-button .p-radiobutton-label-p-disabled {
  color: #8D9696;
}
.rw__custom-radio-button .p-radiobutton-disabled .p-radiobutton-box:before, .rw__custom-radio-button .p-radiobutton:disabled .p-radiobutton-box:before {
  content: "";
  border-color: #8D9696;
}
.rw__custom-radio-button .p-radiobutton-disabled .p-radiobutton-box.p-disabled, .rw__custom-radio-button .p-radiobutton:disabled .p-radiobutton-box.p-disabled {
  opacity: 0.8;
}

.p-field-checkbox {
  display: flex;
  align-items: center;
}

.rw__checkbox .p-checkbox {
  display: block;
  width: 16px;
  height: 16px;
}
.rw__checkbox .p-checkbox:hover {
  box-shadow: none;
}
.rw__checkbox .p-checkbox .p-checkbox-box {
  border: 2px solid #5C7070;
  background: transparent;
  width: 100%;
  height: 100%;
  border-radius: 3px;
}
.rw__checkbox .p-checkbox .p-checkbox-box.p-highlight {
  border-color: #00807D;
  background: #00807D;
}
.rw__checkbox .p-checkbox .p-checkbox-box.p-highlight.p-disabled {
  background: #AFB6B6;
}
.rw__checkbox .p-checkbox .p-checkbox-box.p-disabled {
  border-color: #AFB6B6;
  opacity: 1;
}
.rw__checkbox .p-checkbox .p-checkbox-box .p-checkbox-icon.pi-check:before {
  content: "\f00c";
  font-family: "Font Awesome 6 Pro";
  position: absolute;
  top: 50% !important;
  left: 50%;
  border-right: none;
  border-bottom: none;
  transform: translate(-50%, -50%);
  transform-origin: 0 100%;
  animation: unset;
  font-size: 0.75rem;
}
.rw__checkbox-md .p-checkbox {
  width: 16px;
  height: 16px;
}
.rw__checkbox-md + label {
  line-height: 1.25rem;
}
.rw__checkbox-sm .p-checkbox {
  width: 13px;
  height: 13px;
}
.rw__checkbox-sm + label {
  line-height: 1rem;
}
.rw__checkbox + label {
  line-height: 1.25rem;
  color: #0D2222;
}

.rtl .rw__checkbox .p-checkbox .p-checkbox-box .p-checkbox-icon.pi-check:before {
  top: 50% !important;
  right: auto;
}

.p-field-switch {
  display: flex;
  align-items: center;
}

.rw__switch .p-inputswitch {
  height: 20px;
  width: 32px;
  display: flex;
}
.rw__switch .p-inputswitch-slider {
  background: #5B6565;
  border-radius: 0.75rem;
}
.rw__switch .p-inputswitch-slider:before {
  width: 0.875rem;
  height: 0.875rem;
  margin-top: -0.44rem;
}
.rw__switch .p-inputswitch-checked .p-inputswitch-slider {
  background: #00807D;
}
.rw__switch .p-inputswitch-checked .p-inputswitch-slider:before {
  background-color: #fff;
}
.ltr .rw__switch .p-inputswitch-slider:before {
  left: 3px;
}
.ltr .rw__switch .p-inputswitch-checked .p-inputswitch-slider:before {
  transform: translateX(0.8rem);
}

.rtl .rw__switch .p-inputswitch-slider:before {
  right: 3px;
}
.rtl .rw__switch .p-inputswitch-checked .p-inputswitch-slider:before {
  transform: translateX(-0.7rem);
}

.h-25 {
  height: 25% !important;
}

@media (min-width: 960px) {
  .h-md-25 {
    height: 25% !important;
  }
}
.h-50 {
  height: 50% !important;
}

@media (min-width: 960px) {
  .h-md-50 {
    height: 50% !important;
  }
}
.h-50vh {
  height: 50vh !important;
}

@media (min-width: 960px) {
  .h-md-50vh {
    height: 50vh !important;
  }
}
.h-56vh {
  height: 56vh !important;
}

@media (min-width: 960px) {
  .h-md-56vh {
    height: 56vh !important;
  }
}
.h-75 {
  height: 75% !important;
}

@media (min-width: 960px) {
  .h-md-75 {
    height: 75% !important;
  }
}
.h-100, .rw__input .rw__input-search-with-button button {
  height: 100% !important;
}

@media (min-width: 960px) {
  .h-md-100 {
    height: 100% !important;
  }
}
.h-100vh {
  height: 100vh !important;
}

@media (min-width: 960px) {
  .h-md-100vh {
    height: 100vh !important;
  }
}
.h-auto, .rw__datatable-custom-paginator .p-paginator .p-paginator-current, .rw__datatable-internal-paginator .p-paginator .p-paginator-current {
  height: auto !important;
}

@media (min-width: 960px) {
  .h-md-auto {
    height: auto !important;
  }
}
.minh-auto {
  min-height: auto !important;
}

@media (min-width: 960px) {
  .minh-md-auto {
    min-height: auto !important;
  }
}
.w-20 {
  width: 20% !important;
}

@media (min-width: 960px) {
  .w-md-20 {
    width: 20% !important;
  }
}
.w-25 {
  width: 25% !important;
}

@media (min-width: 960px) {
  .w-md-25 {
    width: 25% !important;
  }
}
.w-30 {
  width: 30% !important;
}

@media (min-width: 960px) {
  .w-md-30 {
    width: 30% !important;
  }
}
.w-35 {
  width: 35% !important;
}

@media (min-width: 960px) {
  .w-md-35 {
    width: 35% !important;
  }
}
.w-40 {
  width: 40% !important;
}

@media (min-width: 960px) {
  .w-md-40 {
    width: 40% !important;
  }
}
.w-50 {
  width: 50% !important;
}

@media (min-width: 960px) {
  .w-md-50 {
    width: 50% !important;
  }
}
.w-60 {
  width: 60% !important;
}

@media (min-width: 960px) {
  .w-md-60 {
    width: 60% !important;
  }
}
.w-60p {
  width: 3.75rem !important;
}

@media (min-width: 960px) {
  .w-md-60p {
    width: 3.75rem !important;
  }
}
.maxw-60p {
  max-width: 3.75rem !important;
}

@media (min-width: 960px) {
  .maxw-md-60p {
    max-width: 3.75rem !important;
  }
}
.w-75 {
  width: 75% !important;
}

@media (min-width: 960px) {
  .w-md-75 {
    width: 75% !important;
  }
}
.w-100 {
  width: 100% !important;
}

@media (min-width: 960px) {
  .w-md-100 {
    width: 100% !important;
  }
}
.w-auto {
  width: auto !important;
}

@media (min-width: 960px) {
  .w-md-auto {
    width: auto !important;
  }
}
.w-90p {
  width: 5.625rem !important;
}

@media (min-width: 960px) {
  .w-md-90p {
    width: 5.625rem !important;
  }
}
.maxw-90p {
  max-width: 5.625rem !important;
}

@media (min-width: 960px) {
  .maxw-md-90p {
    max-width: 5.625rem !important;
  }
}
.minw-0 {
  min-width: 0 !important;
}

@media (min-width: 960px) {
  .minw-md-0 {
    min-width: 0 !important;
  }
}
.minw-auto {
  min-width: auto !important;
}

@media (min-width: 960px) {
  .minw-md-auto {
    min-width: auto !important;
  }
}
.maxw-100 {
  max-width: 100% !important;
}

@media (min-width: 960px) {
  .maxw-md-100 {
    max-width: 100% !important;
  }
}
::-webkit-scrollbar {
  width: 14px;
}

::-webkit-scrollbar-track {
  background: white;
}

::-webkit-scrollbar-thumb {
  background: #D2DADA;
  border-radius: 10px;
  border: solid 4px white;
}

::-webkit-scrollbar-thumb:hover {
  background: #a6a6a6;
}

@media print {
  .header,
  .trial,
  .rw-sidenav,
  .rw__tiral-bar,
  .p-toast {
    display: none !important;
  }
  html[dir=rtl] {
    direction: ltr; /* Temporarily switch to LTR for printing */
  }
}
@media (max-width: 959px) {
  .p-toast {
    min-width: 12.5rem;
    width: auto !important;
  }
}

.container {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 640px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 960px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1366px) {
  .container {
    max-width: 1140px;
  }
}

button {
  user-select: none;
}
button:focus {
  outline: 0;
}

.rtl .fw-400, .rtl .rw__notification-error .notification-body p, .rw__notification-error .notification-body .rtl p, .rtl .rw__notification-warning .notification-body p, .rw__notification-warning .notification-body .rtl p, .rtl .rw__notification-success .notification-body p, .rw__notification-success .notification-body .rtl p, .rtl .rw__notification-info .notification-body p, .rw__notification-info .notification-body .rtl p, .rtl .rw__datatable-custom-paginator .p-paginator .p-link, .rw__datatable-custom-paginator .p-paginator .rtl .p-link, .rtl .rw__datatable-custom-paginator .p-paginator .p-paginator-current, .rw__datatable-custom-paginator .p-paginator .rtl .p-paginator-current, .rtl .rw__p-tabs .p-tabview-nav li.p-highlight.p-disabled .p-tabview-nav-link, .rw__p-tabs .p-tabview-nav li.p-highlight.p-disabled .rtl .p-tabview-nav-link, .rtl .rw__p-tabs .p-tabview-nav li a.p-tabview-nav-link, .rw__p-tabs .p-tabview-nav li .rtl a.p-tabview-nav-link, .rtl .rw__tabs li.p-highlight.p-disabled .p-tabview-nav-link, .rw__tabs li.p-highlight.p-disabled .rtl .p-tabview-nav-link, .rtl .rw__tabs li a.p-tabview-nav-link, .rw__tabs li .rtl a.p-tabview-nav-link, .rtl .rw__breadcrumb .p-breadcrumb ul li a, .rw__breadcrumb .p-breadcrumb ul li .rtl a, .rtl .rw__button, .rtl .rw__button.p-splitbutton .p-splitbutton-defaultbutton, .rw__button.p-splitbutton .rtl .p-splitbutton-defaultbutton, .rtl .rw__radio-button .p-radiobutton-label, .rw__radio-button .rtl .p-radiobutton-label, .rtl .rw__custom-radio-button .p-radiobutton-label, .rw__custom-radio-button .rtl .p-radiobutton-label, .rtl .rw__checkbox + label, .rtl .rw__switch + label {
  font-weight: 500 !important;
}
.rtl .fw-500, .rtl .rw__dynamic-dialog .p-dialog-title, .rw__dynamic-dialog .rtl .p-dialog-title, .rtl .rw__dialog .p-dialog-title, .rw__dialog .rtl .p-dialog-title, .rtl .rw__datatable-v3 .p-datatable-thead tr th, .rw__datatable-v3 .p-datatable-thead tr .rtl th, .rtl .rw__datatable .p-datatable-thead tr th, .rw__datatable .p-datatable-thead tr .rtl th, .rtl .rw__accordion .p-accordion-header-link, .rw__accordion .rtl .p-accordion-header-link, .rtl .rw__p-tabs .p-tabview-nav li.p-highlight a.p-tabview-nav-link, .rw__p-tabs .p-tabview-nav li.p-highlight .rtl a.p-tabview-nav-link, .rtl .rw__tabs li.p-highlight .p-tabview-nav-link, .rw__tabs li.p-highlight .rtl .p-tabview-nav-link, .rtl .rw__input .p-multiselect-token, .rw__input .rtl .p-multiselect-token, .rtl .rw__button {
  font-weight: 600 !important;
}
.rtl .fw-600 {
  font-weight: 700 !important;
}
.rtl .field-checkbox > label,
.rtl .field-radiobutton > label {
  margin-left: 0;
  margin-right: 0.5rem;
}

.rw__breadcrumb .p-breadcrumb {
  padding: 1rem 2rem;
}
.rw__breadcrumb .p-breadcrumb-chevron {
  color: #9cabab;
}
.rw__breadcrumb .p-breadcrumb ul li:not(:only-of-type):not(:last-of-type) .p-menuitem-text,
.rw__breadcrumb .p-breadcrumb ul li .p-menuitem-link-active .p-menuitem-text {
  color: #00A5A3;
}
.rw__breadcrumb .p-breadcrumb ul li a {
  line-height: 1.25rem;
}

.rw__steps .p-steps-item {
  flex: 1 1 auto;
}
.rw__steps .p-steps-item:before {
  content: " ";
  width: 100%;
  top: 50%;
  display: block;
  transform: translateY(-1.4rem);
}
.rw__steps .p-steps-item:last-child {
  flex: 1 1 auto;
}
.rw__steps .p-steps-item:last-child:before {
  content: " ";
  width: 100%;
  top: 50%;
  display: block;
  transform: translateY(-1.4rem);
}
.rw__steps .p-steps-item .p-menuitem-link {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  flex: 0 1 auto;
  overflow: hidden;
  text-decoration: none;
}
.rw__steps .p-steps-item .p-menuitem-link .p-steps-number {
  color: #8D9696;
  border: 0;
  min-width: 2rem;
  height: 2rem;
  line-height: 1.5rem;
  background: #DAF5F5 !important;
  font-size: 1rem;
}
.rw__steps .p-steps-item .p-menuitem-link .p-steps-title {
  font-weight: 400;
  margin-top: 0.5rem;
  color: #5B6565;
}
.rw__steps .p-steps-item.icon-check .p-steps-number {
  position: relative;
  color: transparent !important;
  background-color: #00807D !important;
  border-color: #00807D !important;
}
.rw__steps .p-steps-item.icon-check .p-steps-number:before {
  content: "\f00c";
  font-family: "Font Awesome 6 Pro";
  display: inline-block;
  position: absolute;
  vertical-align: middle;
  color: #fff;
  font-weight: 900;
  text-indent: 0;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.rw__steps .p-steps-item.p-disabled .p-menuitem-link .p-steps-number {
  background-color: #C7CCCC !important;
}
.rw__steps .p-steps-item .step-completed.p-menuitem-link .p-steps-number {
  background-color: rgb(0, 165, 163) !important;
  font-size: 0;
}
.rw__steps .p-steps-item .step-completed.p-menuitem-link .p-steps-number:before {
  content: "\f00c";
  font-family: "Font Awesome 6 Pro";
  font-size: 0.875rem;
  display: inline-block;
  position: absolute;
  vertical-align: middle;
  color: #fff;
  font-weight: 900;
  text-indent: 0;
  left: 50%;
  top: 43%;
  transform: translate(-50%, -50%);
}
.rw__steps .p-steps-item.p-disabled {
  opacity: 1;
}
.rw__steps .p-steps-current .p-steps-number {
  color: #00A5A3 !important;
  border: 2px solid #00A5A3 !important;
}
.rw__steps .p-steps-current .p-steps-title {
  font-weight: 500 !important;
  color: #0D2222 !important;
}
.rw__steps .p-steps.step-completed {
  background-color: rgb(0, 165, 163) !important;
  color: #fff !important;
}

.ltr .rw__steps .p-steps-item:before {
  left: 0;
}
.ltr .rw__steps .p-steps-item:first-child:before {
  left: 50%;
}
.ltr .rw__steps .p-steps-item:last-child:before {
  left: -50%;
}

.rtl .rw__steps .p-steps-item:before {
  right: 0;
}
.rtl .rw__steps .p-steps-item:first-child:before {
  right: 50%;
}
.rtl .rw__steps .p-steps-item:last-child:before {
  right: -50%;
}

.rw__steps-complete-1 .p-steps-item:nth-child(-n+1) .p-menuitem-link .p-steps-number {
  background-color: #00A5A3 !important;
  font-size: 0;
}
.rw__steps-complete-1 .p-steps-item:nth-child(-n+1) .p-menuitem-link .p-steps-number:before {
  color: white;
  content: "\f00c";
  font-family: "Font Awesome 6 Pro";
  font-size: 1rem;
}

.rw__steps-complete-2 .p-steps-item:nth-child(-n+2) .p-menuitem-link .p-steps-number {
  background-color: #00A5A3 !important;
  font-size: 0;
}
.rw__steps-complete-2 .p-steps-item:nth-child(-n+2) .p-menuitem-link .p-steps-number:before {
  color: white;
  content: "\f00c";
  font-family: "Font Awesome 6 Pro";
  font-size: 1rem;
}

.rw__steps-complete-3 .p-steps-item:nth-child(-n+3) .p-menuitem-link .p-steps-number {
  background-color: #00A5A3 !important;
  font-size: 0;
}
.rw__steps-complete-3 .p-steps-item:nth-child(-n+3) .p-menuitem-link .p-steps-number:before {
  color: white;
  content: "\f00c";
  font-family: "Font Awesome 6 Pro";
  font-size: 1rem;
}

.rw__steps-complete-4 .p-steps-item:nth-child(-n+4) .p-menuitem-link .p-steps-number {
  background-color: #00A5A3 !important;
  font-size: 0;
}
.rw__steps-complete-4 .p-steps-item:nth-child(-n+4) .p-menuitem-link .p-steps-number:before {
  color: white;
  content: "\f00c";
  font-family: "Font Awesome 6 Pro";
  font-size: 1rem;
}

.rw__steps-complete-5 .p-steps-item:nth-child(-n+5) .p-menuitem-link .p-steps-number {
  background-color: #00A5A3 !important;
  font-size: 0;
}
.rw__steps-complete-5 .p-steps-item:nth-child(-n+5) .p-menuitem-link .p-steps-number:before {
  color: white;
  content: "\f00c";
  font-family: "Font Awesome 6 Pro";
  font-size: 1rem;
}

.rw__steps-complete-6 .p-steps-item:nth-child(-n+6) .p-menuitem-link .p-steps-number {
  background-color: #00A5A3 !important;
  font-size: 0;
}
.rw__steps-complete-6 .p-steps-item:nth-child(-n+6) .p-menuitem-link .p-steps-number:before {
  color: white;
  content: "\f00c";
  font-family: "Font Awesome 6 Pro";
  font-size: 1rem;
}

.rw__steps-complete-7 .p-steps-item:nth-child(-n+7) .p-menuitem-link .p-steps-number {
  background-color: #00A5A3 !important;
  font-size: 0;
}
.rw__steps-complete-7 .p-steps-item:nth-child(-n+7) .p-menuitem-link .p-steps-number:before {
  color: white;
  content: "\f00c";
  font-family: "Font Awesome 6 Pro";
  font-size: 1rem;
}

.rw__steps-complete-8 .p-steps-item:nth-child(-n+8) .p-menuitem-link .p-steps-number {
  background-color: #00A5A3 !important;
  font-size: 0;
}
.rw__steps-complete-8 .p-steps-item:nth-child(-n+8) .p-menuitem-link .p-steps-number:before {
  color: white;
  content: "\f00c";
  font-family: "Font Awesome 6 Pro";
  font-size: 1rem;
}

.rw__steps-complete-9 .p-steps-item:nth-child(-n+9) .p-menuitem-link .p-steps-number {
  background-color: #00A5A3 !important;
  font-size: 0;
}
.rw__steps-complete-9 .p-steps-item:nth-child(-n+9) .p-menuitem-link .p-steps-number:before {
  color: white;
  content: "\f00c";
  font-family: "Font Awesome 6 Pro";
  font-size: 1rem;
}

.rw__steps-complete-10 .p-steps-item:nth-child(-n+10) .p-menuitem-link .p-steps-number {
  background-color: #00A5A3 !important;
  font-size: 0;
}
.rw__steps-complete-10 .p-steps-item:nth-child(-n+10) .p-menuitem-link .p-steps-number:before {
  color: white;
  content: "\f00c";
  font-family: "Font Awesome 6 Pro";
  font-size: 1rem;
}

.rw__columns {
  margin: 0 -0.75rem;
}
@media (max-width: 959px) {
  .rw__columns {
    flex-direction: column !important;
  }
}
@media (max-width: 1365px) {
  .rw__columns > [class*=rw__col]:not([class*=rw__col-12]) {
    width: 50% !important;
  }
}
@media (max-width: 959px) {
  .rw__columns > [class*=rw__col]:not([class*=rw__col-12]) {
    width: 100% !important;
  }
}
.rw__columns .rw__col-3 {
  padding: 0 0.75rem;
  width: 25%;
}
.rw__columns .rw__col-4 {
  padding: 0 0.75rem;
  width: 33.33%;
}
.rw__columns .rw__col-5 {
  padding: 0 0.75rem;
  width: 41.66%;
}
.rw__columns .rw__col-6 {
  padding: 0 0.75rem;
  width: 50%;
}
.rw__columns .rw__col-7 {
  padding: 0 0.75rem;
  width: 58.33%;
}
.rw__columns .rw__col-8 {
  padding: 0 0.75rem;
  width: 66.66%;
}
.rw__columns .rw__col-9 {
  padding: 0 0.75rem;
  width: 75%;
}
.rw__columns .rw__col-12 {
  padding: 0 0.75rem;
  width: 100%;
}

.rw__tabs li a {
  cursor: pointer;
}
.rw__tabs li a:after {
  border-radius: 0.25rem 0.25rem 0 0;
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 0.25rem;
  width: 100%;
}
.rw__tabs li a.p-tabview-nav-link {
  font-size: 1rem;
}
.rw__tabs li a.active, .rw__tabs li a.rw__active-tab {
  color: #00807D !important;
}
.rw__tabs .p-tabview-panels {
  padding: 0;
}
.rw__tabs-disabled .p-tabview-ink-bar {
  display: none !important;
}
@media (max-width: 1365px) {
  .rw__tabs-box ul {
    overflow: auto;
  }
}
.rw__tabs-box ul li {
  background-color: white;
  border: 0.0625rem solid #DFE2E2;
  min-width: 9.0625rem;
  width: 13.75rem;
}
.rw__tabs-box ul li button {
  background-color: transparent;
  border: 0;
  color: #5B6565;
  display: flex;
  align-items: center;
  font-size: 1rem;
  outline: none;
  padding: 1rem;
  width: 100%;
}
.rw__tabs-box ul li button i {
  font-size: 1.5rem;
}
.rw__tabs-box ul li:not(:first-child) {
  margin-inline-start: 1rem;
}
.rw__tabs-box ul li.active {
  background-color: #EEF8F8;
  border-color: #00A5A3;
}
.rw__tabs-box ul li.active button {
  color: #00807D;
}

.rw__p-tabs .p-tabview-nav {
  border-width: 0 0 2px 0;
}
.rw__p-tabs .p-tabview-nav li {
  padding: 0 1rem;
}
.rw__p-tabs .p-tabview-nav li a {
  padding: 1rem 0 !important;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5rem;
  color: #5B6565;
  overflow: visible;
}
.rw__p-tabs .p-tabview-nav li a:after {
  border-radius: 0.25rem;
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  height: 0.125rem;
  width: 100%;
}
.rw__p-tabs .p-tabview-nav li a.p-tabview-nav-link {
  font-size: 1rem;
}
.rw__p-tabs .p-tabview-nav li a:hover {
  background-color: transparent;
}
.rw__p-tabs .p-tabview-nav li.p-highlight a.p-tabview-nav-link:hover {
  background-color: transparent;
}
.rw__p-tabs .p-tabview-nav li.p-highlight a.p-tabview-nav-link:hover, .rw__p-tabs .p-tabview-nav li.p-highlight a.p-tabview-nav-link:focus, .rw__p-tabs .p-tabview-nav li.p-highlight a.p-tabview-nav-link:hover:after {
  background-color: transparent !important;
}
.rw__p-tabs .p-tabview-nav li.p-tabview-ink-bar {
  display: none !important;
}
.rw__p-tabs .p-tabview-nav li:hover .p-tabview-nav-link {
  background-color: transparent !important;
}
.rw__p-tabs .p-tabview-panels {
  padding: 0;
}
.rw__p-tabs-disabled .p-tabview-ink-bar {
  display: none !important;
}
.rw__p-tabs-transparent .p-tabview-nav,
.rw__p-tabs-transparent .p-tabview-nav li a,
.rw__p-tabs-transparent .p-tabview-panels {
  background-color: transparent !important;
}

.rw__tooltip .p-tooltip-text {
  background-color: #0D2222;
}
.rw__tooltip.p-tooltip {
  max-width: 20.75rem;
}
.rw__tooltip.p-tooltip-left .p-tooltip-text {
  margin-right: 0.125rem;
}
.rw__tooltip.p-tooltip-left .p-tooltip-arrow {
  border-left-color: #0D2222;
  border-width: 0.46875rem 0 0.46875rem 0.375rem;
  margin-top: -0.5rem;
}
.rw__tooltip.p-tooltip-right .p-tooltip-text {
  margin-left: 0.125rem;
}
.rw__tooltip.p-tooltip-right .p-tooltip-arrow {
  border-right-color: #0D2222;
  border-width: 0.46875rem 0.375rem 0.46875rem 0;
  margin-top: -0.5rem;
}
.rw__tooltip.p-tooltip-top .p-tooltip-arrow {
  border-top-color: #0D2222;
  border-width: 0.375rem 0.46875rem 0;
  bottom: -0.125rem;
}

.rw__accordion .p-accordion-tab {
  box-shadow: none !important;
  overflow: hidden;
}
.rw__accordion .p-accordion-header-link {
  padding: 0.75rem 1rem !important;
  text-decoration: none;
}
.rw__accordion .p-accordion-header-link:hover .p-accordion-header-text {
  text-decoration: underline;
}
.rw__accordion .p-accordion-header-text {
  color: #0D2222;
  flex: 1;
}
.rw__accordion .p-accordion-header .rw__tag-circle {
  background-color: #F7EAF4;
  border-radius: 50%;
  color: #561157;
  font-size: 0.75rem;
  height: 1.5rem;
  width: 1.5rem;
}
.rw__accordion .p-accordion-content {
  padding: 0 0.75rem 1rem !important;
}
.rw__accordion .p-accordion-toggle-icon {
  height: 1.5rem;
  width: 1.5rem;
}
.rw__accordion .p-accordion-toggle-icon.pi-chevron-down {
  transform: rotate(180deg);
}
.rw__accordion .p-accordion-toggle-icon.pi-chevron-right {
  transform: rotate(90deg);
}
.rw__accordion .p-accordion .rw__accordion-default-header .p-accordion-header-text {
  display: none !important;
}
.rw__accordion-grey-header .p-accordion-header-link {
  background-color: #F4F5F5 !important;
  justify-content: space-between;
}
.rw__accordion-grey-header .p-accordion-content {
  border-top: 0.0625rem solid #DFE2E2;
}
.rw__accordion-grey-header .p-accordion-toggle-icon {
  background-color: transparent !important;
  font-size: 0.875rem !important;
  height: 1.25rem;
  width: 1.25rem;
}
.rw__accordion-grey-border .p-accordion-header {
  padding: 1rem;
}
.rw__accordion-grey-border .p-accordion-header-link {
  background-color: transparent !important;
  padding: 0 !important;
}
.rw__accordion-grey-border .p-accordion-content {
  padding: 0 1rem 1rem !important;
}
.rw__accordion-grey-border .p-accordion-toggle-icon {
  background-color: transparent !important;
  font-size: 0.875rem !important;
}

.rw__progress-bar .p-progressbar {
  background-color: #DFE2E2;
  border-radius: 0.25rem;
  height: 0.5rem;
}
.rw__progress-bar .p-progressbar-value {
  background-color: #00A5A3;
  border-radius: 0.25rem;
}
.rw__progress-bar .p-progressbar-label {
  display: none !important;
}
.rw__progress-bar-white .p-progressbar {
  background-color: rgba(255, 255, 255, 0.38);
}
.rw__progress-bar-white .p-progressbar-value {
  background-color: white;
}
.rw__progress-bar-purple .p-progressbar-value {
  background-color: #824481;
}

.rw__point-box.cursor-pointer:hover,
.rw__report-box.cursor-pointer:hover {
  background-color: #EEF8F8 !important;
}

.rtl .rw__accordion .p-accordion-toggle-icon.pi-chevron-down {
  transform: rotate(-180deg) !important;
}
.rtl .rw__accordion .p-accordion-toggle-icon.pi-chevron-right {
  transform: rotate(-90deg) !important;
}

.rw__datatable {
  border-radius: 0.25rem;
  min-height: 0;
  max-height: 100%;
}
.rw__datatable p-table {
  flex: 1;
  height: 100%;
  min-height: 0;
}
.rw__datatable .p-datatable {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.rw__datatable .p-datatable .minw-col {
  min-width: 10.75rem;
}
.rw__datatable .p-datatable-header {
  background-color: white;
  padding: 0.4375rem 1rem;
}
.rw__datatable .p-datatable-thead tr {
  border-bottom: 0.0625rem solid #DFE2E2;
}
.rw__datatable .p-datatable-thead tr th {
  background-color: #F7F8F8 !important;
  border: 0;
  font-size: 0.875rem;
  padding: 0.75rem !important;
  width: 10.75rem;
  line-height: 1.25rem;
  vertical-align: middle;
}
.rw__datatable .p-datatable-thead tr th:first-child {
  border-top-left-radius: 0.25rem;
}
.rw__datatable .p-datatable-thead tr th:last-child {
  border-top-right-radius: 0.25rem;
}
.rw__datatable .p-datatable-tbody tr {
  vertical-align: middle;
  border-bottom: 0.0625rem solid #DFE2E2;
}
.rw__datatable .p-datatable-tbody tr td {
  border: 0;
  font-size: 0.875rem;
  min-height: 3.375rem;
  padding: 0.75rem;
  line-height: normal;
  width: 10.75rem;
}
.rw__datatable .p-datatable-tbody tr td.rw__clickable-column {
  cursor: pointer;
}
.rw__datatable .p-datatable-tbody tr td.rw__clickable-column:hover {
  background-color: #F7F8F8;
}
.rw__datatable .p-datatable-tbody tr td.rw__td-indent-2 {
  padding-inline-start: 1.875rem !important;
}
.rw__datatable .p-datatable-tbody tr td.rw__td-indent-3 {
  padding-inline-start: 3.75rem !important;
}
.rw__datatable .p-datatable-tbody tr td.rw__td-indent-4 {
  padding-inline-start: 5.625rem !important;
}
.rw__datatable .p-datatable-tbody tr td.rw__td-indent-5 {
  padding-inline-start: 7.5rem !important;
}
.rw__datatable .p-datatable-tbody tr td.rw__td-indent-6 {
  padding-inline-start: 9.375rem !important;
}
.rw__datatable .p-datatable-tbody tr td.rw__td-indent-7 {
  padding-inline-start: 11.25rem !important;
}
.rw__datatable .p-datatable-tbody tr td.rw__td-indent-8 {
  padding-inline-start: 13.125rem !important;
}
.rw__datatable .p-datatable-tbody tr td.rw__td-indent-9 {
  padding-inline-start: 15rem !important;
}
.rw__datatable .p-datatable-tbody tr td.rw__td-indent-10 {
  padding-inline-start: 16.875rem !important;
}
.rw__datatable .p-datatable-tbody tr.bg-white, .rw__datatable .p-datatable-tbody tr.rw__breadcrumb {
  background-color: white !important;
}
.rw__datatable .p-datatable-tbody tr:last-child {
  border-bottom: 0;
}
.rw__datatable .p-datatable-tbody tr:last-child td {
  border-bottom-width: 0;
}
.rw__datatable .p-datatable-tbody tr:last-child td:first-child {
  border-bottom-left-radius: 0.25rem;
}
.rw__datatable .p-datatable-tbody tr:last-child td:last-child {
  border-bottom-right-radius: 0.25rem;
}
.rw__datatable .p-datatable .rightFrozenColumn,
.rw__datatable .p-datatable .p-frozen-column[ng-reflect-align-frozen=right] {
  right: 0 !important;
  margin-left: auto;
}
.rw__datatable .p-datatable .p-frozen-column.leftFrozenColumn, .rw__datatable .p-datatable .p-frozen-column[ng-reflect-align-frozen=left] {
  border-right: 0.0625rem solid #DFE2E2;
  left: 0 !important;
}
.rw__datatable .p-datatable .sticky-col {
  background-color: white;
  position: sticky;
  right: 0;
}
.rw__datatable .p-datatable .sticky-col:before {
  border-left: 0.0625rem solid #DFE2E2;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
}
.rw__datatable .p-datatable-hoverable-rows tr:hover td {
  background-color: #F7F8F8;
  cursor: pointer;
}
.rw__datatable-head-white .p-datatable-thead tr th {
  background-color: white !important;
}
.rw__datatable-dot {
  background-color: #007ACC;
  top: 0.1875rem;
  left: -1.1875rem;
  height: 0.5rem;
  width: 0.5rem;
}
.rw__datatable-paginator {
  background-color: white;
  min-height: 3.5rem;
}
.rw__datatable-paginator .p-paginator {
  flex-wrap: nowrap;
}
.rw__datatable-paginator .p-paginator-element {
  height: 1.25rem !important;
  min-width: 1.25rem !important;
}
.rw__datatable-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background-color: #00807D;
  color: white !important;
}
.rw__datatable-internal-paginator .p-paginator {
  background-color: white;
  border-top: 0.0625rem solid #DFE2E2;
  border-bottom: 0;
  min-height: 3.5rem;
  padding: 1rem;
}
.rw__datatable-internal-paginator .p-paginator .p-link {
  height: 1.25rem !important;
  min-width: 1.25rem !important;
  margin: 0 0 0 0.75rem !important;
}
.rw__datatable-internal-paginator .p-paginator .p-link.p-highlight {
  background-color: #00807D;
  color: white !important;
}
.rw__datatable-internal-paginator .p-paginator .p-dropdown {
  border-color: #DFE2E2;
  height: 1.5rem;
  margin: 0 0 0 1rem;
}
.rw__datatable-internal-paginator .p-paginator .p-dropdown-label {
  padding: 0 0 0 0.4375rem;
}
.rw__datatable-internal-paginator .p-paginator .p-dropdown-trigger {
  padding: 0 0.4375rem;
  width: auto;
}
.rw__datatable-internal-paginator .p-paginator .p-dropdown-trigger-icon {
  font-size: 0.625rem;
}
.rw__datatable-custom-paginator .p-paginator {
  background-color: white;
  border-top: 0.0625rem solid #DFE2E2;
  border-bottom: 0;
  min-height: 3.5rem;
  padding: 0.875rem 1.875rem;
}
.rw__datatable-custom-paginator .p-paginator .p-paginator-current {
  order: 2;
  line-height: 1.25rem;
}
.rw__datatable-custom-paginator .p-paginator .p-link {
  height: 2.25rem !important;
  min-width: 2.5rem !important;
  margin: 0 !important;
  line-height: 1.25rem;
}
.rw__datatable-custom-paginator .p-paginator .p-link.p-highlight {
  color: #00807D;
  background-color: #EEF8F8 !important;
}
.rw__datatable-custom-paginator .p-paginator .p-dropdown {
  border-color: #DFE2E2;
  height: 2.25rem;
  margin: 0;
  min-width: 5rem;
}
.rw__datatable-custom-paginator .p-paginator .p-dropdown-label {
  padding: 0 0 0 0.4375rem;
}
.rw__datatable-custom-paginator .p-paginator .p-dropdown-trigger {
  padding: 0 0.4375rem;
  width: auto;
}
.rw__datatable-caption-grey .p-datatable-header {
  background-color: #F7F8F8 !important;
}
.rw__datatable-compact .p-datatable-tbody tr td {
  padding-top: 0.375rem !important;
  padding-bottom: 0.375rem !important;
  min-height: 2.1875rem;
}
.rw__datatable-sticky-head thead th {
  position: sticky;
  top: 0;
  z-index: 1;
}
.rw__datatable-no-border {
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
}

.rw__datatable-custom-filters .p-listbox {
  border: 0;
}
.rw__datatable-custom-filters .p-listbox-header {
  padding: 1rem 0.25rem;
}
.rw__datatable-custom-filters .p-listbox-item {
  padding: 0.625rem 0.25rem;
}

.rw__datatable-cost-tax-filter .p-dropdown {
  background: transparent !important;
  border: 0 !important;
  box-shadow: unset !important;
}
.rw__datatable-cost-tax-filter .p-dropdown .p-inputtext {
  padding: 0 !important;
}
.rw__datatable-cost-tax-filter .p-dropdown .p-dropdown-trigger {
  display: none !important;
}

.p-column-filter-overlay-menu .p-column-filter-buttonbar {
  padding: 0.25rem 0.5rem;
}

.rtl .rw__datatable .p-sortable-column-icon {
  margin: 0 0.5rem 0 0;
}
.rtl .rw__datatable .sticky-col {
  left: 0;
  right: auto !important;
}
.rtl .rw__datatable .sticky-col:before {
  border-left: 0 !important;
  border-right: 0.0625rem solid #DFE2E2;
  left: auto !important;
  right: 0;
}
.rtl .rw__datatable .p-frozen-column[ng-reflect-align-frozen=right] {
  border-left-width: 0 !important;
}
.rtl .rw__datatable .p-frozen-column[ng-reflect-align-frozen=left] {
  border-right-width: 0 !important;
  right: 0;
}
.rtl .rw__datatable-dot {
  right: -1.1875rem;
  left: auto !important;
}
.rtl .rw__datatable-custom-paginator .p-paginator.p-paginator-bottom {
  justify-content: left;
}

@media print {
  .rw__datatable .p-datatable th {
    border-bottom: 0 !important;
  }
}
.p-column-filter-overlay-menu .p-column-filter-buttonbar {
  padding: 0.25rem 0.5rem;
}

.rtl .rw__datatable .p-sortable-column-icon {
  margin: 0 0.5rem 0 0;
}
.rtl .rw__datatable .sticky-col {
  left: 0 !important;
  right: auto !important;
}
.rtl .rw__datatable .sticky-col:before {
  border-left: 0 !important;
  border-right: 0.0625rem solid #DFE2E2;
  left: auto !important;
  right: 0;
}
.rtl .rw__datatable .p-frozen-column[ng-reflect-align-frozen=right] {
  border-left-width: 0 !important;
}
.rtl .rw__datatable .p-frozen-column[ng-reflect-align-frozen=left] {
  border-right-width: 0 !important;
  right: 0;
}
.rtl .rw__datatable .leftFrozenColumn {
  border-right: 0 !important;
  border-left: 0.0625rem solid #DFE2E2;
}
.rtl .rw__datatable .rightFrozenColumn {
  margin-left: 0;
  margin-right: auto;
}
.rtl .rw__datatable-dot {
  right: -1.1875rem;
  left: auto !important;
}

.p-datatable-thead > tr th p-sorticon .p-icon-wrapper {
  display: inline;
}
.p-datatable-scrollable .p-datatable-thead > tr,
.p-datatable-scrollable .p-datatable-tbody > tr {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
}
.p-datatable-scrollable .p-datatable-thead > tr th, .p-datatable-scrollable .p-datatable-thead > tr > td,
.p-datatable-scrollable .p-datatable-tbody > tr th,
.p-datatable-scrollable .p-datatable-tbody > tr > td {
  display: flex;
  flex: 1 1 0;
  align-items: center;
}
.p-datatable-gridlines .p-datatable-thead > tr > th, .p-datatable-gridlines .p-datatable-thead > tr > td,
.p-datatable-gridlines .p-datatable-tbody > tr > th,
.p-datatable-gridlines .p-datatable-tbody > tr > td {
  border-width: 0.0625rem !important;
}
.p-datatable-loading-icon {
  height: 2rem;
  width: 2rem;
}

p-table[scrolldirection=both] .p-datatable-scrollable .p-datatable-thead > tr th, p-table[scrolldirection=both] .p-datatable-scrollable .p-datatable-thead > tr > td,
p-table[scrolldirection=both] .p-datatable-scrollable .p-datatable-tbody > tr th,
p-table[scrolldirection=both] .p-datatable-scrollable .p-datatable-tbody > tr > td {
  flex: 0 0 auto;
}

@media print {
  .rw__datatable .p-datatable th {
    border-bottom: 0 !important;
  }
}
.rw__datatable-v3 {
  border: 0.0625rem solid #E3E8E8;
  min-height: 0;
  max-height: 100%;
  position: relative;
}
@media (max-width: 959px) {
  .rw__datatable-v3 {
    border: none;
  }
}
.rw__datatable-v3 .custom-loader {
  position: absolute;
  top: calc(50% + 35px);
  transform: translateY(-50%);
  font-weight: 700;
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.rw__datatable-v3 p-table {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 0;
  overflow: auto;
}
.rw__datatable-v3 .p-datatable {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
}
.rw__datatable-v3 .p-datatable-loading-overlay {
  top: 50%;
  transform: translateY(-50%);
  display: none !important;
}
.rw__datatable-v3 .p-datatable-scrollable .p-datatable-wrapper::-webkit-scrollbar {
  height: 14px;
}
.rw__datatable-v3 .p-datatable-thead {
  z-index: 2 !important;
}
.rw__datatable-v3 .p-datatable-thead tr th {
  border-bottom: 0.0625rem solid #E3E8E8;
  font-size: 0.75rem;
  min-height: 3rem;
  min-width: 12.5rem;
  padding: 0.375rem 1rem;
  line-height: 1rem;
  vertical-align: middle;
  gap: 0.5rem;
  color: #0D2222;
}
.rw__datatable-v3 .p-datatable-thead tr th:first-child {
  border-start-start-radius: 0.375rem;
}
.rw__datatable-v3 .p-datatable-thead tr th:last-child {
  border-start-end-radius: 0.375rem;
}
.rw__datatable-v3 .p-datatable-thead tr th p-sorticon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.3125rem;
  height: 2rem;
  width: 2rem;
}
.rw__datatable-v3 .p-datatable-thead tr th p-sorticon i {
  font-size: 0.875rem;
  margin: 0 !important;
}
.rw__datatable-v3 .p-datatable-thead tr th.rw__filter-active {
  border-bottom-color: #00A5A3;
}
.rw__datatable-v3 .p-datatable-thead tr th.rw__filter-active p-sorticon {
  background-color: !important;
}
.rw__datatable-v3 .p-datatable-thead tr th.rw__filter-active p-sorticon i {
  color: #00807D !important;
}
.rw__datatable-v3 .p-datatable-thead tr th.rightFrozenColumn {
  background-color: #F4F6F6;
  color: #5C7070;
}
.rw__datatable-v3 .p-datatable-thead tr th.rightFrozenColumn:hover {
  background-color: #EBEDED;
  border-bottom-color: #D2D9D9;
}
.rw__datatable-v3 .p-datatable-thead tr th.rightFrozenColumn.col-customized {
  background-color: #D8DDDD;
  color: #0D2222;
}
.rw__datatable-v3 .p-datatable-thead tr th.rightFrozenColumn.col-customized .fa-table-list {
  color: #0D2222;
}
.rw__datatable-v3 .p-datatable-tbody tr:hover td {
  background-color: #F4F6F6;
}
.rw__datatable-v3 .p-datatable-tbody tr:hover .custom-cell:hover {
  background-color: #E3E8E8;
}
.rw__datatable-v3 .p-datatable-tbody tr.selected-row td {
  background-color: #f3fafa;
}
.rw__datatable-v3 .p-datatable-tbody tr.new {
  border-color: #F8DCF1 !important;
}
.rw__datatable-v3 .p-datatable-tbody tr.new td {
  background-color: #fdf9fc;
}
.rw__datatable-v3 .p-datatable-tbody tr.new td:first-child:before {
  background-color: #A45DA3;
  content: "";
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  width: 0.25rem;
}
.rw__datatable-v3 .p-datatable-tbody tr:not(:last-child) {
  border-bottom: 0.0625rem solid #E3E8E8;
}
.rw__datatable-v3 .p-datatable-tbody tr td {
  background-color: white;
  border-bottom: 0;
  font-size: 0.875rem;
  min-height: 4.5rem;
  max-height: 5.25rem;
  padding: 0.75rem 1rem;
  line-height: 1.25rem;
  word-break: break-word;
  white-space: normal !important;
}
.rw__datatable-v3 .p-datatable-tbody tr td .rw__data-val {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.rw__datatable-v3 .p-datatable-tbody tr td .rw__user-role {
  color: #5C7070;
}
@media (max-width: 959px) {
  .rw__datatable-v3 .p-datatable-tbody tr .product-title-container {
    flex-direction: row-reverse;
    justify-content: space-between;
    flex: 1;
  }
}
.rw__datatable-v3 .p-datatable .leftFrozenColumn {
  box-shadow: 0.25rem 0 0.375rem -0.0625rem rgba(0, 0, 0, 0.1);
  flex: auto;
  position: sticky;
  left: 0;
  min-width: auto;
  z-index: 1;
}
.rw__datatable-v3 .p-datatable .rightFrozenColumn {
  box-shadow: -0.25rem 0 0.375rem -0.0625rem rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  flex: auto;
  position: sticky;
  right: 0;
  min-width: auto;
}
.rw__datatable-v3 .p-datatable .rightFrozenColumn .p-button:active, .rw__datatable-v3 .p-datatable .rightFrozenColumn .p-button:focus {
  background-color: #D5ECEC !important;
  color: #094040 !important;
}
.rw__datatable-v3-paginator {
  background-color: white;
  min-height: 3.5rem;
}
.rw__datatable-v3-paginator select {
  height: 2.25rem;
  min-width: 3.125rem;
}
.rw__datatable-v3-paginator .p-paginator {
  padding: 0;
}
.rw__datatable-v3-paginator .p-paginator-pages .p-paginator-page, .rw__datatable-v3-paginator .p-paginator .p-paginator-element {
  border-radius: 50%;
  color: #5C7070;
  min-width: 2.25rem;
  height: 2.25rem;
}
.rw__datatable-v3-paginator .p-paginator-pages .p-paginator-page.p-highlight, .rw__datatable-v3-paginator .p-paginator .p-paginator-element.p-highlight {
  background-color: #D5ECEC;
  color: #00807D;
}
.rw__datatable-v3-paginator .p-paginator-pages .p-paginator-page {
  font-size: 0.875rem;
}
.rw__datatable-v3-paginator .p-paginator-element.p-disabled {
  color: #AFB6B6;
  opacity: 1;
}
.rw__datatable-v3-paginator .p-paginator-element .p-paginator-icon {
  font-size: 1.125rem;
}
.rw__datatable-v3-paginator span:not(.p-dropdown span) {
  color: #5C7070;
}
.rw__datatable-v3-resize .p-datatable-thead th {
  min-width: auto !important;
}
.rw__datatable-v3-resize .p-datatable-thead th.p-resizable-column .p-column-resizer {
  border-left: 0.0625rem solid #E3E8E8;
}
.rw__datatable-v3-resize .p-datatable-tbody td {
  min-width: auto !important;
}
.rw__datatable-v3-box {
  background-color: white;
  overflow: hidden;
}
.rw__datatable-v3-box-row {
  border-top: 0.0625rem solid #F4F5F5;
  padding: 0.75rem;
}
.rw__datatable-v3-box-head {
  padding: 0.25rem 0.25rem 0.25rem 0.75rem;
}
.rw__datatable-v3-box.selected {
  background-color: #EEF8F8;
}
.rw__datatable-v3-box.selected .rw__datatable-v2-box-row {
  border-top-color: #E3E8E8 !important;
}
.rw__datatable-v3-box.new {
  background-color: rgba(247, 234, 244, 0.3);
}
.rw__datatable-v3-box.new .rw__datatable-v2-box-head {
  position: relative;
}
.rw__datatable-v3-box.new .rw__datatable-v2-box-head:before {
  background-color: #A45DA3;
  content: "";
  height: 0.25rem;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}
.rw__datatable-v3-box.new .rw__datatable-v2-box-row {
  border-top-color: #F8DCF1 !important;
}
.rw__datatable-v3-mobile p-table {
  overflow: unset;
}
.rw__datatable-v3-mobile .p-datatable {
  overflow: unset;
}
.rw__datatable-v3-mobile .p-datatable-wrapper {
  overflow: unset !important;
}
.rw__datatable-v3-mobile .p-datatable-thead {
  position: sticky;
  top: 70px;
}
.rw__datatable-v3-mobile .p-datatable-tbody tr {
  display: flex;
  flex-direction: column;
  margin-top: 0.75rem;
}
.rw__datatable-v3-mobile .p-datatable-tbody tr td {
  min-height: 2.5rem;
  border-bottom: 1px solid #DFE2E2 !important;
  order: 1;
}
.rw__datatable-v3-mobile .p-datatable-tbody tr td:last-child {
  order: 0;
}
.rw__datatable-v3-mobile .p-datatable-tbody tr .p-column-title {
  color: #5C7070;
}

.rtl .rw__datatable-v3 .p-datatable .leftFrozenColumn {
  box-shadow: -0.25rem 0 0.375rem -0.0625rem rgba(0, 0, 0, 0.1) !important;
  left: auto !important;
  right: 0 !important;
}
.rtl .rw__datatable-v3 .p-datatable .rightFrozenColumn {
  box-shadow: 0.25rem 0 0.375rem -0.0625rem rgba(0, 0, 0, 0.1) !important;
  right: auto !important;
  left: 0 !important;
}

@media print {
  .rw__datatable-v3 .p-datatable th {
    border-bottom: 0 !important;
  }
}
.rw__datatable-v2-box-row:not(:nth-last-child(2)) {
  border-bottom: 1px solid #F4F6F6 !important;
}

.custom-cell.p-overlaypanel .p-overlaypanel-content {
  background-color: #0D2222;
  color: #E3E8E8;
  border-radius: 0.375rem;
  padding: 1rem;
}
.custom-cell.p-overlaypanel-flipped:before, .custom-cell.p-overlaypanel-flipped:after {
  border-top-color: #0D2222;
}
.custom-cell.p-overlaypanel:after {
  border-bottom-color: #0D2222;
}

.time-selector-container {
  max-width: 12.5rem;
}

.rw__notification-info {
  background-color: #EBF5FF;
  border-width: 0.0625rem 0.0625rem 0.0625rem 0.375rem;
  border-color: #DFE2E2;
  border-left-color: #007ACC;
  border-style: solid;
  padding: 1rem;
  min-height: 56px;
}
.rw__notification-info .notification-body {
  flex: 1;
  align-items: center;
}
.rw__notification-info .notification-body i {
  color: #007ACC;
}
.rw__notification-info .notification-body p {
  line-height: 1.25rem;
  color: #313D3D;
}
.rw__notification-info .notification-footer {
  padding-inline: 0.375rem;
}
.rw__notification-info .notification-footer .fa-xmark {
  color: #0D2222;
  cursor: pointer;
}

.rw__notification-toast-info .p-toast-message {
  background: #EBF5FF !important;
  border-width: 0.0625rem 0.0625rem 0.0625rem 0.375rem !important;
  border-color: #DFE2E2 !important;
  border-left-color: #007ACC !important;
  border-style: solid !important;
}
.rw__notification-toast-info .p-toast-message-icon {
  color: #007ACC !important;
}
.rw__notification-toast-info .p-toast-message-text {
  margin: 0 0 0 1rem !important;
}
.rw__notification-toast-info .p-toast-detail {
  margin: 0 !important;
}
.rw__notification-toast-info .p-toast a {
  color: #00807D;
}

.p-toast {
  opacity: 1;
}
.p-toast .p-toast-message-content {
  gap: 1rem;
}
.p-toast .p-toast-message.p-toast-message-info {
  background-color: #EBF5FF !important;
  border-color: #EBF5FF !important;
  border-left-color: #007ACC !important;
}
.p-toast .p-toast-message.p-toast-message-info .p-toast-message-icon {
  font-size: 1.5rem;
}
.p-toast .p-toast-message.p-toast-message-info .p-toast-message-text {
  color: #0D2222;
  line-height: normal !important;
  margin: 0;
}
.p-toast .p-toast-message.p-toast-message-info .p-toast-detail:only-child {
  margin-top: 0;
}

.rtl .rw__notification-info {
  border-left-color: #DFE2E2;
  border-right-color: #007ACC;
  border-width: 0.0625rem 0.375rem 0.0625rem 0.0625rem;
}
.rtl .p-toast .p-toast-message.p-toast-message-info {
  border-width: 0 6px 0 0;
  border-right-color: #007ACC !important;
}

.rw__notification-success {
  background-color: #EBFFF5;
  border-width: 0.0625rem 0.0625rem 0.0625rem 0.375rem;
  border-color: #DFE2E2;
  border-left-color: #008547;
  border-style: solid;
  padding: 1rem;
  min-height: 56px;
}
.rw__notification-success .notification-body {
  flex: 1;
  align-items: center;
}
.rw__notification-success .notification-body i {
  color: #008547;
}
.rw__notification-success .notification-body p {
  line-height: 1.25rem;
  color: #313D3D;
}
.rw__notification-success .notification-footer {
  padding-inline: 0.375rem;
}
.rw__notification-success .notification-footer .fa-xmark {
  color: #0D2222;
  cursor: pointer;
}

.rw__notification-toast-success .p-toast-message {
  background: #EBFFF5 !important;
  border-width: 0.0625rem 0.0625rem 0.0625rem 0.375rem !important;
  border-color: #DFE2E2 !important;
  border-left-color: #008547 !important;
  border-style: solid !important;
}
.rw__notification-toast-success .p-toast-message-icon {
  color: #008547 !important;
}
.rw__notification-toast-success .p-toast-message-text {
  margin: 0 0 0 1rem !important;
}
.rw__notification-toast-success .p-toast-detail {
  margin: 0 !important;
}
.rw__notification-toast-success .p-toast a {
  color: #00807D;
}

.p-toast {
  opacity: 1;
}
.p-toast .p-toast-message-content {
  gap: 1rem;
}
.p-toast .p-toast-message.p-toast-message-success {
  background-color: #EBFFF5 !important;
  border-color: #EBFFF5 !important;
  border-left-color: #008547 !important;
}
.p-toast .p-toast-message.p-toast-message-success .p-toast-message-icon {
  font-size: 1.5rem;
}
.p-toast .p-toast-message.p-toast-message-success .p-toast-message-text {
  color: #0D2222;
  line-height: normal !important;
  margin: 0;
}
.p-toast .p-toast-message.p-toast-message-success .p-toast-detail:only-child {
  margin-top: 0;
}

.rtl .rw__notification-success {
  border-left-color: #DFE2E2;
  border-right-color: #008547;
  border-width: 0.0625rem 0.375rem 0.0625rem 0.0625rem;
}
.rtl .p-toast .p-toast-message.p-toast-message-success {
  border-width: 0 6px 0 0;
  border-right-color: #008547 !important;
}

.rw__notification-warning {
  background-color: #FFF9EB;
  border-width: 0.0625rem 0.0625rem 0.0625rem 0.375rem;
  border-color: #DFE2E2;
  border-left-color: #D9A200;
  border-style: solid;
  padding: 1rem;
  min-height: 56px;
}
.rw__notification-warning .notification-body {
  flex: 1;
  align-items: center;
}
.rw__notification-warning .notification-body i {
  color: #D9A200;
}
.rw__notification-warning .notification-body p {
  line-height: 1.25rem;
  color: #313D3D;
}
.rw__notification-warning .notification-footer {
  padding-inline: 0.375rem;
}
.rw__notification-warning .notification-footer .fa-xmark {
  color: #0D2222;
  cursor: pointer;
}

.rw__notification-toast-warning .p-toast-message {
  background: #FFF9EB !important;
  border-width: 0.0625rem 0.0625rem 0.0625rem 0.375rem !important;
  border-color: #DFE2E2 !important;
  border-left-color: #D9A200 !important;
  border-style: solid !important;
}
.rw__notification-toast-warning .p-toast-message-icon {
  color: #D9A200 !important;
}
.rw__notification-toast-warning .p-toast-message-text {
  margin: 0 0 0 1rem !important;
}
.rw__notification-toast-warning .p-toast-detail {
  margin: 0 !important;
}
.rw__notification-toast-warning .p-toast a {
  color: #00807D;
}

.p-toast {
  opacity: 1;
}
.p-toast .p-toast-message-content {
  gap: 1rem;
}
.p-toast .p-toast-message.p-toast-message-warning {
  background-color: #FFF9EB !important;
  border-color: #FFF9EB !important;
  border-left-color: #D9A200 !important;
}
.p-toast .p-toast-message.p-toast-message-warning .p-toast-message-icon {
  font-size: 1.5rem;
}
.p-toast .p-toast-message.p-toast-message-warning .p-toast-message-text {
  color: #0D2222;
  line-height: normal !important;
  margin: 0;
}
.p-toast .p-toast-message.p-toast-message-warning .p-toast-detail:only-child {
  margin-top: 0;
}

.rtl .rw__notification-warning {
  border-left-color: #DFE2E2;
  border-right-color: #D9A200;
  border-width: 0.0625rem 0.375rem 0.0625rem 0.0625rem;
}
.rtl .p-toast .p-toast-message.p-toast-message-warning {
  border-width: 0 6px 0 0;
  border-right-color: #D9A200 !important;
}

.rw__notification-error {
  background-color: #FFF0ED;
  border-width: 0.0625rem 0.0625rem 0.0625rem 0.375rem;
  border-color: #DFE2E2;
  border-left-color: #BA1B1B;
  border-style: solid;
  padding: 1rem;
  min-height: 56px;
}
.rw__notification-error .notification-body {
  flex: 1;
  align-items: center;
}
.rw__notification-error .notification-body i {
  color: #BA1B1B;
}
.rw__notification-error .notification-body p {
  line-height: 1.25rem;
  color: #313D3D;
}
.rw__notification-error .notification-footer {
  padding-inline: 0.375rem;
}
.rw__notification-error .notification-footer .fa-xmark {
  color: #0D2222;
  cursor: pointer;
}

.rw__notification-toast-error .p-toast-message {
  background: #FFF0ED !important;
  border-width: 0.0625rem 0.0625rem 0.0625rem 0.375rem !important;
  border-color: #DFE2E2 !important;
  border-left-color: #BA1B1B !important;
  border-style: solid !important;
}
.rw__notification-toast-error .p-toast-message-icon {
  color: #BA1B1B !important;
}
.rw__notification-toast-error .p-toast-message-text {
  margin: 0 0 0 1rem !important;
}
.rw__notification-toast-error .p-toast-detail {
  margin: 0 !important;
}
.rw__notification-toast-error .p-toast a {
  color: #00807D;
}

.p-toast {
  opacity: 1;
}
.p-toast .p-toast-message-content {
  gap: 1rem;
}
.p-toast .p-toast-message.p-toast-message-error {
  background-color: #FFF0ED !important;
  border-color: #FFF0ED !important;
  border-left-color: #BA1B1B !important;
}
.p-toast .p-toast-message.p-toast-message-error .p-toast-message-icon {
  font-size: 1.5rem;
}
.p-toast .p-toast-message.p-toast-message-error .p-toast-message-text {
  color: #0D2222;
  line-height: normal !important;
  margin: 0;
}
.p-toast .p-toast-message.p-toast-message-error .p-toast-detail:only-child {
  margin-top: 0;
}

.rtl .rw__notification-error {
  border-left-color: #DFE2E2;
  border-right-color: #BA1B1B;
  border-width: 0.0625rem 0.375rem 0.0625rem 0.0625rem;
}
.rtl .p-toast .p-toast-message.p-toast-message-error {
  border-width: 0 6px 0 0;
  border-right-color: #BA1B1B !important;
}

.rw__notification .p-toast {
  min-width: 25rem;
  width: auto;
}

.rw__notification-toast-hide-icon .p-toast-message-icon {
  display: none !important;
}
.rw__notification-toast-hide-icon .p-toast-message-text {
  margin-left: 0 !important;
}

#toast-container .p-toast .p-toast-message {
  background-image: none !important;
  border-width: 0 0 0 0.375rem !important;
  border-style: solid !important;
  border-radius: 0.25rem !important;
  box-shadow: none !important;
  display: flex;
  align-items: center;
  padding: 0.8125rem 1.125rem !important;
  opacity: 1 !important;
}
#toast-container .p-toast .p-toast-message .p-toast-message-icon {
  font-family: "Font Awesome 6 Pro";
  font-size: 1.25rem;
  margin-right: 1.125rem;
}

.rtl #toast-container .p-toast .p-toast-message {
  border-width: 0 0.375rem 0 0 !important;
}
.rtl #toast-container .p-toast .p-toast-message .p-toast-message-icon {
  margin-left: 1.125rem;
  margin-right: 0 !important;
}

@media print {
  #toast-container {
    display: none !important;
  }
}
.rw__tag-purple {
  background-color: #F7EAF4;
  color: #561157;
}
.rw__tag-purple:not(.rw__tag-no-hover):hover {
  background-color: #F0BDED;
}

.rw__tag-teal {
  background-color: #DAF5F5;
  color: #005E5D;
}
.rw__tag-teal:not(.rw__tag-no-hover):hover {
  background-color: #9DEFEF;
}

.rw__tag-gray {
  background-color: #DFE2E2;
  color: #313D3D;
}
.rw__tag-gray:not(.rw__tag-no-hover):hover {
  background-color: #C7CCCC;
}

.rw__tag-green {
  background-color: #C2FFE0;
  color: #006636;
}
.rw__tag-green:not(.rw__tag-no-hover):hover {
  background-color: #99FFCC;
}

.rw__tag-yellow {
  background-color: #FFF0D1;
  color: #996B00;
}
.rw__tag-yellow:not(.rw__tag-no-hover):hover {
  background-color: #FCE5A9;
}

.rw__tag-red {
  background-color: #FFDCD6;
  color: #930006;
}
.rw__tag-red:not(.rw__tag-no-hover):hover {
  background-color: #FFBFB5;
}

.rw__tag-blue {
  background-color: #CFE7FF;
  color: #005C99;
}
.rw__tag-blue:not(.rw__tag-no-hover):hover {
  background-color: #A3D1FF;
}

.rw__status-purple {
  background-color: #F7EAF4;
  color: #561157;
}

.rw__status-teal {
  background-color: #EEF8F8;
  color: #005E5D;
}

.rw__status-gray {
  background-color: #F7F8F8;
  color: #313D3D;
}

.rw__status-green {
  background-color: #EBFFF5;
  color: #006636;
}

.rw__status-success {
  background-color: #EBFFF5;
  color: #006636;
}

.rw__status-yellow {
  background-color: #FFF9EB;
  color: #996B00;
}

.rw__status-warning {
  background-color: #FFF9EB;
  color: #996B00;
}

.rw__status-red {
  background-color: #FFF0ED;
  color: #930006;
}

.rw__status-danger {
  background-color: #FFF0ED;
  color: #930006;
}

.rw__status-error {
  background-color: #FFF0ED;
  color: #930006;
}

.rw__status-blue {
  background-color: #EBF5FF;
  color: #005C99;
}

.rw__status-info {
  background-color: #EBF5FF;
  color: #005C99;
}

.rw__status-expired {
  background-color: #edf0f0;
  color: #5B6565;
}

.rw__status-gold {
  background-color: #FCE5A9;
  color: #402D00;
}

.english-font {
  font-family: "Roboto", sans-serif !important;
}

.arabic-font, .rtl .p-dropdown-label, .rtl .p-dropdown-label span,
.rtl .p-multiselect-label,
.rtl .p-multiselect-label span {
  font-family: "Cairo", sans-serif !important;
}

.bg-shorthand-center {
  background: no-repeat center/cover;
}

.cursor-pointer {
  cursor: pointer;
}

.border-danger-dashed {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='6' ry='6' stroke='%23D61F1FFF' stroke-width='3' stroke-dasharray='4%2c 11' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}

.flip-icon-x {
  transform: scaleX(-1);
}

.rw__direction-left {
  direction: ltr !important;
}
.rw__direction-right {
  direction: rtl !important;
}

.pointer-events-none {
  pointer-events: none !important;
}

.ltr {
  direction: ltr;
  text-align: left;
}
.ltr .lh-normal {
  line-height: normal;
}

.rtl {
  direction: rtl;
  text-align: right;
}
.rtl .flip-icon-x-rtl {
  transform: scaleX(-1);
}

.text-start {
  text-align: left !important;
}
.text-end {
  text-align: right !important;
}
.text-capitalize {
  text-transform: capitalize !important;
}

.ltr .text-fix-start {
  text-align: left !important;
}
.ltr .text-fix-end {
  text-align: right !important;
}

.rtl .text-fix-start {
  text-align: right !important;
}
.rtl .text-fix-end {
  text-align: left !important;
}

.list-style-none {
  list-style: none;
}
.list-style-decimal {
  list-style: decimal;
}
.list-style-decimal-leading-zero {
  list-style: decimal-leading-zero;
}
.list-style-list {
  list-style: none;
  padding-inline-start: 0;
  margin-bottom: 0;
}
.list-style-disc {
  list-style: disc;
}
.list-style-disc-internal {
  display: flex;
  align-items: center;
}
.list-style-disc-internal:before {
  background-color: #8D9696;
  content: "";
  height: 0.375rem;
  width: 0.375rem;
}

.lh-normal {
  line-height: normal !important;
}

.text-truncate, .rw__input .p-dropdown-item, .rw__input .p-dropdown-item > div {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.rw__input-text-end .p-inputtext {
  direction: ltr;
  text-align: end;
}

.white-space-nowrap {
  white-space: nowrap;
}

@media (max-width: 959px) {
  .rw__hide-on-mobile {
    display: none !important;
  }
}

@media (min-width: 960px) {
  .rw__hide-on-desktop {
    display: none !important;
  }
}

.rw__hide {
  display: none !important;
}

.zi-1 {
  z-index: 1;
}

a.rw__link {
  color: #00807D;
}
a.rw__link:hover {
  color: rgba(0, 128, 125, 0.8);
}

.table-vertical-align-middle td {
  vertical-align: middle !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.btn-label-minw .p-button-label {
  flex-grow: 0;
}

.invisible {
  visibility: hidden;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.rw__dialog .p-dialog {
  width: 100%;
}
@media (max-width: 959px) {
  .rw__dialog .p-dialog {
    margin: 0 !important;
  }
}
.rw__dialog .p-dialog-header {
  align-items: flex-start;
  padding: 2rem 2rem 1.5rem;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
@media (max-width: 959px) {
  .rw__dialog .p-dialog-header {
    border-radius: 0 !important;
    padding: 1rem 1rem 1.5rem;
  }
}
.rw__dialog .p-dialog-header-close {
  height: 1.5rem !important;
  width: 1.5rem !important;
}
.rw__dialog .p-dialog-header label {
  line-height: 1.75rem;
}
.rw__dialog .p-dialog-title {
  color: #0D2222;
}
.rw__dialog .p-dialog-content {
  padding: 0.5rem 2rem 0;
}
@media (max-width: 959px) {
  .rw__dialog .p-dialog-content {
    padding: 1rem 1rem 0;
  }
}
.rw__dialog .p-dialog-content:first-of-type {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.rw__dialog .p-dialog-content:last-of-type {
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.rw__dialog .p-dialog-footer {
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  padding: 2rem;
}
@media (max-width: 959px) {
  .rw__dialog .p-dialog-footer {
    border-radius: 0 !important;
    padding: 1rem 1rem;
  }
}
.rw__dialog .p-dialog-footer button {
  margin: 0;
}
.rw__dialog .p-dialog-footer button:not(:first-child) {
  margin-inline-start: 1rem;
}
.rw__dialog .p-dialog-footer-gap-0 {
  margin: 0 -2rem;
}
.rw__dialog-disable-overlay .p-component-overlay {
  background-color: transparent !important;
  pointer-events: none !important;
}
.rw__dialog .rw__datatable p-table {
  overflow: auto;
}
.rw__dialog .rw__datatable p-table .p-datatable-wrapper {
  overflow: initial;
}
.rw__dialog-xl .p-dialog {
  max-width: 77.875rem;
}
.rw__dialog-lg .p-dialog {
  max-width: 60rem;
}
.rw__dialog-md .p-dialog {
  max-width: 40rem;
}
.rw__dialog-sm .p-dialog {
  max-width: 30rem;
}
.rw__dialog-no-footer .p-dialog-content {
  padding-bottom: 2rem;
}
.rw__dialog-empty-header .p-dialog-header {
  padding-bottom: 0rem !important;
}
.rw__dialog-content-internal {
  margin: 0 -2rem;
}
@media (max-width: 959px) {
  .rw__dialog:not(.rw__dialog-no-responsive) .p-dialog {
    max-height: 100%;
    height: 100vh;
    max-width: none;
    width: 100vw !important;
  }
}
@media (max-width: 959px) {
  .rw__dialog-responsive-min-height:not(.rw__dialog-no-responsive) .p-dialog {
    max-height: 100%;
    height: auto;
    max-width: none;
    width: 100vw !important;
  }
}
@media screen and (max-width: 500px) {
  .rw__dialog.rw__dialog-responsive-bottom-fixed .p-dialog {
    width: 100%;
    position: fixed !important;
    bottom: 0;
    top: auto !important;
    max-height: 95%;
    animation: boxHeightAuto 1s;
    -webkit-animation: boxHeightAuto 1s;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
  }
  .rw__dialog.rw__dialog-responsive-bottom-fixed .p-dialog-header {
    position: relative;
    border-top-right-radius: 1rem !important;
    border-top-left-radius: 1rem !important;
  }
  .rw__dialog.rw__dialog-responsive-bottom-fixed .p-dialog-header:before {
    content: "";
    position: absolute;
    top: 0.625rem;
    left: 50%;
    transform: translateX(-50%);
    width: 4.0625rem;
    border-radius: 0.3125rem;
    height: 0.3125rem;
    background-color: #8D9696;
  }
}

@media (max-width: 959px) {
  .trail .rw__dialog:not(.rw__dialog-responsive-bottom-fixed) .p-dialog {
    padding-top: 3.75rem !important;
  }
}
@media (max-width: 959px) {
  .trail .rw__dialog-responsive-bottom-fixed .p-dialog {
    max-height: 75%;
  }
}

.rw__dynamic-dialog-sm.p-dynamic-dialog {
  max-width: 30rem;
}
.rw__dynamic-dialog-md.p-dynamic-dialog {
  max-width: 40rem;
}
.rw__dynamic-dialog-lg.p-dynamic-dialog {
  max-width: 60rem;
}
.rw__dynamic-dialog-xl.p-dynamic-dialog {
  max-width: 77.875rem;
}
.rw__dynamic-dialog-no-footer .p-dialog-content {
  padding-bottom: 2rem;
}
.rw__dynamic-dialog.p-dynamic-dialog {
  width: 100%;
}
@media (max-width: 959px) {
  .rw__dynamic-dialog.p-dynamic-dialog {
    max-height: 100%;
    height: 100vh;
    width: 100vw !important;
  }
}
.rw__dynamic-dialog-no-header .p-dialog-content {
  border-radius: 0.25rem;
}
.rw__dynamic-dialog-content-no-space .p-dialog-content {
  padding: 0 !important;
}
.rw__dynamic-dialog .p-dialog-header {
  align-items: flex-start;
  padding: 2rem 2rem 1.5rem;
}
@media (max-width: 959px) {
  .rw__dynamic-dialog .p-dialog-header {
    border-radius: 0 !important;
  }
}
.rw__dynamic-dialog .p-dialog-header-close {
  height: 1.5rem !important;
  width: 1.5rem !important;
}
.rw__dynamic-dialog .p-dialog-header label {
  line-height: 1.75rem;
}
.rw__dynamic-dialog .p-dialog-title {
  color: #0D2222;
}
.rw__dynamic-dialog .p-dialog-content {
  padding: 0.5rem 2rem 0;
}
.rw__dynamic-dialog .p-dialog-content-wrap {
  flex-grow: 1;
}
.rw__dynamic-dialog .p-dialog-footer {
  padding: 2rem 0;
}
@media (max-width: 959px) {
  .rw__dynamic-dialog .p-dialog-footer {
    border-radius: 0 !important;
  }
}
.rw__dynamic-dialog .p-dialog-footer button {
  margin: 0;
}
.rw__dynamic-dialog .rw__datatable p-table {
  overflow: auto;
}
.rw__dynamic-dialog .rw__datatable p-table .p-datatable-wrapper {
  overflow: initial;
}

@keyframes boxHeight {
  from {
    height: 0;
  }
  to {
    height: 95%;
  }
}
@-webkit-keyframes boxHeight {
  from {
    height: 0;
  }
  to {
    height: 95%;
  }
}
@keyframes boxHeightAuto {
  from {
    height: 0;
  }
  to {
    height: auto;
  }
}
@-webkit-keyframes boxHeightAuto {
  from {
    height: 0;
  }
  to {
    height: auto;
  }
}
.sidebar-w840px {
  width: 840px !important;
}

.sidebar-sub-accounts {
  width: 75% !important;
  min-width: 768px;
  max-width: 1280px;
}

.sidebar-no-head .p-sidebar-header {
  display: none;
}

.sidebar-no-foot .p-sidebar-footer {
  display: none;
}

.sidebar-no-content .p-sidebar-content {
  padding: 0;
}

.rw__spinner .p-progress-spinner-circle {
  animation: custom-progress-spinner-dash 1.5s ease-in-out infinite, custom-progress-spinner-color 6s ease-in-out infinite;
}

@keyframes custom-progress-spinner-color {
  100%, 0% {
    stroke: rgba(0, 165, 163, 0.3);
  }
  40% {
    stroke: rgba(0, 165, 163, 0.5);
  }
  66% {
    stroke: rgba(0, 165, 163, 0.8);
  }
  80%, 90% {
    stroke: #00a5a3;
  }
}
.rw__otp-w-100 .rw__input {
  flex: 0 1 100% !important;
  height: 3rem;
}
.rw__otp-lg .rw__input {
  flex: 0 1 3rem !important;
  height: 3rem;
}
.rw__otp .rw__input-lg {
  flex: 0 1 3rem;
  height: 3rem;
}
.rw__otp .rw__input input {
  width: 100%;
  text-align: center;
  padding: 0.625rem;
}