// Spacing
$spacings: (
    "0": 0,
    "1": 2,
    "2": 4,
    "3": 8,
    "4": 12,
    "5": 16,
    "6": 24,
    "7": 32,
    "8": 36,
    "9": 40,
    "10": 42,
    "11": 48,
    "12": 64,
    "13": 80,
    "14": 96,
    "15": 160,
);

@each $name, $value in $spacings {
    html[dir=rtl],
    html[dir=ltr] {
        // Padding Spacing Classes
        .rw__p-#{$name} {
            padding: rem($value) !important;
        }
        .rw__px-#{$name} {
            padding-left: rem($value) !important;
            padding-right: rem($value) !important;
        }
        .rw__py-#{$name} {
            padding-top: rem($value) !important;
            padding-bottom: rem($value) !important;
        }
        .rw__pt-#{$name} {
            padding-top: rem($value) !important;
        }
        .rw__pb-#{$name} {
            padding-bottom: rem($value) !important;
        }
        
        // Margin Spacing Classes
        .rw__m-#{$name} {
            margin: rem($value) !important;
        }
        .rw__mx-#{$name} {
            margin-left: rem($value) !important;
            margin-right: rem($value) !important;
        }
        .rw__my-#{$name} {
            margin-top: rem($value) !important;
            margin-bottom: rem($value) !important;
        }
        .rw__mt-#{$name} {
            margin-top: rem($value) !important;
        }
        .rw__mb-#{$name} {
            margin-bottom: rem($value) !important;
        }

        // LTR Padding/Margin Spacing Classes
        .ltr .rw__ps-#{$name} {
            padding-left: rem($value) !important;
        }
        .ltr .rw__pe-#{$name} {
            padding-right: rem($value) !important;
        }
        .ltr .rw__ms-#{$name} {
            margin-left: rem($value) !important;
        }
        .ltr .rw__me-#{$name} {
            margin-right: rem($value) !important;
        }

        // RTL Padding/Margin Spacing Classes
        .rtl .rw__ps-#{$name} {
            padding-right: rem($value) !important;
        }
        .rtl .rw__pe-#{$name} {
            padding-left: rem($value) !important;
        }
        .rtl .rw__ms-#{$name} {
            margin-right: rem($value) !important;
        }
        .rtl .rw__me-#{$name} {
            margin-left: rem($value) !important;
        }
    }
    .sb-show-main {
        // Padding Spacing Classes
        .rw__p-#{$name} {
            padding: rem($value) !important;
        }
        .rw__px-#{$name} {
            padding-left: rem($value) !important;
            padding-right: rem($value) !important;
        }
        .rw__py-#{$name} {
            padding-top: rem($value) !important;
            padding-bottom: rem($value) !important;
        }
        .rw__pt-#{$name} {
            padding-top: rem($value) !important;
        }
        .rw__pb-#{$name} {
            padding-bottom: rem($value) !important;
        }
        
        // Margin Spacing Classes
        .rw__m-#{$name} {
            margin: rem($value) !important;
        }
        .rw__mx-#{$name} {
            margin-left: rem($value) !important;
            margin-right: rem($value) !important;
        }
        .rw__my-#{$name} {
            margin-top: rem($value) !important;
            margin-bottom: rem($value) !important;
        }
        .rw__mt-#{$name} {
            margin-top: rem($value) !important;
        }
        .rw__mb-#{$name} {
            margin-bottom: rem($value) !important;
        }

        .rw__ps-#{$name} {
            padding-left: rem($value) !important;
        }
        .rw__pe-#{$name} {
            padding-right: rem($value) !important;
        }
        .rw__ms-#{$name} {
            margin-left: rem($value) !important;
        }
        .rw__me-#{$name} {
            margin-right: rem($value) !important;
        }
    }
    @include mq('lg', 'min') {
        .sb-show-main,
        html[dir=rtl],
        html[dir=ltr] {
            .ltr .rw__m-lg-#{$name},
            .rtl .rw__m-lg-#{$name} {
                margin: rem($value) !important;
            }
            .ltr .rw__p-lg-#{$name},
            .rtl .rw__p-lg-#{$name} {
                padding: rem($value) !important;
            }
            .ltr .rw__mx-lg-#{$name},
            .rtl .rw__mx-lg-#{$name} {
                margin-left: rem($value) !important;
                margin-right: rem($value) !important;
            }
            .ltr .rw__my-lg-#{$name},
            .rtl .rw__my-lg-#{$name} {
                margin-top: rem($value) !important;
                margin-bottom: rem($value) !important;
            }
            .ltr .rw__mt-lg-#{$name},
            .rtl .rw__mt-lg-#{$name} {
                margin-top: rem($value) !important;
            }
            .ltr .rw__mb-lg-#{$name},
            .rtl .rw__mb-lg-#{$name} {
                margin-bottom: rem($value) !important;
            }
            .ltr .rw__px-lg-#{$name},
            .rtl .rw__px-lg-#{$name} {
                padding-left: rem($value) !important;
                padding-right: rem($value) !important;
            }
            .ltr .rw__py-lg-#{$name},
            .rtl .rw__py-lg-#{$name} {
                padding-top: rem($value) !important;
                padding-bottom: rem($value) !important;
            }
            .ltr .rw__pt-lg-#{$name},
            .rtl .rw__pt-lg-#{$name} {
                padding-top: rem($value) !important;
            }
            .ltr .rw__pb-lg-#{$name},
            .rtl .rw__pb-lg-#{$name} {
                padding-bottom: rem($value) !important;
            }
            .ltr .rw__ms-lg-#{$name} {
                margin-left: rem($value) !important;
            }
            .ltr .rw__me-lg-#{$name} {
                margin-right: rem($value) !important;
            }
            .ltr .rw__ps-lg-#{$name} {
                padding-left: rem($value) !important;
            }
            .ltr .rw__pe-lg-#{$name} {
                padding-right: rem($value) !important;
            }
            .rtl .rw__ms-lg-#{$name} {
                margin-right: rem($value) !important;
            }
            .rtl .rw__me-lg-#{$name} {
                margin-left: rem($value) !important;
            }
            .rtl .rw__ps-lg-#{$name} {
                padding-right: rem($value) !important;
            }
            .rtl .rw__pe-lg-#{$name} {
                padding-left: rem($value) !important;
            }
        }
    }
    @include mq('md', 'min') {
        .sb-show-main,
        html[dir=rtl],
        html[dir=ltr] {
            .ltr .rw__m-md-#{$name},
            .rtl .rw__m-md-#{$name} {
                margin: rem($value) !important;
            }
            .ltr .rw__p-md-#{$name},
            .rtl .rw__p-md-#{$name} {
                padding: rem($value) !important;
            }
            .ltr .rw__mx-md-#{$name},
            .rtl .rw__mx-md-#{$name} {
                margin-left: rem($value) !important;
                margin-right: rem($value) !important;
            }
            .ltr .rw__my-md-#{$name},
            .rtl .rw__my-md-#{$name} {
                margin-top: rem($value) !important;
                margin-bottom: rem($value) !important;
            }
            .ltr .rw__mt-md-#{$name},
            .rtl .rw__mt-md-#{$name} {
                margin-top: rem($value) !important;
            }
            .ltr .rw__mb-md-#{$name},
            .rtl .rw__mb-md-#{$name} {
                margin-bottom: rem($value) !important;
            }
            .ltr .rw__px-md-#{$name},
            .rtl .rw__px-md-#{$name} {
                padding-left: rem($value) !important;
                padding-right: rem($value) !important;
            }
            .ltr .rw__py-md-#{$name},
            .rtl .rw__py-md-#{$name} {
                padding-top: rem($value) !important;
                padding-bottom: rem($value) !important;
            }
            .ltr .rw__pt-md-#{$name},
            .rtl .rw__pt-md-#{$name} {
                padding-top: rem($value) !important;
            }
            .ltr .rw__pb-md-#{$name},
            .rtl .rw__pb-md-#{$name} {
                padding-bottom: rem($value) !important;
            }
            .ltr .rw__ms-md-#{$name} {
                margin-left: rem($value) !important;
            }
            .ltr .rw__me-md-#{$name} {
                margin-right: rem($value) !important;
            }
            .ltr .rw__ps-md-#{$name} {
                padding-left: rem($value) !important;
            }
            .ltr .rw__pe-md-#{$name} {
                padding-right: rem($value) !important;
            }
            .rtl .rw__ms-md-#{$name} {
                margin-right: rem($value) !important;
            }
            .rtl .rw__me-md-#{$name} {
                margin-left: rem($value) !important;
            }
            .rtl .rw__ps-md-#{$name} {
                padding-right: rem($value) !important;
            }
            .rtl .rw__pe-md-#{$name} {
                padding-left: rem($value) !important;
            }
        }
    }
    @include mq('sm', 'min') {
        html[dir=rtl],
        html[dir=ltr] {
            .ltr .rw__ms-sm-#{$name} {
                margin-left: rem($value) !important;
            }
            .rtl .rw__ms-sm-#{$name} {
                margin-right: rem($value) !important;
            }
        }
    }
}

.rw__mx-auto {
    margin-left: auto;
    margin-right: auto;
}