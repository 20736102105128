.rw__steps {
  .p-steps {
    &-item {
      @extend .position-relative;
      @extend .d-flex;
      @extend .justify-content-center;
      @extend .flex-row;
      flex: 1 1 auto;
      &:before {
        content: ' ';
        @extend .border-top-1;
        width: 100%;
        top: 50%;
        // left: 0;
        display: block;
        @extend .position-absolute;
        transform: translateY(-1.4rem);
      }

      &:first-child {
        &:before {
          // left: 50%;
        }
      }
      &:last-child {
        flex: 1 1 auto;
        &:before {
          content: ' ';
          @extend .border-top-1;
          width: 100%;
          top: 50%;
          // left: -50%;
          display: block;
          @extend .position-absolute;
          transform: translateY(-1.4rem);
        }
      }
      .p-menuitem {
        &-link {
          display: inline-flex;
          flex-direction: column;
          align-items: center;
          flex: 0 1 auto;
          overflow: hidden;
          text-decoration: none;
          @extend .rw__fs-8;
          .p-steps {
            &-number {
              @extend .fw-700;
              @extend .bg-grey-10;
              color: variableValues($gray, '50');
              border: 0;
              min-width: rem(32);
              height: rem(32);
              line-height: rem(24);
              background: variableValues($teal, '20') !important;
              font-size: rem(16);
              @extend .rounded-circle;
            }

            &-title {
              font-weight: variableValues($weights, '400');
              margin-top: 0.5rem;
              color: variableValues($gray, '60');
              @extend .rw__p-0;
            }
          }
        }
      }
      &.icon-check {
        .p-steps-number {
          position: relative;
          color: transparent !important;
          background-color: variableValues($teal, '60') !important;
          border-color: variableValues($teal, '60') !important;
          &:before {
            content: '\f00c';
            font-family: 'Font Awesome 6 Pro';
            display: inline-block;
            position: absolute;
            vertical-align: middle;
            color: #fff;
            font-weight: 900;
            text-indent: 0;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }

      &.p-disabled{
        .p-menuitem-link{
          .p-steps-number{
            background-color: variableValues($gray, '30') !important;
          }
        }
      }

      .step-completed.p-menuitem-link {
        .p-steps-number {
          background-color: rgb(0, 165, 163) !important;
          font-size: 0;
          &:before {
            content: '\f00c';
            font-family: 'Font Awesome 6 Pro';
            font-size: rem(14);
            display: inline-block;
            position: absolute;
            vertical-align: middle;
            color: #fff;
            font-weight: 900;
            text-indent: 0;
            left: 50%;
            top: 43%;
            transform: translate(-50%, -50%);
          }
        }
      }

      &.p-disabled {
        opacity: 1;
      }
    }
    &-current {
      .p-steps {
        &-number {
          // background: variableValues($teal, '20') !important;
          color: variableValues($teal, '50') !important;
          border: 2px solid variableValues($teal, '50') !important;
        }

        &-title {
          font-weight: variableValues($weights, '500') !important;
          color: variableValues($gray, '80') !important;
        }
      }
    }
    &.step-completed {
      background-color: rgb(0, 165, 163) !important;
      color: #fff !important;
    }
  }
}
.ltr {
  .rw__steps {
    .p-steps {
      &-item {
        &:before {
          left: 0;
        }

        &:first-child {
          &:before {
            left: 50%;
          }
        }
        &:last-child {
          &:before {
            left: -50%;
          }
        }
      }
    }
  }
}

.rtl{
  .rw__steps {
    .p-steps {
      &-item {
        &:before {
         right: 0;
        }
  
        &:first-child {
          &:before {
            right: 50%;
          }
        }
        &:last-child {
          &:before {
            right: -50%;
          }
        }
      }
    }
  }
  
}

@for $i from 1 through 10 {
  .rw__steps-complete-#{$i} {
    .p-steps-item:nth-child(-n + #{$i}) .p-menuitem-link .p-steps-number {
      background-color: variableValues($teal, '50') !important;
      font-size: 0;
      &:before {
        color: white;
        content: '';
        font-family: variableValues($fonts, "icons");
        font-size: rem(16);
      }
    }
  }
}