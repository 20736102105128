ngb-tabset ul {
  margin-left: 15px;
}

ngb-tabset ul .nav-item {
  position: relative;
}

ngb-tabset ul .nav-item .nav-link {
  color: #7a8699;
  font-size: 12px;
  font-family: Roboto;
  padding: 5px 20px;
  border-top: 1px solid #cfcfcf;
  border-left: 1px solid #cfcfcf;
  border-right: 1px solid #cfcfcf;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  font-weight: bolder;
}

ngb-tabset ul .nav-item .nav-link.active {
  border-top: 0;
  border-left: 0;
  border-right: 0;

  border-bottom: 2px solid #00aaa8;
  color: #ffffff;
  background-color: #00aaa8;
}

@media (max-width: 767px) {
  ngb-tabset ul .nav-item .nav-link {
    padding: 5px 10px;
  }
}
