::-webkit-scrollbar {
  width: 14px;
}

::-webkit-scrollbar-track {
  background: white;
}

::-webkit-scrollbar-thumb {
  background: #D2DADA;
  border-radius: 10px;
  border: solid 4px white;
}

::-webkit-scrollbar-thumb:hover {
  background: #a6a6a6;
}

@media print {
  .header, 
  .trial, 
  .rw-sidenav,
  .rw__tiral-bar,
  .p-toast {
    display: none !important;
  }
  
  html[dir="rtl"] {
    direction: ltr; /* Temporarily switch to LTR for printing */ 
  }
}
.p-toast{
  @include mq('md', 'max'){
    min-width: rem(200);
    width: auto !important;
  }
}
.container {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;

  @include mq('sm', 'min') {
    max-width: 720px;
  }
  @include mq('md', 'min') {
    max-width: 960px;
  }
  @include mq('lg', 'min') {
    max-width: 1140px;
  }
}

button {
  user-select: none;
  &:focus {
    outline: 0;
  }
}