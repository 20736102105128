.rw__datatable {
    border-radius: rem(4);
    min-height: 0;
    max-height: 100%;
    @extend .border-1;
    p-table {
        flex: 1;
        height: 100%;
        min-height: 0;
    }
    .p-datatable {
        display: flex;
        flex-direction: column;
        height: 100%;
        .minw-col {
            min-width: rem(172);
        }
        &-wrapper {
            @extend .rounded-4;
        }
        &-header {
            background-color: white;
            padding: rem(7) rem(16);
            @extend .border-top-0;
            @extend .border-bottom-1;
            @extend .rounded-top-4;
        }
        &-thead {
            tr {
                border-bottom: rem(1) solid variableValues($gray, "20");
                th {
                    background-color: variableValues($gray, "10") !important;
                    border: 0;
                    font-size: rem(14);
                    padding: rem(12) !important;
                    width: rem(172);
                    line-height: rem(20);
                    vertical-align: middle;
                    @extend .fw-500;
                    &:first-child {
                        border-top-left-radius: rem(4);
                    }
                    &:last-child {
                        border-top-right-radius: rem(4);
                    }
                }
            }
        }
        &-tbody {
            tr {
                vertical-align: middle;
                border-bottom: rem(1) solid variableValues($gray, "20");
                td {
                    border: 0;
                    font-size: rem(14);
                    min-height: rem(54);
                    padding: rem(12);
                    line-height: normal;
                    width: rem(172);
                    &.rw__clickable-column {
                        cursor: pointer;
                        &:hover {
                            background-color: variableValues($gray, "10");
                        }
                    }
                    @for $i from 2 through 10 {
                        $v: ($i - 1) * 30;
                        &.rw__td-indent-#{$i} {
                            padding-inline-start: rem($v) !important; 
                        }
                    }
                }
                &.bg-white {
                    background-color: white !important;
                }
                &:last-child {
                    border-bottom: 0;
                    td {
                        border-bottom-width: 0;
                        &:first-child {
                            border-bottom-left-radius: rem(4);
                        }
                        &:last-child {
                            border-bottom-right-radius: rem(4);
                        }
                    }
                }
            }
        }
        .rightFrozenColumn,
        .p-frozen-column[ng-reflect-align-frozen="right"] {
            right: 0 !important;
            margin-left: auto;
        }
        .p-frozen-column {
            &.leftFrozenColumn,
            &[ng-reflect-align-frozen="left"] {
                border-right: rem(1) solid variableValues($gray, "20");
                left: 0 !important;
            }
        }
        .sticky-col {
            background-color: white;
            position: sticky;
            right: 0;
            &:before {
                border-left: rem(1) solid variableValues($gray, "20");
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
            }
        }
        &-hoverable-rows {
            tr {
                &:hover {
                    td {
                        background-color: variableValues($gray, "10");
                        cursor: pointer;
                    }
                }
            }
        }
    }
    &-head {
        &-white {
            .p-datatable-thead tr th {
                background-color: white !important;
            }
        }
    }
    &-dot {
        background-color: variableValues($blue, "60");
        top: rem(3);
        left: rem(-19);
        height: rem(8);
        width: rem(8);
    }
    &-paginator {
        @extend .border-top-1;
        background-color: white;
        min-height: rem(56);
        @extend .rounded-bottom-4;
        .p-paginator {
            @extend .rw__p-0;
            flex-wrap: nowrap;
            &-element {
                @extend .rounded-circle;
                @extend .rw__m-0;
                @extend .rw__ms-4;
                height: rem(20) !important;
                min-width: rem(20) !important;
                .p-paginator-icon {
                    @extend .text-primary;
                    @extend .rw__fs-10;
                    &.pi-angle-double-left,
                    &.pi-angle-double-right {
                        @extend .rw__fs-11;
                    }
                }
            }
            &-pages {
                .p-paginator-page {
                    @extend .rw__fs-10;
                    @extend .rounded-circle;
                    &.p-highlight {
                        background-color: variableValues($teal, "60");
                        color: white !important;
                    }
                }
            }
        }
    }
    &-internal-paginator {
        .p-paginator {
            background-color: white;
            border-top: rem(1) solid variableValues($gray, "20");
            border-bottom: 0;
            min-height: rem(56);
            padding: rem(16);
            @extend .rounded-bottom-4;
            .p-paginator-current {
                @extend .text-grey-80;
                @extend .rw__fs-10;
                @extend .h-auto;
                @extend .rw__m-0;
                @extend .rw__p-0;
            }
            .p-link {
                @extend .rounded-circle;
                @extend .rw__fs-10;
                @extend .text-grey-70;
                height: rem(20) !important;
                min-width: rem(20) !important;
                margin: 0 0 0 rem(12) !important;
                .p-paginator-icon {
                    @extend .text-grey-80;
                    @extend .rw__fs-10;
                    &.pi-angle-double-left,
                    &.pi-angle-double-right {
                        @extend .rw__fs-11;
                    }
                }
                &.p-highlight {
                    background-color: variableValues($teal, "60");
                    color: white !important;
                }
            }
            .p-dropdown {
                @extend .text-grey-70;
                border-color: variableValues($gray, "20");
                height: rem(24);
                margin: 0 0 0 rem(16);
                &-label {
                    @extend .d-flex;
                    @extend .align-items-center;
                    @extend .rw__fs-10;
                    padding: 0 0 0 rem(7);
                }
                &-trigger {
                    padding: 0 rem(7);
                    width: auto;
                    &-icon {
                        font-size: rem(10);
                    }
                }
            }
        }
    }
    &-custom-paginator {
        .p-paginator {
            background-color: white;
            border-top: rem(1) solid variableValues($gray, "20");
            border-bottom: 0;
            min-height: rem(56);
            padding: rem(14) rem(30);
            @extend .rounded-bottom-4;
            .p-paginator-current {
                @extend .text-grey-80;
                @extend .rw__fs-9;
                @extend .fw-400;
                @extend .h-auto;
                @extend .rw__m-0;
                @extend .rw__p-0;
                order: 2;
                line-height: rem(20);
            }
            .p-link {
                @extend .rounded-circle;
                @extend .text-grey-60;
                @extend .rw__fs-9;
                @extend .fw-400;
                height: rem(36) !important;
                min-width: rem(40) !important;
                margin: 0 !important;
                line-height: rem(20);
                .p-paginator-icon {
                    @extend .text-grey-60;
                    @extend .rw__fs-7;
                    &.pi-angle-double-left,
                    &.pi-angle-double-right {
                        @extend .rw__fs-7;
                    }
                }
                &.p-highlight {
                    color: variableValues($teal, "60");
                    background-color: variableValues($teal, "10") !important;
                }
                &.p-disabled{
                    .p-paginator-icon {
                        @extend .text-grey-40;
                    }
                }
            }
            .p-dropdown {
                @extend .text-grey-70;
                border-color: variableValues($gray, "20");
                height: rem(36);
                margin: 0;
                min-width: rem(80);
                @extend .rw__mx-4;
                &-label {
                    @extend .d-flex;
                    @extend .align-items-center;
                    @extend .rw__fs-9;
                    padding: 0 0 0 rem(7);
                }
                &-trigger {
                    padding: 0 rem(7);
                    width: auto;
                    &-icon {
                        @extend .rw__fs-7;
                    }
                }
            }
        }
    }
    &-caption-grey {
        .p-datatable-header {
            background-color: variableValues($gray, "10") !important;
        }
    }
    &-compact {
        .p-datatable-tbody {
            tr {
                td {
                    padding-top: rem(6) !important;
                    padding-bottom: rem(6) !important;
                    min-height: rem(35);
                }
            }
        }
    }
    &-sticky-head {
        thead {
            th {
                position: sticky;
                top: 0;
                z-index: 1;
            }
        }
    }
    &-no-border {
        border-top: 0 !important;
        border-left: 0 !important;
        border-right: 0 !important;
    }
}

.rw__datatable-custom-filters {
    .p-listbox{
        border: 0;
        &-header {
            padding: rem(16) rem(4);
        }
        &-item {
            padding: rem(10) rem(4);
        }
    }
}

.rw__datatable-cost-tax-filter {
    .p-dropdown {
        background: transparent !important;
        border: 0 !important;
        box-shadow: unset !important;
        .p-inputtext {
          padding: 0 !important
        }
        .p-dropdown-trigger {
          display: none !important;
        }
      }
}

.p-column-filter-overlay-menu .p-column-filter-buttonbar {
    padding: rem(4) rem(8);
}

.rtl {
    .rw__datatable {
        .p-sortable-column-icon {
            margin: 0 rem(8) 0 0;
        }
        .sticky-col {
            left: 0;
            right: auto !important;
            &:before {
                border-left: 0 !important;
                border-right: rem(1) solid variableValues($gray, "20");
                left: auto !important;
                right: 0;
            }
        }
        .p-frozen-column {
            &[ng-reflect-align-frozen="right"] {
                @extend .border-end-1;
                border-left-width: 0 !important;
            }
            &[ng-reflect-align-frozen="left"] {
                @extend .border-start-1;
                border-right-width: 0 !important;
                right: 0;
            }
        }
        &-dot {
            right: rem(-19);
            left: auto !important;
        }
        &-custom-paginator{
            .p-paginator {
                &.p-paginator-bottom{
                    justify-content: left;
                }
            }
        }
    }
}

@media print {
    .rw__datatable {
        .p-datatable {
            th {
                border-bottom: 0 !important;
            }
        }
    }
}

.p-column-filter-overlay-menu .p-column-filter-buttonbar {
    padding: rem(4) rem(8);
}

.rtl {
    .rw__datatable {
        .p-sortable-column-icon {
            margin: 0 rem(8) 0 0;
        }
        .sticky-col {
            left: 0 !important;
            right: auto !important;
            &:before {
                border-left: 0 !important;
                border-right: rem(1) solid variableValues($gray, "20");
                left: auto !important;
                right: 0;
            }
        }
        .p-frozen-column {
            &[ng-reflect-align-frozen="right"] {
                @extend .border-end-1;
                border-left-width: 0 !important;
            }
            &[ng-reflect-align-frozen="left"] {
                @extend .border-start-1;
                border-right-width: 0 !important;
                right: 0;
            }
        }
        .leftFrozenColumn {
            border-right: 0 !important;
            border-left: rem(1) solid variableValues($gray, "20");
        }
        .rightFrozenColumn {
            margin-left: 0;
            margin-right: auto;
        }
        &-dot {
            right: rem(-19);
            left: auto !important;
        }
    }
}

.p-datatable {
    &-thead {
        & > tr {
            th {
                p-sorticon .p-icon-wrapper {
                    display: inline;
                }
            }
        }
    }
    &-scrollable {
        .p-datatable-thead, 
        .p-datatable-tbody {
            & > tr {
                display: flex;
                flex-wrap: nowrap;
                width: 100%;
                th,
                & > td {
                    display: flex;
                    flex: 1 1 0;
                    align-items: center;
                }
            }
        }
    }
    &-gridlines {
        .p-datatable-thead, 
        .p-datatable-tbody {
            & > tr {
                & > th,
                & > td {
                    border-width: rem(1) !important;
                }
            }
        }
    }
    &-loading-icon {
        height: rem(32);
        width: rem(32);
    }
}

p-table[scrolldirection='both'] {
    .p-datatable-scrollable {
        .p-datatable-thead, 
        .p-datatable-tbody {
            & > tr {
                th,
                & > td {
                    flex: 0 0 auto;
                }
            }
        }
    }
}

@media print {
    .rw__datatable {
        .p-datatable {
            th {
                border-bottom: 0 !important;
            }
        }
    }
}
