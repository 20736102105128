// Flex
$flex: (
    "flex" "-row" "flex-direction" "row",
    "flex" "-row-reverse" "flex-direction" "row-reverse",
    "flex" "-column" "flex-direction" "column",
    "flex" "-column-reverse" "flex-direction" "column-reverse",
    "flex-wrap" "" "flex-wrap" "wrap",
    "flex-fill" "" "flex" "1 1 auto",
    "flex-1" "" "flex" "1",
    "flex-grow" "-0" "flex-grow" "0",
    "flex-grow" "-1" "flex-grow" "1",
    "flex-shrink" "-0" "flex-shrink" "0",
    "align-items" "-center" "align-items" "center",
    "align-items" "-start" "align-items" "flex-start",
    "align-items" "-end" "align-items" "flex-end",
    "justify-content" "-center" "justify-content" "center",
    "justify-content" "-start" "justify-content" "flex-start",
    "justify-content" "-end" "justify-content" "flex-end",
    "justify-content" "-between" "justify-content" "space-between",
    "justify-content" "-around" "justify-content" "space-around",
    "justify-content" "-evenly" "justify-content" "space-evenly",
);

// Generate Flex Classes
@each $name, $lastName, $title, $value in $flex {
    .#{$name}#{$lastName} {
        #{$title}: #{$value};
    }
    @include mq('lg', 'min') {
        .#{$name}-lg#{$lastName} {
            #{$title}: #{$value} !important;
        }
    }
    @include mq('md', 'min') {
        .#{$name}-md#{$lastName} {
            #{$title}: #{$value} !important;
        }
    }
    @include mq('sm', 'min') {
        .#{$name}-sm#{$lastName} {
            #{$title}: #{$value} !important;
        }
    }
}
