// Generate Font Sizes Classes
@each $name, $value in $sizes {
    .rw__fs-#{$name} {
      font-size: rem($value) !important;
    }
}

// Generate Font Weight Class
@each $name, $value in $weights {
    .fw-#{$name} {
      font-weight: $value !important;
    }
}

// Headings
$headings: (
    "h1" variableValues($sizes, "1") variableValues($weights, "500") 0 52,
    "h2" variableValues($sizes, "3") variableValues($weights, "500") 0 40,
    "h3" variableValues($sizes, "5") variableValues($weights, "500") 0 32,
    "h4" variableValues($sizes, "6") variableValues($weights, "500") 0 28,
    "h5" variableValues($sizes, "8") variableValues($weights, "500") 0 24,
    "h6" variableValues($sizes, "9") variableValues($weights, "500") 0 20,
);

// Generate Headings Class
@each $name, $size, $weight, $letterSpacing, $lineHeight in $headings {
    .rw__#{$name} {
      font-size: rem($size);
      font-weight: $weight;
      letter-spacing: rem($letterSpacing);
      line-height: rem($lineHeight);
      margin: 0;
    }
}

// Text Align 
$textAlignments: (
    "text-start" "text-align" "left",
    "text-center" "text-align" "center",
    "text-end" "text-align" "right",
);

// Generate Text Alignment Class
@each $name, $title, $value in $textAlignments {
    #{$name} {
      #{$title}: #{$value};
    }
}
