// Position
$position: (
    "static": "static",
    "absolute": "absolute",
    "relative": "relative",
    "fixed": "fixed",
    "sticky": "sticky",
);

// Arrange Positions
$arrangePositions: (
    "top" "top" "0",
    "left" "start" "0",
    "bottom" "bottom" "0",
    "right" "end" "0",
);

// Generate Positions Classes
@each $name, $value in $position {
    .position-#{$name} {
        position: #{$value} !important;
    }
}

// Generate Arrange Positions Classes
@each $name, $title, $value in $arrangePositions {
    .#{$title}-#{$value}{
        #{$name}: #{$value} !important;
    }
}