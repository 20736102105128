.checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 0px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}


input[type=checkbox] {
  position: inherit !important;
  opacity: 1 !important;
  pointer-events: auto !important;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  background-color: #FFFFFF;
  vertical-align: middle;
  border: 1px solid #a4a4a4;
  border-radius: 4px;
}

/* On mouse-over, add a grey background color */
//.checkbox:hover input ~ .checkmark {
//  background-color: #ccc;
//}

/* When the checkbox is checked, add a blue background */
//.checkbox input:checked ~ .checkmark {
//  background-color: #00AAA8;
//}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox .checkmark:after {

  left: 6px;
  top: 0px;
  width: 8px;
  height: 17px;
  border: solid black;
  border-width: 0 4px 4px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

input[type=checkbox].hidden {
  opacity: 0 !important;
}

input[type=checkbox].disabled {
  pointer-events: none !important;
}
